import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

const Footer = () => {
  return (
    <div className="building-footer">
      <div className="building-footer-wrapper">
        <div className="building-footer-title">
          The Campus Energy
          <br />
          Education Dashboard
        </div>
        <div className="building-footer-links">
          <div className="building-footer-column">
            <Link to="/" className="building-footer-column-row">
              Back to Campus Map
            </Link>
            <a
              href="https://eco.ucdavis.edu/ceed"
              className="building-footer-column-row"
              target="_blank"
              rel="noopener noreferrer"
            >
              About the dashboard
            </a>
            <a
              href="https://eco.ucdavis.edu/"
              className="building-footer-column-row"
              target="_blank"
              rel="noopener noreferrer"
            >
              About our office
            </a>
          </div>
          <div className="building-footer-column">
            <a
              href="https://thermoostat.ucdavis.edu"
              target="_blank"
              rel="noopener noreferrer"
              className="building-footer-column-row"
            >
              <b>TherMOOstat</b>
            </a>
            <Link to="/ewc">Energy and Water Challenge</Link>
            <a
              href="http://www.trimthewaste.ucdavis.edu/"
              target="_blank"
              rel="noopener noreferrer"
              className="building-footer-column-row"
            >
              <b>Trim the Waste</b>
            </a>
          </div>
          <div className="building-footer-column">
            <div>
              Follow Joules the Cow:&nbsp;
              <a
                href="https://twitter.com/ucdavisjoules"
                target="_blank"
                rel="noopener noreferrer"
                className="building-footer-column-row"
              >
                <b>@UCDavisJoules</b>
              </a>
            </div>
            <div>
              Contact Us:&nbsp;
              <a
                href="mailto:energyfeedback@ucdavis.edu"
                target="_blank"
                rel="noopener noreferrer"
                className="building-footer-column-row"
              >
                <b>energyfeedback@ucdavis.edu</b>
              </a>
            </div>
            <div>
              Stories and news at:&nbsp;
              <a
                href="https://eco.ucdavis.edu/blog"
                target="_blank"
                rel="noopener noreferrer"
                className="building-footer-column-row"
              >
                <b>eco.ucdavis.edu/blog</b>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
