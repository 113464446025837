import React from "react";
import { waterDescriptions } from "../../../common/config";

export default function WaterStory() {
  let column1 = waterDescriptions.column1.map((data, index) => (
    <div className="water-story-box" key={index}>
      <div>
        {data.title}
        <p>{data.description}</p>
      </div>
    </div>
  ));
  let column2 = waterDescriptions.column2.map((data, index) => (
    <div
      className={"water-story-box"}
      key={data.title + index}
    >
      <div>
        {data.title}
        <p>{data.description}</p>
      </div>
    </div>
  ));
  return (
    <div className="water-story">
      <div className="water-story-column1">{column1}</div>
      <div className="water-story-column2">{column2}</div>
    </div>
  );
};
