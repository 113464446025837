import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as campusActions from "../../actions/campusActions";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { breakPoints } from "../common/config";

import Header from "./header/Header";
import MobileHeader from "./header/mobile/MobileHeader";
import EnergySources from "./energySources/EnergySources";
import Navigation from "./navigation/Navigation";
import MobileNavigation from "./navigation/mobile/MobileNavigation";
import Metrics from "./metrics/Metrics";
import MobileMetrics from "./mobileMetrics/MobileMetrics";
import Usage from "./usage/Usage";
import Footer from "../building/desktop/footer/Footer";
import "./Campus.css";

// sub-components
import CampusDemand from "./demand/CampusDemand";
const SetNavigationView = props => {
  switch (props.view) {
    case "demand":
      return <CampusDemand campus={props.campus} />;
    case "usage":
      return <Usage usageData={props.campus.campusUsage} />;
    case "metrics":
      return <Metrics usageData={props.campus.campusUsage} />;
    default:
      return null;
  }
};
const SetMobileNavigationView = props => {
  switch (props.view) {
    case "demand":
      return <EnergySources campus={props.campus} />;
    case "metrics":
      return <MobileMetrics usageData={props.campus.campusUsage} />;
    default:
      return null;
  }
};

class Campus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campus: {
        campusDemandDaily: [],
        campusDemandMonthly: [],
        campusDemandWeekly: [],
        campusUsage: []
      },
      currentNavigation: "demand"
    };
    this.updateNavigation = this.updateNavigation.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.campus) !== JSON.stringify(this.state.campus))
      this.setState({ campus: this.props.campus });
  }

  componentDidMount() {
    this.props.actions.loadCampusData();
  }

  updateNavigation(event) {
    let newValue = event.target.id;
    this.setState({
      currentNavigation: newValue
    });
  }

  render() {
    return (
      <React.Fragment>
        <MediaQuery maxWidth={breakPoints.mobile}>
          <div className="campus">
            <MobileHeader />
            <MobileNavigation
              selection={this.state.currentNavigation}
              onClick={this.updateNavigation}
            />
            <div className="campus-content">
              <SetMobileNavigationView
                campus={this.state.campus}
                view={this.state.currentNavigation}
              />
            </div>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={breakPoints.tablet}>
          <div className="campus">
            <Header />
            <Navigation
              selection={this.state.currentNavigation}
              onClick={this.updateNavigation}
            />
            <div className="campus-content">
              <SetNavigationView
                campus={this.state.campus}
                view={this.state.currentNavigation}
              />
            </div>
            <Footer />
          </div>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

Campus.propTypes = {
  campus: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    campus: state.campus
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(campusActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Campus);
