import * as types from "./actionTypes";

export function sidebarMenuClick(sidebar) {
  return {
    type: types.SIDEBAR_MENU_CLICK,
    sidebar
  };
}

export const openAboutSidebar = () => {
  let sidebarStatus = {
    about: true,
    apps: false
  };
  return function(dispatch) {
    dispatch(sidebarMenuClick(sidebarStatus));
  };
};

export const openAppsSidebar = () => {
  let sidebarStatus = {
    about: false,
    apps: true
  };
  return function(dispatch) {
    dispatch(sidebarMenuClick(sidebarStatus));
  };
};

export const closeSidebars = () => {
  let sidebarStatus = {
    about: false,
    apps: false
  };
  return function(dispatch) {
    dispatch(sidebarMenuClick(sidebarStatus));
  };
};
