export const chcpCommodityTypes = {
  boiler1: {
    displayName: "Boiler 1 - Steam"
  },
  boiler2: {
    displayName: "Boiler 2 - Steam"
  },
  boiler3: {
    displayName: "Boiler 3 - Steam"
  },
  boiler4: {
    displayName: "Boiler 4 - Steam"
  },
  steamTotal: {
    displayName: "Boiler Total - Steam"
  },
  chiller1: {
    displayName: "Central Heating & Cooling Plant - Chilled Water"
  },
  chiller2: {
    displayName: "Thermal Energy Storage - Chilled Water"
  },
  chilledWaterTotal: {
    displayName: "Chilled Total - Chilled Water"
  },
  boiler1Usage: {
    displayName: "Boiler 1 - Natural Gas"
  },
  boiler2Usage: {
    displayName: "Boiler 2 - Natural Gas"
  },
  boiler3Usage: {
    displayName: "Boiler 3 - Natural Gas"
  },
  boiler4Usage: {
    displayName: "Boiler 4 - Natural Gas"
  },
  gasTotal: {
    displayName: "Boiler Total - Natural Gas"
  },
  chiller1Usage: {
    displayName: "Central Heating & Cooling Plant - Electricity"
  },
  chiller2Usage: {
    displayName: "Thermal Energy Storage 1 - Electricity"
  },
  electricityTotal: {
    displayName: "Chilled Total - Electricity"
  },
  boiler1Metrics: {
    displayName: "Boiler 1"
  },
  boiler2Metrics: {
    displayName: "Boiler 2"
  },
  boiler3Metrics: {
    displayName: "Boiler 3"
  },
  boiler4Metrics: {
    displayName: "Boiler 4"
  },
  totalEfficiency: {
    displayName: "Total Efficiency"
  },
  chcpEfficiency: {
    displayName: "Central Heating and Cooling Plant Efficiency"
  },
  tesEfficiency: {
    displayName: "Thermal Energy Storage Efficiency"
  },
  totalChcpEfficiency: {
    displayName: "Total CHCP Efficiency"
  },
  domestic: {
    displayName: "Domestic Water Use"
  },
  recycled: {
    displayName: "Recycled Water Use"
  },
  total: {
    displayName: "Average Campus Delta T"
  },
  tesDelta: {
    displayName: "Thermal Energy Storage"
  },
  chcpDelta: {
    displayName: "Central Heating & Cooling Plant"
  }
};

export const chcpEnergyTypes = {
  chilledWater: {
    displayName: "Chilled Water",
    color: "#13A9C8",
    opacityColor: "rgba(19, 169, 200, 0.7)",
    subcolor: "#ADE1EB",
    unit: "MMBtu/hr",
    description:
      "Chilled water produced at the CHCP is used to cool campus buildings."
  },
  electricity: {
    displayName: "Electricity",
    color: "#98BF47",
    opacityColor: "rgba(152, 191, 71, 0.7)",
    subcolor: "#C5DA98",
    unit: "MMBtu/hr",
    description:
      "The Chillers at the CHCP use electricity to make chilled water, which is used to cool campus buildings."
  },
  gas: {
    displayName: "Natural Gas",
    color: "#413A6A",
    opacityColor: "rgba(65, 58, 106, 0.7)",
    subcolor: "#A9A7B9",
    unit: "MMBtu/hr",
    description:
      "The CHCP has boilers that use natural gas to make steam, which is used to heat our buildings."
  },
  steam: {
    displayName: "Steam",
    color: "#F49221",
    opacityColor: "rgba(244, 146, 33, 0.7)",
    subcolor: "#FAC990",
    unit: "MMBtu/hr",
    description: "Steam produced at the CHCP is used to heat campus buildings."
  },
  boiler1Metrics: {
    displayName: "Boiler 1",
    color: "#F49221",
    opacityColor: "rgba(244, 146, 33, 0.7)",
    subcolor: "#FAC990",
    unit: "%"
  },
  boiler2Metrics: {
    displayName: "Boiler 2",
    color: "#F49221",
    opacityColor: "rgba(244, 146, 33, 0.7)",
    subcolor: "#FAC990",
    unit: "%"
  },
  boiler3Metrics: {
    displayName: "Boiler 3",
    color: "#F49221",
    opacityColor: "rgba(244, 146, 33, 0.7)",
    subcolor: "#FAC990",
    unit: "%",
    description: "Steam produced at the CHCP is used to heat campus buildings."
  },
  boiler4Metrics: {
    displayName: "Boiler 4",
    color: "#F49221",
    opacityColor: "rgba(244, 146, 33, 0.7)",
    subcolor: "#FAC990",
    unit: "%"
  },
  totalEfficiency: {
    displayName: "Total Efficiency",
    color: "#F49221",
    opacityColor: "rgba(244, 146, 33, 0.7)",
    subcolor: "#FAC990",
    unit: "%"
  },
  chcpEfficiency: {
    displayName: "Central Heating and Cooling Plant",
    color: "#13A9C8",
    opacityColor: "rgba(19, 169, 200, 0.7)",
    subcolor: "#ADE1EB",
    unit: "kW/ton"
  },
  tesEfficiency: {
    displayName: "Thermal Energy Storage",
    color: "#13A9C8",
    opacityColor: "rgba(19, 169, 200, 0.7)",
    subcolor: "#ADE1EB",
    unit: "kW/ton"
  },
  totalChcpEfficiency: {
    displayName: "Total Efficiency",
    color: "#13A9C8",
    opacityColor: "rgba(19, 169, 200, 0.7)",
    subcolor: "#ADE1EB",
    unit: "kW/ton"
  },
  domestic: {
    displayName: "Domestic Water Use",
    color: "#52BFD5",
    opacityColor: "rgba(82, 191, 213, 0.7)",
    hoverColor: "#52BFD5",
    subcolor: "rgba(82, 191, 213, 0.7)",
    unit: "gal"
  },
  recycled: {
    displayName: "Recycled Water Use",
    color: "#ACE1EC",
    opacityColor: "rgba(172, 225, 236, 0.7)",
    hoverColor: "#ACE1EC",
    subcolor: "rgba(172, 225, 236, 0.7)",
    unit: "gal"
  },
  total: {
    displayName: "Average Campus Delta T",
    color: "#13A9C8",
    opacityColor: "rgba(19, 169, 200, 0.7)",
    subcolor: "#ADE1EB",
    unit: "° F"
  },
  tesDelta: {
    displayName: "Thermal Energy Storage",
    color: "#13A9C8",
    opacityColor: "rgba(19, 169, 200, 0.7)",
    subcolor: "#ADE1EB",
    unit: "° F"
  },
  chcpDelta: {
    displayName: "Central Heating & Cooling Plant",
    color: "#13A9C8",
    opacityColor: "rgba(19, 169, 200, 0.7)",
    subcolor: "#ADE1EB",
    unit: "° F"
  }
};

export const chcpDisplayNames = dataType => {
  if (dataType === "chilledWater")
    return {
      chiller1: "Central Heating & Cooling Plant",
      chiller2: "Thermal Energy Storage",
      total: "Total Chilled Water"
    };
  else if (dataType === "steam")
    return {
      boiler1: "Boiler 1",
      boiler2: "Boiler 2",
      boiler3: "Boiler 3",
      boiler4: "Boiler 4",
      total: "Total Steam"
    };
  else if (dataType === "electricity")
    return {
      chiller1Usage: "Central Heating & Cooling Plant",
      chiller2Usage: "Thermal Energy Storage",
      total: "Total Electricity"
    };
  else if (dataType === "gas")
    return {
      boiler1Usage: "Boiler 1",
      boiler2Usage: "Boiler 2",
      boiler3Usage: "Boiler 3",
      boiler4Usage: "Boiler 4",
      total: "Total Natural Gas"
    };
};

export const tooltipData = {
  boilerEfficiency:
    "Boiler efficiency is a measure of the useful heat generated by the consumption of an energy source.  A significant change in the efficiency of a boiler could signify a problem.",
  chilledWater:
    "Chilled water produced at the CHCP is used to cool campus buildings.",
  coolingEfficiency:
    "Cooling efficiency is the power (kW) needed to produce one ton of refrigeration (ton). With this efficiency metric, lower values are better.",
  deltaT:
    "Chilled water is supplied from the chiller plants to campus buildings via the chilled water distribution network. The temperature difference between the chilled water supply and return is a measure of how effectively the chilled water is being utilized by the campus.",
  electricity:
    "The Chillers at the CHCP use electricity to make chilled water, which is used to cool campus buildings.",
  gas:
    "The CHCP has boilers that use natural gas to make steam, which is used to heat our buildings.",
  steam: "Steam produced at the CHCP is used to heat campus buildings.",
  tower:
    "The amount of water added to the cooling tower to achieve a given quantity of cooling is a useful metric for the CHCP and is a measure of the water use of the chiller plants."
};
