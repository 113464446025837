import React from "react";

import aceIcon from "../images/aceIcon.svg";
import classroomIcon from "../images/classroomIcon.svg";
import greenIcon from "../images/greenIcon.svg";
import labIcon from "../images/labIcon.svg";
import aceActiveIcon from "../images/aceActiveIcon.svg";
import classroomActiveIcon from "../images/classroomActiveIcon.svg";
import greenActiveIcon from "../images/greenActiveIcon.svg";
import labActiveIcon from "../images/labActiveIcon.svg";

const filtering = {
  ace: {
    displayName: "ACE Energy Projects",
    icon: aceIcon,
    selectedIcon: aceActiveIcon
  },
  classroom: {
    displayName: "Classrooms",
    icon: classroomIcon,
    selectedIcon: classroomActiveIcon
  },
  green: {
    displayName: "Green Buildings",
    icon: greenIcon,
    selectedIcon: greenActiveIcon
  },
  lab: {
    displayName: "Lab Buildings",
    icon: labIcon,
    selectedIcon: labActiveIcon
  }
};
const FILTERS = ["lab", "ace", "green", "classroom"];

const BuildingFilter = ({ setFilter, selected }) => {
  return (
    <div className="ceed-filters-wrapper">
      <div className="ceed-filters-title">Filter by:</div>
      <div className="ceed-filters">
        {FILTERS.map((filter, index) => {
          return (
            <div
              className={
                "ceed-filters-button" + (filter === selected ? " active" : "")
              }
              key={filter + " " + index}
              onClick={setFilter}
              id={filter}
            >
              <div className="ceed-filters-close" id={filter}>
                &times;
              </div>
              <div className="ceed-filters-icon" id={filter}>
                <img
                  src={
                    filter === selected
                      ? filtering[filter].selectedIcon
                      : filtering[filter].icon
                  }
                  alt={filtering[filter].displayName + " icon"}
                  id={filter}
                />
              </div>
              <div className="ceed-filters-name" id={filter}>
                {filtering[filter].displayName}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BuildingFilter;
