/**
 * Stateless functional component for displaying a single row of
 * search results from SearchBox.js.
 */

import React from "react";
import { Link } from "react-router-dom";

// Sets the appropriate className based on if the building is
// in the CEED colllection, no results, or non-metered.
const formatBuildingName = buildingObject => {
  if (
    buildingObject.buildingData !== undefined &&
    buildingObject.buildingData.length !== 0
  )
    return (
      <div className="mobile-search-row-building">
        {truncateString(buildingObject.primaryDisplay)}
      </div>
    );
  else
    return (
      <div className="mobile-search-row-building no-results">
        {truncateString(buildingObject.primaryDisplay)}
      </div>
    );
};

// Utility function for reducing the length of long building names.
const truncateString = displayString => {
  const MAX_LENGTH = 34;
  if (displayString !== undefined && displayString.length > MAX_LENGTH)
    return displayString.slice(0, MAX_LENGTH - 1) + "...";
  return displayString;
};

const SearchBoxRow = ({ building }) => {
  if (building.buildingData)
    return (
      <Link
        to={"/building/" + building.buildingData[0].id}
        className="mobile-search-row"
        tabIndex={0}
      >
        {formatBuildingName(building)}
      </Link>
    );
  else
    return (
      <div className="mobile-search-row">{formatBuildingName(building)}</div>
    );
};

export default SearchBoxRow;
