/**
 * This component sets up the header portion of the Compare Chart Grid area.
 */

import React from "react";
import PropTypes from "prop-types";

import sortArrow from "../images/sort-icon.svg";

const normalizedVerbiage = (commodity, normalized) => {
  if (normalized) return "EUI";
  switch (commodity) {
    case "electricity":
      return "Usage (kWh)";
    case "steam":
      return "Usage (Lbs)";
    case "chilledWater":
      return "Usage (TonHr)";
    default:
      return "EUI";
  }
};

const CompareGridHeader = ({ onClick, normalized }) => {
  return (
    <div className="building-comparison-grid-header">
      <div
        className="building-comparison-grid-header-row col1"
        onClick={onClick}
        id="name"
      >
        <img src={sortArrow} alt="Sort icon" id="name" />
        <div className="grid-column-text" id="name">
          Building
        </div>
      </div>
      <div
        className="building-comparison-grid-header-row col2"
        onClick={onClick}
        id="eui.total"
      >
        <img src={sortArrow} alt="Sort icon" id="eui.total" />
        <div className="grid-column-text" id="eui.total">
          Total EUI
        </div>
      </div>
      <div
        className="building-comparison-grid-header-row col3"
        onClick={onClick}
        id="eui.electricity"
      >
        <img src={sortArrow} alt="Sort icon" id="eui.electricity" />
        <div className="grid-column-text" id="eui.electricity">
          Electricity
          <br />
          {normalizedVerbiage("electricity", normalized)}
        </div>
      </div>
      <div
        className="building-comparison-grid-header-row col4"
        onClick={onClick}
        id="eui.steam"
      >
        <img src={sortArrow} alt="Sort icon" id="eui.steam" />
        <div className="grid-column-text" id="eui.steam">
          Steam
          <br />
          {normalizedVerbiage("steam", normalized)}
        </div>
      </div>
      <div
        className="building-comparison-grid-header-row col5"
        onClick={onClick}
        id="eui.chilledWater"
      >
        <img src={sortArrow} alt="Sort icon" id="eui.chilledWater" />
        <div className="grid-column-text" id="eui.chilledWater">
          Chilled Water {normalizedVerbiage("chilledWater", normalized)}
        </div>
      </div>
      <div
        className="building-comparison-grid-header-row col6"
        onClick={onClick}
        id="annualCost"
      >
        <img src={sortArrow} alt="Sort icon" id="annualCost" />
        <div className="grid-column-text" id="annualCost">
          Annual Cost
        </div>
      </div>
    </div>
  );
};

CompareGridHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  normalized: PropTypes.bool.isRequired
};

export default CompareGridHeader;
