import React from "react"

import styles from "./MainContent.module.css"

import Demand from "./demand/Demand"

export default function MainContent(props) {
  return (
    <main className={styles.container}>
      <h1>Electricity & Solar Data</h1>
      <div className={styles.info}>
        <h2>
          Electricity Demand
          <br />& Solar Production
        </h2>
        <p>
          When you look at electricity demand in an efficient building, you
          should see <br /> the demand vary with the occupancy of the building
          (the dark line on the graph).
        </p>
      </div>
      <Demand weeklyData={props.demandWeeklyTLC} weeklyOat={props.oatWeekly} />
    </main>
  )
}
