import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function sidebar(state = initialState.sidebar, action) {
  switch (action.type) {
    case types.SIDEBAR_MENU_CLICK:
      return action.sidebar;

    default:
      return state;
  }
}
