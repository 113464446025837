import { buildingTypes } from "../../../../common/config";

const sortByValueType = (arrayOfObjects, valueType) => {
  arrayOfObjects.sort((a, b) => {
    return a[valueType] < b[valueType]
      ? 1
      : b[valueType] < a[valueType]
      ? -1
      : 0;
  });
  return arrayOfObjects;
};

export const findAverageValue = (mapData = [], valueType = "") => {
  let values = [];
  for (let marker of mapData) {
    if (marker[valueType] > 0) {
      values.push(marker[valueType]);
    }
  }
  if (values.length > 0) {
    return values.reduce((a, b) => a + b) / values.length;
  }
  return values;
};

const findNearestValues = (markerClickBuildingData, mapData, valueType) => {
  let sortedData = sortByValueType(mapData, valueType);
  let clickedIndex = sortedData.indexOf(markerClickBuildingData);
  let startIndex;
  //find position of graph for this eui
  if (clickedIndex > 1 && clickedIndex < sortedData.length - 2) {
    // middle
    startIndex = clickedIndex - 2;
  } else if (clickedIndex <= 1 || sortedData.length === 5) {
    // first or second
    startIndex = 0;
  } else if (clickedIndex >= sortedData.length - 2) {
    // second-to-last or last
    startIndex = sortedData.length - 5;
    if (startIndex < 0) startIndex = 0;
  }
  let loopIterations = sortedData.length < 5 ? sortedData.length : 5;

  let nearestValues = [];
  for (let i = 0; i < loopIterations; i++) {
    nearestValues.push(sortedData[startIndex]);
    startIndex++;
  }

  return nearestValues;
};

const formGraphBuildingNames = nearestValuesArray => {
  let buildingNamesArray = [];
  for (let obj of nearestValuesArray) {
    buildingNamesArray.push(obj.name);
  }
  return buildingNamesArray;
};

const formGraphSeriesData = (
  markerClickBuildingData,
  nearestValuesArray,
  valueType
) => {
  let seriesDataArray = [];
  for (let obj of nearestValuesArray) {
    if (obj.id === markerClickBuildingData.id) {
      //push object with value and color
      seriesDataArray.push({
        y: obj[valueType],
        color: buildingTypes[obj.buildingType].color
      });
    } else {
      //push value by itself
      seriesDataArray.push(obj[valueType]);
    }
  }
  return seriesDataArray;
};

export const graphConfigFactory = (
  markerClick,
  filteredMapData,
  avgValue,
  currentMapSelection
) => {
  let nearestValues = findNearestValues(
    markerClick.buildingData,
    filteredMapData,
    currentMapSelection
  );
  let buildingNames = formGraphBuildingNames(nearestValues);
  let seriesData = formGraphSeriesData(
    markerClick.buildingData,
    nearestValues,
    currentMapSelection
  );

  return {
    chart: {
      type: "bar",
      height: 150
    },
    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      categories: buildingNames,
      labels: {
        align: "left",
        style: {
          fontSize: "14px",
          textOverflow: "ellipsis",
          width: "130px"
        },
        y: 4
      },
      offset: 130
    },
    yAxis: {
      title: {
        enabled: false
      },
      plotLines: [
        {
          color: buildingTypes[markerClick.buildingData.buildingType].color,
          value: avgValue,
          width: 1
        }
      ]
    },
    series: [
      {
        color: "#e8e8e8",
        data: seriesData,
        states: {
          hover: {
            enabled: false
          }
        }
      }
    ]
  };
};
