import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function mapDataReducer(state = initialState.mapData, action) {
  switch (action.type) {
    case types.LOAD_MAP_DATA_SUCCESS:
      return action.mapData;
    case types.UPDATE_MAP_DATA_SUCCESS:
      return action.mapData;

    default:
      return state;
  }
}
