import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { buildingProjectTabs } from "../../../common/config";
import headshot from "../images/dan.png";
import { formatDollarValue } from "../../../common/utilities";

const NUMBER_OF_BUILDINGS = 8;

class HvacScheduling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeData: [],
      otherSavings: 0
    };
  }

  componentDidMount() {
    if (this.props.hvac.length > 0) this.setDataPoints();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.hvac.length !== this.props.hvac.length &&
      this.props.hvac.length > 0
    )
      this.setDataPoints();
  }

  setDataPoints() {
    let activeData = [],
      otherSavings = 0;
    for (let i = 0; i < this.props.hvac.length; i++) {
      if (i < NUMBER_OF_BUILDINGS) activeData.push(this.props.hvac[i]);
      else otherSavings += this.props.hvac[i].savings;
    }
    this.setState({
      activeData,
      otherSavings
    });
  }

  render() {
    return (
      <div className="hvac-scheduling standard-card">
        <div className="buildComm-info-wrap">
          <div className="buildComm-description">
            {buildingProjectTabs.hvac.description}
          </div>
          <div className="img-quote-wrap">
            <img src={headshot} alt="dan's headshot" />
            <div className="buildComm-quote dan">
              <i>"{buildingProjectTabs.hvac.quote}"</i>
              <div className="buildComm-name">
                <i>
                  <b>- {buildingProjectTabs.hvac.name}</b>
                </i>
              </div>
            </div>
          </div>
        </div>
        <div className="hvac-table">
          <div className="hvac-table-header">
            <div className="hvac-table-content" style={{ width: "49%" }}>
              Building
            </div>
            <div className="hvac-table-content" style={{ width: "49%" }}>
              Total Savings
            </div>
          </div>
          <div className="hvac-table-data">
            {this.state.activeData.map((item, index) => {
              return (
                <div className="hvac-data-row" key={index}>
                  <div className="hvac-table-content" style={{ width: "49%" }}>
                    {item.buildingName}
                  </div>
                  <div className="hvac-table-content" style={{ width: "49%" }}>
                    {formatDollarValue(item.savings)}
                  </div>
                </div>
              );
            })}
            <div className="hvac-data-row">
              <div className="hvac-table-content" style={{ width: "49%" }}>
                Other Buildings
              </div>
              <div className="hvac-table-content" style={{ width: "49%" }}>
                {formatDollarValue(this.state.otherSavings)}
              </div>
            </div>
            <div className="hvac-table-header">
              <div className="hvac-table-content" style={{ width: "49%" }}>
                Totals
              </div>
              <div className="hvac-table-content" style={{ width: "49%" }}>
                {formatDollarValue(this.props.hvacTotal)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HvacScheduling.propTypes = {
  hvac: PropTypes.array.isRequired,
  hvacTotal: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  return {
    hvac: state.buildingProjects.hvacScheduling,
    hvacTotal: state.buildingProjects.totals.hvacScheduling
  };
};

export default connect(mapStateToProps)(HvacScheduling);
