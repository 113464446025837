/**
 * This component sets up the CSV download button.
 */

import React from "react";
import PropTypes from "prop-types";

import * as utilities from "./utilities";
import { commodityTypes } from "./config";
import { chcpCommodityTypes } from "../chcp/common/config";
import { CSVLink } from "react-csv";
import downloadIcon from "./images/download.png";
import downloadHoverIcon from "./images/download_hover.png";

class CsvButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataReady: false,
      csvData: [],
      img: downloadHoverIcon
    };
  }

  componentWillMount() {
    if (this.props.data.length > 0) this.getCsvData(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.length !== this.props.data.length)
      this.getCsvData(this.props.data);
    else if (
      prevProps.data.length > 0 &&
      this.props.data.length === prevProps.data.length
    ) {
      for (let i = 0; i < prevProps.data.length; i++)
        if (prevProps.data[i].data.length !== this.props.data[i].data.length) {
          this.getCsvData(this.props.data);
          break;
        }
    }
  }

  gatherTimestamps(commodityArray) {
    return new Promise(function(resolve, reject) {
      let timestamps = [];
      if (
        commodityArray.length > 0 &&
        commodityArray[0].commodity !== undefined
      ) {
        for (let i = 0; i < commodityArray.length; i++)
          for (let j = 0; j < commodityArray[i].data.length; j++) {
            let exists = false;
            for (let k = 0; k < timestamps.length; k++)
              if (commodityArray[i].data[j].Timestamp === timestamps[k])
                exists = true;
            if (!exists) timestamps.push(commodityArray[i].data[j].Timestamp);
          }
        resolve(
          timestamps.sort((a, b) => {
            return new Date(b) - new Date(a);
          })
        );
      } else resolve(null);
    });
  }

  // Parses the data into a format ready for the react-csv package.
  async getCsvData(information) {
    let csvData = [],
      // Store the commodity with the most complete amount of data
      completeTimestamps = await this.gatherTimestamps(information);
    if (information.length > 0 && information[0].data.length > 0) {
      for (let i = 0; i < completeTimestamps.length; i++) {
        let dataObject = {};
        dataObject.timestamp = utilities.formatTimestamp(completeTimestamps[i]);
        for (let j = 0; j < information.length; j++)
          for (let k = 0; k < information[j].data.length; k++) {
            if (information[j].data[k].Timestamp === completeTimestamps[i])
              dataObject[information[j].commodity] =
                information[j].data[k].Value;
          }
        csvData.push(dataObject);
      }
    } else
      csvData.push({
        timstamp: null,
        oat: null
      });
    this.setState({
      csvData,
      dataReady: true
    });
  }

  // Creates a filename for downloading building data.
  // Format is 'buildingName_MM_DD_YYYY.csv'.
  getCsvFilename(shortname) {
    let downloadDate = new Date(),
      downloadMonth = downloadDate.getMonth() + 1,
      type = this.props.dataType !== undefined ? this.props.dataType + "_" : "";
    return (
      shortname +
      "_" +
      type +
      downloadMonth +
      "-" +
      downloadDate.getDate() +
      "-" +
      downloadDate.getFullYear() +
      ".csv"
    );
  }

  // Sets up the header row for the CSV based on the
  // commodities available for the selected building.
  getCsvHeaders(commodities) {
    let headers = [{ label: "Date Time", key: "timestamp" }];
    if (commodities.length > 0)
      for (let i = 0; i < commodities.length; i++)
        headers.push({
          label:
            commodityTypes[commodities[i]] !== undefined
              ? commodityTypes[commodities[i]].displayName
              : chcpCommodityTypes[commodities[i]].displayName,
          key: commodities[i]
        });
    return headers;
  }

  render() {
    if (!this.state.dataReady)
      return (
        <div className="building-side-options campus-side-options csv not-ready">
          <div className="building-side-button campus-side-button not-ready">
            Loading CSV
          </div>
        </div>
      );
    else
      return (
        <div className="building-side-options campus-side-options csv">
          <CSVLink
            data={this.state.csvData}
            filename={this.getCsvFilename(this.props.shortname)}
            headers={this.getCsvHeaders(this.props.commodities)}
          >
            <div
              className="building-side-button campus-side-button"
              onMouseOver={() => {
                this.setState({ img: downloadIcon });
              }}
              onMouseOut={() => {
                this.setState({ img: downloadHoverIcon });
              }}
            >
              <img
                className="download-img"
                src={this.state.img}
                alt="download icon"
              />
              Download CSV
            </div>
          </CSVLink>
        </div>
      );
  }
}

CsvButton.propTypes = {
  data: PropTypes.array.isRequired,
  commodities: PropTypes.array.isRequired,
  shortname: PropTypes.string.isRequired,
  dataType: PropTypes.string
};

export default CsvButton;
