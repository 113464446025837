import React, { Component } from "react";
import PropTypes from "prop-types";
import "./SavingsBox.css";
import { numberWithCommas, roundNumber } from "../../../utils/formatting";

class SavingsBox extends Component {
  valueSaved;
  valueSavedAbsolute;
  dotColor;

  componentWillMount = () => {
    let unroundedSavingsValue = this.sumAllSavings();
    let roundedSavingsValue = roundNumber(unroundedSavingsValue, 0);
    let roundedSavingsValueAbsolute = Math.abs(roundedSavingsValue);
    this.valueSaved = numberWithCommas(roundedSavingsValue);
    this.valueSavedAbsolute = numberWithCommas(roundedSavingsValueAbsolute);

    if (this.props.isTotal) {
      this.props.BuildingCommTotal(this.valueSavedAbsolute);
    }

    this.dotColor = this.getDotColor();
  };

  sumAllSavings = () => {
    let counter = 0;
    for (let i = 0; i < this.props.savingsData.length; i++) {
      counter += this.props.savingsData[i];
    }
    return counter;
  };

  getDotColor = () => {
    if (parseInt(this.valueSaved, 10) >= 0) return "green";
    else return "red";
  };

  render() {
    if (this.dotColor === "green") {
      return (
        <div className="savings-wrapper">
          <span>${this.valueSaved}</span>
          <span className="green-dot " />
        </div>
      );
    } else if (this.dotColor === "red") {
      return (
        <div className="savings-wrapper">
          <span className="savings-value">${this.valueSavedAbsolute}</span>
          <span className="red-dot " />
        </div>
      );
    } else {
      return <div>Error</div>;
    }
  }
}

SavingsBox.propTypes = {
  savingsData: PropTypes.array.isRequired
};

export default SavingsBox;
