import React from "react";
import PropTypes from "prop-types";

import arrow from "../../../images/arrow.svg";

//Electricity icons
import energyTip1 from "./images/energyTip1.png";
import energyTip2 from "./images/energyTip2.png";
import naturalLight from "./images/naturalLight.png";
import window from "./images/window.png";
import stairs from "./images/stairs.png";
import laundry from "./images/laundry.png";
import lighting from "./images/lighting.png";
import clothesLine from "./images/clothesLine.png";

//Water icons
import faucet from "./images/faucet.png";
import shower from "./images/shower.png";
import handwash from "./images/handwash.png";
import washingMachine from "./images/washingMachine.png";
import dishes from "./images/dishes.png";
import leaks from "./images/leaks.png";

const energyTips = {
  electricity: [
    {
      tip: "Save energy by using power management features on your computer.",
      icon: energyTip1,
      alt: "Laptop with a battery charging"
    },
    {
      tip: "Use natural light and switch off lights when you leave a room.",
      icon: naturalLight,
      alt: "Window with light coming in"
    },
    {
      tip: "Wash laundry in cold water and do full loads only.",
      icon: laundry,
      alt: "Washing machine with water"
    },
    {
      tip: "Take the stairs instead of the elevator whenever possible.",
      icon: stairs,
      alt: "A set of stairs"
    },
    {
      tip: "Turn off and unplug your electronics when not in use.",
      icon: energyTip2,
      alt: "Monitor with the power off"
    },
    {
      tip:
        "Open a window if the temperature outside if more desirable than the temperature inside and turn off the heating/air conditioning at the same time.",
      icon: window,
      alt: "Open window with thermostat nearby"
    },
    {
      tip: "Use task lighting instead of overhead lighting",
      icon: lighting,
      alt: "A lit office lamp."
    },
    {
      tip:
        "Use a drying rack or a clothes line instead of a dryer whenever possible.",
      icon: clothesLine,
      alt: "Clothes line with clothes drying"
    }
  ],
  water: [
    {
      tip:
        "Turn off the faucet when not using the water (i.e. while brushing your teeth or shaving.)",
      icon: faucet,
      alt: "Faucet turned off"
    },
    {
      tip: "Take efficient showers (Take a two-song shower!)",
      icon: shower,
      alt: "Shower head with a timer"
    },
    {
      tip: "No need to wet hands before lathering up with the foam hand soap.",
      icon: handwash,
      alt: "Hands washing while faucet is off."
    },
    {
      tip: "Use the washing machine for full loads only.",
      icon: washingMachine,
      alt: "Washing machine with full laundry basket beside it"
    },
    {
      tip: "When washing dishes, leave faucet off until ready to rinse dishes.",
      icon: dishes,
      alt: "Dishes being washed with the faucet turned off"
    },
    {
      tip:
        "Report leaks using Student Housing and Dinig Service's online service request.",
      icon: leaks,
      alt: "A faucet with water dripping"
    }
  ]
};

const arrowStyle = {
  padding: "10px",
  alignSelf: "center",
  cursor: "pointer"
};

const halfCardStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "30px 5%",
  background: "white",
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
  textAlign: "center"
};

const headerStyle = {
  fontSize: "16px",
  lineHeight: "19px",
  fontWeight: "600"
};

const tipImageStyle = {
  height: "70px",
  width: "auto",
  padding: "30px"
};

class Tips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      electricity: 0,
      water: 0
    };
    this.changeTip = this.changeTip.bind(this);
  }

  changeTip(event) {
    let direction = event.target.id === "nextTip" ? 1 : -1,
      tipsEnd = energyTips[this.props.commodity].length - 1,
      newValue = this.state[this.props.commodity] + direction;
    if (newValue < 0) newValue = tipsEnd;
    else if (newValue > tipsEnd) newValue = 0;
    this.setState({ [this.props.commodity]: newValue });
  }

  render() {
    return (
      <div className="ewc-building-tips half-card" style={halfCardStyle}>
        <div className="ewc-building-tips-header" style={headerStyle}>
          Tips for Taking Action
        </div>
        <div
          className="ewc-building-tips-slider"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div
            onClick={this.changeTip}
            className="ewc-building-tips-left"
            id="prevTip"
            style={arrowStyle}
          >
            <img
              onClick={this.changeTip}
              src={arrow}
              id="prevTip"
              alt="View the previous tip."
            />
          </div>
          <div className="ewc-building-tips-graphic">
            <img
              src={
                energyTips[this.props.commodity][
                  this.state[this.props.commodity]
                ].icon
              }
              alt={
                energyTips[this.props.commodity][
                  this.state[this.props.commodity]
                ].alt
              }
              style={tipImageStyle}
            />
          </div>
          <div
            onClick={this.changeTip}
            className="ewc-building-tips-right"
            id="nextTip"
            style={arrowStyle}
          >
            <img
              onClick={this.changeTip}
              src={arrow}
              id="nextTip"
              alt="View the next tip."
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
        </div>
        <div className="ewc-building-tips-detail">
          {
            energyTips[this.props.commodity][this.state[this.props.commodity]]
              .tip
          }
        </div>
      </div>
    );
  }
}

Tips.propTypes = {
  commodity: PropTypes.string.isRequired
};

export default Tips;
