export const currentFyFormat = {
  buildingCommissioning: {
    title: "Building Projects",
    color: "#1A98B2"
  },
  opportunitiesLog: {
    title: "Opportunities Log",
    color: "#27C0E6"
  },
  hvacScheduling: {
    title: "HVAC Scheduling",
    color: "#4A90E2"
  },
  holidayShutdowns: {
    title: "Holiday Shutdowns",
    color: "#597D92"
  },
  otherProjects: {
    title: "Other Projects",
    color: "#B1B3B3"
  }
};
