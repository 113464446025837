/**
 * HighCharts utilities that are used to build the demand HighChart.
 * These functions set up the default parameters along with some
 * helper functions for formatting the visual elements of the chart.
 */
import Highcharts from "highcharts";

const MAX_POSSIBLE_VALUES = 3,
  DEFAULT_SECONDARY_Y = false;

// Established the default graph parameters.
export const initializeGraph = () => {
  return {
    chart: {
      type: "spline",
      plotBorderColor: "#F1F1F2",
      plotBorderWidth: 2,
      height: 410
    },
    title: {
      text: null
    },
    xAxis: setXAxis(),
    yAxis: setYAxis(DEFAULT_SECONDARY_Y),
    plotOptions: {
      spline: {
        marker: {
          enabled: false
        }
      },
      series: {
        showInLegend: false,
        states: {
          hover: {
            enabled: true
          }
        },
        animation: {
          duration: 2000,
          easing: "easeOutBounce"
        }
      }
    },
    tooltip: {
      backgroundColor: "#F6F6F6",
      borderColor: "#EEEDEE",
      borderRadius: 3,
      borderWidth: 1.5,
      shadow: false,
      crosshairs: [
        {
          width: 1,
          color: "#222831",
          zIndex: 5
        }
      ],
      style: {
        color: "#2D3138",
        lineHeight: "17px",
        fontSize: "13px"
      },
      headerFormat:
        '<span style="font-size: 14px; color: #2D3138; opacity: 0.5; lineHeight: 17px;">{point.key}</span><table><br/>',
      pointFormat:
        '<span style="background-color:{series.color}; color:{series.color}; font-weight:700; font-size:9px; line-height:18px; vertical-align:top; max-width: 10px; max-height: 10px; border:2px solid white; border-radius: 50%; margin-right:5px; padding-right:3px;">\u25CF </span><span>  {series.name}:</span> {point.y}<br/>',
      valueDecimals: 0,
      shared: true,
      useHTML: true
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: setDefaultSeries(),
    visible: false
  };
};

// Builds out the empty arrays for the chart initialization.
// Outside Air Temperature is considered a commodity in regards to
// data initialization and should be counted.
const setDefaultSeries = () => {
  let defaultSeries = [];
  for (let i = 0; i < MAX_POSSIBLE_VALUES; i++)
    defaultSeries.push({
      data: null
    });

  return defaultSeries;
};

// Sets the X Axis parameters for the Demand Chart. In particular,
// it sets different label types depending on what time interval is selected
export const setXAxis = currentDemandSelection => {
  return [
    {
      scrollbar: {
        showFull: false
      },
      type: "datetime",
      minPadding: 0,
      maxPadding: 0,
      labels: {
        style: {
          color: "#444952",
          fontSize: 13,
          textTransform: "capitalize",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        },
        padding: 0,
        y: 25
      },
      categories: [
        "12am",
        "1am",
        "2am",
        "3am",
        "4am",
        "5am",
        "6am",
        "7am",
        "8am",
        "9am",
        "10am",
        "11am",
        "12pm",
        "1pm",
        "2pm",
        "3pm",
        "4pm",
        "5pm",
        "6pm",
        "7pm",
        "8pm",
        "9pm",
        "10pm",
        "11pm"
      ]
    }
  ];
};

// Sets the Y Axis parameters for the Demand chart. This chart contains
// a left axis[0] (data) and right axis[1] (Outside Air Temperature).
export const setYAxis = secondaryAxisVisibility => {
  return [
    {
      min: 0,
      lineWidth: 2,
      gridLineWidth: 2,
      title: {
        text: "kBtu/hr",
        align: "high",
        rotation: 0,
        offset: 15,
        style: {
          color: "#444952",
          fontSize: 13,
          fontWeight: "bold",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        }
      },
      lineColor: "#F1F1F2",
      gridLineColor: "#F1F1F2",
      minPadding: 0,
      maxPadding: 0,
      opposite: false,
      tickAmount: 6,
      labels: {
        formatter: function() {
          if (this.isLast) {
            return "";
          }
          let label = this.axis.defaultLabelFormatter.call(this);
          if (/^[0-9]{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value, 0);
          }
          return label;
        },
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: 13
        },
        reserveSpace: true
      },
      endOnTick: false
    }
  ];
};
