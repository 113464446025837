import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as rankingActions from "../../../../../actions/rankingActions";
import PropTypes from "prop-types";

import "./FullRankingMenu.css";

class FullRankingMenu extends React.Component {
  render() {
    return (
      <div className="app-menu">
        <div className="menu-title">Building Ranking</div>
        <div
          className="menu-close"
          onClick={this.props.rankingActions.closeFullRankingSidebar}
        >
          <div className="menu-placeholder">&times;</div>
        </div>
      </div>
    );
  }
}

FullRankingMenu.propTypes = {
  rankingActions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    rankingActions: bindActionCreators(rankingActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(FullRankingMenu);
