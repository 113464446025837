export const mapDataQuery = `
	query {
		buildingMarkers:allActiveBuildings{
			id
			name
			buildingType
			savings
			center {
				lat
				long
			}
			certifications {
        name
        type
        url
      }
			euiReady
			euiComingSoon
			energyInfo {
      	commodities
      	unmeteredCommodities
    	}
    	facLinkData {
		  	relatedGross
		  	occupied
				constructed
			}
			primaryUse
			primaryPercent
			secondaryUse
			secondaryPercent
		},
		chilledWaterEuis:allEuisByCommodity(commodity:"chilledWater") {
			commodity
			euis {
				building
				eui
			}
		},
		electricityEuis:allEuisByCommodity(commodity:"electricity") {
			commodity
			euis {
				building
				eui
			}
		},
		gasEuis:allEuisByCommodity(commodity:"gas") {
			commodity
			euis {
				building
				eui
			}
		},
		steamEuis:allEuisByCommodity(commodity:"steam") {
			commodity
			euis {
				building
				eui
			}
		}
}`;
