import React from "react";

const CampusDescription = () => {
  return (
    <div className="campus-header-description">
      <p>
        <b>UC Davis is using less energy today than twenty years ago.</b> This
        reduction occurred concurrent with a period of large campus growth (over{" "}
        <b>2.6M square feet of new building space</b> and{" "}
        <b>6,300 additional students</b>).
      </p>
      <p>
        How does the campus keep energy use low while maintaing this growth? The
        University has been proactive in diversifying energy sources in order to
        meet the campus demand. Explore the campus Demand, Usage, and Metrics
        graphs to see the story of the University's energy reduction over the
        last several decades.
      </p>
    </div>
  );
};

export default CampusDescription;
