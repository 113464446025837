import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as utils from "../../../common/utilities";
import SeasonChart from "./SeasonChart";

class SeasonTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        electricity: [],
        heating: [],
        cooling: []
      },
      totaleui: {
        electricity: 0,
        heating: 0,
        cooling: 0
      },
      percentSaving: {
        electricity: 0,
        heating: 0,
        cooling: 0,
        total: 0
      },
      yMax: 0
    };
  }

  componentWillMount() {
    if (this.props.building.id !== undefined && this.props.building.id !== "") {
      this.props.buildingActions.loadDesktopEnergyData(this.props.building);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.building.id !== this.props.building.id &&
      this.props.building.id !== null
    ) {
      this.props.buildingActions.loadDesktopEnergyData(this.props.building);
    }

    let desktopEnergy = this.props.desktopEnergyData.monthlyUsage;

    if (prevProps.desktopEnergyData.monthlyUsage !== desktopEnergy) {
      let data = this.props.desktopEnergyData.monthlyUsage;
      let electricity = [];
      let heating = [];
      let cooling = [];
      let yMax = 0;
      let findyMax = [];
      let percentSaving_e = 0;
      let percentSaving_h = 0;
      let percentSaving_c = 0;

      for (let i = 0; i < data.length; i++) {
        //separate each commodity to different array
        let commodity = data[i]["commodity"];
        if (commodity === "electricity") {
          electricity.push(data[i]["data"]);
        } else if (commodity === "chilledWater") {
          cooling.push(data[i]["data"]);
        } else {
          //steam
          heating.push(data[i]["data"]);
        }
      }

      [electricity, percentSaving_e] = this.getCommodityData(
        electricity,
        "electricity"
      );
      [heating, percentSaving_h] = this.getCommodityData(heating, "steam");
      [cooling, percentSaving_c] = this.getCommodityData(
        cooling,
        "chilledWater"
      );

      let totalElectricity = Math.round(this.totaleui(electricity));
      let totalHeating = Math.round(this.totaleui(heating));
      let totalcooling = Math.round(this.totaleui(cooling));

      let totalEUI = totalElectricity + totalHeating + totalcooling;

      findyMax.push(Math.max(...electricity));
      findyMax.push(Math.max(...heating));
      findyMax.push(Math.max(...cooling));

      yMax = Math.ceil(Math.max(...findyMax)) / 2;

      this.setState({
        data: {
          electricity,
          heating,
          cooling
        },
        totaleui: {
          electricity: totalElectricity,
          heating: totalHeating,
          cooling: totalcooling,
          total: totalEUI
        },
        percentSaving: {
          electricity: percentSaving_e,
          heating: percentSaving_h,
          cooling: percentSaving_c
        },
        yMax
      });

      let potentialSavingEUI = [];
      potentialSavingEUI.push(totalEUI);
      potentialSavingEUI.push(
        utils.combineEuiValues(this.props.desktopEnergyData.singleBuildingEuis)
      );

      this.props.potentialeui(potentialSavingEUI);
    }
  }

  getCommodityData(data, commodity) {
    data = data[0];
    let tempData = [];
    let originalUsage = [];
    let month = 0;
    let maxLength = 12; // 12 months
    let dataCount = 1;
    let seasonCount = 0;
    let percentSaving = 0;
    let originalTotal = 0;
    let savingsTotal = 0;
    commodity = commodity === "chilledWater" ? "chilledWater" : commodity;

    for (let i = 0; i < data.length; i++) {
      //initializing the array
      if (maxLength > 0) {
        tempData.push(data[i]["Value"]);
        maxLength--;
      } else {
        //sum up all the values per month
        tempData[month] += data[i]["Value"];
        dataCount = month === 0 ? dataCount + 1 : dataCount;
        month = month < 11 ? month + 1 : 0;
      }
    }

    let avgSeasonPercent = this.getSeasonPercentage(
      this.props.building.buildingType
    );

    // take the average of the values per month
    for (let i = 0; i < tempData.length; i++) {
      tempData[i] /= dataCount;
      originalUsage.push(tempData[i]);
      if (i % 3 === 0) {
        seasonCount++;
      }
      if (avgSeasonPercent[commodity][seasonCount - 1] !== 0) {
        tempData[i] *= avgSeasonPercent[commodity][seasonCount - 1];
      }
    }

    for (let i = 0; i < originalUsage.length; i++) {
      originalTotal += originalUsage[i];
      savingsTotal += tempData[i];
    }

    percentSaving = Math.round(
      Math.abs(((originalTotal - savingsTotal) / originalTotal) * 100)
    );

    return [tempData, percentSaving];
  }

  getSeasonPercentage(buildingType) {
    const seasonType = ["Summer", "Spring", "Fall", "Winter"];
    const commodityType = ["electricity", "steam", "chilledWater"];
    let data = this.props.savings;
    let seasonCount = {
      electricity: [0, 0, 0, 0],
      steam: [0, 0, 0, 0],
      chilledWater: [0, 0, 0, 0]
    };
    let seasonPercent = {
      electricity: [0, 0, 0, 0],
      steam: [0, 0, 0, 0],
      chilledWater: [0, 0, 0, 0]
    };

    for (let j = 0; j < data[buildingType].length; j++) {
      let season = data[buildingType][j].season;
      let percent = data[buildingType][j].percent;
      let commodity = data[buildingType][j].commodity;
      percent = percent > 0 ? percent : 0;
      for (let k = 0; k < commodityType.length; k++) {
        if (commodity === commodityType[k]) {
          for (let i = 0; i < seasonType.length; i++) {
            if (season === seasonType[i]) {
              seasonPercent[commodityType[k]][i] += percent;
              seasonCount[commodityType[k]][i]++;
            }
          }
        }
      }
    }

    for (let i = 0; i < commodityType.length; i++) {
      for (let j = 0; j < seasonType.length; j++) {
        if (seasonCount[commodityType[i]][j] !== 0) {
          seasonPercent[commodityType[i]][j] =
            (seasonPercent[commodityType[i]][j] /
              seasonCount[commodityType[i]][j]) *
            0.01;
        }
      }
    }
    return seasonPercent;
  }

  totaleui(data) {
    let total = 0;

    for (let i = 0; i < data.length; i++) {
      total += data[i];
    }

    total /= this.props.building.facLinkData[0]["relatedGross"];

    return total;
  }

  render() {
    return (
      <div>
        <div className="table-rows">
          <span className="table-row-electricity commodity">Electricity</span>
          <span className="table-row-electricity chart">
            <SeasonChart
              commodity="electricity"
              data={this.state.data["electricity"]}
              yMax={this.state.yMax}
            />
          </span>
          <div className="table-row-electricity total">
            <div className="eui electricity">
              {this.state.totaleui.electricity} kBtu/ft&sup2;
            </div>
            <div className="reduction">
              {this.state.percentSaving.electricity}% reduction
            </div>
          </div>
        </div>
        <div className="table-rows">
          <span className="table-row-electricity commodity">Steam</span>
          <span className="table-row-electricity chart">
            <SeasonChart
              commodity="heating"
              data={this.state.data["heating"]}
              yMax={this.state.yMax}
            />
          </span>
          <div className="table-row-electricity total">
            <div className="eui heating">
              {this.state.totaleui.heating} kBtu/ft&sup2;
            </div>
            <div className="reduction">
              {this.state.percentSaving.heating}% reduction
            </div>
          </div>
        </div>
        <div className="table-rows">
          <span className="table-row-electricity commodity">Chilled Water</span>
          <span className="table-row-electricity chart">
            <SeasonChart
              commodity="cooling"
              data={this.state.data["cooling"]}
              yMax={this.state.yMax}
            />
          </span>
          <div className="table-row-electricity total">
            <div className="eui cooling">
              {this.state.totaleui.cooling} kBtu/ft&sup2;
            </div>
            <div className="reduction">
              {this.state.percentSaving.cooling}% reduction
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SeasonTable.propTypes = {
  buildingActions: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  buildingName: PropTypes.string.isRequired,
  savings: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    desktopEnergyData: state.desktopEnergyData
  };
}

export default connect(mapStateToProps)(SeasonTable);
