const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const numericMonths = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12"
};

const quarters = [
  {
    name: "Q1",
    start: "Jan",
    end: "Mar"
  },
  {
    name: "Q2",
    start: "Apr",
    end: "Jun"
  },
  {
    name: "Q3",
    start: "Jul",
    end: "Sep"
  },
  {
    name: "Q4",
    start: "Oct",
    end: "Dec"
  }
];

// create a new, deep copy of an existing object
export const deepCopy = template => {
  return JSON.parse(JSON.stringify(template));
  // return Object.assign({}, template);
};

export const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const roundNumber = (number, precision) => {
  let factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};

export const capitalizeFirstLetter = someString => {
  return someString.charAt(0).toUpperCase() + someString.slice(1);
};

/* get start and end dates for PI data-pulling interval */
export const getTimeInterval = (currentYear, currentMonth) => {
  let dates = getTwelveMonths(currentMonth);

  // combine into year-month format, i.e. "2018-02"
  let startDate = (currentYear - 1).toString() + "-" + numericMonths[dates[0]],
    endDate = "";
  if (currentMonth === "Jan")
    endDate =
      (currentYear - 1).toString() +
      "-" +
      numericMonths[dates[dates.length - 1]];
  else
    endDate =
      currentYear.toString() + "-" + numericMonths[dates[dates.length - 1]];

  return { startDate, endDate };
};

/* get start and end dates for PI data-pulling reference interval */
export const getReferenceTimeInterval = () => {
  let currentDate = new Date();
  let currentMonth = currentDate.getMonth();
  let currentYear = currentDate.getFullYear();

  let returnInterval = {
    start: "",
    end: ""
  };

  if (currentMonth === 0) {
    // can't pull this current year's data if January hasn't finished
    returnInterval.start = (currentYear - 3).toString() + "-04";
    returnInterval.end = (currentYear - 2).toString() + "-01";
  } else {
    returnInterval.start = (currentYear - 2).toString() + "-04";
    returnInterval.end = (currentYear - 1).toString() + "-01";
  }
  return returnInterval;
};

/* return an array of the last twelve months for the M&V tool */
export const getTwelveMonths = currentMonth => {
  let dates = [];

  // start with the current month and proceed to the end of the year
  for (let month = currentMonth; month < months.length; month++) {
    dates.push(months[month]);
  }

  // add the additional months from january -> current month
  for (let month = 0; month < currentMonth; month++) {
    dates.push(months[month]);
  }

  return dates;
};

/* return an array of the last twelve months and labels for the current
year, i.e ["Feb 18", "Mar 18", ... , "Jan 19"] */
export const getMonthsAndYears = (currentYear, currentMonth) => {
  let dates = getTwelveMonths(currentMonth);

  currentYear -= 1; // go back one year and traverse forward
  return dates.map((currentMonth, index) => {
    if (currentMonth === "Jan" && index !== 0)
      // current year started, increment year
      currentYear++;

    // return the months plus the last two digits of the year
    return currentMonth + " " + currentYear.toString().slice(-2);
  });
};

export const getPastFiscalYear = (currentMonth, currentYear) => {
  let monthIndex = Math.floor(currentMonth / 3) - 1;
  let endFiscalYear = quarters[monthIndex].end;
  let beginFiscalYear = quarters[monthIndex + 1].start;
  let range =
    numericMonths[beginFiscalYear] +
    "/" +
    (currentYear - 1) +
    " - " +
    numericMonths[endFiscalYear] +
    "/" +
    currentYear;
  return range;
};
