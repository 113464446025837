import React from "react";
import PropTypes from "prop-types";
import { commodityProperties } from "../../common/utilities";

const DOWNLOAD_COMMODITIES_ORDER = [
  "electricity",
  "steam",
  "chilledWater",
  "gas",
  "solar",
  "domesticWater",
  "heatingGas"
];

const sortCommodities = commoditiesArray => {
  let tempArray = [];
  for (let i = 0; i < DOWNLOAD_COMMODITIES_ORDER.length; i++)
    if (commoditiesArray.includes(DOWNLOAD_COMMODITIES_ORDER[i]))
      tempArray.push(DOWNLOAD_COMMODITIES_ORDER[i]);
  return tempArray;
};

export default function SelectedData({ selectedBuildings, removeBuilding }) {
  return (
    <div className="data-filter-section">
      <div className="data-filter-section-header">Selected Data:</div>
      <div className="selected-data-items">
        {Object.keys(selectedBuildings).map((building, index) => {
          return (
            <div key={building} className="selected-data-item">
              <span>{building}</span>
              {sortCommodities(selectedBuildings[building]).map(
                (commodity, index) => {
                  return (
                    <img
                      key={building + commodity}
                      src={commodityProperties[commodity].image}
                      alt={commodityProperties[commodity].alt}
                    />
                  );
                }
              )}
              <div
                className="selected-data-remove"
                onClick={e => removeBuilding(building)}
              >
                Remove <span>&ndash;</span>
              </div>
            </div>
          );
        })}
        {Object.keys(selectedBuildings).length > 0 ? null : (
          <div className="selected-data-item required">
            Please select data from above.
          </div>
        )}
      </div>
    </div>
  );
}

SelectedData.propTypes = {
  selectedBuildings: PropTypes.object.isRequired,
  removeBuilding: PropTypes.func.isRequired
};
