import React from "react";
import { Link } from "react-router-dom";

import energyLogo from "./images/energy-logo.svg";

import "./Footer.css";

const Footer = () => {
  return (
    <div className="campus-footer">
      <div className="footer-content">
        <div className="footer-title">Dashboards</div>
        <div className="footer-link">
          <Link to="/">Building Energy</Link>
        </div>
        <div className="footer-link">
          <Link to="/energystory">Total Campus Energy</Link>
        </div>
        <div className="footer-link">
          <Link to="/chcp">Campus Energy Production</Link>
        </div>
        <div className="footer-link">
          <Link to="/datadownload">Data Download Tool</Link>
        </div>
        <div className="footer-link">
          <Link to="/energysaving">Energy Saving Projects</Link>
        </div>
        <div className="footer-link">
          <Link to="/ewc">Energy and Water Challenge</Link>
        </div>
      </div>
      <div className="footer-content">
        <div className="footer-title">Resources</div>
        <div className="footer-link">
          <a
            href="https://www.trimthewaste.ucdavis.edu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trim the Waste
          </a>
        </div>
        <div className="footer-link">
          <a
            href="https://mailchi.mp/a5c02ecb6b63/whatswatt"
            target="_blank"
            rel="noopener noreferrer"
          >
            Subscribe to our Newsletter
          </a>
        </div>
      </div>
      <div className="footer-content">
        <div className="footer-logo">
          <a
            href="https://eco.ucdavis.edu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={energyLogo} alt="UC Davis Energy and Engineering" />
          </a>
        </div>
        <div className="footer-description">
          Our goal is to improve campus comfort and efficiency.
        </div>
        <div className="footer-description contact-us">
          <i>Contact our team at:</i>
          <br />
          <a href="mailto:energyfeedback@ucdavis.edu">
            <b>energyfeedback@ucdavis.edu</b>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
