import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function currentMapSelection(
  state = initialState.currentMapSelection,
  action
) {
  switch (action.type) {
    case types.MAP_SELECTION_CHANGE:
      return action.currentMapSelection;

    default:
      return state;
  }
}
