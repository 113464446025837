import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import FullRankingMenu from "./fullRankingMenu/FullRankingMenu";
import BuildingFilter from "../filter/BuildingFilter";
import Ranking from "./Ranking";
import Copyright from "../copyright/Copyright";

import "./Ranking.css";

const euiImage = require("../../../../images/eui.svg");
const aeuImage = require("../../../../images/aeu.svg");

class FullRanking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ranking: null
    };
  }

  setClassOnSlideout = classCheck => {
    if (!classCheck) return "full-ranking-page hide";
    return "full-ranking-page";
  };

  setImage() {
    if (this.props.currentMapSelection === "eui") return euiImage;
    return aeuImage;
  }

  render() {
    return (
      <div className={this.setClassOnSlideout(this.props.ranking.fullRanking)}>
        {this.props.ranking && this.props.ranking.fullRanking ? (
          <div>
            <FullRankingMenu />
            <div className="full-ranking-content">
              <div className="map-options">
                <BuildingFilter />
              </div>
              <Ranking currentMapSelection={this.props.currentMapSelection} />
              <div className="full-ranking-eui-image">
                <img
                  src={this.setImage()}
                  alt="EUI is calculated by taking the annual energy use of a building and dividing it by the square footage."
                />
              </div>
            </div>
            <Copyright />
          </div>
        ) : null}
      </div>
    );
  }
}

FullRanking.propTypes = {
  ranking: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    ranking: state.ranking,
    currentMapSelection: state.currentMapSelection
  };
}

export default connect(mapStateToProps)(FullRanking);
