import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "../../building/loading/Loading";
import * as SavingChartOption from "./savingChart/SavingChartOption";
import SavingChart from "./savingChart/SavingChart";
import Season from "./seasonChart/Season";
import { savingsCalculator } from "../../common/config";
import "./SavingCalculator.css";
import { capitalizeFirstLetter } from "../../common/utilities";

const DEFUALT_COMMODITY = "electricity";
const DEFUALT_BUILDINGTYPE = "classroom";
const DISPLAY_CHART = ["before", "during", "savings"];

let Highcharts = require("highcharts/highstock"),
  demandChart = null;

class SavingCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSliderVal: [6, 22], // default 9am-10pm
      loading: true,
      displayLoading: false,
      currentCommoditySelection: DEFUALT_COMMODITY,
      currentBuildingTypeSelection: DEFUALT_BUILDINGTYPE,
      potentialSavings: 0
    };
    this.changeCommoditySelection = this.changeCommoditySelection.bind(this);
  }

  async componentDidMount() {
    await this.storeChartData();
  }

  async componentWillUnmount() {
    if (demandChart !== null) await demandChart.destroy();
    demandChart = null;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.currentCommoditySelection !==
        prevState.currentCommoditySelection ||
      this.state.currentBuildingTypeSelection !==
        prevState.currentBuildingTypeSelection
    )
      this.updateChart();
  }

  async storeChartData() {
    if (this.state.loading) {
      this.setState({ loading: false }, async () => {
        await this.buildChart();
        this.updateChart(this.state.currentCommoditySelection);
      });
    }
  }

  // Creates a default chart for data to load into.
  buildChart() {
    demandChart = Highcharts.chart(
      "daily-demand",
      SavingChartOption.initializeGraph(this.updateData())
    );
  }

  dataCopy(tempArray) {
    let finalArray = [];
    for (let i = 0; i < tempArray.length; i++)
      finalArray.push(Object.assign({}, tempArray[i]));
    return finalArray;
  }

  // Updates the data on the chart when options are changed.
  updateChart() {
    if (demandChart !== null) {
      demandChart.update({
        series: this.updateData(),
        yAxis: SavingChartOption.setYAxis()
      });
    }
  }

  changeTimeSliderVal = e => {
    this.setState({
      timeSliderVal: e
    });
    this.updateData();
  };

  // updating the graph according to the time slider
  updatingDuring(tempArr) {
    let timeVal = this.state.timeSliderVal;
    let during = tempArr[1].data;
    let before = tempArr[0].data;
    let savings = tempArr[2].data;
    let updateDuring = [];
    let updateSavings = [];

    for (let i = 0; i < before.length; i++) {
      if (i < timeVal[0] || i > timeVal[1]) {
        updateDuring.push(during[i]);
        updateSavings.push(savings[i]);
      } else {
        updateDuring.push(before[i]);
        updateSavings.push(0);
      }
    }

    tempArr[1].data = updateDuring;
    tempArr[2].data = updateSavings;

    if (demandChart !== null) {
      demandChart.update({
        series: tempArr
      });
    }
    updateDuring = [];
  }

  changeCommoditySelection = e => {
    this.setState({ currentCommoditySelection: e.target.id });
  };

  changeBuildingTypeSelection = e => {
    this.setState({ currentBuildingTypeSelection: e.target.id });
  };

  updatePotentialSavings(percent) {
    let potentialSavings = 0;
    let count = 0;
    let sliderVal = this.state.timeSliderVal;
    for (let i = 0; i < percent.length; i++) {
      if (i < sliderVal[0] || i > sliderVal[1]) {
        potentialSavings += percent[i];
        count++;
      }
    }

    if (count === 0) {
      potentialSavings = 0;
    } else {
      potentialSavings = Math.round(potentialSavings / count);
    }

    this.setState({
      potentialSavings
    });
  }

  updateData() {
    let tempArr = [];
    let data = this.props.savings[this.state.currentBuildingTypeSelection];
    let before = [];
    let during = [];
    let percent = [];
    let maxLength = 24;
    let time = 0;
    let commodityCount = 1;
    for (let i = 0; i < data.length; i++) {
      if (data[i]["commodity"] === this.state.currentCommoditySelection) {
        if (maxLength > 0) {
          //initializing the array
          before.push(data[i]["before"]);
          during.push(data[i]["during"]);
          maxLength--;
        } else {
          // sum up all the values per hour
          before[time] += data[i]["before"];
          during[time] += data[i]["during"];
          commodityCount = time === 0 ? commodityCount + 1 : commodityCount;
          time = time < 23 ? time + 1 : 0;
        }
      }
    }

    // take the average of the values per hour
    for (let i = 0; i < before.length; i++) {
      before[i] /= commodityCount;
      during[i] /= commodityCount;
      percent[i] = Math.round((1 - during[i] / before[i]) * 100);
      if (percent[i] < 0) {
        percent[i] = 0;
      }
    }

    this.updatePotentialSavings(percent);

    let totalData = [before, during, percent];

    for (let i = 0; i < DISPLAY_CHART.length; i++) {
      tempArr.push({
        name: capitalizeFirstLetter(DISPLAY_CHART[i]),
        data: totalData[i],
        id: savingsCalculator[DISPLAY_CHART[i]].id,
        zIndex: 3 - i,
        color: savingsCalculator[DISPLAY_CHART[i]].color,
        tooltip: {
          valueSuffix: savingsCalculator[DISPLAY_CHART[i]].valueSuffix
        },
        marker: {
          states: {
            hover: { enabled: DISPLAY_CHART[i] === "savings" ? false : true }
          }
        }
      });
    }

    this.updatingDuring(tempArr);
  }

  render() {
    if (this.state.loading && this.state.displayLoading) return <Loading />;
    else if (this.state.loading) return null;
    return (
      <div>
        <div className="building-demand">
          <SavingChart
            timeSliderVal={this.changeTimeSliderVal}
            currentCommoditySelection={this.state.currentCommoditySelection}
            changeCommoditySelection={this.changeCommoditySelection}
            currentBuildingTypeSelection={
              this.state.currentBuildingTypeSelection
            }
            changeBuildingTypeSelection={this.changeBuildingTypeSelection}
            potentialSavings={this.state.potentialSavings}
          />
          {/* <Season
            buildingActions={this.props.buildingActions}
            savings={this.props.savings}
          /> */}
        </div>
      </div>
    );
  }
}

SavingCalculator.propTypes = {
  buildingActions: PropTypes.object.isRequired,
  savings: PropTypes.object.isRequired
};

export default SavingCalculator;
