/**
 * This component builds out the Demand 'page' on an individual building.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import Loading from "../../loading/Loading";
import * as utilities from "../../../common/utilities";
import * as demandChartOptions from "./demandChart/demandChartOptions";
import DemandChart from "./demandChart/DemandChart";
import WhatsPowering from "./whatsPowering/WhatsPowering";
import UsageNext from "./usageNext/UsageNext";
import "./Demand.css";

const DEFAULT_DEMAND_SELECTION = "dailyData";

let Highcharts = require("highcharts/highstock"),
  dailyDemandChart = null;
Highcharts.setOptions({
  lang: {
    thousandsSep: ","
  },
  global: {
    useUTC: false //Global Highcharts option that set timestamps to local time.
  }
});

class Demand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDemandSelection: DEFAULT_DEMAND_SELECTION,
      checkBoxes: {
        dailyData: Object.assign({}, utilities.setDefaultCheckboxes),
        weeklyData: Object.assign({}, utilities.setDefaultCheckboxes),
        monthlyData: Object.assign({}, utilities.setDefaultCheckboxes)
      },
      chartData: {
        dailyData: [],
        weeklyData: [],
        monthlyData: []
      },
      dataReady: {
        dailyData: false,
        weeklyData: false,
        monthlyData: false
      },
      xAxis: {
        dailyData: [],
        weeklyData: [],
        monthlyData: []
      },
      loading: true,
      displayLoading: false
    };
    this.changeDemandSelection = this.changeDemandSelection.bind(this);
    this.toggleData = this.toggleData.bind(this);
  }

  async componentDidMount() {
    this.mounted = true;
    if (this.props[this.state.currentDemandSelection].length === 0)
      setTimeout(() => {
        if (this.mounted) this.setState({ displayLoading: true });
      }, 500);
    if (this.checkDataLoad(this.props.dailyData))
      await this.storeChartData(
        this.props.dailyData,
        this.props.dailyOat,
        "dailyData"
      );
    if (this.checkDataLoad(this.props.weeklyData))
      await this.storeChartData(
        this.props.weeklyData,
        this.props.weeklyOat,
        "weeklyData"
      );
    if (this.checkDataLoad(this.props.monthlyData))
      await this.storeChartData(
        this.props.monthlyData,
        this.props.monthlyOat,
        "monthlyData"
      );
  }

  async componentDidUpdate(prevProps, prevState) {
    //Added for when a building has data preloaded from the map click.
    if (this.props.commodities.length !== prevProps.commodities.length) {
      if (
        this.checkDataLoad(this.props.dailyData) &&
        this.checkDataLoad(this.props.weeklyData) &&
        this.checkDataLoad(this.props.monthlyData)
      ) {
        await this.storeChartData(
          this.props.dailyData,
          this.props.dailyOat,
          "dailyData"
        );
        await this.storeChartData(
          this.props.weeklyData,
          this.props.weeklyOat,
          "weeklyData"
        );
        await this.storeChartData(
          this.props.monthlyData,
          this.props.monthlyOat,
          "monthlyData"
        );
      }
    }
    if (this.props.commodities.length !== 0) {
      if (
        JSON.stringify(prevProps.dailyData) !==
        JSON.stringify(this.props.dailyData)
      )
        if (
          this.props.dailyData.length > 0 &&
          this.checkDataLoad(this.props.dailyData)
        ) {
          this.storeChartData(
            this.props.dailyData,
            this.props.dailyOat,
            "dailyData"
          );
        }
      if (
        JSON.stringify(prevProps.weeklyData) !==
        JSON.stringify(this.props.weeklyData)
      )
        if (
          this.props.weeklyData.length > 0 &&
          this.checkDataLoad(this.props.weeklyData)
        )
          this.storeChartData(
            this.props.weeklyData,
            this.props.weeklyOat,
            "weeklyData"
          );
      if (
        JSON.stringify(prevProps.monthlyData) !==
        JSON.stringify(this.props.monthlyData)
      )
        if (
          this.props.monthlyData.length > 0 &&
          this.checkDataLoad(this.props.monthlyData)
        )
          this.storeChartData(
            this.props.monthlyData,
            this.props.monthlyOat,
            "monthlyData"
          );
    }
  }

  async componentWillUnmount() {
    if (dailyDemandChart !== null) await dailyDemandChart.destroy();
    dailyDemandChart = null;
    this.mounted = false;
  }

  // Creates a default chart for data to load into.
  buildChart() {
    dailyDemandChart = Highcharts.chart(
      "daily-demand",
      demandChartOptions.initializeGraph("buildingDemand")
    );
  }

  // Updates the time selected for the Demand chart.
  changeDemandSelection(event) {
    let newSelection = event.target.id;
    this.setState({ currentDemandSelection: newSelection });
    this.updateChart(newSelection);
  }

  checkDataLoad(data, dataType) {
    if (data.length === 0) return false;
    else if (
      data[0].building !== this.props.shortname ||
      data.length < this.props.commodities.length
    )
      return false;
    else {
      let approved = true;
      for (let i = 0; i < this.props.commodities.length; i++) {
        let found = false;
        for (let j = 0; j < data.length; j++) {
          if (this.props.commodities[i] === data[j].commodity) {
            found = true;
            // if (data[j].data.length === 0) approved = false;
          }
        }
        if (!found) approved = false;
      }
      if (approved && dataType !== undefined)
        this.setState({
          dataReady: {
            ...this.state.dataReady,
            [dataType]: true
          }
        });
      return approved;
    }
  }

  async storeChartData(demandData, oatData, dataType) {
    if (await this.checkDataLoad(demandData, dataType)) {
      let tempData = await demandChartOptions.buildSeries(
          demandData,
          oatData,
          this.props.commodities,
          this.state.checkBoxes[dataType]
        ),
        tempX = demandChartOptions.setXAxis(dataType);

      this.setState(
        {
          chartData: { ...this.state.chartData, [dataType]: tempData },
          xAxis: { ...this.state.xAxis, [dataType]: tempX }
        },
        () => {
          if (this.state.loading && dataType === DEFAULT_DEMAND_SELECTION) {
            if (this.state.displayLoading) {
              setTimeout(() => {
                if (this.mounted)
                  this.setState({ loading: false }, async () => {
                    await this.buildChart();
                    this.updateChart(DEFAULT_DEMAND_SELECTION);
                  });
              }, 3500);
            } else
              this.setState({ loading: false }, async () => {
                await this.buildChart();
                this.updateChart(DEFAULT_DEMAND_SELECTION);
              });
          }
        }
      );
    }
  }

  // Updates the commodities or outside air temperature display for the
  // demand chart.
  toggleData(event) {
    let id = event.target.id;
    if (dailyDemandChart !== null) {
      let updateValue = !dailyDemandChart.get(id).visible;
      let steamUpdatedValue = !dailyDemandChart.get('steam').visible;
      for (let i = 0; i < dailyDemandChart.series.length; i++) {
        if (dailyDemandChart.series[i].options.id === id) {
          let newChartData = Object.assign({}, this.state.chartData);
          newChartData[this.state.currentDemandSelection][
            i
          ].visible = updateValue;
          this.setState({ chartData: newChartData });
          if (updateValue) dailyDemandChart.series[i].show();
          else dailyDemandChart.series[i].hide();
        }
        if (id === "hotWater" && dailyDemandChart.series[i].options.id === "steam") {
          let newChartData = Object.assign({}, this.state.chartData);
          newChartData[this.state.currentDemandSelection][
            i
          ].visible = steamUpdatedValue;
          this.setState({ chartData: newChartData });
          if (steamUpdatedValue) dailyDemandChart.series[i].show();
          else dailyDemandChart.series[i].hide();
        }
      }
      // Update the checkbox state
      let tempCheckboxes = Object.assign({}, this.state.checkBoxes);
      tempCheckboxes[this.state.currentDemandSelection][id] = updateValue;
      this.setState({ checkBoxes: tempCheckboxes });
      // If Outside Air Temperature, toggle the secondary yAxis
      if (id === "oat")
        dailyDemandChart.update({
          yAxis: demandChartOptions.setYAxis(updateValue)
        });
    }
  }

  // Updates the data on the chart when options are changed.
  updateChart(newDemandSelection) {
    if (dailyDemandChart !== null) {
      if (this.state.chartData[newDemandSelection].length > 0)
        dailyDemandChart.update({
          colors: utilities.commoditiesColorArray(
            this.state.chartData[newDemandSelection]
          ),
          series: this.state.chartData[newDemandSelection],
          yAxis: demandChartOptions.setYAxis(
            this.state.checkBoxes[newDemandSelection].oat
          ),
          xAxis: this.state.xAxis[newDemandSelection]
        });
    }
  }

  render() {
    if (this.state.loading && this.state.displayLoading) return <Loading />;
    else if (this.state.loading) return null;
    return (
      <div className="building-demand">
        <DemandChart
          currentDemandSelection={this.state.currentDemandSelection}
          dataReady={this.state.dataReady}
          demandData={this.props[this.state.currentDemandSelection]}
          commodities={this.props.commodities}
          checkState={this.state.checkBoxes[this.state.currentDemandSelection]}
          shortname={this.props.shortname}
          changeDemandSelection={this.changeDemandSelection}
          toggleData={this.toggleData}
        />
        <WhatsPowering
          commodities={this.props.commodities.filter((val)=> val !== "hotWater")}
          shortname={this.props.shortname}
        />
        <UsageNext onClick={this.props.onClick} />
      </div>
    );
  }
}

Demand.propTypes = {
  dailyData: PropTypes.array.isRequired,
  weeklyData: PropTypes.array.isRequired,
  monthlyData: PropTypes.array.isRequired,
  dailyOat: PropTypes.array.isRequired,
  weeklyOat: PropTypes.array.isRequired,
  monthlyOat: PropTypes.array.isRequired,
  commodities: PropTypes.array.isRequired,
  shortname: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Demand;
