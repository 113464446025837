import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TotalMetrics from "./totalMetrics/TotalMetrics";
import BoilerChart from "./metricsCharts/BoilerChart";
import ChillerChart from "./metricsCharts/ChillerChart";
import DeltaChart from "./metricsCharts/DeltaChart";
import TowerChart from "./metricsCharts/TowerChart";
import Loading from "../../building/loading/Loading";

const DEFAULT_CARD_SELECTION = "boilerEfficiency",
  METRICS_CHARTS = {
    boilerEfficiency: BoilerChart,
    chillerEfficiency: ChillerChart,
    deltaT: DeltaChart,
    waterTower: TowerChart
  };

class Metrics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TotalMetricsReady: false,
      boilerEfficiencyReady: false,
      chillerEfficiencyReady: false,
      deltaTReady: false,
      waterTowerReady: false,
      currentCardSelection: DEFAULT_CARD_SELECTION
    };
    this.setNewCard = this.setNewCard.bind(this);
  }

  componentDidMount() {
    if (JSON.stringify(this.props.totalData) !== JSON.stringify({}))
      this.dataIsReady("TotalMetricsReady", true);
    if (JSON.stringify(this.props.boilerEfficiency) !== JSON.stringify({}))
      this.dataIsReady("boilerEfficiencyReady", true);
    if (JSON.stringify(this.props.chillerEfficiency) !== JSON.stringify({}))
      this.dataIsReady("chillerEfficiencyReady", true);
    if (JSON.stringify(this.props.deltaT) !== JSON.stringify({}))
      this.dataIsReady("deltaTReady", true);
    if (JSON.stringify(this.props.waterTower) !== JSON.stringify({}))
      this.dataIsReady("waterTowerReady", true);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.totalData) !==
      JSON.stringify(prevProps.totalData)
    )
      this.dataIsReady("TotalMetricsReady", true);
    if (
      JSON.stringify(this.props.boilerEfficiency) !==
      JSON.stringify(prevProps.boilerEfficiency)
    )
      this.dataIsReady("boilerEfficiencyReady", true);
    if (
      JSON.stringify(this.props.chillerEfficiency) !==
      JSON.stringify(prevProps.chillerEfficiency)
    )
      this.dataIsReady("chillerEfficiencyReady", true);
    if (JSON.stringify(this.props.deltaT) !== JSON.stringify(prevProps.deltaT))
      this.dataIsReady("deltaTReady", true);
    if (
      JSON.stringify(this.props.waterTower) !==
      JSON.stringify(prevProps.waterTower)
    )
      this.dataIsReady("waterTowerReady", true);
  }

  dataIsReady(field, value) {
    this.setState({ [field]: value });
  }

  displayCard() {
    let Component = METRICS_CHARTS[this.state.currentCardSelection];
    return (
      <Component metricsData={this.props[this.state.currentCardSelection]} />
    );
  }

  setNewCard(newSelection) {
    this.setState({ currentCardSelection: newSelection });
  }

  render() {
    if (
      !this.state.TotalMetricsReady ||
      !this.state.boilerEfficiencyReady ||
      !this.state.chillerEfficiencyReady ||
      !this.state.deltaTReady ||
      !this.state.waterTowerReady
    )
      return <Loading />;
    return (
      <div className="metrics-tab">
        <TotalMetrics
          totalData={this.props.totalData}
          changeSelection={this.setNewCard}
          currentCard={this.state.currentCardSelection}
        />
        {this.displayCard()}
      </div>
    );
  }
}

Metrics.propTypes = {
  boilerEfficiency: PropTypes.object.isRequired,
  chillerEfficiency: PropTypes.object.isRequired,
  deltaT: PropTypes.object.isRequired,
  waterTower: PropTypes.object.isRequired,
  totalData: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    boilerEfficiency: state.chcpMetrics.boilerEfficiencyMetrics,
    chillerEfficiency: state.chcpMetrics.coolingEfficiencyMetrics,
    deltaT: state.chcpMetrics.deltaTMetrics,
    waterTower: state.chcpMetrics.towerMetrics,
    totalData: state.chcpMetrics.totalMetrics
  };
}

export default connect(mapStateToProps)(Metrics);
