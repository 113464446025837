import React from "react";
import { Link } from "react-router-dom";
import {
  capitalizeFirstLetter,
  commaFormatNumbers,
  truncateString
} from "../../../../common/utilities";

const BuildingList = ({ buildings }) => {
  return (
    <div className="ceed-mobile-buildings">
      <div className="mobile-buildings-headers">
        <div className="mobile-buildings-header">Building</div>
        <div className="mobile-buildings-header">Monthly Use</div>
      </div>
      {buildings.map((building, index) => (
        <Link to={"/building/" + building.id} key={index}>
          <div className="mobile-building-card">
            <div className="mobile-building-details">
              <b>{truncateString(building.name, 19)}</b>
              {capitalizeFirstLetter(building.buildingType)}
            </div>
            <div className="mobile-building-statistics">
              <div className="building-stats-kwh">
                {commaFormatNumbers(building.kwh)} <span>kWh</span>
              </div>
              equivalent to
              <div className="building-stats-homes">
                {commaFormatNumbers(building.homes)} <span>CA homes</span>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default BuildingList;
