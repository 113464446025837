import Highcharts from "highcharts";
import {
  getDailyLabels,
  getWeeklyLabels,
  getMonthlyLabels
} from "../../../common/axisLabels";

export const graphConfigBuilder = (series, currentSelection) => {
  return {
    chart: {
      zoomType: "x",
      type: "area",
      plotBorderColor: "#FFFFFF",
      plotBorderWidth: 2,
      height: 330
    },
    credits: {
      enabled: false
    },
    navigator: {
      enabled: false
    },
    rangeSelector: {
      enabled: false
    },
    title: {
      text: null
    },
    time: {
      useUTC: false
    },
    legend: {
      enabled: false
    },
    xAxis: {
      type: "datetime",
      minPadding: 0,
      maxPadding: 0,
      labels: {
        step: currentSelection === "campusDemandMonthly" ? 2 : null,
        style: {
          color: "#444952",
          fontSize: 13,
          textTransform: "capitalize",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        },
        padding: 0,
        y: 25,
        formatter: function() {
          switch (currentSelection) {
            case "campusDemandDaily":
              return getDailyLabels(this.value);
            case "campusDemandWeekly":
              return getWeeklyLabels(this.value);
            case "campusDemandMonthly":
              return getMonthlyLabels(this.value);
            default:
              return "";
          }
        }
      }
    },
    yAxis: {
      min: 0,
      gridLineWidth: 2,
      title: {
        text: "MMBtu/hr",
        align: "high",
        rotation: 0,
        offset: 15,
        style: {
          color: "#676767",
          fontSize: 13,
          fontWeight: "bold",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        }
      },
      lineColor: "#F1F1F2",
      gridLineColor: "#F1F1F2",
      minPadding: 0,
      maxPadding: 0,
      opposite: false,
      tickAmount: 5,
      labels: {
        formatter: function() {
          if (this.isLast) {
            return "";
          }
          let label = this.axis.defaultLabelFormatter.call(this);
          if (/^[0-9]{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value, 0);
          }
          return label;
        },
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: 13
        },
        reserveSpace: true
      },
      endOnTick: false
    },
    plotOptions: {
      areaspline: {
        marker: {
          enabled: false
        },
        lineWidth: 0
      },
      series: {
        stacking: "normal",
        fillOpacity: 0.8,
        marker: {
          enabled: false
        },
        states: {
          hover: {
            enabled: false
          }
        },
        animation: {
          duration: 1000,
          easing: "easeOutBounce"
        }
      }
    },
    tooltip: {
      backgroundColor: "#F6F6F6",
      borderColor: "#EEEDEE",
      borderRadius: 3,
      borderWidth: 1.5,
      shadow: true,
      crosshairs: true,
      style: {
        color: "#2D3138",
        lineHeight: "17px",
        fontSize: "13px"
      },
      headerFormat:
        '<span style="font-size: 14px; color: #2D3138; opacity: 0.8; lineHeight: 17px;">{point.key}</span><table><br/>',
      pointFormat:
        '<span style="background-color:{series.color}; color:{series.color}; font-weight:700; font-size:9px; line-height:18px; vertical-align:top; max-width: 10px; max-height: 10px; border:2px solid white; border-radius: 50%; margin-right:5px; padding-right: 3px;">\u25CF </span><span>  {series.name}:</span> {point.y} MMBtu/hr<br/>',
      footerFormat:
      '<span style= "color:#ffffff00; font-weight:700; font-size:9px; line-height:18px; vertical-align:top; max-width: 10px; max-height: 10px; margin-right:17px; padding-right: 3px;"> </span><span>Total:</span> {point.total:.,0f} MMBtu<br/>',
      shared: true,
      useHTML: true,
      xDateFormat: "%A, %b %e, %l:%M%P"
    },
    series
  };
};
