import React from "react";
import PropTypes from "prop-types";

import * as screen from "./loadingOptions";

import "./Loading.css";

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSelection: {}
    };
  }

  componentDidMount() {
    this.setState({ loadingSelection: this.selectLoading() });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.specificScreen !== this.props.specificScreen)
      this.setState({ loadingSelection: this.selectLoading() });
  }

  selectLoading() {
    if (this.props.specificScreen !== undefined)
      return screen[this.props.specificScreen];
    return screen["loading"][
      Math.floor(Math.random() * screen["loading"].length)
    ];
  }

  render() {
    return (
      <div className="building-loading">
        <a
          href={this.state.loadingSelection.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="loading-wrapper graph-card">
            {this.props.loadingBubble !== false && (
              <div className="loading-pulse">
                <div />
                <div />
              </div>
            )}
            <div className="loading-image">
              <img
                src={this.state.loadingSelection.image}
                alt="Loading data, please wait."
                className="loading-image-file"
              />
            </div>
            <div className="loading-headline">
              {this.state.loadingSelection.headline}
            </div>
            <div
              className="loading-body"
              dangerouslySetInnerHTML={this.state.loadingSelection.body}
            />
          </div>
        </a>
      </div>
    );
  }
}

Loading.propTypes = {
  specificScreen: PropTypes.string,
  loadingBubble: PropTypes.bool
};

export default Loading;
