import React from "react";

import { energyStoryTypes } from "../../../common/config";
import zoomIcon from "../../../../images/zoom.svg";

import { seriesBuilder } from "./seriesBuilder";
import { graphConfigBuilder } from "./graphConfigBuilder";
import EnergyUseStory from "../../usage/totalEnergy/EnergyUseStory";
import TotalDemandCsv from "./TotalDemandCsv";
import infoIcon from "../../../chcp/common/images/info-icon.svg";

//styles
import "./TotalDemandChart.css";

let Highcharts = require("highcharts");

// Creates a dynamic class for each parameter to reference.
const setCheckboxSpanClass = commodity => {
  return "campus-commodity-label " + commodity;
};
const DEFAULT_SELECTION = "campusDemandWeekly";

class TotalDemandChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartRef: null,
      maxEpoch: 0,
      campus: [],
      currentDemandSelection: DEFAULT_SELECTION
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.campus !== state.campus) {
      return {
        campus: props.campus
      };
    } else {
      return null;
    }
  }

  toggleSeries(seriesId, checkedState) {
    for (let i = 0; i < this.state.chartRef.series.length; i++) {
      if (this.state.chartRef.series[i].options.id === seriesId) {
        if (checkedState === true) {
          this.state.chartRef.series[i].show();
        } else {
          this.state.chartRef.series[i].hide();
        }
        break;
      }
    }
  }

  // Creates a default chart for data to load into.
  buildChart(demandSelection) {
    let series = seriesBuilder(this.state.campus[demandSelection]);

    let graphConfig = graphConfigBuilder(
      series,
      this.state.currentDemandSelection
    );

    let chartRef = Highcharts.chart("total-campus-demand-chart", graphConfig);

    this.setState({ chartRef });
  }

  toggleTimeChange = newDemandSelection => {
    this.setState({ currentDemandSelection: newDemandSelection }, () => {
      this.buildChart(newDemandSelection);
    });
  };

  componentDidMount() {
    if (this.state.campus[DEFAULT_SELECTION] !== undefined) {
      this.buildChart(this.state.currentDemandSelection);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.campus !== this.state.campus &&
      this.state.campus[this.state.currentDemandSelection] !== undefined
    )
      this.buildChart(this.state.currentDemandSelection);
  }

  render() {
    if (this.state.campus[DEFAULT_SELECTION] === undefined) return null;
    return (
      <div className="graph-card">
        <div className="total-campus-graph-title">
          Energy Demand
          <div className="demand-zoom-helper">
            <img src={zoomIcon} alt="Magnifying glass" /> Click and drag to
            zoom.
          </div>
        </div>
        <img className="info-icon" src={infoIcon} alt="info icon" />
        <div className="speech-bubble-ds">
          <div className="speech-bubble-ds-arrow" />
          <p>
            <b>MMBtu/hr</b>
          </p>
          <p>
            An MMBtu is one million Btus. An MMBtu/hr is a rate of energy
            demand.
          </p>
        </div>
        <div className="campus-graph">
          <div className="campus-chart">
            <div id="total-campus-demand-chart" />
          </div>
          <div className="campus-side-options">
            <button
              className="campus-side-button"
              aria-pressed={
                this.state.currentDemandSelection === "campusDemandDaily"
              }
              onClick={e => this.toggleTimeChange("campusDemandDaily")}
              onKeyPress={e => this.toggleTimeChange("campusDemandDaily")}
            >
              Past 24 Hrs
            </button>
            <button
              className="campus-side-button"
              aria-pressed={
                this.state.currentDemandSelection === "campusDemandWeekly"
              }
              onClick={e => this.toggleTimeChange("campusDemandWeekly")}
              onKeyPress={e => this.toggleTimeChange("campusDemandWeekly")}
            >
              Past 7 Days
            </button>
            <button
              className="campus-side-button"
              aria-pressed={
                this.state.currentDemandSelection === "campusDemandMonthly"
              }
              onClick={e => this.toggleTimeChange("campusDemandMonthly")}
              onKeyPress={e => this.toggleTimeChange("campusDemandMonthly")}
            >
              Past 30 Days
            </button>
            <div className="campus-commodities">
              {this.state.campus[this.state.currentDemandSelection].map(
                commodityData => (
                  <div key={commodityData.commodity}>
                    <label
                      className={"campus-commodity " + commodityData.commodity}
                      htmlFor={commodityData.commodity}
                    >
                      <input
                        type="checkbox"
                        defaultChecked={true}
                        onChange={e =>
                          this.toggleSeries(
                            commodityData.commodity,
                            e.target.checked
                          )
                        }
                        id={commodityData.commodity}
                      />
                      {commodityData.commodity === "pge_demand" ? (
                        <span
                          className={setCheckboxSpanClass(
                            commodityData.commodity
                          )}
                        >
                          Carbon Based Electricity
                          <div className="pge_demand-label-spacer">
                            and Hydropower
                          </div>
                        </span>
                      ) : (
                        <span
                          className={setCheckboxSpanClass(
                            commodityData.commodity
                          )}
                        >
                          {
                            energyStoryTypes[commodityData.commodity]
                              .displayName
                          }
                        </span>
                      )}
                    </label>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <EnergyUseStory />
      </div>
    );
  }
}

export default TotalDemandChart;
