import React from "react";
import PropTypes from "prop-types";
import {
  commodityProperties,
  downloadBuildingTypes
} from "../../common/utilities";
import sortArrow from "../../common/images/sort-icon.svg";
import DropDown, { CHECKBOX, RADIO } from "../../dropdown/Dropdown";
import "./BuildingFilterHeader.css";

export const EUI_FILTER_TYPES = {
  showAll: { displayName: "Show All" },
  active: { displayName: "Active" },
  comingSoon: { displayName: "Coming Soon" },
  unavailable: { displayName: "Unavailable" }
};

class BuildingFilterHeader extends React.Component {
  constructor(props) {
    super(props);

    this.commodityFilterHandler = this.commodityFilterHandler.bind(this);
  }

  commodityFilterHandler = (commodityName, isSelected) => {
    // Toggle commodity only if needed
    if (
      (isSelected === false &&
        this.props.commodityFilter[commodityName] === true) ||
      (isSelected === true &&
        this.props.commodityFilter[commodityName] === false)
    ) {
      this.props.toggleCommodityFilter(commodityName);
    }
  };

  buildingTypeFilterHandler = (buildingTypeName, isSelected) => {
    // Toggle buildingType only if needed
    this.props.toggleBuildingTypeFilter(buildingTypeName);
  };

  euiTypeFilterHandler = (euiTypeName, isSelected) => {
    // Toggle euiType only if needed
    this.props.toggleEuiTypeFilter(euiTypeName);
  };

  getCommodityItems = () => {
    return Object.keys(commodityProperties).map(commodity => {
      return {
        text: commodityProperties[commodity].displayName,
        value: commodity,
        selected: this.props.commodityFilter[commodity]
      };
    });
  };

  getBuildingTypeItems = () => {
    return Object.keys(downloadBuildingTypes).map(buildingType => {
      return {
        text: downloadBuildingTypes[buildingType],
        value: buildingType,
        selected: this.props.buildingTypeFilter[buildingType]
      };
    });
  };

  getEuiTypeItems = () => {
    return Object.keys(EUI_FILTER_TYPES).map(euiType => {
      return {
        text: EUI_FILTER_TYPES[euiType].displayName,
        value: euiType,
        selected: this.props.euiTypeFilter[euiType]
      };
    });
  };

  render() {
    return (
      <div className="building-selector-grid-header">
        <div className="building-selector-grid-header-row col1" id="name">
          <div className="grid-column-text" id="name">
            Building
          </div>
          <div
            className="grid-column-text-selector underline"
            id="name"
            onClick={this.props.toggleBuildingNameSort}
          >
            <img src={sortArrow} alt="Sort icon" id="name" />
            Sort A-Z
          </div>
        </div>
        <div
          className="building-selector-grid-header-row col2"
          id="commodities"
        >
          <div className="grid-column-text" id="commodities">
            Commodities
          </div>
          <DropDown
            id="commodity-dropdown"
            items={this.getCommodityItems()}
            type={CHECKBOX}
            onSelectHandler={this.commodityFilterHandler}
          />
        </div>
        <div
          className="building-selector-grid-header-row col2"
          id="commodities"
        >
          <div className="grid-column-text" id="commodities">
            Building Type
          </div>
          <DropDown
            id="buildingtype-dropdown"
            items={this.getBuildingTypeItems()}
            type={RADIO}
            onSelectHandler={this.buildingTypeFilterHandler}
          />
        </div>
        <div
          className="building-selector-grid-header-row col2"
          id="commodities"
        >
          <div className="grid-column-text" id="commodities">
            Eui Status
          </div>
          <DropDown
            id="eui-dropdown"
            items={this.getEuiTypeItems()}
            type={RADIO}
            onSelectHandler={this.euiTypeFilterHandler}
          />
        </div>
      </div>
    );
  }
}

BuildingFilterHeader.propTypes = {
  toggleCommodityFilter: PropTypes.func.isRequired,
  commodityFilter: PropTypes.object.isRequired,
  toggleBuildingTypeFilter: PropTypes.func.isRequired,
  buildingTypeFilter: PropTypes.object.isRequired,
  toggleEuiTypeFilter: PropTypes.func.isRequired,
  euiTypeFilter: PropTypes.object.isRequired,
  toggleBuildingNameSort: PropTypes.func.isRequired
};

export default BuildingFilterHeader;
