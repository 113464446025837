import React, { Component } from "react";
import "./HeaderRow.css";
import TextBox from "../gridBoxes/TextBox";

class HeaderRow extends Component {
  render() {
    return (
      <div className="heading-wrapper" style={{ display: "flex" }}>
        <div className="heading-buildingProject">
          <TextBox name="buildingProject-heading" text="Building Project" />
        </div>
        <div className="heading-electricity">
          <TextBox name="electricity-heading" text="Electricity" />
        </div>
        <div className="heading-steam">
          <TextBox name="steam-heading" text="Steam" />
        </div>
        <div className="heading-chilledWater">
          <TextBox name="chilledWater-heading" text="Chilled Water" />
        </div>
        <div className="heading-monthlySavings">
          <TextBox name="monthlySavings-heading" text="Total" />
        </div>
      </div>
    );
  }
}

export default HeaderRow;
