import React from "react";
import { Popup } from "react-leaflet";
import {
  capitalizeFirstLetter,
  formatDollarValue,
  commaFormatNumbers
} from "../../../common/utilities";
import { buildingPopup } from "../../../common/config";

const GREEN_BUILDINGS = ["ghausi", "pes", "vm3a", "shields"];

class EuiPopups extends React.Component {
  header() {
    let value = "";
    let marker = this.props.marker;
    if (marker.euiComingSoon) value = "Coming Soon";
    else if (marker.eui === 0) value = "Incomplete";
    else {
      if (this.props.currentMapSelection === "eui") {
        value = marker.eui;
      } else {
        value = marker.annualUsage;
        if (value !== null) {
          value = commaFormatNumbers(value);
        }
      }
    }

    return (
      <div className="popup-eui-header">
        <span className="popup-eui-value">{value}</span>
        {!marker.euiComingSoon && marker.eui !== 0 ? (
          this.props.currentMapSelection === "eui" ? (
            <span className="popup-eui-units"> kBtu/ft²</span>
          ) : (
            <span className="popup-eui-units"> kBtu/yr</span>
          )
        ) : null}
        <br />
        {this.props.currentMapSelection === "eui"
          ? this.averageEui()
          : this.averageAnnualUsage()}
      </div>
    );
  }

  averageEui() {
    return (
      <span className="popup-eui-label">
        Average {capitalizeFirstLetter(this.props.marker.buildingType)} EUI:{" "}
        {this.props.marker.euiAverage} kBtu/ft²
      </span>
    );
  }

  averageAnnualUsage() {
    return (
      <span className="popup-eui-label">
        Average {capitalizeFirstLetter(this.props.marker.buildingType)} Annual
        Usage: {commaFormatNumbers(this.props.marker.annualAverage)} kBtu/yr
      </span>
    );
  }

  certification() {
    let certificate = this.props.marker.certifications;
    if (certificate !== null && certificate !== undefined) {
      if (certificate.length !== 0) {
        let type = certificate[0].type;
        return (
          <div className="popup-building-info">
            <span>LEED Certification</span>
            {type !== null && type !== "" ? (
              <span>
                <b>{capitalizeFirstLetter(type)}</b>
              </span>
            ) : (
              <span>
                <b>LEED</b>
              </span>
            )}
          </div>
        );
      }
    }
  }

  greenBuilding() {
    let markerCertification = this.props.marker.certifications;
    if (markerCertification.length > 0) {
      let certificate = markerCertification[0].name;
      let type = markerCertification[0].type;
      if (type !== null && type.length > 0) {
        type = capitalizeFirstLetter(type);
      }
      if (type === "Certified") {
        type = "";
      }
      return (
        <div className="popup-green-building">
          <div className="popup-green-building-content">
            This building is {certificate.toUpperCase()} {type} Certified and
            the HVAC system was commissioned by our energy engineers.
          </div>
          {this.props.clicked ? this.clickedBuildingInfo() : null}
        </div>
      );
    }
  }

  clickedBuildingInfo() {
    let savings = this.props.marker.savings;
    return (
      <div className="popup-clicked-building-info">
        {savings !== 0 ? (
          <div className="popup-clicked-savings">
            <span>Energy Savings</span>
            <span>
              <b>{formatDollarValue(savings)}</b>
            </span>
          </div>
        ) : null}
        {this.certification()}
        {this.viewEnergyData()}
      </div>
    );
  }

  buildingTypeInfo() {
    if (
      this.props.greenButton &&
      GREEN_BUILDINGS.includes(this.props.marker.id)
    ) {
      return (
        <div className="popup-green-building">
          <div className="popup-green-building-content">
            This building's HVAC system was commissioned by our energy
            engineers.
          </div>
          {this.props.clicked ? this.clickedBuildingInfo() : null}
        </div>
      );
    }
    if (!this.props.clicked) {
      return (
        <div className="popup-building-type">
          <img
            src={buildingPopup[this.props.marker.buildingType].icon}
            alt="Building Type Icon"
          />
          <div className="popup-building-type-info">
            {buildingPopup[this.props.marker.buildingType].description}
          </div>
        </div>
      );
    } else {
      return this.clickedBuildingInfo();
    }
  }

  viewEnergyData() {
    return (
      <div className="popup-view">
        <a href={`/building/${this.props.marker.id}`}>
          <u style={{ color: "#2D3138" }}>View Energy Data</u>
        </a>
      </div>
    );
  }

  render() {
    return (
      <Popup className="popup-eui" closeButton={false}>
        <div>
          <div
            className={`popup-eui-header-wrapper ${this.props.marker.buildingType}`}
          >
            <div className="popup-eui-header">{this.header()}</div>
          </div>
          {this.props.greenButton ? (
            this.props.marker.certifications !== null &&
            this.props.marker.certifications.length > 0 ? (
              <div className="popup-clicked-building-info">
                {this.greenBuilding()}
              </div>
            ) : (
              this.buildingTypeInfo()
            )
          ) : (
            this.buildingTypeInfo()
          )}
        </div>
      </Popup>
    );
  }
}

export default EuiPopups;
