import React from "react";
import PropTypes from "prop-types";

import "./Navigation.css";

const Navigation = ({ options, selection, onClick }) => {
  const navigationClass = property => {
    let baseClass = "building-navigation-button";
    if (property === selection) return baseClass + " active";
    return baseClass;
  };

  const wrapperClass = optionsLength => {
    let className = "building-navigation-wrapper";
    if (optionsLength > 3) className += " extended";
    return className;
  };

  return (
    <div className="building-navigation">
      <div className={wrapperClass(options.length)}>
        {options.map(option => (
          <div
            key={option}
            className={navigationClass(option)}
            id={option}
            onClick={onClick}
          >
            {option.toUpperCase()}
          </div>
        ))}
      </div>
    </div>
  );
};

Navigation.propTypes = {
  options: PropTypes.array.isRequired,
  selection: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default Navigation;
