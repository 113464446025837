import { chcpEnergyTypes, tooltipData } from "../../common/config";

export const circleChartOptions = data => {
  let preppedData = setChartData(data);
  return {
    chart: {
      type: "pie",
      margin: [0, 0, 0, 0]
    },
    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ["50%", "50%"]
      },
      series: {
        states: {
          hover: {
            enabled: true
          }
        }
      }
    },
    tooltip: {
      backgroundColor: "#F6F6F6",
      borderColor: "#C3E5F2",
      borderRadius: 3,
      borderWidth: 1.5,
      shadow: true,
      outside: true,
      pointFormat:
        '<span style="font-size:14px;line-height:18px;"><b>{point.percent}% of Total Production</b></span><br/>{point.information}',
      style: {
        textOverflow: "allow",
        width: 140,
        whiteSpace: "wrap",
        fontSize: "12px"
      },
      useHTML: true
    },
    colorAxis: {
      reversed: true
    },
    series: [
      {
        name: null,
        data: preppedData,
        size: "100%",
        innerSize: "85%",
        dataLabels: {
          enabled: false
        },
        id: "total-production-circle-data"
      }
    ]
  };
};

const setChartData = rawData => {
  let dataSet = [],
    total = 0;
  for (let totalKey in rawData)
    if (rawData[totalKey].annual !== undefined && rawData[totalKey].annual > 0)
      total += Math.round(rawData[totalKey].annual);
  for (let key in rawData) {
    if (rawData[key].annual !== undefined && rawData[key].annual > 0)
      dataSet.push({
        name: chcpEnergyTypes[key].displayName,
        y: Math.round(rawData[key].annual),
        color: chcpEnergyTypes[key].color,
        percent: Math.round((rawData[key].annual / total) * 100),
        information: tooltipData[key]
      });
  }
  return dataSet.reverse();
};
