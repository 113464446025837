import React from "react";
import PropTypes from "prop-types";
import "./Navigation.css";

const Navigation = ({ selection, onClick }) => {
  const navigationClass = property => {
    let baseClass = "saving-navigation-button " + property;
    if (property === selection) return baseClass + " active";
    return baseClass;
  };
  return (
    <div className="saving-navigation">
      <div className="saving-navigation-wrapper">
        <div
          className={navigationClass("totalSavings")}
          id="totalSavings"
          onClick={onClick}
        >
          TOTAL SAVINGS
        </div>
        <div
          className={navigationClass("buildingProjects")}
          id="buildingProjects"
          onClick={onClick}
        >
          BUILDING PROJECTS
        </div>
        <div
          className={navigationClass("savingsCalculator")}
          id="savingsCalculator"
          onClick={onClick}
        >
          SAVINGS CALCULATOR
        </div>
      </div>
    </div>
  );
};

Navigation.propTypes = {
  selection: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Navigation;
