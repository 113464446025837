/**
 * This component returns the appropriate building type description based on user selection.
 */

import React from "react";
import PropTypes from "prop-types";

import { commodityTypes } from "../../../../common/config";

const WhatsPoweringExample = ({ selection }) => {
  let normalExample = "./images/" + selection + "-normal.png",
    abnormalExample = "./images/" + selection + "-abnormal.png";
  if (selection === "solar")
    return (
      <div className="demand-whats-powering-examples solar">
        <div className="demand-whats-powering-case">
          <div className="demand-whats-powering-case-top">
            <div className="demand-whats-powering-case-title">Normal Use</div>
            <img
              src={require(`${normalExample}`)}
              alt={"Normal Usage Example for " + selection + "."}
            />
          </div>
          <div className="demand-whats-powering-case-description">
            {commodityTypes[selection].normalUseDescription}
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="demand-whats-powering-examples">
        <div className="demand-whats-powering-case">
          <div className="demand-whats-powering-case-top">
            <div className="demand-whats-powering-case-title">Normal Use</div>
            <img
              src={require(`${normalExample}`)}
              alt={"Normal Usage Example for " + selection + "."}
            />
          </div>
          <div className="demand-whats-powering-case-description">
            {commodityTypes[selection].normalUseDescription}
          </div>
        </div>
        <div className="demand-whats-powering-case">
          <div className="demand-whats-powering-case-top">
            <div className="demand-whats-powering-case-title">
              What to Look For
            </div>
            <img
              src={require(`${abnormalExample}`)}
              alt={"Abnormal Usage Example for " + selection + "."}
            />
          </div>
          <div className="demand-whats-powering-case-description">
            {commodityTypes[selection].abnormalUseDescription}
          </div>
        </div>
      </div>
    );
};

WhatsPoweringExample.propTypes = {
  selection: PropTypes.string.isRequired
};

export default WhatsPoweringExample;
