/**
 * This component sets up the CSV download button.
 */

 import React from "react";
 import { CSVLink } from "react-csv";
 
 //Fixes fy formatting bug in Excel.
 const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

 const convertMonth = (month) => month < 10 ? '0' + month : month;

 const convertData = data => {
   const csvData = []
   for (let i =0; i < data.length; i++) {
    let year = data[i].year
    for (let j=0; j < months.length; j++) {
      if (!data[i].monthlyUsage[months[j]]) break;
      let temp = Object.assign({}, data[i].monthlyUsage[months[j]])
      temp["date"] = year+"-"+convertMonth(j+1)
      csvData.push(temp)
    }
   }
   return csvData;
 };
 
 // Creates a filename for downloading campus data.
 // Format is 'water_MM_DD_YYYY.csv'.
 const getCsvFilename = shortname => {
   let downloadDate = new Date(),
     downloadMonth = downloadDate.getMonth() + 1;
   return (
     shortname +
     "_" +
     downloadMonth +
     "-" +
     downloadDate.getDate() +
     "-" +
     downloadDate.getFullYear() +
     ".csv"
   );
 };
 
 // Sets up the header row for the CSV based on the
 // water data available.
 const getCsvHeaders = sources => {
   let headers = [{ label: "Date", key: "date" }];
   if (sources.length > 0)
     for (let i = 0; i < sources.length; i++)
       headers.push({
         label: sources[i].displayName,
         key: sources[i].value
       });
   return headers;
 };
 
 export default function WaterUsageCsv({ data, sources, icon }) {
   return (
     <div className="water-csv">
       <CSVLink
         className="csv-link"
         data={convertData(data)}
         filename={getCsvFilename("water")}
         headers={getCsvHeaders(sources)}
       >
         <div className="water-csv-button">
           <img className="download-img" src={icon} alt="download icon" />
           <div>Download CSV</div>
         </div>
       </CSVLink>
     </div>
   );
 };
 