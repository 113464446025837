import React from "react";
import PropTypes from "prop-types";
import { currentFyFormat } from "../../common/config";
import { formatDollarValue } from "../../../common/utilities";

let Highcharts = require("highcharts/highstock"),
  currentSavingsCircle = null;
Highcharts.setOptions({
  lang: {
    thousandsSep: ","
  }
});

class CurrentSavingsCircle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverPercent: null,
      hoverTitle: null,
      hoverValue: null
    };
  }
  componentDidMount() {
    let ready = false;
    for (let key in this.props.savings)
      if (this.props.savings[key] > 0) ready = true;
    if (ready) this.initializeChart();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.savings) !== JSON.stringify(prevProps.savings)
    )
      this.updateChart();
  }

  componentWillUnmount() {
    currentSavingsCircle = null;
  }

  displayHover(event) {
    this.setState({
      hoverPercent: event.target.percentage.toFixed(1),
      hoverTitle: event.target.name,
      hoverValue: event.target.y
    });
  }

  resetHover(event) {
    event.preventDefault();
    this.setState({
      hoverPercent: null,
      hoverTitle: null,
      hoverValue: null
    });
  }

  updateChart() {
    if (currentSavingsCircle === null) {
      this.initializeChart();
    } else {
      currentSavingsCircle.update({
        series: this.setChartValues(this.props.savings),
        xAxis: {
          categories: this.props.years,
          tickLength: 0,
          labels: {
            style: {
              fontWeight: "bold",
              fontSize: "13px",
              lineHeight: 2,
              letterSpacing: "0.8px",
              color: "#000000"
            }
          }
        }
      });
    }
  }

  async initializeChart() {
    currentSavingsCircle = Highcharts.chart("savings-current-graph", {
      chart: {
        type: "pie",
        margin: [0, 0, 0, 0],
        backgroundColor: "#F6F6F7",
        height: "180px"
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      plotOptions: {
        pie: {
          borderWidth: 0,
          shadow: false,
          center: ["50%", "50%"]
        },
        series: {
          point: {
            events: {
              mouseOver: this.displayHover.bind(this),
              mouseOut: this.resetHover.bind(this)
            }
          },
          states: {
            hover: {
              enabled: true,
              brightness: 0,
              halo: {
                opacity: 1
              }
            }
          }
        }
      },
      tooltip: {
        backgroundColor: "#F6F6F6",
        borderColor: "#C3E5F2",
        borderRadius: 3,
        borderWidth: 1.5,
        headerFormat: "",
        shadow: true,
        outside: true,
        pointFormatter: function() {
          return (
            '<span style="font-size:14px;line-height:18px;">' +
            this.name +
            "<br /><b>" +
            this.percentage.toFixed(1) +
            "%</b>"
          );
        },
        style: {
          textOverflow: "allow",
          width: 140,
          whiteSpace: "wrap",
          fontSize: "12px"
        },
        useHTML: true,
        enabled: false
      },
      colorAxis: {
        reversed: true
      },
      series: [
        {
          name: null,
          data: await this.setChartData(),
          size: "100%",
          innerSize: "80%",
          dataLabels: {
            enabled: false
          },
          id: "savings-current-graph"
        }
      ]
    });
  }

  setChartData() {
    return new Promise((res, rej) => {
      try {
        let dataSet = [];
        for (let key in this.props.savings) {
          if (this.props.savings[key] > 0)
            dataSet.push({
              name: currentFyFormat[key].title,
              y: Math.round(this.props.savings[key]),
              color: currentFyFormat[key].color,
              percent: Math.round(
                (this.props.savings[key] / this.props.total) * 100
              )
            });
        }
        res(dataSet);
      } catch (e) {
        throw new Error(e);
        res([]);
      }
    });
  }

  render() {
    return (
      <div className="savings-current-graph">
        <div className="savings-current-circle" id="savings-current-graph" />
        <div className="savings-current-hover">
          {this.state.hoverTitle}
          {this.state.hoverTitle !== null ? ":" : ""}{" "}
          <b>{formatDollarValue(this.state.hoverValue)}</b>
        </div>
      </div>
    );
  }
}

CurrentSavingsCircle.propTypes = {
  savings: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired
};

export default CurrentSavingsCircle;
