import * as types from "./actionTypes";

export function filterButtonClick(filterButtons) {
  return {
    type: types.FILTER_BUTTON_CLICK,
    filterButtons
  };
}

export const filterButtonChanged = (currentButtonsState, filterClicked) => {
  let newFilterButtonsState = Object.assign({}, currentButtonsState);
  newFilterButtonsState[filterClicked] = !currentButtonsState[filterClicked];
  return function(dispatch) {
    dispatch(filterButtonClick(newFilterButtonsState));
  };
};

export function filterSliderChange(filterSlider) {
  return {
    type: types.FILTER_SLIDER_CHANGE,
    filterSlider
  };
}

export const sliderValueChange = (currentSliderState, newValues) => {
  let newSliderState = Object.assign({}, currentSliderState);
  newSliderState.values = newValues;
  return function(dispatch) {
    dispatch(filterSliderChange(newSliderState));
  };
};

export const setSliderValues = (
  currentSliderState,
  buildingList,
  currentMapSelection
) => {
  let values = [],
    newSliderState = Object.assign({}, currentSliderState);
  buildingList.forEach(marker => {
    if (marker.euiReady) {
      values.push(marker[currentMapSelection]);
    }
  });

  newSliderState.maxValue = Math.ceil(Math.max(...values));
  newSliderState.values = [0, newSliderState.maxValue];

  return function(dispatch) {
    dispatch(filterSliderChange(newSliderState));
  };
};

export function greenBuildingClicked(greenButton) {
  return {
    type: types.GREEN_BUILDING_CLICK,
    greenButton
  };
}
