import React, { Component } from "react";
import "./BuildingCommissioning.css";
import ProjectGrid from "./projectGrid/ProjectGrid";
import UsageChart from "./UsageChart";
import SavingsTable from "./SavingsTable";
import Loading from "../../../building/loading/Loading";
import { buildingProjectTabs } from "../../../common/config";
import headshot from "../images/hiroko.png";
import {
  capitalizeFirstLetter,
  roundNumber,
  getTwelveMonths,
  getMonthsAndYears,
  deepCopy
} from "../utils/formatting";
import {
  buildingNameList,
  commodityList,
  shortNameList,
  usageTemplateJSON,
  usageTemplateObject
} from "./Lists";
import { getMonthlyUsage, getMonthlyBaselineUsage } from "../utils/apolloFetch";
import { getChartTitle, getChartTitleFormatting } from "./ChartSettings";
import * as conversion from "../utils/unitConversion";

class BuildingCommissioning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasData: false,
      graphSelected: false,
      selectedGraph: usageTemplateJSON,
      selectedBuilding: "ghausi",
      selectedCommodity: "electricity",
      selectedSavings: [],
      selectedProject: "buildingProjects"
    };
    this.handleGraphSelect.bind(this);
  }
  // create JSON objects
  monthlyUsageJSON = deepCopy(usageTemplateJSON);
  monthlyBaselineUsageJSON = deepCopy(usageTemplateJSON);

  // create Usage objects
  monthlyUsageSource = deepCopy(usageTemplateObject);
  monthlyBaselineUsageSource = deepCopy(usageTemplateObject);
  monthlyUsageKBTU = deepCopy(usageTemplateObject);
  monthlyBaselineUsageKBTU = deepCopy(usageTemplateObject);
  monthlyCost = deepCopy(usageTemplateObject);
  monthlyBaselineCost = deepCopy(usageTemplateObject);
  savingsInDollars = deepCopy(usageTemplateJSON);

  componentDidMount() {
    this.getUsageData();
  }

  getUsageData = async () => {
    for (let index = 0; index < shortNameList.length; index++) {
      // apollo graphQL queries
      this.monthlyUsageJSON[shortNameList[index]] = await getMonthlyUsage(
        shortNameList[index]
      );
      this.monthlyBaselineUsageJSON[
        shortNameList[index]
      ] = await getMonthlyBaselineUsage(shortNameList[index]);
    }

    // default units - Monthly Usage in kbtu, Monthly Baseline Usage in source
    for (let building in this.monthlyUsageJSON) {
      // unpack the JSON and assign to javascript objects
      this.monthlyUsageKBTU[building] = this.assignMonthlyUsage(
        this.monthlyUsageJSON,
        building,
        commodityList
      );
      this.monthlyBaselineUsageSource[building] = this.assignMonthlyUsage(
        this.monthlyBaselineUsageJSON,
        building,
        commodityList
      );
    }

    // populate monthlyUsageSource and monthlyBaselineUsageKBTU objects
    this.monthlyUsageSource = conversion.convertUsageUnits(
      this.monthlyUsageKBTU,
      "kbtu_to_source",
      shortNameList,
      commodityList
    );
    this.monthlyBaselineUsageKBTU = conversion.convertUsageUnits(
      this.monthlyBaselineUsageSource,
      "source_to_kbtu",
      shortNameList,
      commodityList
    );

    // populate totals array of usage objects
    this.populateTotals();

    // populate savings array
    for (
      let buildingIndex = 0;
      buildingIndex < shortNameList.length;
      buildingIndex++
    ) {
      let building = shortNameList[buildingIndex];
      for (
        let commodityIndex = 0;
        commodityIndex < commodityList.length;
        commodityIndex++
      ) {
        let commodity = commodityList[commodityIndex];
        let conversionRate = this.monthlyUsageJSON[building][commodityIndex].rate
        let usage = this.monthlyUsageKBTU[building][commodity].map(value => value * conversionRate)
        let baselineUsage = this.monthlyBaselineUsageKBTU[building][commodity].map(value => value * conversionRate)
        this.savingsInDollars[building][commodity] = baselineUsage.map(
          (baselineValue, index) => {
            let actualValue = usage[index];
            return baselineValue - actualValue;
          }
        );
        this.monthlyCost[building][commodity] = usage;
        this.monthlyBaselineCost[building][commodity] = baselineUsage;
      }
    }
    // assign total fields of dollar arrays
    for (
      let buildingIndex = 0;
      buildingIndex < shortNameList.length;
      buildingIndex++
    ) {
      let building = shortNameList[buildingIndex];
      this.savingsInDollars[building][
        "totals"
      ] = this.calculateTotalDollarSavings(building, this.savingsInDollars);
      this.monthlyCost[building]["totals"] = this.calculateTotalDollarSavings(
        building,
        this.monthlyCost
      );
      this.monthlyBaselineCost[building][
        "totals"
      ] = this.calculateTotalDollarSavings(building, this.monthlyBaselineCost);
    }

    let tempObject = this.resetGraphObject();
    this.setState({ selectedGraph: tempObject });

    this.setState({ hasData: true });
  };

  assignMonthlyUsage = (jsonObject, buildingName, commodityList) => {
    let jsonBuildingObject = {
      electricity: [],
      steam: [],
      chilledWater: []
    };

    for (
      let commodityIndex = 0;
      commodityIndex < commodityList.length;
      commodityIndex++
    ) {
      jsonBuildingObject[
        commodityList[commodityIndex]
      ] = this.unpackCommodityJSON(jsonObject[buildingName], commodityIndex);
    }
    return jsonBuildingObject;
  };

  unpackCommodityJSON = (jsonObject, commodityIndex) => {
    let jsonData = [];
    for (let innerIterator in jsonObject[commodityIndex].data) {
      jsonData.push(jsonObject[commodityIndex].data[innerIterator].Value);
    }
    return jsonData;
  };

  // populate the totals fields of all Monthly Usage Objects
  populateTotals = () => {
    for (
      let buildingIndex = 0;
      buildingIndex < shortNameList.length;
      buildingIndex++
    ) {
      let building = shortNameList[buildingIndex];

      this.monthlyUsageKBTU[building]["totals"] = this.calculateTotals(
        this.monthlyUsageKBTU,
        building,
        commodityList[0],
        commodityList[1],
        commodityList[2]
      );

      this.monthlyBaselineUsageKBTU[building]["totals"] = this.calculateTotals(
        this.monthlyBaselineUsageKBTU,
        building,
        commodityList[0],
        commodityList[1],
        commodityList[2]
      );
    }
  };

  calculateTotals = (
    usageObject,
    building,
    commodity1,
    commodity2,
    commodity3
  ) => {
    let totals = [];
    for (
      let index = 0;
      index < usageObject[building][commodity1].length;
      index++
    ) {
      totals.push(
        usageObject[building][commodity1][index] +
          usageObject[building][commodity2][index] +
          usageObject[building][commodity3][index]
      );
    }
    return totals;
  };

  calculateTotalDollarSavings = (building, dollarObject) => {
    let accumulator = [];
    let commodity1 = commodityList[0];
    let commodity2 = commodityList[1];
    let commodity3 = commodityList[2];
    for (
      let index = 0;
      index < dollarObject[building][commodity1].length;
      index++
    ) {
      accumulator.push(
        dollarObject[building][commodity1][index] +
          dollarObject[building][commodity2][index] +
          dollarObject[building][commodity3][index]
      );
    }
    return accumulator;
  };

  handleGraphSelect = (e, graphIndex, building) => {
    // change the selected graph when child is selected
    e.preventDefault();
    this.toggleSelectedGraph(graphIndex, building);
  };

  toggleSelectedGraph = async (graphIndex, selectedBuilding) => {
    // async because of setState
    let tempSelectedGraph = this.resetGraphObject(); // temporary variable for updating state
    tempSelectedGraph[selectedBuilding][graphIndex] = true;

    if (graphIndex !== commodityList.length) {
      // commodity graph
      await this.setState({
        selectedGraph: tempSelectedGraph,
        selectedBuilding: selectedBuilding,
        selectedCommodity: commodityList[graphIndex],
        graphSelected: true
      });
    } else {
      // totals graph
      await this.setState({
        selectedGraph: tempSelectedGraph,
        selectedBuilding: selectedBuilding,
        selectedCommodity: "totals",
        graphSelected: true
      });
    }
  };

  resetGraphObject = () => {
    // return an array of 0's with size-initialized
    let tempObject = this.state.selectedGraph;
    for (let building in tempObject) {
      for (
        let graphIndex = 0;
        graphIndex < commodityList.length + 1;
        graphIndex++
      ) {
        tempObject[building][graphIndex] = false;
      }
    }
    return tempObject;
  };

  getBuildingCommTotal = e => {
    this.props.buildingCommTotal(e);
  };

  // return the basic project grid for both views (initial render and chart selected)
  getBasicGrid = () => {
    return (
      <div className="project-grid-wrapper">
        <div className="buildComm-info-wrap">
          <div className="buildComm-description">
            {buildingProjectTabs.buildingComm.description}
          </div>
          <div className="img-quote-wrap">
            <img src={headshot} alt="hiroko's headshot" />
            <div className="buildComm-quote">
              <i>"{buildingProjectTabs.buildingComm.quote}"</i>
              <div className="buildComm-name">
                <i>
                  <b>- {buildingProjectTabs.buildingComm.name}</b>
                </i>
              </div>
            </div>
          </div>
        </div>
        <div className="buildingProjects-title">
          Cost Analysis
          <span>Select a graph to see the savings</span>
        </div>
        <div className="project-grid">
          <ProjectGrid
            commodityList={commodityList}
            buildingNameList={buildingNameList}
            shortNameList={shortNameList}
            savingsInDollars={this.savingsInDollars}
            selectGraph={this.handleGraphSelect}
            graphSelectionArray={this.state.selectedGraph}
            assignSavingsToParentState={this.assignSavingsToState}
            buildingCommTotal={this.getBuildingCommTotal}
          />
        </div>
      </div>
    );
  };

  // return the highcharts jsx for a selected building to the render function
  getChartContainer = () => {
    let building = this.state.selectedBuilding;
    let commodity = this.state.selectedCommodity;
    let currentDate = new Date();

    return (
      <div className="chart-container">
        <div className={getChartTitleFormatting(commodity)}>
          {capitalizeFirstLetter(getChartTitle(commodity))}
        </div>
        <UsageChart
          commodity={commodity}
          monthlyUsage={this.monthlyUsageKBTU[building][commodity].map(value =>
            roundNumber(value, 0)
          )}
          monthlyBaselineUsage={this.monthlyBaselineUsageKBTU[building][
            commodity
          ].map(value => roundNumber(value, 0))}
          monthlyCost={this.monthlyCost[building][commodity].map(value =>
            roundNumber(value, 0)
          )}
          monthlyBaselineCost={this.monthlyBaselineCost[building][
            commodity
          ].map(value => roundNumber(value, 0))}
          months={getTwelveMonths(currentDate.getMonth())}
        />
      </div>
    );
  };

  // return the savings table jsx to the render function
  getTableContainer = () => {
    let currentDate = new Date();
    return (
      <div className="table-container">
        <SavingsTable
          dates={getMonthsAndYears(
            currentDate.getYear(),
            currentDate.getMonth()
          )}
          usage={
            this.monthlyUsageKBTU[this.state.selectedBuilding][
              this.state.selectedCommodity
            ]
          }
          savings={
            this.savingsInDollars[this.state.selectedBuilding][
              this.state.selectedCommodity
            ]
          }
        />
      </div>
    );
  };

  render() {
    if (this.state.hasData) {
      // initial render
      return (
        <div className="building-commissioning standard-card">
          {this.getBasicGrid()}
          {this.state.graphSelected && this.getChartContainer()}
          {this.state.graphSelected && this.getTableContainer()}
          <div className="clear" />
        </div>
      );
    } else {
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    }
  }
}

export default BuildingCommissioning;
