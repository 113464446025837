let Highcharts = require("highcharts");

export const initializeGraph = args => {
  window.usageChart = new Highcharts.Chart({
    colors: [args.color],
    title: null,
    chart: {
      renderTo: "compare-building-types-top-chart",
      type: "bar",
      height: 175,
      plotBorderColor: "#F1F1F2",
      plotBorderWidth: 0
    },
    backgroundColor: "#f1f1f2",
    borderWidth: 0,
    style: {
      color: "#000",
      fontFamily: [
        "Proxima Nova",
        "Lucida Grande",
        "Lucida Sans",
        "Verdana",
        "sans-serif"
      ]
    },
    shadow: false,
    crosshairs: true,
    shared: true,
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    xAxis: {
      categories: args.xAxis,
      labels: {
        align: "left",
        reserveSpace: true,
        style: {
          color: "#888788",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: "14px"
        }
      },
      title: {
        style: {
          color: "#888788",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        }
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: "transparent",
      minorTickLength: 0,
      tickLength: 0
    },
    yAxis: {
      title: {
        text: "kBtu/sq.ft.",
        align: "middle",
        rotation: 0,
        offset: 28,
        style: {
          color: "#888788",
          fontSize: "12px"
        }
      },
      plotLines: [
        {
          color: "#d4d3d4",
          value: args.euiAverage,
          width: 2,
          zIndex: 5
        }
      ],
      labels: {
        style: {
          color: "#888788",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: "14px"
        }
      },
      lineWidth: 2,
      gridLineWidth: 2,
      lineColor: "#F1F1F2",
      gridLineColor: "#F1F1F2",
      tickColor: "#fff",
      tickWidth: 0,
      maxPadding: 0,
      opposite: false,
      tickAmount: 4,
      showLastLabel: true,
      endOnTick: false
    },
    tooltip: {
      formatter: function() {
        let format =
          '<span style="font-size:12px;font-weight:600;color:' +
          args.color +
          '">' +
          this.x;
        format += "<br/>" + this.y + " kBtu/sq.ft.</span>";
        format +=
          '<br /><span style="font-size:12px;font-weight:600;color:#888788">' +
          args.buildingType +
          " Average EUI";
        format += "<br/>" + args.euiAverage + " kBtu/sq.ft.</span>";
        return format;
      },
      shared: true,
      useHTML: true,
      valueDecimals: 0,
      outside: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    series: [
      {
        showInLegend: false,
        name: args.seriesLabel,
        data: args.series
      }
    ]
  });
};
