/**
 * Component for the Search box located at the top right of the map.
 * The state searchData is a combined array of Facilities Link building
 * data combined with the CEED Building data. The search iterates
 * through this array to identify matches based on the building's
 * Primary Display name, Official name, short name (id), or it's CEED
 * collection name.
 */

import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as markerClickActions from "../../../../actions/markerClickActions";
import PropTypes from "prop-types";

import SearchBoxRow from "./SearchBoxRow.js";
import magnifyingGlass from "./images/magnifying-glass.svg";
import "./SearchBox.css";

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTarget: "",
      results: [],
      searchIconClass: "", // Set to "hide" to remove magnifying glass.
      meteringUnavailableClass: "search-metering-unavailable"
    };
    this.loadBuildingOptions = this.loadBuildingOptions.bind(this);
    this.resetSearchQuery = this.resetSearchQuery.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
  }

  // Begins searching when a key is pressed in the searchbox.
  loadBuildingOptions(event) {
    let buildingSearchTarget = event.target.value,
      searchResults = [],
      searchIcon = "";
    if (event.target.value.length > 0) {
      searchIcon = "hide"; // Hide magnifying glass icon during search.
      searchResults = this.parseBuildingList(buildingSearchTarget);
      if (searchResults.length === 0)
        searchResults.push({
          primaryDisplay: "No results found."
        });
    }
    this.setState(prevState => {
      return {
        searchTarget: buildingSearchTarget,
        results: searchResults,
        searchIconClass: searchIcon,
        meteringUnavailableClass: "search-metering-unavailable"
      };
    });
  }

  // Performs the search as described in the intro.
  parseBuildingList(searchString) {
    let results = [];
    for (let i = 0; i < this.props.searchData.length; i++) {
      let buildingObject = this.props.searchData[i];
      let name = buildingObject.primaryDisplay.toLowerCase(),
        officialName = buildingObject.officialName.toLowerCase(),
        shortName = "",
        ceedName = "",
        query = searchString.toLowerCase(),
        aliases = [],
        found = false;
      if (buildingObject.buildingData.length !== 0) {
        shortName = buildingObject.buildingData[0].id.toLowerCase();
        ceedName = buildingObject.buildingData[0].name.toLowerCase();
        aliases = buildingObject.buildingData[0].alias;
      }
      if (
        name.indexOf(query) !== -1 ||
        officialName.indexOf(query) !== -1 ||
        shortName.indexOf(query) !== -1 ||
        ceedName.indexOf(query) !== -1
      )
        found = true;
      //Check possible alias names if not found on first pass.
      if (!found)
        if (aliases !== null && aliases.length > 0)
          for (let j = 0; j < aliases.length; j++) {
            let aliasName = aliases[j].toLowerCase();
            if (aliasName.indexOf(query) !== -1) found = true;
          }
      if (found) results.push(buildingObject);
    }
    return results;
  }

  // Clears the current search back to defaults.
  resetSearchQuery(event) {
    if (event === undefined || event.type === "click" || event.key === "Enter")
      this.setState(prevState => {
        return {
          searchTarget: "",
          results: [],
          searchIconClass: "",
          meteringUnavailableClass: "search-metering-unavailable"
        };
      });
  }

  // Performs the appropriate action when a building row is clicked.
  onClickSearch(event, clickedBuilding) {
    if (
      event === undefined ||
      event.type === "click" ||
      event.key === "Enter"
    ) {
      if (this.props.enableSeason) {
        this.props.displayBuildingName(clickedBuilding);
      }
      if (clickedBuilding.buildingData === undefined) this.resetSearchQuery();
      else if (clickedBuilding.buildingData.length !== 0) {
        let mapDatum = this.props.mapData.find(
          datum => datum.id === clickedBuilding.buildingData[0].id
        );
        if (clickedBuilding.buildingData[0].annualCost) {
          this.props.actions.markerClickHaveCost(true, mapDatum);
        } else {
          this.props.actions.markerClickNeedCost(true, mapDatum);
        }

        this.props.actions.changeMapLocation(
          clickedBuilding.buildingData[0].center
        );
        this.resetSearchQuery();
      } else {
        this.setState(prevState => {
          return {
            results: [],
            searchTarget: clickedBuilding.primaryDisplay,
            meteringUnavailableClass: "search-metering-unavailable show"
          };
        });
      }
    }
  }

  render() {
    return (
      <div className="search-box">
        <div className="search-input-box">
          <input
            type="text"
            name="buildingSearch"
            className="search-input-control"
            autoComplete="off"
            placeholder="Building Search"
            value={this.state.searchTarget}
            onChange={this.loadBuildingOptions}
          />
          <img
            src={magnifyingGlass}
            alt="Search icon"
            className={this.state.searchIconClass}
          />
          <div
            className="search-input-clear"
            onClick={this.resetSearchQuery}
            onKeyPress={this.resetSearchQuery}
            tabIndex="0"
          >
            &times;
          </div>
        </div>
        <div className={this.state.meteringUnavailableClass}>
          Energy Metering Data
          <br />
          Unavailable for this Building
        </div>
        <div className="search-results">
          {this.state.results.map((building, key) => (
            <SearchBoxRow
              key={key}
              building={building}
              onClick={event => this.onClickSearch(event, building)}
            />
          ))}
        </div>
      </div>
    );
  }
}

SearchBox.propTypes = {
  searchData: PropTypes.array.isRequired,
  mapData: PropTypes.array.isRequired,
  enableSeason: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    searchData: state.searchData,
    mapData: state.mapData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(markerClickActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
