/**
 * This component builds out the CompareChart component on an individual building.
 */

import React from "react";
import PropTypes from "prop-types";

import * as utilities from "../../../../common/utilities";
import CompareGridHeader from "./CompareGridHeader";
import CompareGridRow from "./CompareGridRow";

const BUILDING_TYPES = ["lab", "office", "housing", "classroom", "community"],
  MAX_LIST_HEIGHT = 410;

class CompareChart extends React.Component {
  constructor(props) {
    super(props);
    this.listContainer = React.createRef();
    this.state = {
      filters: {
        lab: true,
        office: true,
        housing: true,
        classroom: true,
        community: true,
        normalized: true
      },
      sortSelection: "eui.total",
      sortAscending: false,
      filteredList: [],
      maxEui: 0,
      scrollable: true
    };
    this.toggleType = this.toggleType.bind(this);
    this.sortComparables = this.sortComparables.bind(this);
  }

  componentWillMount() {
    if (this.props.comparables.length > 0)
      this.buildFilterList(this.props.comparables, this.state.filters);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.listContainer.current !== null) {
      if (
        this.listContainer.current.clientHeight >= MAX_LIST_HEIGHT &&
        !prevState.scrollable
      )
        this.setState({ scrollable: true });
      if (
        this.listContainer.current.clientHeight < MAX_LIST_HEIGHT &&
        prevState.scrollable
      )
        this.setState({ scrollable: false });
    }
    if (
      this.props.comparables !== prevProps.comparables &&
      this.props.comparables.length > 0
    )
      this.buildFilterList(this.props.comparables, this.state.filters);
  }

  buildFilterList(comparablesArray, filtering) {
    let filteredComparables = this.applyFilters(comparablesArray, filtering),
      splitArray = [];
    filteredComparables.sort(
      this.dynamicSort(this.state.sortSelection, this.state.sortAscending)
    );
    splitArray = this.splitArray(filteredComparables);
    this.setState(prevState => {
      return {
        filteredList: splitArray
      };
    });
    this.setMaxEui(splitArray);
  }

  applyFilters(comparablesArray, filtering) {
    let filteredArray = [];
    for (let i = 0; i < comparablesArray.length; i++)
      if (filtering[comparablesArray[i].buildingType])
        filteredArray.push(comparablesArray[i]);
    return filteredArray;
  }

  setCheckboxSpanClass(buildingType) {
    return "building-comparison-options-building-types-label " + buildingType;
  }

  setSliderLabelClass() {
    let newClass = "building-comparison-options-normalize-label";
    if (!this.state.filters.normalized) newClass += " inactive";
    return newClass;
  }

  toggleType(event) {
    let newFilters = Object.assign({}, this.state.filters);
    newFilters[event.target.id] = !newFilters[event.target.id];
    this.setState(prevState => {
      return {
        filters: newFilters
      };
    });
    this.buildFilterList(this.props.comparables, newFilters);
  }

  dynamicSort(property, order) {
    let sortOrder = 1;
    if (!order) {
      sortOrder = -1;
    }
    if (property.indexOf("eui.") !== -1) {
      property = property.slice(4);
      if (this.state.filters.normalized || property === "total") {
        return function(a, b) {
          let result =
            parseInt(a.eui[property], 10) < parseInt(b.eui[property], 10)
              ? -1
              : parseInt(a.eui[property], 10) > parseInt(b.eui[property], 10)
              ? 1
              : 0;
          return result * sortOrder;
        };
      }
      return function(a, b) {
        let result =
          parseInt(a.annualUsage[property], 10) <
          parseInt(b.annualUsage[property], 10)
            ? -1
            : parseInt(a.annualUsage[property], 10) >
              parseInt(b.annualUsage[property], 10)
            ? 1
            : 0;
        return result * sortOrder;
      };
    }
    return function(a, b) {
      let result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  setCompareBuildingClass() {
    let comparableClass = "building-comparison-main-building";
    if (this.state.scrollable) comparableClass += " scrollable";
    return comparableClass;
  }

  splitArray(comparables) {
    let threeCommodities = [],
      twoCommodities = [],
      oneCommodity = [],
      noCommodity = [];
    for (let i = 0; i < comparables.length; i++) {
      let euiProps = Object.assign({}, comparables[i].eui),
        count = 0;
      for (let item in euiProps)
        if (euiProps[item] !== 0 && item !== "gas" && item !== "solar") count++;
      switch (count) {
        case 4:
          threeCommodities.push(comparables[i]);
          break;
        case 3:
          twoCommodities.push(comparables[i]);
          break;
        case 2:
          oneCommodity.push(comparables[i]);
          break;
        default:
          noCommodity.push(comparables[i]);
      }
    }
    return threeCommodities
      .concat(twoCommodities)
      .concat(oneCommodity)
      .concat(noCommodity);
  }

  setMaxEui(limitedArray) {
    let max = this.props.building.eui.total;
    for (let i = 0; i < limitedArray.length; i++)
      if (limitedArray[i].eui.total > max) max = limitedArray[i].eui.total;
    this.setState(prevState => {
      return {
        maxEui: max
      };
    });
  }

  sortComparables(event) {
    let newSortLayout = event.target.id,
      newSortedArray = this.applyFilters(
        this.props.comparables,
        this.state.filters
      ),
      newSortDirection = false,
      splitArray = [];
    if (newSortLayout === this.state.sortSelection) {
      newSortDirection = !this.state.sortAscending;
      newSortedArray.sort(this.dynamicSort(newSortLayout, newSortDirection));
      splitArray = this.splitArray(newSortedArray);
      this.setState(prevState => {
        return {
          sortAscending: newSortDirection,
          filteredList: splitArray
        };
      });
    } else {
      if (newSortLayout === "name") newSortDirection = true;
      newSortedArray.sort(this.dynamicSort(newSortLayout, newSortDirection));
      splitArray = this.splitArray(newSortedArray);
      this.setState(prevState => {
        return {
          sortAscending: newSortDirection,
          filteredList: splitArray,
          sortSelection: newSortLayout
        };
      });
    }
    this.setMaxEui(splitArray);
  }

  render() {
    return (
      <div className="building-comparison-wrapper graph-card">
        <div className="building-comparison-title">
          Compare {this.props.building.name} with other buildings:
        </div>
        <div className="building-comparison-options">
          <div className="building-comparison-options-building-types">
            {BUILDING_TYPES.map(type => (
              <label
                key={type}
                className="building-comparison-building-types-label"
                htmlFor={type}
              >
                <input
                  type="checkbox"
                  defaultChecked={this.state.filters[type]}
                  onClick={this.toggleType}
                  id={type}
                />
                <span className={this.setCheckboxSpanClass(type)}>
                  {utilities.capitalizeFirstLetter(type)}
                </span>
              </label>
            ))}
          </div>
          <div className="building-comparison-options-normalize">
            <label className="building-comparison-normalize-switch">
              <input
                type="checkbox"
                checked={!!this.state.filters.normalized}
                onChange={this.toggleType}
                id="normalized"
              />
              <span className="building-comparison-normalize-slider" />
            </label>
            <div
              className={this.setSliderLabelClass()}
              onClick={this.toggleType}
              id="normalized"
            >
              Normalized by Sq. ft.
            </div>
          </div>
        </div>
        <CompareGridHeader
          onClick={this.sortComparables}
          normalized={this.state.filters.normalized}
        />
        <div className={this.setCompareBuildingClass()}>
          <CompareGridRow
            building={this.props.building}
            onClick={this.sortComparables}
            normalized={this.state.filters.normalized}
            maxEui={this.state.maxEui}
          />
        </div>
        <div
          className="building-comparison-comparables"
          ref={this.listContainer}
        >
          {this.state.filteredList.map((building, index) => (
            <a href={"/building/" + building.id} key={index}>
              <CompareGridRow
                building={building}
                onClick={this.sortComparables}
                normalized={this.state.filters.normalized}
                maxEui={this.state.maxEui}
              />
            </a>
          ))}
        </div>
      </div>
    );
  }
}

CompareChart.propTypes = {
  building: PropTypes.object.isRequired,
  comparables: PropTypes.array.isRequired
};

export default CompareChart;
