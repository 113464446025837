/**
 * This component builds out the Demand chart.
 */

import React from "react";
import PropTypes from "prop-types";
import infoIcon from "../../../../chcp/common/images/info-icon.svg";
import { commodityTypes } from "../../../../common/config";
import CsvButton from "../../../../common/CsvButton";
import zoomIcon from "../../../../../images/zoom.svg";

const DATA_TYPE = "demand";

// Sets the Time parameter buttons active state.
const setButtonClass = (id, currentDemandSelection, ready) => {
  if (id === currentDemandSelection) return "building-side-button active";
  else if (!ready) return "building-side-button not-ready";
  return "building-side-button";
};

// Creates a dynamic class for each parameter to reference.
const setCheckboxSpanClass = commodity => {
  return "building-commodity-label " + commodity;
};

const DemandChart = ({
  currentDemandSelection,
  dataReady,
  demandData,
  commodities,
  checkState,
  shortname,
  changeDemandSelection,
  toggleData
}) => {
  return (
    <div className="building-demand graph-card">
      <div className="building-demand-title">
        Daily Demand
        <div className="demand-zoom-helper">
          <img src={zoomIcon} alt="Magnifying glass" /> Click and drag to zoom.
        </div>
      </div>
      <img className="demand-info-icon" src={infoIcon} alt="info icon" />
      <div className="demand-speech-bubble-ds">
        <div className="demand-speech-bubble-ds-arrow" />
        <p>
          <b>kBtu/hr</b>
        </p>
        <p>
          A Btu is 1055 joules, and is the amount of energy required to heat 1
          lb of water 1°F. A kBtu/hr is a rate of energy demand.
        </p>
      </div>
      <div className="building-graph">
        <div className="building-chart">
          <div id="daily-demand" />
        </div>
        <div className="building-side-options">
          <div
            className={setButtonClass(
              "dailyData",
              currentDemandSelection,
              dataReady.dailyData
            )}
            id="dailyData"
            onClick={dataReady.dailyData ? changeDemandSelection : null}
          >
            {dataReady.dailyData ? "Past 24 hrs" : "Loading..."}
          </div>
          <div
            className={setButtonClass(
              "weeklyData",
              currentDemandSelection,
              dataReady.weeklyData
            )}
            id="weeklyData"
            onClick={dataReady.weeklyData ? changeDemandSelection : null}
          >
            {dataReady.weeklyData ? "Past 7 Days" : "Loading..."}
          </div>
          <div
            className={setButtonClass(
              "monthlyData",
              currentDemandSelection,
              dataReady.monthlyData
            )}
            id="monthlyData"
            onClick={dataReady.monthlyData ? changeDemandSelection : null}
          >
            {dataReady.monthlyData ? "Past 30 Days" : "Loading..."}
          </div>
          <div className="building-commodities-wrap">
            <div className="building-commodities">
              {commodities.map(commodity => !(commodities.includes("hotWater") && commodity === 'steam') && (
                <label
                  key={commodity}
                  className="building-commodity"
                  htmlFor={commodity}
                >
                  <input
                    type="checkbox"
                    checked={checkState[commodity]}
                    onChange={toggleData}
                    id={commodity}
                  />
                  <span className={setCheckboxSpanClass(commodity)}>
                  {commodity === "steam"
                          ? "Hot Water"
                          : commodityTypes[commodity].displayName}
                  </span>
                </label>
              ))}
              <label key="oat" className="building-commodity" htmlFor="oat">
                <input
                  type="checkbox"
                  checked={checkState.oat}
                  onChange={toggleData}
                  id="oat"
                />
                <span className="building-commodity-label oat">
                  Outside Air
                  <div className="building-side-oat-label-spacer">
                    Temperature
                  </div>
                </span>
              </label>
            </div>
          </div>
          <CsvButton
            data={demandData}
            commodities={commodities}
            shortname={shortname}
            dataType={DATA_TYPE}
          />
        </div>
      </div>
    </div>
  );
};

DemandChart.propTypes = {
  currentDemandSelection: PropTypes.string.isRequired,
  dataReady: PropTypes.object.isRequired,
  demandData: PropTypes.array.isRequired,
  commodities: PropTypes.array.isRequired,
  checkState: PropTypes.object.isRequired,
  shortname: PropTypes.string.isRequired,
  changeDemandSelection: PropTypes.func.isRequired,
  toggleData: PropTypes.func.isRequired
};

export default DemandChart;
