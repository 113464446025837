import React from "react";
import { Link } from "react-router-dom";

const HOUSING_BUILDINGS = {
  cuarto: {
    buildings: "Yosemite Hall, Shasta Hall, Tahoe residents",
    team: "Cuarto"
  },
  "segundo.regan": {
    buildings: "Campo, Indio, Nova, Paloma, Rienda, Sereno, Talara",
    team: "Segundo Regan"
  },
  "segundo.highrises": {
    buildings: "Bixby, Gilmore, Malcolm, Ryerson",
    team: "Segundo High Rises"
  },
  "segundo.north": {
    buildings: "Alder, Miller, Thompson",
    team: "Segundo North"
  },
  tercero1: {
    buildings: "Kearney, Laben",
    team: "Tercero 1"
  },
  tercero2: {
    buildings: "Campbell, Potter, Wall",
    team: "Tercero 2"
  },
  tercero3: {
    buildings:
      "Hawthorn, Live Oak, Currant, Mahogany, Sequoia, Scrub Oak, Pine",
    team: "Tercero 3"
  },
  tercero4: {
    buildings: "Cottonwood, Madrone, Redwood",
    team: "Tercero 4"
  }
};

const EwcRankingTable = ({ tableData }) => {
  return (
    <div className="ewc-ranking-table">
      <div className="ranking-table-header">
        <div className="ranking-table-headline-team">Team</div>
        <div className="ranking-table-headline-savings">% Savings</div>
      </div>
      {tableData.map((listing, index) => {
        return (
          <Link
            to={"/building/" + listing.building + "?tab=savings"}
            key={listing + index}
          >
            <div className="ranking-table-card-wrapper">
              <div className="ranking-table-card">
                <div className={"ranking-table-value value" + (index + 1)}>
                  {index < 3 ? index + 1 : ""}
                </div>
                <div className="ranking-table-building">
                  {HOUSING_BUILDINGS[listing.building].team}
                </div>
                <div className="ranking-table-percent">{listing.Value}%</div>
              </div>
              <div className="ranking-table-buildings">
                {HOUSING_BUILDINGS[listing.building].buildings}
              </div>
              <div className="ranking-table-divider"></div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default EwcRankingTable;
