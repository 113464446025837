import React from "react";

const BuildingTypeDescriptions = ({ buildingType }) => {
  return (
    <React.Fragment>
      {buildingType === "classroom" && (
        <p className="building-type-description">
          <b>Classroom buildings</b> have fluctuating periods of high occupancy
          and vacancy. The fluctuating occupancy should be matched by the HVAC
          system that provides heating, cooling and ventilation to the space. So
          when there’s a class, the system should be running accordingly to keep
          people comfortable.
        </p>
      )}
      {buildingType === "community" && (
        <p className="building-type-description">
          The <b>community category</b> describes buildings that have higher
          traffic flow, with gathering spaces for people to meet and/or study.
          Some of these buildings include the ARC and the Student Community
          Center. This category also includes buildings with functions other
          than classrooms, offices and laboratories, like the ‘hospital’ space
          in the Student Health & Wellness building.
        </p>
      )}
      {buildingType === "lab" && (
        <p className="building-type-description">
          Buildings with <b>laboratory spaces</b> typically use a lot of energy
          because of the required ventilation. The air coming into the lab must
          be 100% outside air, and then it must completely leave the building.
          Moving this quantity of air with fans along with heating and cooling
          the air is an energy intensive process.
        </p>
      )}
      {buildingType === "office" && (
        <p className="building-type-description">
          <b>Office spaces</b> are where most of the UC Davis staff and faculty
          spend their time. Unlike a laboratory space, the air coming into the
          room can be recirculated throughout the building. This lowers energy
          use because less energy is needed to heat and cool the outside air to
          reach the desired temperature.
        </p>
      )}
      {buildingType === "housing" && (
        <p className="building-type-description">
          The <b>housing category</b> is specifically for the residence hall
          buildings on campus. The buildings are clustered together based on
          when they were built, and these clusters are metered for their energy
          use as one group. The building clusters fall into three distinct
          housing areas: Segundo, Tercero and Cuarto.
        </p>
      )}
    </React.Fragment>
  );
};

export default BuildingTypeDescriptions;
