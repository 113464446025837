/**
 * This component builds out the CompareBuildingTypes card on an individual building.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import * as utilities from "../../../../common/utilities";
import { buildingTypes } from "../../../../common/config";
import BuildingDescription from "./BuildingDescription";
import BuildingTypeStats from "./BuildingTypeStats";
import { initializeGraph } from "./chartOptions/compareBuildingTypesChart";
import "./CompareBuildingTypes.css";

const BUILDING_TYPES = ["lab", "classroom", "housing", "office", "community"],
  MAX_TOP_BUILDINGS = 6;

class CompareBuildingTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buildingSelection: this.props.buidingType,
      averages: {
        defaultType: 0
      },
      graphBuildings: {}
    };
    this.setSelection = this.setSelection.bind(this);
  }

  componentWillMount() {
    this.processBuildings(this.props.buildings);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.buildingType !== nextProps.buildingType)
      this.setState(prevState => {
        return {
          buildingSelection: nextProps.buildingType
        };
      });
  }

  componentDidMount() {
    this.loadBuildingTypesGraph(
      this.state.graphBuildings,
      this.state.buildingSelection
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.buildings !== prevProps.buildings)
      this.processBuildings(this.props.buildings);
    if (
      this.state.graphBuildings !== prevState.graphBuildings ||
      this.state.buildingSelection !== prevState.buildingSelection
    )
      this.loadBuildingTypesGraph(
        this.state.graphBuildings,
        this.state.buildingSelection
      );
  }

  activeButtonCheck(buildingType) {
    if (this.state.buildingSelection === buildingType)
      return buildingType + " active";
    return buildingType;
  }

  loadBuildingTypesGraph(buildings, selection) {
    let buildingSet = buildings[selection],
      graphArgs = {
        color: buildingTypes[selection].color,
        euiAverage: this.state.averages[selection],
        series: [],
        xAxis: [],
        buildingType: utilities.capitalizeFirstLetter(selection)
      };
    for (let i = 0; i < buildingSet.length; i++) {
      graphArgs.series.push(buildingSet[i].eui);
      graphArgs.xAxis.push(buildingSet[i].name);
    }
    initializeGraph(graphArgs);
  }

  processBuildings(buildings) {
    let averages = {},
      sortedBuildings = {};
    buildings.sort(function(a, b) {
      return b.eui - a.eui;
    });
    for (let i = 0; i < BUILDING_TYPES.length; i++) {
      let total = 0,
        amount = 0;
      sortedBuildings[BUILDING_TYPES[i]] = [];
      for (let j = 0; j < buildings.length; j++) {
        if (
          buildings[j].buildingType === BUILDING_TYPES[i] &&
          buildings[j].eui !== null &&
          buildings[j].eui !== 0
        ) {
          total += parseInt(buildings[j].eui, 10);
          amount++;
          if (amount <= MAX_TOP_BUILDINGS)
            sortedBuildings[BUILDING_TYPES[i]].push(buildings[j]);
        }
      }
      averages[BUILDING_TYPES[i]] = Math.round(total / amount);
    }
    this.setState(prevState => {
      return {
        averages: averages,
        graphBuildings: sortedBuildings
      };
    });
  }

  setSelection(event) {
    let selection = event.target.id;
    this.setState(prevState => {
      return {
        buildingSelection: selection
      };
    });
  }

  render() {
    return (
      <div className="compare-building-types graph-card">
        <div className="compare-building-types-title">
          UC Davis Building Types
        </div>
        <div className="compare-building-types-options">
          {BUILDING_TYPES.map(type => (
            <div
              className={
                "compare-building-types-button " + this.activeButtonCheck(type)
              }
              id={type}
              onClick={this.setSelection}
              key={type}
            >
              {buildingTypes[type].displayName.toUpperCase()}
            </div>
          ))}
        </div>
        <BuildingDescription selection={this.state.buildingSelection} />
        <BuildingTypeStats
          selection={this.state.buildingSelection}
          average={this.state.averages[this.state.buildingSelection]}
        />
      </div>
    );
  }
}

CompareBuildingTypes.propTypes = {
  buildings: PropTypes.array.isRequired,
  buidingType: PropTypes.string.isRequired
};

export default CompareBuildingTypes;
