import React from "react";
import PropTypes from "prop-types";
import ucdavis from "./images/ucdavis.png";
import EnergySourceDescriptions from "./EnergySourcesDescription";
import TotalEnergySourceChart from "./energySourcesChart/TotalEnergySourceChart";
import "./EnergySources.css";

const SOLAR_FARM_COMMODITIES = ["pge_demand", "solar_farm_demand"];

class EnergySources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalDemand: {},
      campus: {}
    };
  }

  componentWillMount() {
    if (
      this.props.campus !== {} &&
      this.props.campus.campusDemandDaily.length !== 0
    )
      this.setState({ campus: this.props.campus });
  }

  componentDidUpdate(prevProps) {
    if (this.props.campus !== prevProps.campus)
      this.setState({ campus: this.props.campus });
  }

  setOptions(commodity) {
    switch (commodity) {
      case "chcp_gas_demand":
        return { color: "#514A76", displayName: "Heating Natural Gas" };
      case "pge_demand":
        return {
          color: "#95BC43",
          displayName: "Carbon-Based Electricity and Hydropower"
        };
      case "solar_farm_demand":
        return { color: "#FED979", displayName: "Solar Farm Electricity" };
      case "rooftop_solar_demand":
        return { color: "#F2C75A", displayName: "Rooftop Solar Electricity" };
      default:
        return {};
    }
  }
  /*<EnergySourcesChart campus={this.state.campus} />*/
  render() {
    return (
      <div className="campus-energy">
        <h1>UC Davis Energy Sources</h1>
        <img
          className="campus-energy-image"
          src={ucdavis}
          alt="Energy Sources infographic"
        />
        <EnergySourceDescriptions />
        <TotalEnergySourceChart campus={this.props.campus} />
      </div>
    );
  }
}

EnergySources.propTypes = {
  campus: PropTypes.object.isRequired
};

export default EnergySources;
