import React from "react";
import PropTypes from "prop-types";
import { DATA_TYPE } from "../DataFilter";
import { helpHovers } from "../../common/utilities";
import HelpIcon from "../../common/images/helpicon.png";

export default function DataTypeSelection({
  toggleDataTypeSelection,
  selectedDataType
}) {
  return (
    <div className="data-filter-inner-column col1">
      <span className="data-filter-section-header">
        Choose usage or demand data*
      </span>
      <div className="radio-group">
        <div
          className={
            selectedDataType !== DATA_TYPE.USAGE_DATA
              ? "custom-radio-button input-box input-box-unselected"
              : "custom-radio-button input-box input-box-selected"
          }
        >
          <input
            type="radio"
            id="usage-data"
            value={DATA_TYPE.USAGE_DATA}
            name="data-types"
            checked={selectedDataType === DATA_TYPE.USAGE_DATA}
            onChange={toggleDataTypeSelection}
          />
          <label htmlFor="usage-data">Usage Data</label>
        </div>
        <div className="helpicon">
          <img src={HelpIcon} alt="Usage Data Help" />
          <div className="helpicon-hovers wide">{helpHovers.usage}</div>
        </div>
      </div>
      <div className="radio-group">
        <div
          className={
            selectedDataType !== DATA_TYPE.DEMAND_DATA
              ? "custom-radio-button input-box input-box-unselected"
              : "custom-radio-button input-box input-box-selected"
          }
        >
          <input
            type="radio"
            id="demand-data"
            value={DATA_TYPE.DEMAND_DATA}
            name="data-types"
            checked={selectedDataType === DATA_TYPE.DEMAND_DATA}
            onChange={toggleDataTypeSelection}
          />
          <label htmlFor="demand-data">Demand Data</label>
        </div>
        <div className="helpicon">
          <img src={HelpIcon} alt="Demand Data Help" />
          <div className="helpicon-hovers wide">{helpHovers.demand}</div>
        </div>
      </div>
    </div>
  );
}

DataTypeSelection.propTypes = {
  toggleDataTypeSelection: PropTypes.func.isRequired,
  selectedDataType: PropTypes.string.isRequired
};
