import React from "react";
import PropTypes from "prop-types";
import * as utilities from "../../../common/utilities";

import Tooltip from "../../common/Tooltip";

class TotalProductionGrid extends React.Component {
  render() {
    return (
      <div className="total-production-grid">
        <div className="production-grid-header">
          <div className="production-grid-column column-1">Commodity</div>
          <div className="production-grid-column column-2">
            Average Monthly Production
          </div>
          <div className="production-grid-column column-3">
            Total Annual Production
          </div>
        </div>
        <div className="production-grid-row steam" id="production-grid-steam">
          <div className="production-grid-column column-1">
            <Tooltip tooltipType="steam" />
            <b>Steam</b>
          </div>
          <div className="production-grid-column column-2">
            <b>{utilities.commaFormatNumbers(this.props.steam.monthly)}</b>{" "}
            MMBtu
          </div>
          <div className="production-grid-column column-3">
            <b>{utilities.commaFormatNumbers(this.props.steam.annual)}</b> MMBtu
          </div>
        </div>
        <div
          className="production-grid-row chilled-water"
          id="production-grid-chilled-water"
        >
          <div className="production-grid-column column-1">
            <Tooltip tooltipType="chilledWater" />
            <b>Chilled Water</b>
          </div>
          <div className="production-grid-column column-2">
            <b>
              {utilities.commaFormatNumbers(this.props.chilledWater.monthly)}
            </b>{" "}
            MMBtu
          </div>
          <div className="production-grid-column column-3">
            <b>
              {utilities.commaFormatNumbers(this.props.chilledWater.annual)}
            </b>{" "}
            MMBtu
          </div>
        </div>
      </div>
    );
  }
}

TotalProductionGrid.propTypes = {
  chilledWater: PropTypes.object.isRequired,
  steam: PropTypes.object.isRequired
};

export default TotalProductionGrid;
