export const LOAD_BUILDING_SUCCESS = "LOAD_BUILDING_SUCCESS";
export const LOAD_DESKTOP_ENERGY_SUCCESS = "LOAD_DESKTOP_ENERGY_SUCCESS";
export const LOAD_TLC_ENERGY_SUCCESS = "LOAD_TLC_ENERGY_SUCCESS";
export const LOAD_MAP_DATA_SUCCESS = "LOAD_MAP_DATA_SUCCESS";
export const LOAD_CEED_MOBILE_SUCCESS = "LOAD_CEED_MOBILE_SUCCESS";
export const LOAD_CONNECTIONS_SUCCESS = "LOAD_CONNECTIONS_SUCCESS";
export const LOAD_MAP_SEARCH_DATA_SUCCESS = "LOAD_MAP_SEARCH_DATA_SUCCESS";
export const LOAD_MAP_LOCATION_SUCCESS = "LOAD_MAP_LOCATION_SUCCESS";
export const UPDATE_MAP_DATA_SUCCESS = "UPDATE_MAP_DATA_SUCCESS";
export const LOAD_MARKER_DATA_SUCCESS = "LOAD_MARKER_DATA_SUCCESS";
export const UPDATE_MARKER_DATA_SUCCESS = "UPDATE_MARKER_DATA_SUCCESS";
// export const MARKER_CLICK = "MARKER_CLICK";
export const GREEN_BUILDING_CLICK = "GREEN_BUILDING_CLICK";
export const FILTER_BUTTON_CLICK = "FILTER_BUTTON_CLICK";
export const FILTER_SLIDER_CHANGE = "FILTER_SLIDER_CHANGE";
export const SIDEBAR_MENU_CLICK = "SIDEBAR_MENU_CLICK";
export const MAP_SELECTION_CHANGE = "MAP_SELECTION_CHANGE";
export const FULL_RANKING_CLICK = "FULL_RANKING_CLICK";
export const LOAD_ACE_SUCCESS = "LOAD_ACE_SUCCESS";
export const LOAD_CAMPUS_SUCCESS = "LOAD_CAMPUS_SUCCESS";
export const LOAD_EWC_RANKING_SUCCESS = "LOAD_EWC_RANKING_SUCCESS";
export const LOAD_EWC_WEEKLY_SUCCESS = "LOAD_EWC_WEEKLY_SUCCESS";
//Data Download
export const DATA_DOWNLOAD_LOAD_BUILDINGS_SUCCESS =
  "DATA_DOWNLOAD_LOAD_BUILDINGS_SUCCESS";
export const DATA_DOWNLOAD_REQUEST_SUCCESS = "DATA_DOWNLOAD_REQUEST_SUCCESS";
// CHCP Data
export const LOAD_CHCP_PRODUCTION_SUCCESS = "LOAD_CHCP_PRODUCTION_SUCCESS";
export const LOAD_CHCP_USAGE_SUCCESS = "LOAD_CHCP_USAGE_SUCCESS";
export const LOAD_CHCP_METRICS_SUCCESS = "LOAD_CHCP_METRICS_SUCCESS";
export const LOAD_SAVINGS_SUCCESS = "LOAD_SAVINGS_SUCCESS";
export const LOAD_BUILDING_PROJECTS_SUCCESS = "LOAD_BUILDING_PROJECTS_SUCCESS";
// Water Dashboard Data
export const LOAD_WATER_GOAL_SUCCESS = "LOAD_WATER_GOAL_SUCCESS";
export const LOAD_WATER_USAGE_SUCCESS = "LOAD_WATER_USAGE_SUCCESS";
