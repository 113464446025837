/**
 * This component builds out the 'What's Powering this Building?' card on the demand tab of an individual building.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import { commodityTypes } from "../../../../common/config";
import WhatsPoweringDescription from "./WhatsPoweringDescription";
import WhatsPoweringExample from "./WhatsPoweringExample";
import "./WhatsPowering.css";

class WhatsPowering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commoditySelection: "electricity",
      loading: true
    };
    this.setSelection = this.setSelection.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.commodities.length > 0 &&
      nextProps.commodities !== this.props.commodities
    ) {
      let selection = nextProps.commodities[0];
      this.setState(prevState => {
        return {
          commoditySelection: selection,
          loading: false
        };
      });
    }
  }

  componentDidMount() {
    if (this.props.commodities.length > 0) {
      let selection = this.props.commodities[0];
      this.setState(prevState => {
        return {
          commoditySelection: selection,
          loading: false
        };
      });
    }
  }

  activeButtonCheck(commodityType) {
    if (this.state.commoditySelection === commodityType)
      return commodityType + " active";
    return commodityType;
  }

  setSelection(event) {
    let selection = event.target.id;
    this.setState(prevState => {
      return {
        commoditySelection: selection
      };
    });
  }

  render() {
    if (this.state.loading) return null;
    return (
      <div className="demand-whats-powering graph-card">
        <div className="demand-whats-powering-title">
          What's Powering this Building?
        </div>
        <div className="demand-whats-powering-options">
          {this.props.commodities.map(commodity => {
            let icon = "./images/" + commodity + "-icon.svg";
              return (
                <div
                  className={
                    "demand-whats-powering-button " +
                    this.activeButtonCheck(commodity)
                  }
                  id={commodity}
                  onClick={this.setSelection}
                  key={commodity}
                >
                  <div className="demand-whats-powering-icon" id={commodity}>
                    <img
                      src={require(`${icon}`)}
                      alt={"Commodity icon."}
                      id={commodity}
                    />
                  </div>

                  {commodity === "steam" ? "HOT WATER" : commodityTypes[commodity].displayName.toUpperCase()}
                </div>
              );
          })}
        </div>
        <WhatsPoweringDescription selection={this.state.commoditySelection} />
        <WhatsPoweringExample selection={this.state.commoditySelection} />
      </div>
    );
  }
}

WhatsPowering.propTypes = {
  commodities: PropTypes.array.isRequired,
  shortname: PropTypes.string.isRequired
};

export default WhatsPowering;
