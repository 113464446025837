import React from "react"

import styles from "./ExampleCard.module.css"

import exampleGraphImage from "../../images/example-graph.jpg"

export default function ExampleCard() {
  return (
    <article className={styles.container}>
      <h2>What is normal energy use for the TLC?</h2>
      <img src={exampleGraphImage} alt="example graph" />
    </article>
  )
}
