import React from "react";

const SavingDescription = () => {
  return (
    <div className="saving-header-description">
      <p>
        Our Energy & Engineering Team has engineers, data scientists, and many
        stellar interns who work on energy projects across campus.
        <br />
        Together they put their passion for energy efficiency at work to improve
        our buildings' HVAC systems and get the campus closer to carbon
        neutrality. Read more about our team on the
        <a
          className="link"
          href="https://eco.ucdavis.edu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>
            {" "}
            <u>Facilities website</u>
          </b>
        </a>
        .
      </p>
    </div>
  );
};

export default SavingDescription;
