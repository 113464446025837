import React from "react";

import Menu from "./menu/Menu.js";
import menuIcon from "./images/menu.svg";

import "./Navigation.css";

const LOCATIONS_MENU_ORDER = {
    ewc: ["ewc", "ceed", "energystory"],
    energystory: ["energystory", "ceed", "ewc"]
  },
  DEFAULT_MENU = ["ceed", "ewc", "energystory"];

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
    this.setMenuOpen = this.setMenuOpen.bind(this);
  }

  setMenuOpen() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  render() {
    return (
      <React.Fragment>
        <div className="mobile-navigation">
          <div
            className="mobile-navigation-menu"
            onClick={e => this.setMenuOpen()}
          >
            <img src={menuIcon} alt="Open dashboards menu" />
          </div>
          <div className="mobile-navigation-title">{this.props.children}</div>
          <div className="mobile-navigation-right"></div>
        </div>
        <Menu
          menuOpen={this.state.menuOpen}
          menuOptions={
            LOCATIONS_MENU_ORDER[this.props.location] || DEFAULT_MENU
          }
          onClick={this.setMenuOpen}
        />
      </React.Fragment>
    );
  }
}

export default Navigation;
