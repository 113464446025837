import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const dataDownload = (state = initialState.dataDownload, action) => {
  switch (action.type) {
    case types.DATA_DOWNLOAD_LOAD_BUILDINGS_SUCCESS:
      let newDataDownloadBuildings = Object.assign({}, state);
      newDataDownloadBuildings.buildings = action.buildings;
      return newDataDownloadBuildings;
    case types.DATA_DOWNLOAD_REQUEST_SUCCESS:
      let newDataDownloadRequest = Object.assign({}, state);
      newDataDownloadRequest.downloadRequest = action.downloadRequest;
      return newDataDownloadRequest;
    default:
      return state;
  }
};
