import React from "react";
import { descriptions } from "../../../common/config";

const EnergyUseStory = () => {
  let column1 = descriptions.column1.map((data, index) => (
    <div className="total-energy-story-box" key={index}>
      <img src={data.icon} alt={data.title} />
      <div>
        {data.title}
        <p>{data.description}</p>
        <p className="total-energy-story-note">{data.note}</p>
      </div>
    </div>
  ));
  let column2 = descriptions.column2.map((data, index) => (
    <div
      className={"total-energy-story-box " + data.id}
      key={data.title + index}
    >
      <img src={data.icon} alt={data.title} />
      <div>
        {data.title}
        <div className={"total-energy-story-flag " + data.id}>{data.flag}</div>
        <p>{data.description}</p>
        <p className="total-energy-story-note">{data.note}</p>
      </div>
    </div>
  ));
  return (
    <div className="total-energy-story">
      <div className="total-energy-story-column1">{column1}</div>
      <div className="total-energy-story-column2">{column2}</div>
    </div>
  );
};

export default EnergyUseStory;
