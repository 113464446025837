import React, { Component } from "react";
import ReactHighcharts from "react-highcharts";
import PropTypes from "prop-types";

class UsageChart extends Component {
  chartConfig = {
    chart: {
      type: "column"
    },
    title: {
      text: null
    },
    xAxis: {
      categories: this.props.months
    },
    yAxis: {
      title: {
        text: "kBtu"
      }
    },
    series: [
      {
        name: "actual",
        data: [],
        color: ""
      },
      {
        name: "model",
        data: [],
        color: "grey"
      }
    ],
    tooltip: {}, // tooltip overriden with custom tooltip
    credits: {
      enabled: false
    }
  };

  componentDidMount() {
    this.getChartData(this.props);
  }

  componentWillReceiveProps = nextProps => {
    this.getChartData(nextProps);
  };

  shouldComponentUpdate() {
    return false;
  }

  getChartData = props => {
    let chart = this.refs.chart.getChart();
    chart.update({
      series: this.getSeries(props),
      tooltip: this.getToolTip(props)
    });
  };

  getSeriesColor = commodity => {
    let color = "";
    switch (commodity) {
      case "electricity":
        color = "#98BE48";
        break;
      case "steam":
        color = "#F49233";
        break;
      case "chilledWater":
        color = "#25A9C6";
        break;
      case "totals":
        color = "#222932";
        break;
      default:
        color = "grey";
        break;
    }
    return color;
  };

  getSeries = props => {
    return [
      {
        data: props.monthlyUsage,
        color: this.getSeriesColor(props.commodity)
      },
      {
        data: props.monthlyBaselineUsage
      }
    ];
  };

  getToolTip = props => {
    return {
      headerFormat: "",
      formatter: function() {
        let index = this.series.data.indexOf(this.point);
        if (this.series.name === "actual") {
          return (
            ` Usage (kBtu): <b>${this.y}</b><br/>` +
            ` Cost: <b>${"$" + props.monthlyCost[index]}</b>`
          );
        } else {
          // modeled (baseline) bar hovered on
          return (
            ` Usage (kBtu): <b>${this.y}</b><br/>` +
            ` Cost: <b>${"$" + props.monthlyBaselineCost[index]}</b>`
          );
        }
      }
    };
  };

  render() {
    return (
      <div>
        <ReactHighcharts config={this.chartConfig} ref="chart" />
      </div>
    );
  }
}

UsageChart.defaultProps = {
  commodity: "",
  monthlyUsage: [],
  monthlyBaselineUsage: [],
  monthlyCost: [],
  monthlyBaselineCost: [],
  months: []
};

UsageChart.propTypes = {
  commodity: PropTypes.string.isRequired,
  monthlyUsage: PropTypes.array.isRequired,
  monthlyBaselineUsage: PropTypes.array.isRequired,
  monthlyCost: PropTypes.array.isRequired,
  monthlyBaselineCost: PropTypes.array.isRequired,
  months: PropTypes.array.isRequired
};

export default UsageChart;
