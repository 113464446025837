/**
 * This component sets up the CSV download button.
 */

import React from "react";
import PropTypes from "prop-types";
import * as utilities from "../../../../common/utilities";
import { CSVLink } from "react-csv";

// Creates a filename for downloading building data.
// Format is 'buildingName_MM_DD_YYYY.csv'.
const getCsvFilename = (shortname, commodity) => {
  let downloadDate = new Date(),
    downloadMonth = downloadDate.getMonth() + 1;
  return (
    shortname +
    "_" +
    commodity +
    "_usage_" +
    downloadMonth +
    "-" +
    downloadDate.getDate() +
    "-" +
    downloadDate.getFullYear() +
    ".csv"
  );
};

// Sets up the header row for the CSV export.
const getCsvHeaders = unit => {
  let usageLabel = "Usage (" + unit + ")";
  return [
    { label: "Month", key: "month" },
    { label: usageLabel, key: "units" },
    { label: "Cost ($)", key: "cost" },
    { label: "kBtu", key: "kBtu" }
  ];
};

//Determines if newly received props need to be parsed for export.
const startProcess = breakdownData => {
  if (breakdownData.length > 0) {
    for (let i = 0; i < breakdownData.length; i++)
      breakdownData[i].cost = utilities.formatDollarValue(
        breakdownData[i].cost
      );
    return breakdownData;
  }
  return null;
};

const CommodityCsvButton = ({
  breakdownData,
  commodity,
  shortname,
  unit,
  icon
}) => {
  return (
    <div className="commodity-csv csv">
      <CSVLink
        data={startProcess(breakdownData)}
        filename={getCsvFilename(shortname, commodity)}
        headers={getCsvHeaders(unit)}
      >
        <div className="commodity-side-button">
          <img className="download-img" src={icon} alt="download icon" />
          <div>Download Data</div>
        </div>
      </CSVLink>
    </div>
  );
};

CommodityCsvButton.propTypes = {
  breakdownData: PropTypes.array.isRequired,
  commodity: PropTypes.string.isRequired,
  shortname: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired
};

export default CommodityCsvButton;
