import React from "react";
import PropTypes from "prop-types";

let Highcharts = require("highcharts/highstock"),
  totalSavingsChart = null;
Highcharts.setOptions({
  lang: {
    thousandsSep: ","
  }
});

class TotalSavingsChart extends React.Component {
  componentDidMount() {
    let ready = false;
    for (let key in this.props.savings)
      if (this.props.savings[key] > 0) ready = true;
    if (ready) this.initializeChart();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.savings) !== JSON.stringify(prevProps.savings)
    )
      this.updateChart();
  }

  componentWillUnmount() {
    totalSavingsChart = null;
  }

  updateChart() {
    if (totalSavingsChart === null) {
      this.initializeChart();
    } else {
      totalSavingsChart.update({
        series: this.setChartValues(this.props.savings),
        xAxis: {
          categories: this.props.years,
          tickLength: 0,
          labels: {
            style: {
              fontWeight: "bold",
              fontSize: "13px",
              lineHeight: 2,
              letterSpacing: "0.8px",
              color: "#000000"
            }
          }
        }
      });
    }
  }

  initializeChart() {
    totalSavingsChart = Highcharts.chart("cumulative-bar-chart", {
      chart: {
        height: 222,
        type: "column",
        events: {
          load() {
            let chart = this,
              tickLength = chart.xAxis[0].tickPositions.length;

            chart.series.forEach(s => {
              s.points.forEach(p => {
                //get last points
                if (p.index == tickLength - 1) {
                  p.update({
                    color: "#d6e5b6"
                  });
                }
              });
            });
          }
        }
      },
      title: {
        text: null
      },
      legend: {
        enabled: false
      },
      xAxis: {
        categories: this.props.years,
        tickLength: 0,
        labels: {
          style: {
            fontWeight: "normal",
            fontSize: "13px",
            lineHeight: 2,
            letterSpacing: "0.8px",
            color: "#000000"
          }
        }
      },
      yAxis: {
        title: {
          enabled: false
        },
        labels: {
          formatter: function() {
            return "$" + Highcharts.numberFormat(this.value, 0);
          },
          style: {
            color: "#9d9d9d",
            fontSize: "13px",
            lineHeight: 2,
            letterSpacing: "0.8px"
          }
        },
        tickAmount: 7,
        lineWidth: 1,
        tickWidth: 1
      },
      tooltip: {
        valuePrefix: "$"
      },
      series: this.setChartValues(this.props.savings),
      plotOptions: {
        dataLabels: {
          enabled: false
        }
      },
      credits: {
        enabled: false
      }
    });
  }

  setChartValues(savings) {
    let values = [];
    let tempData = [];

    for (let j = 0; j < this.props.years.length; j++) {
      tempData.push(this.props.savings[this.props.years[j]]);
    }
    values.push({
      name: "Savings",
      data: tempData,
      color: "#98be48"
    });

    return values.reverse();
  }

  render() {
    return <div id="cumulative-bar-chart" />;
  }
}

TotalSavingsChart.propTypes = {
  years: PropTypes.array.isRequired,
  savings: PropTypes.object.isRequired
};

export default TotalSavingsChart;
