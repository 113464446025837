import React from "react";
import ModalContent from "./modalContent/ModalContent";

let localStorageCheck = () => {
  if (typeof Storage !== "undefined") {
    if (localStorage.ceedVisitor) return true;
    return false;
  } else return false; //Older browser that does not support local storage.
};
let visitor = localStorageCheck();
let VisitorCheck = ({ previousVisitor, onClick }) => {
  if (previousVisitor) return null;
  return <ModalContent onClick={onClick} />;
};

class welcomeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previousVisitor: visitor
    };
    this.closeModal = this.closeModal.bind(this);
  }
  render() {
    return (
      <VisitorCheck
        previousVisitor={this.state.previousVisitor}
        onClick={this.closeModal}
      />
    );
  }

  closeModal() {
    localStorage.ceedVisitor = true;
    this.setState({
      previousVisitor: true
    });
  }
}

export default welcomeModal;
