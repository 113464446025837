export const initializeGraph = (commodity, data, yMax) => {
  return {
    chart: {
      type: "column",
      height: 120,
      backgroundColor: "transparent"
    },
    title: {
      text: null
    },
    xAxis: {
      visible: false,
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ]
    },
    yAxis: {
      visible: false,
      max: yMax
    },
    plotOptions: {
      series: {
        pointWidth: 30
      }
    },
    series: setSeries(commodity, data),
    legend: {
      enabled: false
    },
    tooltip: {
      valueSuffix: " kBtu",
      valueDecimals: 0
    },
    credits: {
      enabled: false
    }
  };
};

export const setSeries = (commodity, data) => {
  switch (commodity) {
    case "electricity":
      return [
        {
          name: "Potential energy usage",
          data: data,
          color: "rgba(152, 190, 72, 0.6)",
          borderColor: "#98BE48",
          borderWidth: 1,
          states: {
            hover: {
              color: "#98BE48"
            }
          }
        }
      ];
    case "heating":
      return [
        {
          name: "Potential energy usage",
          data: data,
          color: "rgba(244, 146, 51, 0.6)",
          borderColor: "#F49233",
          borderWidth: 1,
          states: {
            hover: {
              color: "#F49233"
            }
          }
        }
      ];
    case "cooling":
      return [
        {
          name: "Potential energy usage",
          data: data,
          color: "rgba(37, 169, 198, 0.6)",
          borderColor: "#25A9C6",
          borderWidth: 1,
          states: {
            hover: {
              color: "#25A9C6"
            }
          }
        }
      ];
    default:
      return null;
  }
};
