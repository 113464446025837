import React, { useState, useEffect } from "react"
import MediaQuery from "react-responsive"
import MobileComingSoon from "../mobileComingSoon/MobileComingSoon"
import { breakPoints } from "../common/config"
import { connect } from "react-redux"
import {
  loadWaterGoalData,
  loadWaterUsageData,
} from "../../actions/waterActions"
import Header from "./header/Header"
import Navigation from "./navigation/Navigation"
import Goals from "./goals/Goals"
import Footer from "../building/desktop/footer/Footer"
import Usage from "./usage/Usage"
import Metrics from "./metrics/Metrics"

function SetNavigationView({ view }) {
  switch (view) {
    case "goals":
      return <Goals />
    case "usage":
      return <Usage/>
    case "metrics":
      return <Metrics/>
  }
}

function Water(props) {
  const [currentNavigation, setCurrentNavigation] = useState("goals")

  function updateNavigation(event) {
    let newValue = event.target.id
    setCurrentNavigation(newValue)
  }

  useEffect(() => {
    props.loadWaterUsageData()
    props.loadWaterGoalData()
  }, [])

  return (
    <>
      <MediaQuery maxWidth={breakPoints.mobile}>
        <MobileComingSoon />
      </MediaQuery>
      <MediaQuery minWidth={breakPoints.tablet}>
        <Header />
        <Navigation selection={currentNavigation} onClick={updateNavigation} />
        <div className="campus-content">
          <SetNavigationView view={currentNavigation} />
        </div>
        <Footer />
      </MediaQuery>
    </>
  )
}

function mapStateToProps(state) {
  return {
    waterGoalData: state.waterGoal,
    waterUsageData: state.waterUsage,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadWaterUsageData: () =>
      dispatch(
        loadWaterUsageData()
      ),
    loadWaterGoalData: () =>
      dispatch(
        loadWaterGoalData()
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Water)
