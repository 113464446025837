import React, { useState, useEffect } from "react"

import styles from "./Tlc.module.css"

import HeatingCooling from "./heatingCooling/HeatingCooling"
import ElectricitySolar from "./electricitySolar/ElectricitySolar";
import BottomNavigation from "./heatingCooling/bottomNavigation/BottomNavigation"
import { connect } from "react-redux";
import { loadTLCEnergyData } from "../../actions/buildingActions";

function Tlc(props) {
  const hcString = "View Heating & Cooling Data"
  const esString = "View Electricity & Solar Data"

  const [isHcPage, setIsHcPage] = useState(false)
  const [nextPageString, setNextPageString] = useState(hcString)

  // don't want to be able to scroll on this page
  useEffect(() => {
    props.loadTLCEnergyData()

    document.body.style.overflow = "hidden"

    return () => {
      document.body.style.overflow = ""
    }
  }, [])

  useEffect(() => {
    setNextPageString(isHcPage ? esString : hcString)
  }, [isHcPage])

  return (
    <div className={styles.container}>
      {isHcPage ? (
        <HeatingCooling data={props.tlcHeatingCoolingData} oatWeekly={props.tlcOatWeekly}/>
      ) : (
        <ElectricitySolar data={props.tlcElecSolarData} oatWeekly={props.tlcOatWeekly}/>
      )}
      <BottomNavigation
        nextPageString={nextPageString}
        setIsHcPage={setIsHcPage}
      />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    tlcHeatingCoolingData: state.tlcEnergyData.demandWeeklyTLC.slice(0,2),
    tlcElecSolarData: state.tlcEnergyData.demandWeeklyTLC.slice(2,),
    tlcOatWeekly: state.tlcEnergyData.oatWeekly
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadTLCEnergyData: () =>
      dispatch(
        loadTLCEnergyData({
          id: "tlc",
          energyInfo: {
            commodities: ["chilledWater", "hotWater", "electricity", "solar"],
          },
        })
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tlc)
