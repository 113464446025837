import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as mobileActions from "../../../../actions/mobileActions";

import BuildingFilter from "./buildingFilter/BuildingFilter";
import Search from "./search/Search";
import BuildingList from "./buildingList/BuildingList";
import Footer from "../../../common/mobile/footer/Footer";

import ceedBackground from "./images/ceed-background.png";

import "./Ceed.css";

const DEFAULT_FILTER = "all";

class Ceed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buildingTypeFilter: DEFAULT_FILTER
    };
    this.setBuildingTypeFilter = this.setBuildingTypeFilter.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadCeedMobileData();
  }

  setBuildingTypeFilter(event) {
    let buildingTypeFilter = event.target.id;
    if (buildingTypeFilter === this.state.buildingTypeFilter)
      buildingTypeFilter = DEFAULT_FILTER;
    this.setState({ buildingTypeFilter });
  }

  render() {
    return (
      <div className="ceed-mobile">
        <div
          className="ceed-search-box"
          style={{ backgroundImage: "url(" + ceedBackground + ")" }}
        >
          <div className="ceed-search-campus">UC Davis</div>
          <div className="ceed-search-title">
            Building
            <br />
            Energy
          </div>
          <div className="ceed-search-box">
            <Search />
          </div>
          <div className="ceed-search-slogan">
            The first step to saving energy is being
            <br />
            able to see how much you use.
          </div>
        </div>
        <BuildingFilter
          setFilter={this.setBuildingTypeFilter}
          selected={this.state.buildingTypeFilter}
        />
        <BuildingList
          buildings={this.props.buildingData[this.state.buildingTypeFilter]}
        />
        <Footer mode="dark" fixed={true} />
      </div>
    );
  }
}

Ceed.propTypes = {
  actions: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    buildingData: state.ceedMobileData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(mobileActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ceed);
