import React from "react";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "../../common/utilities";
import "./Dropdown.css";

export const CHECKBOX = "checkbox";
export const RADIO = "radio";
export const DEFAULT = "default";

class DropDown extends React.PureComponent {
  constructor(props) {
    super(props);

    // Create key/value pair to track selected items
    // This creates an object of objects. We'll expand this out when we save to state.
    // let stateItems = this.props.items.map(item => {
    //   var tmp = {};
    //   tmp[item.value] = {selected: item.selected, defaultSelected: false};
    //   return tmp;
    // });

    this.state = {
      showOptions: false,
      defaultSelectText: this.props.defaultSelectText || "Show All"
      //items: Object.assign({}, ...stateItems) /* Turns this into an object with key/value pairs */
    };

    this.expandDropdown = this.expandDropdown.bind(this);
    this.handleExpandEvent = this.handleExpandEvent.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.expandDropdown(false);
    }
  }

  handleExpandEvent(event) {
    if (event.type === "click" && !this.state.showOptions) {
      this.expandDropdown(true);
    }
  }

  expandDropdown(showOptions) {
    this.setState(Object.assign({}, this.state, { showOptions }));
  }

  getCustomClasses() {
    if (this.props.customClass !== undefined) {
      if (
        this.state.showOptions === true &&
        this.props.expandedClass !== undefined
      ) {
        return "dropdown-menu custom-checkbox " + this.props.expandedClass;
      }
      return "dropdown-menu custom-checkbox " + this.props.customClass;
    }

    return "dropdown-menu dropdown-select custom-checkbox";
  }

  getSelectedItemText() {
    let text = this.state.defaultSelectText;
    let numSelected = 0;
    for (let i = 0; i < this.props.items.length; i++)
      if (this.props.items[i].selected) {
        text = numSelected > 0 ? text : this.props.items[i].text;
        numSelected++;
      }
    if (numSelected > 1) {
      text = text + " +" + (numSelected - 1);
    }

    text = capitalizeFirstLetter(text);
    return text;
  }

  render() {
    return (
      <div className={this.getCustomClasses()}>
        <div
          className="dropdown-button"
          ref={this.setWrapperRef}
          id={this.props.id}
          type="button"
          aria-haspopup="true"
          aria-expanded="false"
          tabIndex="0"
          onClick={this.handleExpandEvent}
        >
          <span
            className={
              this.props.customClass === undefined ? "selected-item" : ""
            }
          >
            {this.getSelectedItemText()}
          </span>
          {!this.state.showOptions ? (
            ""
          ) : (
            <DropDownItems id={this.props.id}>
              {this.props.items.map((item, index) => {
                switch (this.props.type) {
                  case CHECKBOX:
                    return (
                      <CheckboxItem
                        key={item.value + index}
                        item={item}
                        index={index}
                        onSelectHandler={this.props.onSelectHandler}
                      />
                    );
                  case RADIO:
                    return (
                      <RadioItem
                        key={item.value}
                        item={item}
                        index={index}
                        capitalizeItems={this.props.capitalizeItems}
                        expandDropdown={this.expandDropdown}
                        onSelectHandler={this.props.onSelectHandler}
                      />
                    );
                  default:
                    return (
                      <DefaultItem key={item.value} item={item} index={index} />
                    );
                }
              })}
            </DropDownItems>
          )}
        </div>
      </div>
    );
  }
}

const DropDownItems = props => (
  <ul className="dropdown-menu" aria-labelledby={props.id}>
    {props.children}
  </ul>
);

const DefaultItem = ({ item }) => {
  return <li className="capitalize">{item.text}</li>;
};

const CheckboxItem = ({ item, onSelectHandler }) => {
  return (
    <li className="checkbox capitalize">
      <input
        type="checkbox"
        id={item.value}
        value={item.value}
        onChange={event => {
          onSelectHandler(item.value, event.target.checked);
        }}
        checked={item.selected}
      />
      <label htmlFor={item.value}>{item.text}</label>
    </li>
  );
};

const RadioItem = ({
  item,
  capitalizeItems,
  expandDropdown,
  onSelectHandler
}) => {
  return (
    <li className={capitalizeItems === true ? "radio capitalize" : "radio"}>
      <input
        type="radio"
        id={item.value}
        value={item.value}
        name="radio-group"
        onChange={event => {
          expandDropdown(false);
          onSelectHandler(item.value, event.target.checked);
        }}
        defaultChecked={item.selected}
      />
      <label htmlFor={item.value}>
        <span>{item.text}</span>
      </label>
    </li>
  );
};

DropDown.propTypes = {
  onSelectHandler: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  type: PropTypes.string,
  defaultSelectText: PropTypes.string,
  capitalizeItems: PropTypes.bool,
  customClass: PropTypes.string,
  expandedClass: PropTypes.string
};

export default DropDown;
