import React from "react";
import PropTypes from "prop-types";
import { circleChartOptions } from "./totalProductionCircle";
import * as utilities from "../../../common/utilities";

import TotalProductionGrid from "./TotalProductionGrid";

import "./TotalProduction.css";

let Highcharts = require("highcharts/highstock"),
  productionCircle = null;
Highcharts.setOptions({
  lang: {
    thousandsSep: ","
  }
});

class TotalProduction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0
    };
  }

  componentDidMount() {
    this.setTotal();
  }

  componentDidUpdate(prevProps) {
    for (let step in this.props.totalData)
      if (this.props.totalData[step] !== prevProps.totalData[step])
        this.setTotal();
  }

  async componentWillUnmount() {
    if (productionCircle !== null) await productionCircle.destroy();
    productionCircle = null;
  }

  renderGraph() {
    productionCircle = null;
    productionCircle = Highcharts.chart(
      "total-production-circle",
      circleChartOptions(this.props.totalData)
    );
  }

  setTotal() {
    let total = 0;
    for (let key in this.props.totalData)
      if (this.props.totalData[key].annual !== undefined)
        total += Math.round(this.props.totalData[key].annual);
    if (total > 0 && total !== this.state.total)
      this.setState(
        {
          total
        },
        this.renderGraph()
      );
  }

  render() {
    return (
      <div className="total-production graph-card">
        <div className="total-production-value">
          <div className="production-value-header">
            Total Annual
            <br />
            Production
          </div>
          <div className="production-value-body">
            <b>{utilities.commaFormatNumbers(this.state.total)}</b>
            <br />
            MMBtu
          </div>
        </div>
        <div className="total-production-circle" id="total-production-circle" />
        <TotalProductionGrid
          steam={this.props.totalData.steam}
          chilledWater={this.props.totalData.chilledWater}
        />
      </div>
    );
  }
}

TotalProduction.propTypes = {
  totalData: PropTypes.object.isRequired
};

export default TotalProduction;
