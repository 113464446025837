import * as types from "./actionTypes";
import { createApolloFetch } from "apollo-fetch";
import * as graphqlQueries from "../queries/waterDashboard";

const uri = "/api/graphql";
const apolloFetch = createApolloFetch({ uri });

const loadWaterGoalSuccess = waterGoalData => {
  return {
    type: types.LOAD_WATER_GOAL_SUCCESS,
    waterGoalData
  };
};

const loadWaterUsageSuccess = waterUsageData => {
  return {
    type: types.LOAD_WATER_USAGE_SUCCESS,
    waterUsageData
  };
};

export const loadWaterGoalData = () => {
  return dispatch => {
    apolloFetch({
      query: graphqlQueries.waterGoalQuery,
    }).then( waterGoalData => {
      dispatch(loadWaterGoalSuccess(waterGoalData.data));
    });
  };
};

export const loadWaterUsageData = () => {
  return dispatch => {
    apolloFetch({
      query: graphqlQueries.waterUsageQuery,
    }).then(waterUsageData => {
      dispatch(loadWaterUsageSuccess(waterUsageData.data));
    });
  };
};
