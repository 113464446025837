import React from "react";
import PropTypes from "prop-types";

import MobileBarLine from "./MobileBarLine";
import * as mobilemetricGraphOptions from "./mobilemetricGraphOptions";
let Highcharts = require("highcharts"),
  metricsBarLineChart = null;
Highcharts.setOptions({
  lang: {
    thousandsSep: ","
  }
});

class MobileMetricsGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      metricsView: "totalEnergy",
      selection: 0
    };
    this.setViewSelection = this.setViewSelection.bind(this);
    this.setButtonView = this.setButtonView.bind(this);
  }

  buildBarLineChart() {
    let xAxis = [],
      selectionBarData = {
        data: [],
        name: "MMBtu/sq",
        type: "column",
        tooltip: {
          useHTML: true,
          id: "Year",
          valueSuffix: null
        },
        color: "rgba(206, 226, 164,0.7)",
        states: {
          hover: {
            color: "#CEE2A4"
          }
        }
      },
      selectionLineData = {
        data: [],
        name: this.selectLabel(this.state.metricsView),
        type: "spline",
        tooltip: {
          useHTML: true,
          id: "Year",
          valueDecimals: 0,
          valueSuffix: null
        },
        yAxis: 1,
        color: "#4B5059",
        marker: {
          enabled: false
        },
        lineWidth: 1
      },
      series = [];
    for (let i = 0; i < this.props.metricsData.length; i++) {
      let metric = this.props.metricsData[i];
      xAxis.push(this.parseYearPrefix(metric.fy));
      selectionBarData.data.push(parseFloat(metric.energyPerFt));
      selectionLineData.data.push(metric[this.state.metricsView]);
    }
    series.push(selectionBarData);
    series.push(selectionLineData);
    metricsBarLineChart = Highcharts.chart(
      "mobile-use-bar-line",
      mobilemetricGraphOptions.initializeMobileBarLineGraph(xAxis, series)
    );
  }

  parseYearPrefix(yearData) {
    if (yearData[0] === "9") return "19" + yearData;
    return "20" + yearData;
  }

  selectLabel(selection) {
    if (selection === "students") return "Students";
    if (selection === "sqFt") return "sq.ft.";
    else return "MMBtu";
  }

  setButtonView(option) {
    let className = "mobile-metrics-view-button";
    if (option === this.state.metricsView) className += " active";
    return className;
  }

  setButtonYear(option) {
    let className = "mobile-metrics-year-button";
    if (option.key === this.state.yearSelection) className += " active";
    return className;
  }

  setViewSelection(event) {
    let selection = event.target.id;
    this.setState({
      metricsView: selection
    });
    this.updateBarLineChartSeries(selection);
  }

  setYearSelection(event) {
    let selection = parseInt(event.target.id, 10);
    this.setState(prevState => {
      if (prevState.yearSelection === selection) selection = null;
      this.setSelectedYearBand(selection);
      return {
        yearSelection: selection,
        selectionAverage: this.calculateSelectionAverage(
          selection,
          this.state.metricsView
        )
      };
    });
  }

  updateBarLineChartSeries(newSelection) {
    let newSeries = [],
      selectionBarData = {
        data: [],
        type: "column"
      },
      selectionLineData = {
        data: [],
        name: this.selectLabel(newSelection),
        type: "spline"
      };
    for (let i = 0; i < this.props.metricsData.length; i++) {
      let metric = this.props.metricsData[i];
      selectionBarData.data.push(parseFloat(metric.energyPerFt));
      selectionLineData.data.push(metric[newSelection]);
    }
    newSeries.push(selectionBarData);
    newSeries.push(selectionLineData);
    metricsBarLineChart.update({
      series: newSeries,
      yAxis: [{}, { title: { text: selectionLineData.name } }]
    });
  }

  componentDidMount() {
    if (this.props.metricsData.length > 0) {
      this.buildBarLineChart();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.metricsData !== prevProps.metricsData &&
      this.props.metricsData.length > 0
    ) {
      this.buildBarLineChart();
    }
  }

  componentWillUnmount() {
    metricsBarLineChart.destroy();
  }

  render() {
    return (
      <div className="mobile-campus-metrics mobile-card">
        <div className="mobile-metrics-headline">Energy Use Intensity/Year</div>
        <div className="mobile-metrics-view-selection-options">
          <span>Compare:</span>
          <div
            className={this.setButtonView("totalEnergy")}
            id="totalEnergy"
            onClick={this.setViewSelection}
          >
            Energy Use
          </div>
          <div
            className={this.setButtonView("students")}
            id="students"
            onClick={this.setViewSelection}
          >
            Enrolled Student
          </div>
          <div
            className={this.setButtonView("sqFt")}
            id="sqFt"
            onClick={this.setViewSelection}
          >
            Square Footage
          </div>
        </div>
        <MobileBarLine
          data={this.props.metricsData}
          selection={this.state.metricsView}
          selectionLabel={this.selectLabel(this.state.metricsView)}
        />
      </div>
    );
  }
}

MobileMetricsGraph.propTypes = {
  metricsData: PropTypes.array.isRequired
};

export default MobileMetricsGraph;
