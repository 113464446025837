const TAGS_START = "01/01/2017";

export const getDateFormats = () => {
  let currentDate = new Date(),
    yesterday = new Date(),
    sevenDaysAgo = new Date(),
    thirtyDaysAgo = new Date(),
    tagStart = new Date(TAGS_START);
  yesterday.setDate(yesterday.getDate() - 1);
  sevenDaysAgo.setDate(currentDate.getDate() - 7);
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  return {
    currentMonth:
      currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1),
    lastYearMonth:
      currentDate.getFullYear() - 1 + "-" + (currentDate.getMonth() + 1),
    currentTime: currentDate,
    yesterday: yesterday,
    weekAgo: sevenDaysAgo,
    monthAgo: thirtyDaysAgo,
    tagStart
  };
};

export const getCommodityTotal = commodityData => {
  return new Promise((resolve, reject) => {
    let firstKey = Object.keys(commodityData)[1],
      tempTotal = [];
    for (let k = 0; k < commodityData[firstKey].length; k++) {
      commodityData[firstKey][k].Value = parseFloat(
        commodityData[firstKey][k].Value || 0
      );
      tempTotal.push(Object.assign({}, commodityData[firstKey][k]));
    }
    for (let tempKey in commodityData)
      for (let i = 0; i < commodityData[tempKey].length; i++) {
        if (tempKey !== "total" && tempKey !== firstKey) {
          commodityData[tempKey][i].Value =
            parseFloat(commodityData[tempKey][i].Value) || 0;
          tempTotal[i].Value += commodityData[tempKey][i].Value;
        }
      }
    resolve(tempTotal);
  });
};

export const processYears = sampleData => {
  let years = ["Past 12 Months"];
  let count = 0;
  for (let i = sampleData.length - 1; i > 0; i--) {
    let year = sampleData[i].Month.slice(-4);
    if (year === sampleData[sampleData.length - 1].Month.slice(-4)) {
      count++;
      if (count === 12) years.push(year);
    } else {
      if (year !== sampleData[i - 1].Month.slice(-4)) years.push(year);
    }
  }
  let firstYear = sampleData[0].Month.slice(-4),
    addFirstYear = true;
  for (let j = 0; j < 12; j++)
    if (sampleData[j].Month.slice(-4) !== firstYear) addFirstYear = false;
  if (addFirstYear) years.push(firstYear);
  return years;
};
