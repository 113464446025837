export const circleColor = buildingType => {
  switch (buildingType) {
    case "office":
      return "#93bb45";
    case "lab":
      return "#2a575f";
    case "housing":
      return "#3778BC";
    case "classroom":
      return "#3ea0b7";
    case "community":
      return "#107d4b";
    default:
      return null;
  }
};

export const commodityName = shortCommodity => {
  switch (shortCommodity) {
    case "electricity":
      return "Electricity";
    case "chilledWater":
      return "Chilled Water";
    case "steam":
      return "Steam";
    case "gas":
      return "Natural Gas";
    case "solar":
      return "Solar";
    default:
      return null;
  }
};

export const sortCommodity = (commodityA, commodityB) => {
  const sortOrder = ["electricity", "chilledWater", "steam", "gas", "solar"];
  return sortOrder.indexOf(commodityA) - sortOrder.indexOf(commodityB);
};
