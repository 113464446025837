import React, { Component } from "react";

class Equation extends Component {
  render() {
    return (
      <div className="sidebar-info">
        <div className="sidebar-description">
          The circles on the map are sized by their Energy Use Intensities
          (EUI). An EUI is a metric for energy use that’s normalized by square
          footage.
        </div>
        <div className="sidebar-equation">
          <div className="sidebar-eui">Energy Use Intensite</div>
          <div className="sidebar-equal">=</div>
          <div className="sidebar-eui-equation">
            <span className="sidebar-eq-top">
              annual energy use <span className="sidebar-unit">(kBtu)</span>
            </span>
            <span className="sidebar-eq-bot">
              building square footage{" "}
              <span className="sidebar-unit">
                (ft<sup>2</sup>)
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Equation;
