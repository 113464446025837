/**
 * This component builds out the Demand chart.
 */

import React from "react";
import PropTypes from "prop-types";
import TimeSlider from "./TimeSlider";
import infoIcon from "../../../chcp/common/images/info-icon.svg";
import DropdownMenu from "../../common/DropdownMenu";
import { buildingTypes, commodityTypes } from "../../../common/config";

const COMMODITY_OPTIONS = {
    electricity: commodityTypes.electricity,
    steam: commodityTypes.steam,
    chilledWater: commodityTypes.chilledWater
  },
  BUILDING_OPTIONS = {
    classroom: buildingTypes.classroom,
    lab: buildingTypes.lab,
    community: buildingTypes.community,
    office: buildingTypes.office
  };

const SavingChart = ({
  timeSliderVal,
  changeCommoditySelection,
  currentCommoditySelection,
  currentBuildingTypeSelection,
  changeBuildingTypeSelection,
  potentialSavings
}) => {
  return (
    <div className="building-demand graph-card">
      <div className="wrap">
        <div className="step1">
          <div className="savings-calculator-description">
            There is potential to save energy if the operation of the HVAC
            system doesn't align with building occupancy, aka we don't want to
            heat and cool buildings when no one's in them. Explore potential
            energy savings by selecting a building type, and the hours when the
            building's HVAC equipment could be turned down.
          </div>
          <div className="savings-calculator-title">Hourly Energy Savings</div>
        </div>
        <div className="feature-wrap">
          <div className="select">
            <div className="select-wrap">
              <div className="select-text">Select Building Type:</div>
              <DropdownMenu
                referenceName="building-type-dropdown"
                options={BUILDING_OPTIONS}
                selection={currentBuildingTypeSelection}
                onChange={changeBuildingTypeSelection}
              />
            </div>
            <div className="select-wrap">
              <div className="select-text">Select Commodity:</div>
              <DropdownMenu
                referenceName="commodity-type-dropdown"
                options={COMMODITY_OPTIONS}
                selection={currentCommoditySelection}
                onChange={changeCommoditySelection}
              />
            </div>
          </div>
          <div className="sliders">
            <div className="time-slider enter-new">
              Explore potential savings throught the day:
              <TimeSlider timeSliderVal={timeSliderVal} />
            </div>
          </div>
        </div>
      </div>
      <div className="building-demand-title">
        <b>Demand</b>
      </div>
      <div className="savings-calculator-graph">
        <div className="savings-calculator-chart">
          <div id="daily-demand" />
        </div>
        <div className="legend">
          <div className="potential-savings">Potential Savings:</div>
          <div className="percent">{potentialSavings}%</div>
          <div className="demand current">
            <div className="demand-potential-wrap">
              <div className="checkbox current" />
              Current Demand
            </div>
          </div>
          <div className="demand">
            <div className="demand-potential-wrap">
              <div className="checkbox potential" />
              Potential Demand
              <img
                className="potential-info-icon"
                src={infoIcon}
                alt="info icon"
              />
            </div>
            <div className="potential-speech-bubble-hover" />
            <div className="potential-speech-bubble-wrap">
              <div className="potential-speech-bubble-arrow" />
              <div className="potential-speech-bubble">
                <p>
                  The potential demand is calculated as the energy use in the
                  building when no one is there and the HVAC system can be
                  turned down.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SavingChart.propTypes = {
  timeSliderVal: PropTypes.func.isRequired,
  currentCommoditySelection: PropTypes.string.isRequired,
  currentBuildingTypeSelection: PropTypes.string.isRequired,
  changeCommoditySelection: PropTypes.func.isRequired,
  changeBuildingTypeSelection: PropTypes.func.isRequired
};

export default SavingChart;
