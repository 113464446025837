import React from "react"
import { Link } from "react-router-dom";

import DashboardMenu from "../../common/dashboardMenu/DashboardMenu"
import { commodityProperties } from "../common/utilities"
import "./Header.css"

import arrowImage from "../../../images/arrow.svg";

const KEY_COMMODITIES = [
  "electricity",
  "steam",
  "chilledWater",
  "gas",
  "solar",
  "domesticWater",
  "heatingGas",
]

const Header = () => {
  return (
    <React.Fragment>
      <div className="datadownload-header">
        <DashboardMenu />
        <div className="datadownload-header-title">
          Energy Data Download Tool
        </div>
        <Link to="/" className="building-header-map-link">
          <img
            src={arrowImage}
            alt="Back to campus map arrow."
            id="bounce-arrow-left"
          />
          Back to map
        </Link>
      </div>
      <div className="datadownload-beta-banner">
        This is a BETA version of the Download Tool. For the best experience, we
        recommend using the Google Chrome browser.
      </div>
      <div className="datadownload-header-key">
        <div className="header-key-label">Energy Data Commodities:</div>
        <div className="header-key-commodities">
          {KEY_COMMODITIES.map((commodity) => {
            return (
              <div className="header-key-commodity" key={commodity}>
                <img
                  src={commodityProperties[commodity].image}
                  alt={commodityProperties[commodity].alt}
                />
                {commodityProperties[commodity].topText}
                <br />
                {commodityProperties[commodity].bottomText}
              </div>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Header
