import React from "react";
import downloadImage from "../common/images/downloadStarted.png";
import { commodityProperties } from "../common/utilities";
import "./DownloadStarted.css";

const KEY_COMMODITIES = [
  "electricity",
  "steam",
  "chilledWater",
  "gas",
  "solar",
  "domesticWater",
  "heatingGas"
];

const DownloadStart = () => {
  return (
    <div className="datadownload-started">
      <div className="datadownload-started-image">
        <div className="datadownload-started-image-background">
          {KEY_COMMODITIES.map(commodity => {
            return (
              <img
                className={"datadownload-started-commodity-icon " + commodity}
                key={commodity}
                src={commodityProperties[commodity].image}
                alt={commodityProperties[commodity].alt}
              />
            );
          })}
          <div className="temp" />
        </div>
      </div>
      <div className="datadownload-started-content">
        <div className="started-content-item header">
          Your download is on its way!
        </div>
        <br />
        <div className="started-content-item">
          Thanks for using our Download Tool. In this BETA version we’re working
          hard to get you your more data, faster. One area we’re working on
          improving is the hourly demand data download.
          <br />
          <br />
          If you’ve tried downloading hourly demand data, we recommend chosing a
          timeframe no larger than 4-6 months.
          <br />
          <br />
          <i>
            If you’re waiting for your download, try exploring our other
            websites linked in the footer. We have an Energy Map for you to
            explore building energy use, or a page for you to see total campus
            energy use, and a dashboard for energy savings projects happing on
            campus.
          </i>
        </div>
      </div>
    </div>
  );
};

export default DownloadStart;
