import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function mapSearchDataReducer(
  state = initialState.searchData,
  action
) {
  switch (action.type) {
    case types.LOAD_MAP_SEARCH_DATA_SUCCESS:
      return action.searchData;

    default:
      return state;
  }
}
