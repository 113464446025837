import * as types from "../actionTypes";
import { createApolloFetch } from "apollo-fetch";
import * as chcpQueries from "../../queries/chcpQuery";
import * as chcpUtils from "./chcpUtilities";

const uri = "/api/graphql";
const apolloFetch = createApolloFetch({ uri });

const ANNUAL_USAGE_TOTAL_TAGS = [
    "Boiler_Plant.Annual_Gas_Consumption_MMBtu",
    "CHCP_TES.Annual_Electricity_Usage_MMBtu"
  ],
  MONTHLY_USAGE_TOTAL_TAGS = [
    "Boiler_Plant.Monthly_Gas_Consumption_MMBtu",
    "CHCP_TES.Monthly_Electricity_Usage_MMBtu"
  ],
  DEMAND_USAGE_TAGS = [
    "Boiler_1.Gas_Flow_Rate_MMBtu/hr",
    "Boiler_2.Gas_Flow_Rate_MMBtu/hr",
    "Boiler_3.Gas_Flow_Rate_MMBtu/hr",
    "Boiler_4.Gas_Flow_Rate_MMBtu/hr",
    "CHCP.Chilled_Water_Electricity_Demand_MMBtu/hr",
    "TES.Chilled_Water_Electricity_Demand_MMBtu/hr"
  ],
  USAGE_TAGS = [
    "Boiler_1.Monthly_Gas_Consumption_MMBtu",
    "Boiler_2.Monthly_Gas_Consumption_MMBtu",
    "Boiler_3.Monthly_Gas_Consumption_MMBtu",
    "Boiler_4.Monthly_Gas_Consumption_MMBtu",
    "CHCP.Monthly_Electricity_Usage_MMBtu",
    "TES.Monthly_Electricity_Usage_MMBtu"
  ];

const chcpUsageSuccess = chcpUsage => {
  return {
    type: types.LOAD_CHCP_USAGE_SUCCESS,
    chcpUsage
  };
};

export const loadUsageTotal = () => {
  let dates = chcpUtils.getDateFormats();
  return function(dispatch) {
    apolloFetch({
      query: chcpQueries.usageTotal,
      variables: {
        currentTime: dates.currentMonth,
        usageStart: dates.lastYearMonth,
        annualTags: ANNUAL_USAGE_TOTAL_TAGS,
        monthlyTags: MONTHLY_USAGE_TOTAL_TAGS
      }
    }).then(async chcpUsageData => {
      if (chcpUsageData.data !== undefined) {
        let annual = await splitTotalData(chcpUsageData.data.totalAnnual),
          monthly = await splitTotalData(chcpUsageData.data.totalMonthly),
          gas = {
            monthly: monthly["gas"],
            annual: annual["gas"]
          },
          electricity = {
            monthly: monthly["electricity"],
            annual: annual["electricity"]
          };
        dispatch(
          chcpUsageSuccess({
            totalUsage: {
              gas,
              electricity
            }
          })
        );
      } else
        console.log("A Usage data error occurred. Please refresh your page.");
    });
  };
};

export const loadUsageRealTime = () => {
  let dates = chcpUtils.getDateFormats(),
    variables = {
      currentTime: dates.currentTime,
      usageStart: dates.tagStart,
      dailyStart: dates.yesterday,
      weeklyStart: dates.weekAgo,
      monthlyStart: dates.monthAgo,
      demandTags: DEMAND_USAGE_TAGS,
      usageTags: USAGE_TAGS
    };
  return function(dispatch) {
    let daily = processDemandQuery(
        { query: chcpQueries.usageDaily, variables },
        "daily"
      ),
      weekly = processDemandQuery(
        { query: chcpQueries.usageWeekly, variables },
        "weekly"
      ),
      monthly = processDemandQuery(
        { query: chcpQueries.usageMonthly, variables },
        "monthly"
      ),
      historic = processUsageQuery(
        { query: chcpQueries.usageHistoric, variables },
        "historic"
      );
    Promise.all([daily, weekly, monthly, historic]).then(values => {
      let finalData = {
        daily: {},
        weekly: {},
        monthly: {},
        historic: {}
      };
      for (let finalKey in finalData)
        for (let i = 0; i < values.length; i++)
          if (finalKey === values[i].label)
            finalData[finalKey] = values[i].data;
      dispatch(
        chcpUsageSuccess({
          realTimeUsage: finalData
        })
      );
    });
  };
};

const processDemandQuery = (query, label) => {
  return new Promise((resolve, reject) => {
    apolloFetch(query).then(async chcpDemandData => {
      let formatData = {
        commodities: ["electricity", "gas"],
        gas: {
          total: [],
          boiler1Usage: [],
          boiler2Usage: [],
          boiler3Usage: [],
          boiler4Usage: []
        },
        electricity: {
          total: [],
          chiller1Usage: [],
          chiller2Usage: []
        }
      };
      let rawDemandData = [];
      for (let key in chcpDemandData.data)
        rawDemandData = chcpDemandData.data[key];
      for (let i = 0; i < rawDemandData.length; i++) {
        if (rawDemandData[i].commodity.indexOf(DEMAND_USAGE_TAGS[0]) !== -1)
          formatData.gas.boiler1Usage = rawDemandData[i].data;
        else if (
          rawDemandData[i].commodity.indexOf(DEMAND_USAGE_TAGS[1]) !== -1
        )
          formatData.gas.boiler2Usage = rawDemandData[i].data;
        else if (
          rawDemandData[i].commodity.indexOf(DEMAND_USAGE_TAGS[2]) !== -1
        )
          formatData.gas.boiler3Usage = rawDemandData[i].data;
        else if (
          rawDemandData[i].commodity.indexOf(DEMAND_USAGE_TAGS[3]) !== -1
        )
          formatData.gas.boiler4Usage = rawDemandData[i].data;
        else if (
          rawDemandData[i].commodity.indexOf(DEMAND_USAGE_TAGS[4]) !== -1
        )
          formatData.electricity.chiller1Usage = rawDemandData[i].data;
        else if (
          rawDemandData[i].commodity.indexOf(DEMAND_USAGE_TAGS[5]) !== -1
        )
          formatData.electricity.chiller2Usage = rawDemandData[i].data;
      }
      formatData.gas.total = await chcpUtils.getCommodityTotal(formatData.gas);
      formatData.electricity.total = await chcpUtils.getCommodityTotal(
        formatData.electricity
      );
      resolve({ label, data: formatData });
    });
  });
};

const processUsageQuery = (query, label) => {
  return new Promise((resolve, reject) => {
    apolloFetch(query).then(async chcpUsageData => {
      let formatData = {
        commodities: ["electricity", "gas"],
        gas: {},
        electricity: {}
      };
      let rawUsageData = [];
      for (let key in chcpUsageData.data)
        rawUsageData = chcpUsageData.data[key];
      for (let i = 0; i < rawUsageData.length; i++) {
        if (rawUsageData[i].commodity.indexOf(USAGE_TAGS[0]) !== -1)
          formatData.gas.boiler1Usage = rawUsageData[i].data;
        else if (rawUsageData[i].commodity.indexOf(USAGE_TAGS[1]) !== -1)
          formatData.gas.boiler2Usage = rawUsageData[i].data;
        else if (rawUsageData[i].commodity.indexOf(USAGE_TAGS[2]) !== -1)
          formatData.gas.boiler3Usage = rawUsageData[i].data;
        else if (rawUsageData[i].commodity.indexOf(USAGE_TAGS[3]) !== -1)
          formatData.gas.boiler4Usage = rawUsageData[i].data;
        else if (rawUsageData[i].commodity.indexOf(USAGE_TAGS[4]) !== -1)
          formatData.electricity.chiller1Usage = rawUsageData[i].data;
        else if (rawUsageData[i].commodity.indexOf(USAGE_TAGS[5]) !== -1)
          formatData.electricity.chiller2Usage = rawUsageData[i].data;
      }
      formatData.years = chcpUtils.processYears(
        formatData.gas.boiler1Usage.length >=
          formatData.electricity.chiller1Usage.length
          ? formatData.gas.boiler1Usage
          : formatData.electricity.chiller1Usage
      );
      resolve({ label, data: formatData });
    });
  });
};

const splitTotalData = totalData => {
  let tempGas = 0,
    tempElectricity = 0;
  for (let i = 0; i < totalData.length; i++) {
    for (let j = 0; j < totalData[i].data.length; j++) {
      if (totalData[i].commodity.toLowerCase().indexOf("gas") !== -1)
        tempGas += totalData[i].data[j].Value;
      else tempElectricity += totalData[i].data[j].Value;
    }
    if (
      totalData[i].commodity.toLowerCase().indexOf("gas") !== -1 &&
      totalData[i].data.length > 1
    )
      tempGas = tempGas / totalData[i].data.length;
    else if (totalData[i].data.length > 1)
      tempElectricity = tempElectricity / totalData[i].data.length;
  }
  return {
    gas: Math.round(tempGas),
    electricity: Math.round(tempElectricity)
  };
};
