import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function desktopEnergyReducer(
  state = initialState.desktopEnergyData,
  action
) {
  switch (action.type) {
    case types.LOAD_DESKTOP_ENERGY_SUCCESS:
      let newDesktopEnergyState = Object.assign({}, state);
      for (let item in action.desktopEnergyData)
        newDesktopEnergyState[item] = action.desktopEnergyData[item];
      return newDesktopEnergyState;

    default:
      return state;
  }
}
