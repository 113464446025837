import React from "react";
import PropTypes from "prop-types";
import * as utilities from "../../../common/utilities";

import boilerImage from "./images/boiler.svg";
import chillerImage from "./images/chiller.svg";
import deltaImage from "./images/deltaT.svg";
import towerImage from "./images/tower.svg";

import "./TotalMetrics.css";

class TotalMetrics extends React.Component {
  setCardClass(cardValue) {
    let defaultClass = "metrics-mini-card";
    if (cardValue === this.props.currentCard) defaultClass += " active";
    return defaultClass;
  }

  render() {
    return (
      <div className="total-metrics">
        <div
          className={this.setCardClass("boilerEfficiency")}
          onClick={e => this.props.changeSelection("boilerEfficiency")}
        >
          <div className="metrics-image">
            <img src={boilerImage} alt="Boiler efficiency icon" />
          </div>
          <div className="metrics-info">Boiler Efficiency</div>
          <div className="metrics-value">
            {this.props.totalData.boilerEfficiency.value}%
          </div>
        </div>
        <div
          className={this.setCardClass("chillerEfficiency")}
          onClick={e => this.props.changeSelection("chillerEfficiency")}
        >
          <div className="metrics-image">
            <img src={chillerImage} alt="Chiller efficiency icon" />
          </div>
          <div className="metrics-info">Cooling Efficiency</div>
          <div className="metrics-value">
            {this.props.totalData.coolingEfficiency.value}{" "}
            <span className="metrics-label">kW/ton</span>
          </div>
        </div>
        <div
          className={this.setCardClass("waterTower")}
          onClick={e => this.props.changeSelection("waterTower")}
        >
          <div className="metrics-image">
            <img src={towerImage} alt="Cooling Tower icon" />
          </div>
          <div className="metrics-info">
            Cooling Tower
            <br />
            Water Use
          </div>
          <div className="metrics-value">
            {utilities.commaFormatNumbers(this.props.totalData.tower.value)}{" "}
            <span className="metrics-label">gal</span>
          </div>
        </div>
        <div
          className={this.setCardClass("deltaT")}
          onClick={e => this.props.changeSelection("deltaT")}
        >
          <div className="metrics-image">
            <img src={deltaImage} alt="Delta T icon" />
          </div>
          <div className="metrics-info">
            Chilled Water
            <br />
            Delta T
          </div>
          <div className="metrics-value">
            {this.props.totalData.deltaT.value}&deg;{" "}
            <span className="metrics-label">F</span>
          </div>
        </div>
      </div>
    );
  }
}

TotalMetrics.propTypes = {
  totalData: PropTypes.object.isRequired,
  changeSelection: PropTypes.func.isRequired,
  currentCard: PropTypes.string.isRequired
};

export default TotalMetrics;
