import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as buildingActions from "../../../actions/buildingActions";

import Navigation from "../../common/mobile/navigation/Navigation";
import MobileBuildingHeader from "./mobileBuildingHeader/MobileBuildingHeader";
import NavBar from "./navBar/NavBar";
import DemandGraph from "./demandGraph/DemandGraph";
import ReportWaste from "./reportWaste/ReportWaste";
import Footer from "../../common/mobile/footer/Footer";
import EwcBuilding from "../../ewc/ewcBuilding/EwcBuilding";
import successImage from "./images/success-story.png";
import backArrow from "./images/back-arrow.svg";

import "./Mobile.css";

const DEFAULT_TAB = "demand";

class Mobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelection: DEFAULT_TAB
    };
    this.updateSelection = this.updateSelection.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tab !== this.props.tab && this.props.tab === "savings")
      this.setState({ tabSelection: "ranking" });
  }

  scrollToReport() {
    document.getElementById("report").scrollIntoView({ behavior: "smooth" });
  }

  updateSelection(event) {
    let tabSelection = event.target.id;
    if (tabSelection === "report-button") {
      if (this.state.tabSelection !== "demand") {
        tabSelection = "demand";
        this.setState({ tabSelection }, () => {
          this.scrollToReport();
        });
      } else this.scrollToReport();
    } else this.setState({ tabSelection });
  }

  render() {
    return (
      <div className="mobile-building-view">
        <Navigation>
          <Link to={this.state.tabSelection === "ranking" ? "/ewc" : "/"}>
            <div className="mobile-navigation-title">
              <img src={backArrow} alt="Back to building list" />
              {this.state.tabSelection === "ranking"
                ? "Team Rankings"
                : "Building List"}
            </div>
          </Link>
        </Navigation>
        <MobileBuildingHeader buildingInfo={this.props.building} />
        <NavBar
          buildingType={this.props.building.buildingType}
          tabSelection={this.state.tabSelection}
          onClick={this.updateSelection}
        />
        {this.state.tabSelection === "ranking" && (
          <EwcBuilding rankingWeekly={this.props.ewcWeekly} />
        )}
        {this.state.tabSelection === "demand" && (
          <React.Fragment>
            <DemandGraph buildingId={this.props.building.id} />
            <ReportWaste />
            <div className="mobile-building-success">
              <img src={successImage} alt="Mrak Hall success story" />
              <div className="mobile-building-success-details">
                <div className="mobile-building-success-detail">
                  Your Feedback is Making a Difference!
                </div>
                <a
                  href="https://facilities.ucdavis.edu/energy-engineering/projects"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="mobile-building-success-button">
                    Read Success Stories
                  </div>
                </a>
              </div>
            </div>
            <Footer mode="light" />
          </React.Fragment>
        )}
      </div>
    );
  }
}

Mobile.propTypes = {
  building: PropTypes.object.isRequired,
  ewcRanking: PropTypes.array,
  ewcWeekly: PropTypes.object,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    ewcRanking: state.ewcRanking,
    ewcWeekly: state.ewcWeekly
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(buildingActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Mobile);
