import React from "react";
import * as utilities from "../../../common/utilities";

const getTitleLabel = selection => {
  if (selection === "students") return "Students";
  return "Square Footage";
};

const MetricsStats = ({ data, selection, selectionLabel }) => {
  if (data === undefined) return null;
  return (
    <div className="campus-metrics-line-data">
      <div className="line-data-footage">
        <span>
          <svg width="12" height="12">
            <rect width="12" height="12" fill="#25a9c6" />
            Sorry, your browser does not support inline SVG.
          </svg>
        </span>
        <div className="line-data-footage-amount"></div>
      </div>
    </div>
  );
};

const MetricsBarLine = ({ data, selection, selectionLabel }) => {
  return (
    <div className="campus-metrics-line">
      <div className="campus-metrics-line-graph">
        <div id="energy-use-bar-line" />
      </div>
    </div>
  );
};

export default MetricsBarLine;
