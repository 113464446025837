import React, { Component } from "react";
import "./TextBox.css";

class TextBox extends Component {
  render() {
    return <div className="textbox">{this.props.text}</div>;
  }
}

export default TextBox;
