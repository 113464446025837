import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sidebarActions from "../../../../../actions/sidebarActions";
import PropTypes from "prop-types";

import "./AppsMenu.css";

class AppsMenu extends React.Component {
  render() {
    return (
      <div className="app-menu">
        <div className="apps-base">Dashboards Menu</div>
        <div className="menu-close" onClick={this.props.actions.closeSidebars}>
          <div className="menu-placeholder">&times;</div>
        </div>
      </div>
    );
  }
}

AppsMenu.propTypes = {
  actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sidebarActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(AppsMenu);
