import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import MediaQuery from "react-responsive";
import * as buildingActions from "../../actions/buildingActions";

import Mobile from "./mobile/Mobile";
import Desktop from "./desktop/Desktop";
import { breakPoints } from "../common/config";

const AVAILABLE_TABS = ["demand", "usage", "comparison", "savings"];

class Building extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadTab: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0); // Added for Energy Water Challenge being scrollable.
    if (this.props.building.id !== this.props.match.params.buildingId)
      this.props.actions.loadBuildingData(this.props.match.params.buildingId);
    if (
      this.props.building.id === this.props.match.params.buildingId &&
      this.props.building.buildingType === "housing"
    )
      this.props.actions.loadEwcRankingWeekly(this.props.building.id);
    if (this.props.location.search.indexOf("tab=") !== -1)
      this.setTab(this.props.location.search);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.building) !== JSON.stringify(prevProps.building)
    )
      if (
        this.props.building.id === this.props.match.params.buildingId &&
        this.props.building.buildingType === "housing"
      )
        this.props.actions.loadEwcRankingWeekly(this.props.building.id);
  }

  setTab(queryParams) {
    let tempQuery = queryParams
      .slice(queryParams.indexOf("tab=") + 4)
      .toLowerCase();
    for (let i = 0; i < AVAILABLE_TABS.length; i++)
      if (tempQuery.indexOf(AVAILABLE_TABS[i]) > -1)
        this.setState({ loadTab: AVAILABLE_TABS[i] });
  }

  render() {
    return (
      <React.Fragment>
        <MediaQuery maxWidth={breakPoints.mobile}>
          <Mobile building={this.props.building} tab={this.state.loadTab} />
        </MediaQuery>
        <MediaQuery minWidth={breakPoints.tablet}>
          <Desktop building={this.props.building} tab={this.state.loadTab} />
        </MediaQuery>
      </React.Fragment>
    );
  }
}

Building.propTypes = {
  building: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    building: state.building
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(buildingActions, dispatch)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Building)
);
