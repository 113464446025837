import React from "react";
import euiCircleGraphic from "../../images/eui-circle-graphic.png";

export default function ModalPage2() {
  return (
    <div className="modal-content-column">
      <div className="modal-content-wrapper">
        <div className="modal-content-columns-wrapper">
          <div className="modal-content-column1">
            <div className="modal-content-headline">
              Compare buildings by their energy use.
            </div>
            <p>
              Building energy use is measured in{" "}
              <b>Energy Use Intensity (EUI)</b>
              , the{" "} 
              <b>the annual energy use per square foot.</b>
            </p>
            <div className="modal-content-fraction-wrapper">
              <div>Energy Use Intensity (EUI)</div> 
              <div>=</div>
              <div className="modal-content-fraction">
                <span>annual energy use (kBtu)</span> 
                <hr/> 
                <span>building square footage (ft²)</span>
              </div>
            </div>
          </div>
          <div className="modal-content-column2">
            <img src={euiCircleGraphic} alt="EUI circle"/>
          </div>
        </div>
      </div>
    </div>
  )
}