import React from "react";
import PropTypes from "prop-types";

import TotalDemandChart from "./totalDemandChart/TotalDemandChart";

class CampusDemand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalDemand: {},
      solarDemand: {},
      campus: {}
    };
  }

  componentWillMount() {
    if (
      this.props.campus !== {} &&
      this.props.campus.campusDemandDaily.length !== 0
    )
      this.setState({ campus: this.props.campus });
  }

  componentDidUpdate(prevProps) {
    if (this.props.campus !== prevProps.campus)
      this.setState({ campus: this.props.campus });
  }

  setOptions(commodity) {
    switch (commodity) {
      case "chcp_gas_demand":
        return { color: "#875878", displayName: "Heating Natural Gas" };
      case "pge_demand":
        return {
          color: "#98BF47",
          displayName: "Carbon-Based Electricity and Hydropower"
        };
      case "solar_farm_demand":
        return { color: "#CAD93E", displayName: "Solar Farm Electricity" };
      case "rooftop_solar_demand":
        return { color: "#cad93f", displayName: "Rooftop Solar Electricity" };
      default:
        return {};
    }
  }

  render() {
    return (
      <div className="campus-demand">
        <TotalDemandChart campus={this.state.campus} />
      </div>
    );
  }
}

CampusDemand.propTypes = {
  campus: PropTypes.object.isRequired
};

export default CampusDemand;
