import React from "react";
import { Link } from "react-router-dom";
import arrowImage from "../../../images/arrow.svg";
import ceedIcon from "./images/ceedIcon.png";
import chcpIcon from "./images/chcp.png";
import dataDownloadIcon from "./images/dataDownload.png";
import ewcIcon from "./images/ewc.png";
import savingsIcon from "./images/savings.png";
import totalCampusIcon from "./images/totalCampus.png";
import waterDashboardIcon from "./images/waterDashboard.png";

import "./DashboardMenu.css";

export const dashboardMenuItems = {
    ceed: {
      icon: ceedIcon,
      alt: "Energy Map Icon",
      displayName: "Energy Map",
      link: "/"
    },
    savings: {
      icon: savingsIcon,
      alt: "Energy Saving Projects Icon",
      displayName: "Energy Saving Projects",
      link: "/energysaving"
    },
    totalCampus: {
      icon: totalCampusIcon,
      alt: "Total Campus Energy Icon",
      displayName: "Total Campus Energy",
      link: "/energystory"
    },
    chcp: {
      icon: chcpIcon,
      alt: "Central Heating and Cooling Plant Icon",
      displayName: "Central Heating and Cooling Plant",
      link: "/chcp"
    },
    dataDownload: {
      icon: dataDownloadIcon,
      alt: "Data Download Tool Icon",
      displayName: "Data Download Tool",
      link: "/datadownload"
    },
    ewc: {
      icon: ewcIcon,
      alt: "Energy and Water Challenge Icon",
      displayName: "Energy and Water Challenge",
      link: "/ewc"
    },
    water: {
      icon: waterDashboardIcon,
      alt: "Water Dashboard Icon",
      displayName: "Water Dashboard",
      link: "/water"
    }
  },
  MENU_OPTIONS = [
    "ceed",
    "totalCampus",
    "chcp",
    "dataDownload",
    "savings",
    "ewc",
    "water"
  ];

class DashboardMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
    this.dropdown = null;
    this.setReference = element => {
      this.dropdown = element;
    };
    this.handleOutsideClick = event => {
      if (this.dropdown !== null && !this.dropdown.contains(event.target))
        this.setState({ menuOpen: false });
      else return;
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.menuOpen)
      document.addEventListener("mousedown", this.handleOutsideClick, false);
    else
      document.removeEventListener("mousedown", this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick, false);
  }

  openMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen
    });
  }

  render() {
    return (
      <div ref={this.setReference} className="dashboards-menu-navigation">
        <div className="dashboards-link" onClick={e => this.openMenu()}>
          Dashboards
          <img src={arrowImage} alt="View more dashboards arrow." />
        </div>
        {this.state.menuOpen &&
          MENU_OPTIONS.map((item, index) => {
            return (
              <Link to={dashboardMenuItems[item].link} key={index}>
                <div className="dashboards-menu">
                  <img
                    src={dashboardMenuItems[item].icon}
                    alt={dashboardMenuItems[item].alt}
                  />
                  {dashboardMenuItems[item].displayName}
                </div>
              </Link>
            );
          })}
      </div>
    );
  }
}

export default DashboardMenu;
