import React from "react";
import PropTypes from "prop-types";
import TotalEnergyGraph from "./TotalEnergyGraph";
import EnergyUseStory from "./EnergyUseStory";
import TotalEnergyCsv from "./TotalEnergyCsv";
import * as totalEnergyChartOptions from "./totalEnergyChartOptions";
import infoIcon from "../../../chcp/common/images/info-icon.svg";
import downloadHoverIcon from "../../../common/images/download_csv.png";
import downloadIcon from "../../../common/images/download_csv_hover.png";
import HighchartsReact from 'highcharts-react-official';
import HighchartsSankey from 'highcharts/modules/sankey';


let Highcharts = require("highcharts"),
  energyBarChart = null;
Highcharts.setOptions({
  lang: {
    thousandsSep: ","
  }
});

HighchartsSankey(Highcharts);

export const getOptions = (type) => ({
  chart: {
    type,
  },
  title: {
    text: "Energy Gas Sankey Diagram"
  },
  style: {
    fontFamily: [
      "Proxima Nova",
      "Lucida Grande",
      "Lucida Sans",
      "Verdana",
      "sans-serif"
    ]
  },
  series: [
    {
      keys: ['from', 'to', 'weight', 'color'],
      dataLabels: {
        nodeFormat: '{point.name}',
        color: 'black',
      },
      data: [
        ['Electricity', 'Cooling', 202187, "#13A9C8"],
        ['Electricity', 'Lighting', 129225, "#98BF47"],
        ['Electricity', 'Plug Loads', 268797, "#98BF47"],
        ['Electricity', 'Heating', 176514, "#F69322"],
        ['Gas', 'Heating', 977242, "#413A6A"],
      ],
      nodes: [{
        id: 'Gas',
        color: "#413A6A"
      },
      {
        id: 'Electricity',
        color: "#98BF47"
      },
      {
        id: 'Lighting',
        color: "#98BF47"
      },
      {
        id: 'Plug Loads',
        color: "#98BF47"
      },
      {
        id: 'Heating',
        color: "#F69322"
      },
      {
        id: 'Cooling',
        color: "#13A9C8"
      },
    ],
    }],
  tooltip: {
    formatter: function(){
      return this.point.weight.toLocaleString() + " MMbtu";
    }
  },
  credits: {
    enabled: false,
  },
});

const SOURCES = {
  energy: [
    {
      value: "gas",
      displayName: "Heating Plant Natural Gas",
      costDisplayName: "Gas",
      color: "#875878"
    },
    {
      value: "primateGas",
      displayName: "Primate Heating Plant Natural Gas",
      color: "#875878"
    },
    {
      value: "buildingGas",
      displayName: "Building Natural Gas",
      color: "#413A6A"
    },
    {
      value: "grid",
      displayName: "Carbon-Based Electricity",
      color: "#98BF47"
    },
    {
      value: "carbonNeutral",
      displayName: "Hydropower",
      color: "#C1D991"
    },
    {
      value: "solarFarm",
      displayName: "Solar Farm Electricity",
      color: "#CAD93E"
    },
    {
      value: "rooftopSolar",
      displayName: "Rooftop Solar Electricity",
      color: "#cad93f"
    }
  ],
  cost: [
    {
      value: "electricityCost",
      displayName: "Electricity Cost",
      color: "#98BF47"
    },
    { value: "gasCost", displayName: "Gas Cost", color: "#413A6A" }
  ]
};

class TotalEnergy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "energy",
      checkBoxes: {
        gas: true,
        primateGas: true,
        buildingGas: true,
        grid: true,
        carbonNeutral: true,
        solarFarm: true,
        rooftopSolar: true,
        electricityCost: true,
        gasCost: true
      },
      chartData: {
        xAxis: [],
        energy: [],
        cost: []
      },
      downloadIcon: downloadHoverIcon
    };
    this.setViewSelection = this.setViewSelection.bind(this);
    this.updateGraphOptions = this.updateGraphOptions.bind(this);
  }

  buildEnergyChart() {
    let xAxis = [],
      energy = [],
      cost = [];
    for (let i = 0; i < SOURCES.energy.length; i++)
      energy.push(
        totalEnergyChartOptions.energySource(
          SOURCES.energy[i].value,
          SOURCES.energy[i].displayName,
          SOURCES.energy[i].color,
          this.state.checkBoxes[SOURCES.energy[i].value]
        )
      );
    for (let j = 0; j < SOURCES.cost.length; j++)
      cost.push(
        totalEnergyChartOptions.costSource(
          SOURCES.cost[j].value,
          SOURCES.cost[j].displayName,
          SOURCES.cost[j].color,
          this.state.checkBoxes[SOURCES.cost[j].value]
        )
      );
    for (let x = 0; x < this.props.energyData.length; x++) {
      let data = this.props.energyData[x];
      xAxis.push(this.cutYearPrefix(data.fy));
      for (let y = 0; y < energy.length; y++)
        energy[y].data.push(data[energy[y].id]);
      for (let z = 0; z < cost.length; z++) cost[z].data.push(data[cost[z].id]);
    }
    let newChartData = Object.assign({}, this.state.chartData);
    newChartData.xAxis = xAxis;
    newChartData.energy = energy;
    newChartData.cost = cost;
    this.setState({ chartData: newChartData });
    energyBarChart = Highcharts.chart(
      "total-energy-chart",
      totalEnergyChartOptions.initializeEnergyGraph(xAxis, energy)
    );
  }

  cutYearPrefix(yearData) {
    if (yearData[0] === "F") return yearData.substring(2);
  }

  setButtonView(option) {
    let className = "total-energy-view-button";
    if (option === this.state.view) className += " active";
    return className;
  }

  setViewSelection(event) {
    let selection = event.target.id;
    this.setState({ view: selection });
    this.updateGraphView(selection);
  }

  updateGraphOptions(event) {
    let selection = event.target.id;
    this.setState(prevState => {
      let newCheckBoxes = Object.assign({}, prevState.checkBoxes);
      newCheckBoxes[selection] = !newCheckBoxes[selection];
      return {
        checkBoxes: newCheckBoxes
      };
    });
    this.updateGraphView(this.state.view, selection);
  }

  updateGraphView(view, checkBox) {
    let chartData = Object.assign({}, this.state.chartData);
    if (checkBox === undefined) {
      energyBarChart.destroy();
      energyBarChart = Highcharts.chart(
        "total-energy-chart",
        totalEnergyChartOptions.initializeEnergyGraph(
          chartData.xAxis,
          chartData[view]
        )
      );
    } else {
      for (let i = 0; i < chartData[view].length; i++)
        if (chartData[view][i].id === checkBox)
          chartData[view][i].visible = !chartData[view][i].visible;
      energyBarChart.update({ series: chartData[view] });
      this.setState({ chartData: chartData });
    }
  }

  componentDidMount() {
    if (this.props.energyData.length > 0) {
      this.buildEnergyChart();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.energyData !== prevProps.energyData &&
      this.props.energyData.length > 0
    )
      this.buildEnergyChart();
  }

  componentWillUnmount() {
    energyBarChart.destroy();
  }

  render() {
    return (
      <div>
      <div className="usage-total-energy campus-card">
        <div className="total-energy-headline">Energy Usage</div>
        <img
          className="total-energy-info-icon"
          src={infoIcon}
          alt="info icon"
        />
        <div className="speech-bubble-ds">
          <div className="speech-bubble-ds-arrow" />
          <p>
            <b>MMBtu/hr</b>
          </p>
          <p>
            An MMBtu is one million Btus and a unit of energy usage over a
            period of time.
          </p>
        </div>
        <div className="campus-graph">
          <div className="campus-chart">
            <div id="total-energy-chart" />
          </div>
          <div className="total-energy-view-selection-options">
            <div
              className={this.setButtonView("energy")}
              id="energy"
              onClick={this.setViewSelection}
            >
              Energy Use
            </div>
            <div
              className={this.setButtonView("cost")}
              id="cost"
              onClick={this.setViewSelection}
            >
              Cost
            </div>
            <TotalEnergyGraph
              onClick={this.updateGraphOptions}
              options={this.state.checkBoxes}
              sources={SOURCES[this.state.view]}
            />
            <div
              className="csv-div"
              onMouseOver={() => {
                this.setState({ downloadIcon: downloadIcon });
              }}
              onMouseOut={() => {
                this.setState({ downloadIcon: downloadHoverIcon });
              }}
            >
              <TotalEnergyCsv
                data={this.props.energyData}
                sources={SOURCES}
                icon={this.state.downloadIcon}
              />
            </div>
          </div>
        </div>
        <EnergyUseStory />
      </div>
      <br></br>
      <div className="usage-total-energy campus-card">
        <div className="energy-gas-sankey-diagram">
          <HighchartsReact highcharts={Highcharts} options={getOptions('sankey')}/>
        </div>
      </div>
      </div>
    );
  }
}

TotalEnergy.propTypes = {
  energyData: PropTypes.array.isRequired
};

export default TotalEnergy;
