import React from "react";
import PropTypes from "prop-types";
import {
  commodityProperties,
  downloadBuildingTypes
} from "../common/utilities";
import BuildingFilterSearch from "./buildingFilterSearch/BuildingFilterSearch";
import BuildingFilterHeader, {
  EUI_FILTER_TYPES
} from "./buildingFilterHeader/BuildingFilterHeader";
import BuildingFilterGridRow from "./buildingFilterGrid/BuildingFilterGridRow";
import "./BuildingFilter.css";

const SORT_DIR = {
    ASCENDING: "asc",
    DESCENDING: "desc"
  },
  VISIBLE_BUILDING_THRESHOLD = 16;

class BuildingFilter extends React.Component {
  constructor(props) {
    super(props);
    let euiTypeFilter = Object.assign({}, ...this.initEuiTypeFilter());

    this.state = {
      commodityFilter: this.initCommodityFilter(),
      buildingTypeFilter: this.initBuildingTypeFilter(),
      euiTypeFilter,
      allBuildingsData: this.props.allBuildingsData,
      visibleBuildings: this.props.allBuildingsData,
      sortDirection: SORT_DIR.ASCENDING
    };
    this.toggleCommodityFilter = this.toggleCommodityFilter.bind(this);
    this.toggleBuildingTypeFilter = this.toggleBuildingTypeFilter.bind(this);
    this.toggleEuiTypeFilter = this.toggleEuiTypeFilter.bind(this);
    this.toggleBuildingNameSort = this.toggleBuildingNameSort.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.state.commodityFilter) !==
        JSON.stringify(prevState.commodityFilter) ||
      JSON.stringify(this.state.buildingTypeFilter) !==
        JSON.stringify(prevState.buildingTypeFilter) ||
      JSON.stringify(this.state.euiTypeFilter) !==
        JSON.stringify(prevState.euiTypeFilter) ||
      JSON.stringify(this.props.searchText) !==
        JSON.stringify(prevProps.searchText)
    )
      this.setVisibleBuildings();
  }

  addVisible() {
    let selectedBuildings = {};
    for (let i = 0; i < this.state.visibleBuildings.length; i++)
      selectedBuildings[
        this.state.visibleBuildings[i].name
      ] = this.state.visibleBuildings[i].commodities;
    this.props.handleSelectVisible(selectedBuildings);
  }

  canShowBuilding(building) {
    // Filter by text search first. Case insensitive.
    if (
      building.name
        .toLowerCase()
        .indexOf(this.props.searchText.toLowerCase()) === -1
    ) {
      return false;
    }
    return (
      this.checkBuildingType(building) &&
      this.checkCommodities(building) &&
      this.checkEui(building)
    );
  }

  checkBuildingType(building) {
    if (this.state.buildingTypeFilter.showAll) return true;
    else return this.state.buildingTypeFilter[building.buildingType];
  }

  checkCommodities(building) {
    let showBuilding = true,
      checkedCommodities = [];
    for (let key in this.state.commodityFilter)
      if (this.state.commodityFilter[key]) checkedCommodities.push(key);
    if (checkedCommodities.length === 0) return showBuilding;
    else
      for (let i = 0; i < checkedCommodities.length; i++)
        if (!building.commodities.includes(checkedCommodities[i]))
          showBuilding = false;
    return showBuilding;
  }

  checkEui(building) {
    if (this.state.euiTypeFilter.showAll) return true;
    else return this.state.euiTypeFilter[building.euiStatus];
  }

  initCommodityFilter() {
    let commodityFilter = {};
    Object.keys(commodityProperties).map(commodityName => {
      return (commodityFilter[commodityName] = false);
    });
    return commodityFilter;
  }

  initBuildingTypeFilter() {
    let buildingTypeFilter = {};
    Object.keys(downloadBuildingTypes).map(buildingType => {
      return (buildingTypeFilter[buildingType] = false);
    });
    buildingTypeFilter["showAll"] = true;
    return buildingTypeFilter;
  }

  initEuiTypeFilter() {
    return Object.keys(EUI_FILTER_TYPES).map(euiType => {
      let myFilter = {};
      myFilter[euiType] = euiType === "showAll" ? true : false;
      return myFilter;
    });
  }

  toggleCommodityFilter(commodityName) {
    let commodityFilter = Object.assign({}, this.state.commodityFilter);
    commodityFilter[commodityName] = !commodityFilter[commodityName];
    this.setState({ commodityFilter });
  }

  toggleBuildingTypeFilter(buildingTypeName) {
    let buildingTypeFilter = Object.assign({}, this.state.buildingTypeFilter);
    for (let key in buildingTypeFilter) buildingTypeFilter[key] = false;
    buildingTypeFilter[buildingTypeName] = true;
    this.setState({ buildingTypeFilter });
  }

  setVisibleBuildings() {
    let visibleBuildings = [];
    for (let i = 0; i < this.props.allBuildingsData.length; i++) {
      if (this.canShowBuilding(this.props.allBuildingsData[i]))
        visibleBuildings.push(this.props.allBuildingsData[i]);
    }
    this.setState({
      visibleBuildings
    });
  }

  toggleEuiTypeFilter(euiName) {
    let euiTypeFilter = Object.assign({}, this.state.euiTypeFilter);
    for (let key in euiTypeFilter) euiTypeFilter[key] = false;
    euiTypeFilter[euiName] = true;
    this.setState({ euiTypeFilter });
  }

  toggleBuildingNameSort() {
    let allBuildingsData = [...this.state.allBuildingsData];
    let sortDirection =
      this.state.sortDirection === SORT_DIR.ASCENDING
        ? SORT_DIR.DESCENDING
        : SORT_DIR.ASCENDING; // Toggle
    if (sortDirection === SORT_DIR.ASCENDING) {
      allBuildingsData.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else {
      allBuildingsData.sort((a, b) => (a.name < b.name ? 1 : -1));
    }
    this.setState({
      allBuildingsData: allBuildingsData,
      sortDirection: sortDirection
    });
  }

  render() {
    return (
      <div className="datadownload-outer-filter">
        <div className="datadownload-inner-filter graph-card">
          <div className="datadownload-upper-search">
            <BuildingFilterSearch
              onChangeHandler={this.props.handleSearchEvent}
            />
            {this.state.visibleBuildings.length < VISIBLE_BUILDING_THRESHOLD &&
              this.state.visibleBuildings.length !== 0 && (
                <div
                  className="datadownload-select-visible"
                  onClick={e => this.addVisible()}
                >
                  Select Visible
                </div>
              )}
          </div>
          <BuildingFilterHeader
            commodityFilter={this.state.commodityFilter}
            toggleCommodityFilter={this.toggleCommodityFilter}
            buildingTypeFilter={this.state.buildingTypeFilter}
            toggleBuildingTypeFilter={this.toggleBuildingTypeFilter}
            euiTypeFilter={this.state.euiTypeFilter}
            toggleEuiTypeFilter={this.toggleEuiTypeFilter}
            toggleBuildingNameSort={this.toggleBuildingNameSort}
          />
          <div className="building-selector-main-building">
            <div className="building-selector-rows-wrapper">
              {this.state.visibleBuildings.length > 0 &&
                this.state.visibleBuildings.map((building, index) => {
                  return (
                    <BuildingFilterGridRow
                      key={building.name}
                      building={building}
                      selectedBuilding={
                        this.props.buildingSelection[building.name] !==
                        undefined
                          ? this.props.buildingSelection[building.name]
                          : []
                      }
                      onClickHandler={this.props.handleSelectBuildingCommodity}
                      firstRow={index === 0 ? true : false}
                    />
                  );
                })}
              {this.state.visibleBuildings.length === 0 && (
                <div
                  className="building-selector-row-none"
                  style={{
                    color: "#f41f1f",
                    fontWeight: "600",
                    padding: "40px 15px 0"
                  }}
                >
                  No buildings match the filtered criteria.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BuildingFilter.propTypes = {
  allBuildingsData:
    PropTypes.array.isRequired /* We move this to state so user can sort */,
  buildingSelection: PropTypes.object.isRequired,
  handleSelectBuildingCommodity: PropTypes.func.isRequired,
  handleSelectVisible: PropTypes.func.isRequired,
  handleSearchEvent: PropTypes.func.isRequired
};

export default BuildingFilter;
