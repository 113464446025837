export const mobileBuildingsQuery = `
	query {
		buildings:allActiveBuildings{
			id
			name
			buildingType
			energyInfo {
      	commodities
    	}
			certifications {
				name
			}
		}
}`;

export const mobileAnnualUsageQuery = name => {
  return `
		query buildingUsageMonthly($buildingId: String!, $commodities:[String]!, $startMonth:String!, $endMonth:String!) {
			${name}:monthlyUsage(
				buildingId: $buildingId 
				commodities: $commodities 
				startMonth: $startMonth 
				endMonth: $endMonth
			) {
				commodity
				data {
					Month
					Value
				}
			}
		}
	`;
};

export const mobileDemandQuery = `
query buildingDemandDaily($id: String!, $commodities:[String]!, $currentTime:String!, $halfDayStart:String!, $threeDayStart:String!, $sevenDayStart:String!) {
  halfDay : demand(
    buildingId: $id 
    commodities: $commodities
    startTime: $halfDayStart 
    endTime: $currentTime
    interval:"5m" 
  ) {
    commodity
    data {
      Timestamp
      Value
    }
  },
  threeDay : demand(
    buildingId: $id 
    commodities: $commodities
    startTime: $threeDayStart 
    endTime: $currentTime
    interval:"30m" 
  ) {
    commodity
    data {
      Timestamp
      Value
    }
  },
  sevenDay : demand(
    buildingId: $id 
    commodities: $commodities
    startTime: $sevenDayStart 
    endTime: $currentTime
    interval:"60m" 
  ) {
    commodity
    data {
      Timestamp
      Value
    }
  }
}
`;
