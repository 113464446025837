import { divIcon } from "leaflet";

// export const joulesTipIcon = () = {
//  TODO
// };

const staticBuildingLabels = [
  "Asmundson Hall",
  "Rifle Range",
  "Student Community Center",
  "Segundo Dining Commons",
  "Activities and Recreation Center",
  "Plant & Environmental Sciences",
  "Shields Library",
  "Giedt Hall",
  "Mrak Hall",
  "Meyer Hall",
  "Ghausi Hall",
  "Robert Mondavi Institute",
  "Valley Hall",
  "Tupper Hall",
  "International Center",
  "Manetti Shrem Museum of Art",
  "Tercero 4",
  "Briggs Hall",
  "Young Hall"
];

export const buildingIcon = (clickState, clickGreenButton, marker) => {
  const iconDefaultSize = 13;

  let buildingLabelClass = "building-name";
  if (
    clickGreenButton &&
    marker.greenBuilding &&
    marker.name !== "Valley Hall"
  ) {
    buildingLabelClass += " static";
  } else if (!clickGreenButton) {
    if (
      (clickState.buildingData && clickState.buildingData.id === marker.id) ||
      staticBuildingLabels.indexOf(marker.name) >= 0
    ) {
      buildingLabelClass += " static";
    }
  }

  const svgDot = `<div>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-labelledby="${marker.name} Title" alt="${marker.name}"
                     viewBox="0 0 20 20" width="${iconDefaultSize}" height="${iconDefaultSize}" role="img">
                      <circle stroke="#FFF" stroke-width="2" stroke-miterlimit="10" cx="10" cy="10" r="8.8"/>
                    </svg>
                  </div>
                  <div class="${buildingLabelClass}" id="${marker.name} Title">${marker.name}</div>`;

  return divIcon({
    className: `building-dot-icon ${marker.buildingType}-type`,
    alt: marker.name,
    iconSize: [iconDefaultSize, iconDefaultSize],
    html: svgDot
  });
};
