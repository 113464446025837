/**
 * This component returns the appropriate building type description based on user selection.
 */

import React from "react";
import PropTypes from "prop-types";
import * as utilities from "../../../../common/utilities";

const BuildingTypeStats = ({ selection, average }) => {
  return (
    <div className="compare-building-types-stats">
      <div className="compare-building-types-average">
        <span>{utilities.capitalizeFirstLetter(selection)} Average EUI</span>
        <div className="compare-building-types-average-value">
          {average} kBtu/sq.ft.
        </div>
      </div>
      <div className="compare-building-types-top-buildings">
        <span>
          {utilities.capitalizeFirstLetter(selection)} Buildings with the
          Highest EUI
        </span>
        <div
          className="compare-building-types-top-chart"
          id="compare-building-types-top-chart"
        />
      </div>
    </div>
  );
};

BuildingTypeStats.propTypes = {
  selection: PropTypes.string.isRequired,
  average: PropTypes.number.isRequired
};

export default BuildingTypeStats;
