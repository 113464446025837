import React from "react"
import PropTypes from "prop-types"

import "./Navigation.css"

export default function Navigation({ selection, onClick }) {
  const navigationClass = (property) => {
    let baseClass = "water-navigation-button"
    if (property === selection) return baseClass + " active"
    return baseClass
  }

  return (
    <div className="water-navigation">
      <div className="water-navigation-wrapper">
        <div className={navigationClass("goals")} id="goals" onClick={onClick}>
          GOALS
        </div>
        <div className={navigationClass("usage")} id="usage" onClick={onClick}>
          USAGE
        </div>
        <div
          className={navigationClass("metrics")}
          id="metrics"
          onClick={onClick}
        >
          DEMAND
        </div>
      </div>
    </div>
  )
}

Navigation.propTypes = {
  selection: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
