import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Navigation from "../../common/mobile/navigation/Navigation";
import Ceed from "./ceed/Ceed";
import facilitiesLogo from "../../common/mobile/images/facilities-logo-white.svg";

import "./Mobile.css";

class Mobile extends React.Component {
  render() {
    return (
      <div className="mobile-main">
        <Navigation location={this.props.location.pathname}>
          <img src={facilitiesLogo} alt="UC Davis Facilities logo" />
        </Navigation>
        <Ceed />
      </div>
    );
  }
}

Mobile.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Mobile);
