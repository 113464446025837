/**
 * This component builds out the CommodityBreakdown 'page' on an individual building.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Select,
  Option
} from "../../../../main/sidebar/dropdown/react-a11y-select/src/index";
import { initializeGraph } from "./chartOptions/breakdownChartOptions";
import CommodityCsvButton from "./CommodityCsvButton";
import * as utilities from "../../../../common/utilities";
import { commodityTypes } from "../../../../common/config";
import downloadIcon from "../../../../common/images/download.png";
import downloadHoverIcon from "../../../../common/images/download_hover.png";
import * as usageUtilities from "./commodityBreakdownUtilities.js";
import "./CommodityBreakdown.css";
let CA_LOCAL = null,
  CA_UTC = null,
  UTC_DIFFERENCE = null;
try {
  CA_LOCAL = new Date().toLocaleString("en-US", {
    timeZone: "America/Los_Angeles" || "UTC"
  });
} catch {
  CA_LOCAL = new Date();
} finally {
  CA_UTC = Date.parse(CA_LOCAL);
  UTC_DIFFERENCE = Date.now() - CA_UTC; //Pi tags are pulled with a CA timestamp, this addresses users in areas outside the timezone.
}

class CommodityBreakdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      componentMounted: false,
      commodities: [],
      commoditySelection: "",
      yearSelection: "Past 12 Months",
      breakdownData: null,
      commodityPiData: null,
      commodityPiTotals: null,
      downloadIcon: downloadHoverIcon
    };
    this.changeCommodity = this.changeCommodity.bind(this);
    this.changeYear = this.changeYear.bind(this);
  }

  componentWillMount() {
    let orderedCommodities = utilities.reorderCommodities(
      this.props.building.energyInfo.commodities
    );
    this.setState(prevState => {
      return {
        commodities: orderedCommodities
      };
    });
    if (this.state.commoditySelection === "")
      this.setState(prevState => {
        return {
          commoditySelection: orderedCommodities[0]
        };
      });
    if (this.props.usageData.length > 0)
      this.loadCommodity(this.props.usageData);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState !== undefined &&
      prevState.isLoading &&
      this.state.breakdownData !== null &&
      this.state.commodityPiData !== null
    ) {
      if (
        this.state.commoditySelection !== "" &&
        this.state.breakdownData[this.state.commoditySelection] !== undefined
      ) {
        this.setState(prevState => {
          return {
            isLoading: false
          };
        });
        let loadGraph = () => {
          this.loadCommodityGraph(
            this.state.commodityPiData,
            this.state.breakdownData,
            this.state.commoditySelection,
            this.state.yearSelection,
            true
          );
        };
        setTimeout(function() {
          loadGraph();
        }, 300);
      }
    }
    if (this.props.building !== prevProps.building) {
      let orderedCommodities = utilities.reorderCommodities(
        this.props.building.energyInfo.commodities
      );
      this.setState(prevState => {
        return {
          commodities: orderedCommodities,
          commoditySelection: orderedCommodities[0]
        };
      });
    }
    if (
      this.props.usageData !== prevProps.usageData &&
      this.props.usageData.length > 0
    )
      this.loadCommodity(this.props.usageData);
  }

  componentDidMount() {
    if (!this.state.isLoading)
      this.loadCommodityGraph(
        this.state.commodityPiData,
        this.state.breakdownData,
        this.state.commoditySelection,
        this.state.yearSelection
      );
    this.setState(prevState => {
      return {
        componentMounted: true
      };
    });
  }

  /**
Seperate the data into individual years per commodity, and set the commodity's unit format.
*/
  loadCommodity(usageData) {
    let breakdownData = {},
      totalYearData = {},
      totals = {};
    for (let i = 0; i < usageData.length; i++) {
      let commodity = usageData[i].commodity;
      breakdownData[commodity] = {
        years: this.loadYears(usageData[i].data, commodity),
        unit: commodityTypes[commodity].unit
      };
      let yearData = {
          [commodity]: this.loadCommodityYearData(
            breakdownData[commodity].years,
            usageData[i].data,
            commodity,
            usageData[i].rate
          )
        },
        totalData = { [commodity]: this.calcTotals(yearData[commodity]) };
      totalYearData = { ...totalYearData, ...yearData };
      totals = { ...totals, ...totalData };
    }
    this.setState(prevState => {
      return {
        breakdownData: breakdownData,
        commodityPiData: totalYearData,
        commodityPiTotals: totals
      };
    });
  }

  /**
Establish which years worth of data that are available per commodity. Build Last 12 months as final step in array.
*/
  loadYears(yearData, com) {
    let j = 0;
    let currentYear = new Date();
    let commodityYears = [];
    for (let i = 0; i < yearData.length; i++) {
      if (yearData[i].Value !== null) {
        let newYear = new Date(yearData[i].Timestamp);
        if (j === 0 && currentYear.getFullYear() !== newYear.getFullYear()) {
          commodityYears.push(newYear.getFullYear());
          j++;
        } else if (
          j > 0 &&
          commodityYears[j - 1] !== newYear.getFullYear() &&
          currentYear.getFullYear() !== newYear.getFullYear()
        ) {
          commodityYears.push(newYear.getFullYear());
          j++;
        } else if (currentYear.getFullYear() === newYear.getFullYear()) {
          commodityYears.push("Past 12 Months");
          break;
        }
      }
    }
    // temporary fix to a blank page error
    if (!commodityYears.includes("Past 12 Months")) commodityYears.push("Past 12 Months")
    // if (currentYear.getMonth() === 0) commodityYears.push("Past 12 Months");
    this.loadCommodityYearData(commodityYears, yearData, com);
    return commodityYears;
  }

  /**
Load the commodity data into the individual years.
*/
  loadCommodityYearData(years, data, com, rate) {
    let obj = {};
    if (rate) {
      for (let i = 0; i < years.length; i++) {
        let newData = [];
        for (let j = 0; j < data.length; j++) {
          let temp = usageUtilities.parseYear(years[i], data[j], com, rate);
          if (temp !== undefined) newData.push(temp);
        }
        if (i === years.length - 1)
          for (let l = data.length - 12; l < data.length; l++) {
            let lastTwelve = new Date(
              Date.parse(data[l].Timestamp) - UTC_DIFFERENCE
            );
            newData.push(
              usageUtilities.parseYear(
                lastTwelve.getFullYear(),
                data[l],
                com,
                rate
              )
            );
          }
        obj[years[i]] = newData;
      }
      return obj;
    }
  }

  /**
Return each year's worth of commodity values for the grand totals.
*/
  calcTotals(obj) {
    let totals = {};
    for (let key in obj) {
      let item = obj[key],
        kbtu = 0,
        units = 0,
        cost = 0;
      if (item[0] !== undefined) {
        for (let i = 0; i < item.length; i++) {
          kbtu += item[i].kBtu;
          units += parseInt(item[i].units, 10);
          cost += item[i].cost;
        }
        totals[key] = {
          kBtu: kbtu,
          units: units,
          cost: cost
        };
      }
    }
    return totals;
  }

  /**
Return an array from the data pushed in. If data needs to be integers, parse will be true.
*/
  createArray(data, key, parse) {
    let array = [];
    for (let i = 0; i < data.length; i++) {
      if (parse) array.push(parseInt(data[i][key], 10));
      else array.push(data[i][key]);
    }
    return array;
  }

  /**
Refreshes the commodity-breakdown graph based on current selections in app. Timeout is used to allow time for the div to load properly, and give Highcharts a location to place the graph.
*/
  loadCommodityGraph(
    commodityPiData,
    breakdownData,
    commodity,
    year,
    firstLoad
  ) {
    let loading = firstLoad || false;
    let graphData = {};
    let usageDate = this.createArray(
      commodityPiData[commodity][year],
      "month",
      false
    );
    graphData.series = this.createArray(
      commodityPiData[commodity][year],
      "units",
      true
    ).map((val, idx) => ((this.props.building.energyInfo.hotWaterConversion && commodity === "steam" && new Date(usageDate[idx].replace(" ", " 20")) > new Date(this.props.building.energyInfo.hotWaterConversion)) ? {y: val, color: commodityTypes["hotWater"].color, unit: "Btu"} : {y:val, color: commodityTypes[commodity].color, unit: breakdownData[commodity].unit}));
    graphData.seriesName = commodity;
    graphData.seriesLabel = breakdownData[commodity].unit;
    graphData.xAxis = usageDate
    //Allow time for the component to mount.
    if (loading)
      setTimeout(function() {
        initializeGraph(graphData);
      }, 100);
    else initializeGraph(graphData);
  }

  changeCommodity(event) {
    let newCommodity = event,
      newYear = "Past 12 Months";
    this.loadCommodityGraph(
      this.state.commodityPiData,
      this.state.breakdownData,
      newCommodity,
      newYear
    );
    this.setState(prevState => {
      return {
        commoditySelection: newCommodity,
        yearSelection: newYear
      };
    });
  }

  changeYear(event) {
    let newYear = event;
    this.loadCommodityGraph(
      this.state.commodityPiData,
      this.state.breakdownData,
      this.state.commoditySelection,
      newYear
    );
    this.setState(prevState => {
      return {
        yearSelection: newYear
      };
    });
  }

  render() {
    if (this.state.isLoading) return null;
    return (
      <div className="commodity-breakdown">
        <div className="commodity-graph">
          <div className="commodity-title">Commodity Usage</div>
          <div className="commodity-select">
            <span className="commodity-show-label">Show:</span>
            <Select
              label="Commodity Type"
              initialValue={this.state.commoditySelection}
              onChange={this.changeCommodity}
              key={this.state.commoditySelection}
            >
              {this.state.commodities.map((commodity, index) => {
                if (commodity !== "solar")
                  return (
                    <Option value={commodity} key={index}>
                      { commodity === "steam"
                        ? "Hot Water"
                        : commodityTypes[commodity].displayName}
                    </Option>
                  );
                return null;
              })}
            </Select>
            <Select
              label="Commodity Year"
              initialValue={this.state.yearSelection}
              onChange={this.changeYear}
              key={this.state.yearSelection}
            >
              {this.state.breakdownData[this.state.commoditySelection].years
                .slice(0)
                .reverse()
                .map((year, index) => {
                  return (
                    <Option value={year} key={index}>
                      {year}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div className="commodity-display">
            <div id="commodity-breakdown-graph" />
          </div>
        </div>
        <div className="commodity-stats">
          <div className="commodity-stats-title" />
          <div className="commodity-header">
            <span className="column1">Month</span>
            <span className="column2">
              Usage 
            </span>
            <span className="column3">Cost ($)</span>
          </div>
          {this.state.commodityPiData[this.state.commoditySelection][
            this.state.yearSelection
          ].map((item, index) => {
            return (
              <div className="commodity-data" key={index}>
                <span className="column1">{item.month}</span>
                <span className="column2">
                  {(this.props.building.energyInfo.hotWaterConversion && this.state.commoditySelection === "steam" && new Date(item.month.replace(" ", " 20")) > new Date(this.props.building.energyInfo.hotWaterConversion)) ? utilities.commaFormatNumbers(item.kBtu) + " Btu" : utilities.commaFormatNumbers(item.units) + " " + this.state.breakdownData[this.state.commoditySelection].unit}
                </span>
                <span className="column3">
                  {utilities.formatDollarValue(item.cost)}
                </span>
              </div>
            );
          })}
          <div className="commodity-footer">
            <span className="column1">Totals:</span>
            <span className="column2">
            {(this.props.building.energyInfo.hotWaterConversion && this.state.commoditySelection === "steam" && (this.props.building.energyInfo.hotWaterConversion.split("-")[0] <= this.state.yearSelection || this.state.yearSelection === "Past 12 Months")) ? utilities.commaFormatNumbers(this.state.commodityPiTotals[this.state.commoditySelection][
                  this.state.yearSelection
                ].kBtu) + " Btu" : utilities.commaFormatNumbers(this.state.commodityPiTotals[this.state.commoditySelection][
                  this.state.yearSelection
                ].units) + " " + this.state.breakdownData[this.state.commoditySelection].unit}
            </span>
            <span className="column3">
              {utilities.formatDollarValue(
                this.state.commodityPiTotals[this.state.commoditySelection][
                  this.state.yearSelection
                ].cost
              )}
            </span>
          </div>
        </div>
        <div
          className="csv-div"
          onMouseOver={() => {
            this.setState({ downloadIcon: downloadIcon });
          }}
          onMouseOut={() => {
            this.setState({ downloadIcon: downloadHoverIcon });
          }}
        >
          <CommodityCsvButton
            breakdownData={
              this.state.commodityPiData[this.state.commoditySelection][
                this.state.yearSelection
              ]
            }
            shortname={this.props.building.id}
            commodity={this.state.commoditySelection}
            unit={this.state.breakdownData[this.state.commoditySelection].unit}
            icon={this.state.downloadIcon}
          />
        </div>
      </div>
    );
  }
}

CommodityBreakdown.propTypes = {
  usageData: PropTypes.array.isRequired,
  building: PropTypes.object.isRequired
};

export default CommodityBreakdown;
