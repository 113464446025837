import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TotalUsage from "./totalUsage/TotalUsage";
import UsageChart from "./usageChart/UsageChart";
import Loading from "../../building/loading/Loading";

class Usage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TotalUsageReady: false,
      UsageChartReady: false
    };
  }

  componentDidMount() {
    if (JSON.stringify(this.props.totalData) !== JSON.stringify({}))
      this.dataIsReady("TotalUsageReady", true);
    if (JSON.stringify(this.props.realTimeData) !== JSON.stringify({}))
      this.dataIsReady("UsageChartReady", true);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.realTimeData) !==
      JSON.stringify(prevProps.realTimeData)
    )
      this.dataIsReady("UsageChartReady", true);
    if (
      JSON.stringify(this.props.totalData) !==
      JSON.stringify(prevProps.totalData)
    )
      this.dataIsReady("TotalUsageReady", true);
  }

  dataIsReady(field, value) {
    this.setState({ [field]: value });
  }

  render() {
    if (!this.state.TotalUsageReady || !this.state.UsageChartReady)
      return <Loading />;
    return (
      <div className="usage-tab">
        <TotalUsage totalData={this.props.totalData} />
        <UsageChart usageData={this.props.realTimeData} />
      </div>
    );
  }
}

Usage.propTypes = {
  realTimeData: PropTypes.object.isRequired,
  totalData: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    realTimeData: state.chcpUsage.realTimeUsage,
    totalData: state.chcpUsage.totalUsage
  };
}

export default connect(mapStateToProps)(Usage);
