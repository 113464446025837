import React from "react";
// redux actions, store
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as mapDataActions from "../../../../actions/mapDataActions";
import * as filterActions from "../../../../actions/filterActions";
import BuildingTypeIcon from "../stats/BuildingTypeIcon";
import "./BuildingTypeButtons.css";
import plusIcon from "./images/plus-icon.svg";

const BUILDING_TYPE = ["lab", "office", "housing", "classroom", "community"];

const filterBuildingTypes = (buttons, mapData) => {
  let newMapData = [];
  mapData.forEach(marker => {
    marker.buildingTypeVisible = buttons[marker.buildingType];
    newMapData.push(marker);
  });
  return newMapData;
};

class BuildingTypeButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterButtons: {
        lab: true,
        office: true,
        housing: true,
        classroom: true,
        community: true
      }
    };
  }

  componentWillMount() {
    if (this.state.filterButtons !== this.props.filterButtons) {
      this.setState(prevState => {
        return { filterButtons: this.props.filterButtons };
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.filterButtons !== nextProps.filterButtons) {
      this.setState(prevState => {
        return { filterButtons: nextProps.filterButtons };
      });
      if (nextProps.mapData.length > 0)
        this.props.mapActions.updateMapData(
          filterBuildingTypes(nextProps.filterButtons, nextProps.mapData)
        );
    }
  }

  render() {
    const buttonClick = buildingType => {
      this.props.buttonActions.filterButtonChanged(
        this.state.filterButtons,
        buildingType
      );
    };

    return (
      <div className="buttons-wrapper">
        {BUILDING_TYPE.map((type, index) => (
          <button
            key={index}
            type="button"
            aria-pressed={this.state.filterButtons[type]}
            className={type}
            onClick={() => buttonClick(type)}
          >
            <span className="icon">
              <img src={plusIcon} alt="On or off plus icon" />
            </span>
            <span className="text">
              {type.charAt(0).toUpperCase()}
              {type.slice(1)}
            </span>
            <BuildingTypeIcon buildingType={type} filterButton="true" />
          </button>
        ))}
      </div>
    );
  }
}

BuildingTypeButtons.propTypes = {
  mapData: PropTypes.array.isRequired,
  filterButtons: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    mapData: state.mapData,
    filterButtons: state.filterButtons
  };
};

const mapDispatchToProps = dispatch => {
  return {
    mapActions: bindActionCreators(mapDataActions, dispatch),
    buttonActions: bindActionCreators(filterActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildingTypeButtons);
