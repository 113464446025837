import React from 'react'
import filterByUsage from "../../images/filter-by-eui-animation.png";
import filterByBuilding from "../../images/filter-by-building-type-animation.png";
import buildingData from "../../images/view-building-data-animation.png";

export default function InfoModal({infoPage, setInfoPage}) {
  const columns = {
    "filter-by-eui": {
      title: "Filter By\n Energy Usage", 
      content: "You can use either end of the slider to see which buildings use the most energy, least energy, or which fall within two values.", 
      img: filterByUsage
    }, 
    "filter-by-building-type": {
      title: "Filter By\n Building Type",
      content: "Toggle off and on any building type to compare or isolate them. By default, all building types are showing.",
      img: filterByBuilding
    }, 
    "view-building-data": {
      title: "View\n Building Data",
      content: "Click on an individual circle to learn more about the building. You can also click ‘view energy data’ to see real-time energy use.",
      img: buildingData
    }
  }
  return (
    <>
    <div className='info-modal-button-wrapper'>
      <div className="info-modal-back-button" onClick={()=> setInfoPage(null)}>
        Back
      </div>
    </div>
    <div className="info-modal-wrapper">
      <div className="info-modal-column1">
        <div className="info-modal-headline">
          <b>{columns[infoPage].title}</b>
        </div>
        <p>
          {columns[infoPage].content}
        </p>
        {infoPage === "filter-by-eui" && (
          <div className="info-toggle-button" onClick={()=> setInfoPage("filter-by-building-type")}>
            Next: Filter By Building Type &gt;
          </div>
        )}
        {infoPage === "filter-by-building-type" && (
          <div className="info-toggle-button" onClick={()=> setInfoPage("view-building-data")}>
            Next: See Building Data &gt;
          </div>
        )}
      </div>
      <div className="info-modal-column2">
        <img src={columns[infoPage].img} alt={`${infoPage}-animation`}/>
      </div>
    </div>
    </>
  )}