/**
 * This component returns the appropriate building type description based on user selection.
 */

import React from "react";
import PropTypes from "prop-types";

import { commodityTypes } from "../../../../common/config";

const WhatsPoweringDescription = ({ selection }) => {
  return (
    <div className="demand-whats-powering-description">
      <div className="demand-whats-powering-main">
        {commodityTypes[selection].description}
      </div>
      <div className="demand-whats-powering-details">
        <div className="demand-whats-powering-details-column">
          Used for:{" "}
          {commodityTypes[selection].uses.map((item, index) => {
            return <span key={index}>{item}</span>;
          })}
        </div>
        <div className="demand-whats-powering-details-column">
          Dependent on outdoor temperature?{" "}
          <span>{commodityTypes[selection].oatDependent}</span>
        </div>
      </div>
    </div>
  );
};

WhatsPoweringDescription.propTypes = {
  selection: PropTypes.string.isRequired
};

export default WhatsPoweringDescription;
