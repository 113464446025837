import React from "react";
import PropTypes from "prop-types";

import { fySort } from "../chcpUtilities";
import MetricsGraph from "./energyUse/MetricsGraph";
import "./Metrics.css";

class Metrics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      metrics: []
    };
  }

  componentDidMount() {
    if (this.props.usageData.length > 0) this.parseData();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.usageData) !==
      JSON.stringify(prevProps.usageData)
    )
      this.parseData();
  }

  calculatePerAmount(total, item) {
    const convertMMBTUtoBTU = 1000000;
    return Math.round((total / item) * convertMMBTUtoBTU);
  }

  async parseData() {
    let tempMetrics = [],
      sortedFiscalYears = await fySort(this.props.usageData);
    for (let i = 0; i < sortedFiscalYears.length; i++) {
      let item = sortedFiscalYears[i],
        tempMetricsObj = {
          fy: this.sliceFiscalYear(item.fy),
          students: item.students,
          sqFt: Math.round(item.campusSqFt),
          totalEnergy: Math.round(item.totalMMBTU),
          energyPerFt: this.calculatePerAmount(
            item.totalMMBTU,
            item.campusSqFt
          ),
          energyPerStudent: this.calculatePerAmount(
            item.totalMMBTU,
            item.students
          )
        };
      tempMetrics.push(tempMetricsObj);
    }
    this.setState({
      metrics: tempMetrics
    });
  }

  sliceFiscalYear(year) {
    return year.slice(2);
  }

  render() {
    return (
      <div className="campus-metrics">
        <MetricsGraph metricsData={this.state.metrics} />
      </div>
    );
  }
}

export default Metrics;
