import React from "react";

import tweetImage from "./images/tweet.svg";
import fbImage from "./images/fb.png";

const smWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "30px 5%"
};

const smHeaderStyle = {
  fontSize: "15px",
  lineHeight: "19px"
};

const smShareButton = {
  height: "36px",
  width: "240px"
};

const SocialMedia = () => {
  return (
    <div className="ewc-building-social-media" style={smWrapperStyle}>
      <div className="ewc-building-social-media-header" style={smHeaderStyle}>
        <b>Did you put this tip into action?</b>
        <br />
        Share it on social media to help
        <br />
        spread the word about the Challenge!
      </div>
      <div
        className="ewc-building-social-media-share"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <a
          target="_blank"
          href="https://twitter.com/intent/tweet?hashtags=AggiesConserve,EnergyWaterChallenge&text=I%27m%20taking%20action%20in%20my%20residence%20hall%20to%20save%20water%20and%20energy%21%20&url=https%3A%2F%2Fchallenge.ucdavis.edu%2F"
          rel="noopener noreferrer"
        >
          <div
            className="ewc-building-social-media-tweet"
            style={smShareButton}
          >
            <img src={tweetImage} alt="Twitter logo" />
            <div className="ewc-building-social-tweet-text">
              share on Twitter
            </div>
          </div>
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fchallenge.ucdavis.edu%2F&amp;src=sdkpreparse"
          rel="noopener noreferrer"
        >
          <div
            className="ewc-building-social-media-facebook"
            style={smShareButton}
          >
            <img src={fbImage} alt="Facebook logo" />
            <div className="ewc-building-social-fb-text">share on Facebook</div>
          </div>
        </a>
      </div>
      <div className="ewc-building-social-media-submit">
        Submit your tips by emailing
        <br />
        <a href="mailto:sustainablehousing@ucdavis.edu">
          sustainablehousing@ucdavis.edu
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
