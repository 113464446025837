const STARTING_FY = "93";

export const fySort = dataArray => {
  return new Promise((resolve, reject) => {
    let sortedArray = [],
      currentFY = STARTING_FY;
    while (sortedArray.length !== dataArray.length) {
      sortedArray.push(findNextFY(dataArray, currentFY));
      if (
        sortedArray[sortedArray.length - 1] !== null &&
        sortedArray[sortedArray.length - 1].fy !== undefined
      )
        currentFY = sortedArray[sortedArray.length - 1].fy.slice(-2);
    }
    resolve(sortedArray);
  });
};

const findNextFY = (data, value) => {
  for (let i = 0; i < data.length; i++) {
    let checkValue = data[i].fy.slice(2, 4);
    if (checkValue === value) return data[i];
  }
  return null;
};
