import React, { Component } from "react";
import PropTypes from "prop-types";

import UsageChart from "./usageChart/UsageChart";
import CommodityBreakdown from "./commodityBreakdown/CommodityBreakdown";
import CompareNext from "./compareNext/CompareNext";
import Loading from "../../loading/Loading";
import "./Usage.css";

const HEADER_HEIGHT = 410;

class Usage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parsedUsage: []
    };
  }

  componentDidMount() {
    if (this.props.usageData.length > 0)
      if (this.props.usageData[0].building === this.props.building.id)
        this.parseUsage();
    if (window.scrollY > HEADER_HEIGHT) window.scrollTo(0, HEADER_HEIGHT);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.usageData) !==
      JSON.stringify(this.props.usageData)
    )
      if (this.props.usageData[0].building === this.props.building.id)
        this.parseUsage();
  }

  parseUsage() {
    let parsedUsage = [];
    let temp = {};
    for (let i = 0; i < this.props.usageData.length; i++) {
      let tempUsageObject = {
          commodity: this.props.usageData[i].commodity,
          data: []
        },
        tempData = this.props.usageData[i].data;
      if (tempUsageObject.commodity === "steam" && this.props.building.energyInfo.hotWaterConversion) {
        let hotWaterUsage = []
        for (let j = 0; j < tempData.length; j++) {
          if (tempData[j].Value !== null) {
            if (new Date(tempData[j].Timestamp) > new Date(this.props.building.energyInfo.hotWaterConversion)) {
              hotWaterUsage.push(tempData[j])
            } else {
              tempUsageObject.data.push(tempData[j]);
            }
          }
        }
        parsedUsage.push({
          commodity: "hotWater",
          data: hotWaterUsage
        })
        parsedUsage.push(tempUsageObject);
      } else {
        for (let j = 0; j < tempData.length; j++) {
          if (tempData[j].Value !== null) tempUsageObject.data.push(tempData[j]);
        }
        if (tempUsageObject.commodity === "electricity") {
          temp = tempUsageObject;
        } else {
          parsedUsage.push(tempUsageObject);
        } 
      }
    }
    temp && parsedUsage.push(temp);
    console.log(parsedUsage)
    this.setState({ parsedUsage });
  }

  render() {
    if (this.state.parsedUsage.length === 0) {
      return null;
    }
    let enoughData = false;
    for (let i = 0; i < this.state.parsedUsage.length; i++) {
      if (this.state.parsedUsage[i].data.length > 0) enoughData = true;
    }
    if (!enoughData)
      return <Loading specificScreen="dataUnavailable" loadingBubble={false} />;
    return (
      <div className="building-usage">
        <UsageChart
          usageData={this.state.parsedUsage}
          oatData={this.props.oatData}
          building={this.props.building}
          commodities={{
            monthly: this.props.monthlyCommodities,
            annual: this.props.annualCommodities
          }}
        />
        <CommodityBreakdown
          usageData={this.props.usageData}
          building={this.props.building}
        />
        <CompareNext onClick={this.props.onClick} />
      </div>
    );
  }
}

Usage.propTypes = {
  usageData: PropTypes.array.isRequired,
  oatData: PropTypes.array.isRequired,
  monthlyCommodities: PropTypes.array.isRequired,
  annualCommodities: PropTypes.array.isRequired,
  building: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Usage;
