import { commodityTypes } from "../../../../common/config";

let CA_LOCAL = null,
  CA_UTC = null,
  UTC_DIFFERENCE = null;
try {
  CA_LOCAL = new Date().toLocaleString("en-US", {
    timeZone: "America/Los_Angeles" || "UTC"
  });
} catch (err) {
  CA_LOCAL = new Date();
} finally {
  CA_UTC = Date.parse(CA_LOCAL);
  UTC_DIFFERENCE = Date.now() - CA_UTC; //Pi tags are pulled with a CA timestamp, this addresses users in areas outside the timezone.
}

/**
Return the 3 character month abbreviation.
*/
export const convertMonths = (month, year) => {
  year = year.slice(-2);
  switch (month) {
    case 0:
      return "JAN " + year;
    case 1:
      return "FEB " + year;
    case 2:
      return "MAR " + year;
    case 3:
      return "APR " + year;
    case 4:
      return "MAY " + year;
    case 5:
      return "JUN " + year;
    case 6:
      return "JUL " + year;
    case 7:
      return "AUG " + year;
    case 8:
      return "SEP " + year;
    case 9:
      return "OCT " + year;
    case 10:
      return "NOV " + year;
    case 11:
      return "DEC " + year;
    default:
      return null;
  }
};

/**
Set the month, kBtu, unit conversion, and cost per month per commodity.
*/
export const parseYear = (year, data, com, rate = 0) => {
  let piDate = Date.parse(data.Timestamp),
    thisDate = new Date(piDate - UTC_DIFFERENCE),
    monthObj = {
      month: convertMonths(thisDate.getMonth(), thisDate.getYear().toString()),
      kBtu: data.Value,
      units: (data.Value / commodityTypes[com].kBtuConversion).toFixed(0),
      cost: rate * data.Value
    };
  if (thisDate.getFullYear() === year) return monthObj;
};
