import React from "react";
import PropTypes from "prop-types";
import * as utils from "../../../../common/utilities";

const Stats = ({ building, euiValues }) => {
  const checkBuildingStats = () => {
    let buildingSize = 0;
    building.facLinkData.forEach(function(d) {
      if (d.relatedGross) buildingSize += d.relatedGross;
    });
    /*Sums the different gross facLinkData arrays. This can occur in buildings like Science lab and lecture
    hall where two technically different buildings share faclink data
    */
    if (building.euiReady)
      return (
        <div className="building-header-stats">
          <div className="header-stats-column">
            <div className="header-stats-column-title">Building Type:</div>
            <div className="header-stats-column-stat">
              {utils.capitalizeFirstLetter(building.buildingType)}
            </div>
          </div>
          <div className="header-stats-column">
            <div className="header-stats-column-title">
              Energy Use Intensity:
            </div>
            <div className="header-stats-column-stat">
              {utils.combineEuiValues(euiValues)} kBtu/ft<sup>2</sup>
            </div>
          </div>
          <div className="header-stats-column">
            <div className="header-stats-column-title">Annual Usage:</div>

            <div className="header-stats-column-stat">
              {utils.calculateAnnualUsage(euiValues, buildingSize)} kBtu
            </div>
          </div>
        </div>
      );
    else if (!building.euiReady && building.name !== "")
      return (
        <div className="building-header-stats">
          <div className="header-stats-column">
            <div className="header-stats-column-title">Building Type:</div>
            <div className="header-stats-column-stat">
              {utils.capitalizeFirstLetter(building.buildingType)}
            </div>
          </div>
        </div>
      );
    else return null;
  };

  if (building.name === "") return null;

  return (
    <div className="building-header-stats-wrapper">{checkBuildingStats()}</div>
  );
};

Stats.propTypes = {
  building: PropTypes.object.isRequired,
  euiValues: PropTypes.array.isRequired
};

export default Stats;
