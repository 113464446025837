import React from "react"

import styles from "./DefinitionsCard.module.css" 

import chillWaterImage from "../../images/chill-water.svg"
import hotWaterImage from "../../images/hot-water.svg"

export default function DefinitionsCard() {
  return (
    <article className={styles.container}>
      <h2>
        <img src={chillWaterImage} alt="cool water image" />
        Cooling: Chilled Water
      </h2>
      <p>
        Campus buildings use chilled water from the Central Heating and Cooling
        Plant in their HVAC systems for cooling.
      </p>
      <h2>
        <img src={hotWaterImage} alt="hot water image" />
        Heating: Hot Water
      </h2>
      <p>
        UC Davis uses hot water in the HVAC (heating, cooling, and air
        conditioning) system to heat the buildings.
      </p>
    </article>
  )
}
