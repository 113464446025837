import React from "react";

import ceedLogo from "./images/ceed-logo.svg";
import sadJoules from "./images/sad-joules.svg";
import "./ErrorMessage.css";

const ErrorMessage = () => {
  return (
    <div className="error-message">
      <div className="error-message-joules">
        <img src={sadJoules} alt="Joules is sad." />
      </div>
      <div className="error-message-header">
        Oops! Something on
        <br /> CEED is broken.
      </div>
      <div className="error-message-content">
        Please check back soon for exciting energy data.
      </div>
      <div className="error-message-logo">
        <img src={ceedLogo} alt="CEED Logo" />
      </div>
    </div>
  );
};

export default ErrorMessage;
