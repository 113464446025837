import { combineReducers } from "redux";
import building from "./buildingReducer";
import { chcpProduction, chcpUsage, chcpMetrics } from "./chcpReducer";
import {
  aceReducer as aceUsage,
  campusReducer as campus
} from "./campusReducer";
import desktopEnergyData from "./desktopEnergyReducer";
import mapData from "./mapDataReducer";
import ceedMobileData from "./mobileReducer";
import connectionData from "./connectionsReducer";
import mapLocation from "./mapLocationReducer";
import searchData from "./mapSearchDataReducer";
import markerClick from "./markerClickReducer";
import sidebar from "./sidebarReducer";
import currentMapSelection from "./currentMapSelectionReducer";
import ranking from "./rankingReducer";
import { filterButtons, filterSlider, greenButton } from "./filterReducer";
import { ewcRanking, ewcWeekly } from "./ewcRankingReducer";
import { dataDownload } from "./dataDownloadReducer";
import { buildingProjects, savingsCalculator } from "./energySavingReducer";
import { waterUsage, waterGoal } from "./waterReducer";
import tlcEnergyData from "./tlcEnergyReducer"

const rootReducer = combineReducers({
  building,
  campus,
  chcpProduction,
  chcpUsage,
  chcpMetrics,
  desktopEnergyData,
  ewcRanking,
  ewcWeekly,
  aceUsage,
  mapData,
  ceedMobileData,
  connectionData,
  mapLocation,
  searchData,
  markerClick,
  sidebar,
  currentMapSelection,
  ranking,
  filterButtons,
  filterSlider,
  dataDownload,
  greenButton,
  buildingProjects,
  savingsCalculator,
  waterUsage,
  waterGoal,
  tlcEnergyData,
});

export default rootReducer;
