/**
 * HighCharts utilities that are used to build the demand HighChart.
 * These functions set up the default parameters along with some
 * helper functions for formatting the visual elements of the chart.
 */

// import { commodityTypes } from "../../../../common/config"
import { getDailyLabels } from "../../../common/axisLabels"

import Highcharts from "highcharts"
const MAX_POSSIBLE_COMMODITIES = 5,
  DEFAULT_SECONDARY_Y = true

const commodityTypes = {
  electricity: {
    displayName: "Electricity",
    color: "#98BF47",
    graphColor: "rgba(152, 191, 71, 0.7)",
    gradient: [
      [0, "#98BF47"],
      [1, "#c9dd9f"],
    ],
    unit: "kWh",
    kBtuConversion: 3.142,
    cost: 0.02245,
    description:
      "Lights, plug loads, and fans in the HVAC system are run off of the electricity delivered to the building. UC Davis gets its electricity from a variety of non-renewable and renewable sources.",
    uses: ["Plug Loads", "Fans", "Lighting"],
    oatDependent: "No",
    normalUseDescription:
      "The demand should drop when occupants leave the building.",
    abnormalUseDescription: "Look for high use on weekends and at night.",
  },
  gas: {
    displayName: "Natural Gas",
    color: "#8D67D6",
    gradient: [
      [0, "#8D67D6"],
      [1, "#aa95d3"],
    ],
    unit: "CuFt",
    kBtuConversion: 1.05,
    cost: 0.0088,
    description:
      "Natural gas is purchased from PG&E and used in buildings for heating and hot water. It is primarily used in smaller buildings that do not have steam connections to the central plant.",
    uses: ["Heating"],
    oatDependent: "Yes",
    normalUseDescription:
      "Similar to steam, natural gas usage typically spikes in the morning when a building requires heating as the occupants arrive.",
    abnormalUseDescription: "Look for high use on weekends and at night.",
  },
  chilledWater: {
    displayName: "Chilled Water",
    color: "#1caac8",
    graphColor: "rgba(28, 170, 200, 0.7)",
    gradient: [
      [0, "#1caac8"],
      [1, "#90d6e5"],
    ],
    unit: "TonHr",
    kBtuConversion: 12,
    cost: 0.00797,
    description:
      "Campus buildings use chilled water from the Central Heating and Cooling Plant in their HVAC systems for cooling.\r\n\r\nThe amount of chilled water required by the cooling system is metered and traced by Facilities Management. Chilled water is metered in ton-hours, which we can convert back into Kwh of electricity used to produce the chilled water at the CHCP.",
    uses: ["Cooling"],
    oatDependent: "Yes",
    normalUseDescription:
      "Chilled water usage typically increases in the afternoons, when it's warmer outside.",
    abnormalUseDescription:
      "If you see chilled water use that is consistently high in a building, even in the mornings and at night, this should seem suspicious.",
  },
  solar: {
    displayName: "Solar",
    color: "#CAD93E",
    gradient: [
      [0, "#CAD93E"],
      [1, "#D5E072"],
    ],
    unit: "kWh",
    kBtuConversion: 3.142,
    cost: 0,
    description:
      "Solar electricity is generated from a 62 acre solar farm on campus, providing around 14% of the UC Davis electricity supply. Additionally, UC Davis receives solar power from an off-site solar farm that supplies power that is shared by all UC campuses. Lastly, some campue buildings have rooftop solar and parking lots have solar shade structures that provide additional clean power for the campus.",
    uses: ["Plug Loads", "Fans", "Lighting"],
    oatDependent: "No",
    normalUseDescription:
      "Solar energy supply rises and falls along with the daylight and evening hours.",
    abnormalUseDescription: null,
  },
  oat: {
    displayName: "Outside Air Temperature",
    color: "rgba(204,204,204,0.3)",
    gradient: [],
    unit: "degrees",
    kBtuConversion: 0,
    cost: 0,
    description: "",
    uses: [],
    oatDependent: "No",
    normalUseDescription: null,
    abnormalUseDescription: null,
  },
  hotWater: {
    displayName: "Hot Water",
    color: "#ffc149",
    gradient: [
      [0, "#ffc149"],
      [1, "#FDC962"],
    ],
    unit: "Lbs",
    kBtuConversion: (1194 - 180) / 1000,
    cost: 0.01186,
    description:
      "UC Davis uses steam condensate in the HVAC (heating, cooling, and air conditioning) system to heat the buildings and to heat hot water used in the building.\r\n\r\nEach building receives steam from the UCD central plant, and the amount of steam used by the building is metered. Steam is metered in pounds of steam, but we convert this to therms, and into kBtus to sum with chilled water and electricity data.",
    uses: ["Heating"],
    oatDependent: "Yes",
    normalUseDescription:
      "Usage typically spikes in the morning when a building requires heating as the occupants arrive.",
    abnormalUseDescription:
      "Steam use commonly occurs in the mornings and in cooler outside air temperatures. Seeing steam use outside these parameters is suspicious.",
  },
}

// Established the default graph parameters.
export const initializeGraph = (isHC) => {
  return {
    chart: {
      zoomType: "x",
      type: "areaspline",
      plotBorderColor: "#E8E8E8",
      plotBorderWidth: 2,
      height: 470,
      backgroundColor: "transparent",
    },
    title: {
      text: null,
    },
    xAxis: setXAxis(),
    yAxis: setYAxis(isHC),
    legend: {
      enabled: false,
    },
    plotOptions: {
      areaspline: {
        marker: {
          enabled: false,
        },
      },
      series: {
        stacking: "normal",
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: {
          duration: 2000,
          easing: "easeOutBounce",
        },
      },
    },
    tooltip: {
      backgroundColor: "#F6F6F6",
      borderColor: "#EEEDEE",
      borderRadius: 3,
      borderWidth: 1.5,
      shadow: true,
      crosshairs: true,
      style: {
        color: "#2D3138",
        lineHeight: "17px",
        fontSize: "13px",
      },
      headerFormat:
        '<span style="font-size: 14px; color: #2D3138; opacity: 0.5; lineHeight: 17px;">{point.key}</span><table><br/>',
      pointFormat:
        '<span style="background-color:{series.color}; color:{series.color}; font-weight:700; font-size:9px; line-height:18px; vertical-align:top; max-width: 10px; max-height: 10px; border:2px solid white; border-radius: 50%; margin-right:5px; padding-right: 3px;">\u25CF </span><span>  {series.name}:</span> {point.y}<br/>',
      shared: true,
      useHTML: true,
      xDateFormat: "%A, %b %e, %l:%M%P",
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    series: setDefaultSeries(),
    time: {
      useUTC: false,
    },
    visible: false,
  }
}

// Builds out the empty arrays for the chart initialization.
// Outside Air Temperature is considered a commodity in regards to
// data initialization and should be counted.
const setDefaultSeries = () => {
  let defaultSeries = []
  for (let i = 0; i < MAX_POSSIBLE_COMMODITIES; i++)
    defaultSeries.push({
      data: null,
    })
  return defaultSeries
}

// Sets the parameters for each of the commodities. This includes
// color, tooltip information, and visibility status.
export const buildSeries = async (
  commodityData,
  oatData,
  activeCommodities,
  checkedState,
  isHC
) => {
  let seriesData = buildBaseCommodities(
    commodityData,
    activeCommodities,
    checkedState,
    isHC
  )
  isHC && seriesData.push(buildOat(oatData, true))
  return seriesData
}

const buildBaseCommodities = (data, commodities, visibility, isHC) => {
  let tempSeries = []
  const valueSuffix = isHC ? " kBtu/hr" : " kW"
  for (let h = 0; h < commodities.length; h++) {
    for (let i = 0; i < data.length; i++) {
      if (commodities[h] === data[i].commodity)
        tempSeries.push({
          name: commodityTypes[data[i].commodity].displayName,
          id: data[i].commodity,
          data: formatData(data[i].data),
          color: commodityTypes[data[i].commodity].gradient[0][1],
          tooltip: {
            valueDecimals: 0,
            valueSuffix,
          },
          yAxis: 0,
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: commodityTypes[data[i].commodity].gradient,
          },
          visible: visibility,
        })
    }
  }
  return tempSeries
}

// Sets the X Axis parameters for the Demand Chart. In particular,
// it sets different label types depending on what time interval is selected
export const setXAxis = (currentDemandSelection) => {
  return [
    {
      scrollbar: {
        enabled: true,
        showFull: false,
      },
      type: "datetime",
      minPadding: 0,
      maxPadding: 0,
      labels: {
        step: 1,
        style: {
          color: "#444952",
          fontSize: 13,
          textTransform: "capitalize",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif",
          ],
        },
        padding: 0,
        y: 25,
        formatter: function () {
          return getDailyLabels(this.value)
        },
      },
    },
  ]
}

// Sets the Y Axis parameters for the Demand chart. This chart contains
// a left axis[0] (data) and right axis[1] (Outside Air Temperature).
export const setYAxis = (secondaryAxisVisibility) => {
  const unit = secondaryAxisVisibility ? "kBtu/hr" : "kW"
  return [
    {
      min: 0,
      lineWidth: 2,
      gridLineWidth: 2,
      title: {
        text: unit,
        align: "high",
        rotation: 0,
        offset: 15,
        style: {
          color: "#2D3138",
          fontWeight: "normal",
          fontSize: 13,
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif",
          ],
        },
      },
      lineColor: "#A3A3A3",
      gridLineColor: "#E8E8E8",
      minPadding: 0,
      maxPadding: 0,
      opposite: false,
      tickAmount: 5,
      labels: {
        formatter: function () {
          if (this.isLast) {
            return ""
          }
          let label = this.axis.defaultLabelFormatter.call(this)
          if (/^[0-9]{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value, 0)
          }
          return label
        },
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif",
          ],
          fontSize: 13,
        },
        reserveSpace: true,
      },
      endOnTick: false,
    },
    {
      //secondary yAxis
      title: {
        text: "\xB0F",
        align: "high",
        rotation: 0,
        offset: 15,
      },
      labels: {
        formatter: function () {
          if (this.isLast) {
            return ""
          }
          return this.value
        },
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif",
          ],
          fontSize: 13,
        },
        reserveSpace: true,
      },
      opposite: true,
      visible: secondaryAxisVisibility,
      tickAmount: 5,
      lineWidth: 2,
      lineColor: "#A3A3A3",
      gridLineColor: "#E8E8E8",
      minPadding: 0,
    },
  ]
}

// Adds an Outside Air Temperature series to the graph.
const buildOat = (oatData, visible) => {
  return {
    name: "Outside Air Temperature",
    id: "oat",
    data: formatData(oatData),
    type: "line",
    tooltip: {
      valueDecimals: 0,
      valueSuffix: " \xB0F",
    },
    color: "black",
    lineWidth: 1,
    marker: {
      enabled: false,
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    yAxis: 1,
    visible: visible,
  }
}

// Sets the data to values and associated dates for Highcharts
// to parse and display appropriately.
const formatData = (dataArray) => {
  let formattedArray = []
  for (let i = 0; i < dataArray.length; i++) {
    let newDate = new Date(dataArray[i].Timestamp)
    if (dataArray[i].Value > 0)
      formattedArray.push([Date.parse(newDate), dataArray[i].Value])
    else formattedArray.push([Date.parse(newDate), 0])
  }
  return formattedArray
}
