import React from "react";
import PropTypes from "prop-types";
import caret from "../../../images/caret.png";

import "./DropdownMenu.css";

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
    this[this.props.referenceName] = null;
    this.setReference = element => {
      this[this.props.referenceName] = element;
    };
    this.handleOutsideClick = event => {
      if (
        this[this.props.referenceName] !== null &&
        !this[this.props.referenceName].contains(event.target)
      )
        this.setState({ menuOpen: false });
      else return;
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.menuOpen)
      document.addEventListener("mousedown", this.handleOutsideClick, false);
    else
      document.removeEventListener("mousedown", this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick, false);
  }

  buildDotSvg(color) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        className="dropdown-svg"
      >
        <circle r="4" cy="4" cx="4" fill={color} />
      </svg>
    );
  }

  openMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen
    });
  }

  render() {
    return (
      <div
        ref={this.setReference}
        className={"dropdown-menu " + this.props.referenceName}
      >
        <div
          className={
            "dropdown-options " +
            this.props.referenceName +
            (this.state.menuOpen ? " active" : "")
          }
          onClick={e => this.openMenu()}
          style={{ zIndex: "100", position: "relative" }}
        >
          {this.buildDotSvg(this.props.options[this.props.selection].color)}{" "}
          {this.props.options[this.props.selection].displayName}
          <img
            src={caret}
            className="dropdown-options-caret"
            alt="Select options arrow"
          />
        </div>
        {this.state.menuOpen &&
          Object.keys(this.props.options).map(key => {
            return (
              <div
                className={"dropdown-option " + this.props.referenceName}
                id={key}
                onClick={e => {
                  this.setState({
                    menuOpen: false
                  });
                  this.props.onChange(e);
                }}
                key={key}
                style={{ zIndex: "300", position: "relative" }}
              >
                {this.buildDotSvg(this.props.options[key].color)}{" "}
                {this.props.options[key].displayName}
              </div>
            );
          })}
      </div>
    );
  }
}

DropdownMenu.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  selection: PropTypes.string.isRequired,
  referenceName: PropTypes.string.isRequired
};

export default DropdownMenu;
