import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { breakPoints } from "../common/config";
import * as mapDataActions from "../../actions/mapDataActions";

import Mobile from "./mobile/Mobile";
import Map from "./map/Map";
import Sidebar from "./sidebar/Sidebar";
import WelcomeModal from "./welcomeModal/WelcomeModal";
import AppsPage from "./sidebar/appsPage/AppsPage";
import FullRanking from "./sidebar/ranking/FullRanking";
import Stats from "./sidebar/stats/Stats";
import SearchBox from "./map/searchBox/SearchBox";

import "./Main.css";

class Main extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  //Added for any existing external legacy (AngularJS) redirects for energystory
  //Otherwise, routes to main page
  componentDidMount() {
    if (window.location.hash.indexOf("energystory") !== -1)
      this.context.router.history.push("/energystory");
    else this.props.actions.loadMapData();
  }

  render() {
    return (
      <React.Fragment>
        <MediaQuery maxWidth={breakPoints.mobile}>
          <Mobile />
        </MediaQuery>

        <MediaQuery minWidth={breakPoints.tablet}>
          <div className="Main">
            <WelcomeModal />
            <Sidebar />
            <AppsPage />
            <FullRanking />
            <Stats />
            <SearchBox enableSeason={false} />
            <Map />
          </div>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(mapDataActions, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(Main);
