import React, { Component } from "react";
import "./BarChart.css";
import PropTypes from "prop-types";

// constants for svg chart properties
const SVG_MAXWIDTH = 72; // the combined width of all bars
const MONTHS = 12;
const barWidth = SVG_MAXWIDTH / MONTHS;
const xPositions = [
  "30",
  "38",
  "46",
  "54",
  "62",
  "70",
  "78",
  "86",
  "94",
  "102",
  "110",
  "118"
]; // starting render position for svg bars

class BarChart extends Component {
  scaledSVG_Heights = [];
  scaledSVG_Y = [];

  componentWillMount() {
    let scalingFactor = this.calculateScalingFactor();
    this.scaleData(scalingFactor);
  }

  calculateScalingFactor = () => {
    // largest savings (absolute value)
    let maxSavings = Math.max(...this.props.savingsData);
    let minSavings = Math.min(...this.props.savingsData);
    let scalingFactor = 0;

    if (Math.abs(maxSavings) > Math.abs(minSavings)) {
      scalingFactor = Math.abs(maxSavings);
    } else {
      scalingFactor = Math.abs(minSavings);
    }

    return scalingFactor;
  };

  scaleData = scalingFactor => {
    let percentages = [];

    for (let i = 0; i < this.props.savingsData.length; i++) {
      switch (Math.sign(this.props.savingsData[i])) {
        case 1: // number is positive
          percentages[i] = Math.abs(this.props.savingsData[i] / scalingFactor);
          this.scaledSVG_Y[i] =
            this.props.svgHeight / 2 -
            (this.props.svgHeight / 2) * percentages[i];
          this.scaledSVG_Heights[i] =
            this.props.svgHeight / 2 - this.scaledSVG_Y[i]; // bar ends at the middle line
          break;
        case -1: // number is negative
          percentages[i] = Math.abs(this.props.savingsData[i] / scalingFactor);
          this.scaledSVG_Y[i] = this.props.svgHeight / 2; // bar begins at the middle line
          this.scaledSVG_Heights[i] =
            (this.props.svgHeight / 2 - 5) * percentages[i];
          break;
        default:
          this.scaledSVG_Y[i] = 0;
          this.scaledSVG_Heights[i] = 0;
          break;
      }
    }
  };

  getClassName = index => {
    // handle color changes to selected graphs
    let className = "bar";
    if (this.props.isSelected) {
      // graph is selected
      switch (this.props.graphType) {
        case "electricity":
          className = "selectedBar-electricity";
          break;
        case "steam":
          className = "selectedBar-steam";
          break;
        case "chilledWater":
          className = "selectedBar-chilledWater";
          break;
        case "totalSavings":
          className = "selectedBar-totals";
          break;
        default:
          className = "bar";
          break;
      }
    }

    if (className !== "bar" && this.props.savingsData[index] < 0)
      className += "-negative";

    return className;
  };

  render() {
    let { svgHeight, svgWidth } = this.props;
    return (
      <div className="barChart-container">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          className="chart"
          width={svgWidth}
          height={svgHeight}
          aria-labelledby="title"
          role="img"
        >
          {this.scaledSVG_Heights.map((svgHeight, index) => {
            return (
              <g className={this.getClassName(index)} key={index}>
                <rect
                  width={barWidth}
                  height={svgHeight}
                  x={xPositions[index]}
                  y={this.scaledSVG_Y[index]}
                />
              </g>
            );
          })}
        </svg>
      </div>
    );
  }
}

BarChart.defaultProps = {
  svgHeight: 70,
  svgWidth: 150,
  chartData: [],
  isSelected: false,
  graphType: ""
};

BarChart.propTypes = {
  savingsData: PropTypes.array.isRequired,
  isSelected: PropTypes.bool.isRequired,
  graphType: PropTypes.string.isRequired
};

export default BarChart;
