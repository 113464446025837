import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formatDollarValue } from "../../../common/utilities";
import CurrentSavingsCircle from "./CurrentSavingsCircle";
import { currentFyFormat } from "../../common/config";

const FISCAL_GOAL = 2400000;

const CurrentSavings = ({ fySavings, fyTotal, projectedSavings }) => {
  return (
    <div className="total-savings-current">
      <div className="total-savings-title" style={{ width: "64%" }}>
        Current Fiscal Year Savings
      </div>
      <div
        className="savings-current-amount"
        style={{ width: "34%", textAlign: "right" }}
      >
        <b>{formatDollarValue(fyTotal)}</b>/{formatDollarValue(FISCAL_GOAL)}
      </div>
      <div className="savings-current-svg">
        <svg height="20" width="1000">
          <g fill="none" strokeWidth="20">
            // Goal
            <path
              strokeDasharray="1000, 1000"
              stroke="white"
              strokeLinecap="round"
              d="M10 10 l1000 0"
            />
            // Projected Savings
            <path
              strokeDasharray={
                (projectedSavings / FISCAL_GOAL) * 1000 + ", 1000"
              }
              stroke="#D9E4BB"
              strokeLinecap="round"
              d="M10 10 l1000 0"
            />
            // Current Savings
            <path
              strokeDasharray={(fyTotal / FISCAL_GOAL) * 1000 + ", 1000"}
              stroke="#9FBC5A"
              strokeLinecap="round"
              d="M10 10 l1000 0"
            />
          </g>
          Sorry, your browser does not support inline SVG.
        </svg>
      </div>
      <div className="savings-current-values">
        <div className="savings-current-value">
          <div className="savings-current-value-color">
            <svg width="14" height="14">
              <rect width="14" height="14" style={{ fill: "#9FBC5A" }} />
              Sorry, your browser does not support inline SVG.
            </svg>
          </div>
          Actual savings: <b>{formatDollarValue(fyTotal)}</b>
        </div>
        <div className="savings-current-value">
          <div className="savings-current-value-color">
            <svg width="14" height="14">
              <rect width="14" height="14" style={{ fill: "#D9E4BB" }} />
              Sorry, your browser does not support inline SVG.
            </svg>
          </div>
          Projected savings: <b>{formatDollarValue(projectedSavings)}</b>
        </div>
        <div className="savings-current-value">
          <div className="savings-current-value-color">
            <svg width="14" height="14">
              <rect
                width="14"
                height="14"
                style={{
                  fill: "#FFFFFF",
                  stroke: "#000000",
                  strokeWidth: "1px"
                }}
              />
              Sorry, your browser does not support inline SVG.
            </svg>
          </div>
          Goal: <b>{formatDollarValue(FISCAL_GOAL)}</b>
        </div>
      </div>
      <div
        className="total-savings-title"
        style={{ width: "100%", marginTop: "60px" }}
      >
        Current Fiscal Year Savings Breakdown
      </div>
      <div className="savings-current-table">
        <div className="current-table-header">
          <div className="current-table-content" style={{ width: "64%" }}>
            Project Type
          </div>
          <div className="current-table-content right" style={{ width: "34%" }}>
            Savings
          </div>
        </div>
        {Object.keys(fySavings).map(key => {
          return (
            <div className="current-data-row" key={key}>
              <div className="current-table-content" style={{ width: "64%" }}>
                <div className="current-table-dot">
                  <svg height="10" width="10">
                    <circle
                      cx="5"
                      cy="5"
                      r="5"
                      fill={currentFyFormat[key].color}
                    />
                  </svg>
                </div>
                {currentFyFormat[key].title}
              </div>
              <div
                className="current-table-content right"
                style={{ width: "34%" }}
              >
                {formatDollarValue(fySavings[key])}
              </div>
            </div>
          );
        })}
        <div className="current-table-header">
          <div className="current-table-content" style={{ width: "64%" }}>
            Total
          </div>
          <div className="current-table-content right" style={{ width: "34%" }}>
            {formatDollarValue(fyTotal)}
          </div>
        </div>
      </div>
      <CurrentSavingsCircle savings={fySavings} total={fyTotal} />
    </div>
  );
};

export default CurrentSavings;
