import React, { Component } from "react";
import PropTypes from "prop-types";
import "./CompareNext.css";

class CompareNext extends Component {
  render() {
    return (
      <div className="compare-next-wrapper">
        Next: Compare your building's Energy Use Intensity in the &nbsp;
        <span
          className="compare-link"
          id="comparison"
          onClick={this.props.onClick}
        >
          Comparison tab.
        </span>
      </div>
    );
  }
}

CompareNext.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default CompareNext;
