import React, { Component } from "react";
import PropTypes from "prop-types";
import "./BuildingProjectRow.css";
import TextBox from "../gridBoxes/TextBox";
import GraphBox from "../gridBoxes/GraphBox";

class BuildingProjectRow extends Component {
  render() {
    return (
      <div className="building-wrapper" style={{ display: "flex" }}>
        <div className="graphRow-box">
          <div className="building-title">
            <TextBox text={this.props.buildingName} />
          </div>
        </div>
        {this.props.commodityList.map((commodity, index) => (
          <div
            key={index}
            className="graphRow-box"
            onClick={e => {
              this.props.selectGraph(e, index, this.props.shortName);
            }}
          >
            <GraphBox
              savingsData={this.props.savings[commodity]}
              isSelected={this.props.graphSelectionArray[index]}
              graphType={this.props.commodityList[index]}
            />
          </div>
        ))}
        <div
          key={this.props.commodityList.length + 1}
          className="graphRow-box"
          onClick={e => {
            this.props.selectGraph(
              e,
              this.props.commodityList.length,
              this.props.shortName
            );
          }}
        >
          <GraphBox
            savingsData={this.props.savings["totals"]}
            isSelected={
              this.props.graphSelectionArray[this.props.commodityList.length]
            }
            graphType="totalSavings"
          />
        </div>
      </div>
    );
  }
}

BuildingProjectRow.defaultProps = {
  commodityList: ["electricity", "steam", "chilledWater"],
  buildingName: "",
  shortName: "",
  savings: [],
  graphSelectionArray: []
};

BuildingProjectRow.propTypes = {
  commodityList: PropTypes.array.isRequired,
  buildingName: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  savings: PropTypes.object.isRequired,
  selectGraph: PropTypes.func.isRequired,
  graphSelectionArray: PropTypes.object.isRequired
};

export default BuildingProjectRow;
