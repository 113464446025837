import React from "react";
import PropTypes from "prop-types";
import MobileMetricsGraph from "./mobileEnergyUse/MobileMetricsGraph";
import "./MobileMetrics.css";

class MobileMetrics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      metrics: []
    };
  }

  componentWillMount() {
    if (this.props.usageData.length > 0) this.parseData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.usageData !== prevProps.usageData) this.parseData();
  }

  calculatePerAmount(total, item) {
    const convertMMBTUtoBTU = 1000000;
    return Math.round((total / item) * convertMMBTUtoBTU);
  }

  parseData() {
    let tempMetrics = [];
    let metLength = 0;
    if (this.props.usageData.length > 9) {
      metLength = this.props.usageData.length - 10;
    }
    for (let i = 0; i < this.props.usageData.length - metLength; i++) {
      let item = this.props.usageData[metLength + i],
        tempMetricsObj = {
          fy: this.sliceFiscalYear(item.fy),
          students: item.students,
          sqFt: Math.round(item.campusSqFt),
          totalEnergy: Math.round(item.totalMMBTU),
          energyPerFt: this.calculatePerAmount(
            item.totalMMBTU,
            item.campusSqFt
          ),
          energyPerStudent: this.calculatePerAmount(
            item.totalMMBTU,
            item.students
          )
        };
      tempMetrics.push(tempMetricsObj);
    }
    this.setState({
      metrics: tempMetrics
    });
  }

  sliceFiscalYear(year) {
    return year.slice(2);
  }

  render() {
    return (
      <div className="mobile-metrics">
        <MobileMetricsGraph metricsData={this.state.metrics} />
      </div>
    );
  }
}

export default MobileMetrics;
