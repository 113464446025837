import React from "react";

import { DateTime } from "luxon";
import PropTypes from "prop-types";

import { commodityTypes } from "../../../../common/config";
import infoIcon from "../../../../chcp/common/images/info-icon.svg";
import { graphConfigBuilder, usageYAxis } from "./graphConfigBuilder";
import { seriesBuilder } from "./seriesBuilder";

import CsvButton from "../../../../common/CsvButton";
import * as utilities from "../../../../common/utilities";

import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more.src.js";
HighchartsMore(Highcharts);

const DEFAULT_TIME_INTERVAL = "monthly",
  DATA_TYPE = "usage";

// Creates a dynamic class for each parameter to reference.
const setCheckboxSpanClass = commodity => {
  return "building-commodity-label " + commodity;
};

class UsageChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartRef: null,
      maxEpoch: 0,
      series: [],
      timeInterval: DEFAULT_TIME_INTERVAL,
      oatChecked: false,
      checkBoxes: {
        monthly: Object.assign({}, utilities.setDefaultCheckboxes),
        annual: Object.assign({}, utilities.setDefaultCheckboxes)
      },
      showAnnual: false
    };
  }

  componentDidMount() {
    if (this.props.usageData.length > 0 && this.props.oatData.length > 0)
      this.buildChart();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.usageData) !==
        JSON.stringify(this.props.usageData) &&
      this.props.usageData.length > 0 &&
      this.props.oatData.length > 0
    )
      this.buildChart();
    if (
      JSON.stringify(prevProps.oatData) !==
        JSON.stringify(this.props.oatData) &&
      this.props.oatData.length > 0 &&
      this.props.usageData.length > 0
    )
      this.buildChart();
  }

  checkDefaultTimeInterval() {
    if (
      this.props.commodities["monthly"].length === 0 &&
      this.props.commodities["annual"].length > 0
    )
      this.toggleTimeChange("annual");
  }

  toggleSeries(seriesId, checkedState) {
    for (let i = 0; i < this.state.chartRef.series.length; i++) {
      if (this.state.chartRef.series[i].options.id === seriesId) {
        if (checkedState === true) {
          this.state.chartRef.series[i].show();
        } else {
          this.state.chartRef.series[i].hide();
        }
      }
      if (seriesId === "steam" && this.state.chartRef.series[i].options.id === "hotWater") {
        if (checkedState === true) {
          this.state.chartRef.series[i].show();
        } else {
          this.state.chartRef.series[i].hide();
        }
      }
    }
    // Update the checkbox state
    let tempCheckboxes = Object.assign({}, this.state.checkBoxes);
    tempCheckboxes[this.state.timeInterval][seriesId] = checkedState;
    this.setState({ checkBoxes: tempCheckboxes });
    if (seriesId === "tempRange") {
      let visibleToggle = usageYAxis(checkedState);
      this.setState({ oatChecked: checkedState });
      this.state.chartRef.update(visibleToggle);
    }
  }

  toggleTimeChange(timeInterval) {
    let updatedGraphConfig = {};

    if (timeInterval === "monthly") {
      updatedGraphConfig = {
        plotOptions: {
          series: {
            dataGrouping: {
              units: [["month", [1]]]
            }
          },
          column: {
            pointWidth: 8
          }
        },
        xAxis: {
          max: this.state.maxEpoch,
          range: 36 * 30 * 24 * 3600 * 1000 // 36 months
        },
        series: this.state.series
      };
    } else {
      let currentYear = new Date().getFullYear();
      let lastYearTime = new Date(currentYear - 1, 11, 31).getTime();

      updatedGraphConfig = {
        plotOptions: {
          series: {
            dataGrouping: {
              units: [["year", null]]
            }
          },
          column: {
            pointWidth: 20
          }
        },
        xAxis: {
          range: 72 * 30 * 24 * 3600 * 1000 // 72 months
        },
        series: this.state.series.map(series => {
          if (series.id === "avgTemp" || series.id === "tempRange") {
            return {
              ...series,
              visible: false
            };
          } else {
            return {
              ...series,
              data: series.data.filter(datum => datum[0] < lastYearTime)
            };
          }
        })
      };
    }

    //hide outside air temperature checkbox and data
    this.setState({ oatChecked: false });
    this.toggleSeries("tempRange", false);

    this.state.chartRef.update(updatedGraphConfig);

    this.setState({ timeInterval }, () => {
      this.resetToggles(timeInterval);
    });
  }

  resetToggles(selection) {
    for (let i = 0; i < this.state.chartRef.series.length; i++)
      this.state.chartRef.series[i].hide();
    for (let j = 0; j < this.props.commodities[selection].length; j++)
      this.toggleSeries(
        this.props.commodities[selection][j],
        this.state.checkBoxes[selection][this.props.commodities[selection][j]]
      );
  }

  setAnnualButton() {
    let showAnnual = false;
    if (this.props.commodities.annual.length > 0)
      for (let i = 0; i < this.props.usageData.length; i++)
        if (this.props.usageData[i].data.length >= 13) showAnnual = true;
    this.setState({ showAnnual });
  }

  setInitialCheckboxes() {
    let checkBoxState = Object.assign({}, this.state.checkBoxes);
    for (let key in checkBoxState) {
      for (let checkMark in checkBoxState[key])
        checkBoxState[key][checkMark] = false;
      for (let i = 0; i < this.props.commodities[key].length; i++)
        checkBoxState[key][this.props.commodities[key][i]] = true;
    }
    return checkBoxState;
  }

  // Creates a default chart for data to load into.
  buildChart() {
    let [max] = this.props.usageData[0].data.slice(-1); // max === last value of array
    let maxEpoch = DateTime.fromISO(max.Timestamp)
      .plus({ minutes: 1 })
      .minus({ months: 1 })
      .valueOf();
    let checkBoxes = this.setInitialCheckboxes();
    let series = seriesBuilder(
      this.props.usageData,
      this.props.oatData,
      this.props.commodities[this.state.timeInterval]
    );
    let graphConfig = graphConfigBuilder(max, maxEpoch, series);
    let chartRef = Highcharts.StockChart("past-usage", graphConfig);
    this.setState({ chartRef, maxEpoch, series, checkBoxes }, () => {
      this.resetToggles(this.state.timeInterval);
      this.setAnnualButton();
      this.checkDefaultTimeInterval();
    });
  }

  render() {
    return (
      <div className="building-usage graph-card">
        <div className="building-demand-title">Past Usage</div>
        <img className="demand-info-icon" src={infoIcon} alt="info icon" />
        <div className="demand-speech-bubble-ds">
          <div className="demand-speech-bubble-ds-arrow" />
          <p>
            <b>kBtu</b>
          </p>
          <p>
            A Btu is 1055 joules, and is the amount of energy required to heat 1
            lb of water 1°F. A kBtu is a unit of energy usage over a period of
            time.
          </p>
        </div>
        <div className="building-graph">
          <div className="building-chart">
            <div id="past-usage" />
          </div>
          <div className="building-side-options">
            {this.props.commodities["monthly"].length > 0 && (
              <button
                className="building-side-button"
                aria-pressed={this.state.timeInterval === "monthly"}
                onClick={e => this.toggleTimeChange("monthly")}
                onKeyPress={e => this.toggleTimeChange("monthly")}
              >
                Monthly
              </button>
            )}
            {this.state.showAnnual && (
              <button
                className="building-side-button"
                aria-pressed={this.state.timeInterval === "annual"}
                onClick={e => this.toggleTimeChange("annual")}
                onKeyPress={e => this.toggleTimeChange("annual")}
              >
                Annual
              </button>
            )}
            <div className="building-commodities-wrap">
              <div className="building-commodities">
                {this.props.commodities[this.state.timeInterval].map(
                  commodity => commodity !== "hotWater" && ( 
                    <label
                      key={commodity}
                      className="building-commodity"
                      htmlFor={commodity}
                    >
                      <input
                        type="checkbox"
                        checked={
                          this.state.checkBoxes[this.state.timeInterval][
                            commodity
                          ]
                        }
                        onChange={e =>
                          this.toggleSeries(commodity, e.target.checked)
                        }
                        id={commodity}
                      />
                      <span className={commodity === "steam" && this.props.building.energyInfo.hotWaterConversion ? setCheckboxSpanClass("hotWater") : setCheckboxSpanClass(commodity)}>
                        {commodity === "steam"
                          ? "Hot Water"
                          : commodityTypes[commodity].displayName}
                      </span>
                    </label>
                  )
                )}
                {this.state.timeInterval === "monthly" ? (
                  <label key="oat" className="building-commodity" htmlFor="oat">
                    <input
                      type="checkbox"
                      checked={this.state.oatChecked}
                      onChange={e =>
                        this.toggleSeries("tempRange", e.target.checked)
                      }
                      id="oat"
                    />
                    <span className="building-commodity-label oat">
                      Outside Air
                      <div className="building-side-oat-label-spacer">
                        Temperature
                      </div>
                    </span>
                  </label>
                ) : null}
              </div>
            </div>
            <div className="building-side-options campus-side-options csv-usage">
              <CsvButton
                data={this.props.usageData}
                commodities={this.props.usageData.map(
                  commodityData => commodityData.commodity
                )}
                dataType={DATA_TYPE}
                shortname={this.props.building.id}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UsageChart.propTypes = {
  usageData: PropTypes.array.isRequired,
  oatData: PropTypes.array.isRequired,
  commodities: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired
};

export default UsageChart;
