import React from "react";
import PropTypes from "prop-types";

import "./MobileNavigation.css";

const Navigation = ({ selection, onClick }) => {
  const navigationClass = property => {
    let baseClass = "campus-navigation-button";
    if (property === selection) return baseClass + " active";
    return baseClass;
  };

  return (
    <div className="campus-navigation">
      <div className="campus-navigation-wrapper">
        <div
          className={navigationClass("demand")}
          id="demand"
          onClick={onClick}
        >
          ENERGY SOURCES
        </div>
        <div
          className={navigationClass("metrics")}
          id="metrics"
          onClick={onClick}
        >
          METRICS
        </div>
      </div>
    </div>
  );
};

Navigation.propTypes = {
  selection: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Navigation;
