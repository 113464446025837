import React from "react";
import PropTypes from "prop-types";
import { descriptions } from "../../common/config";
class EnergySourceDescriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      naturalGas: {
        name: "Natural Gas",
        id: "naturalGas",
        active: false,
        content: descriptions.column1
      },
      carbonElectricity: {
        name: "Carbon-Based Electricity",
        id: "carbonElectricity",
        active: false,
        content: [descriptions.column2[0]]
      },
      solarFarm: {
        name: "Solar Farm Electricity",
        id: "solarFarm",
        active: false,
        content: [descriptions.column2[1]]
      },
      hydroPower: {
        name: "Hydropower",
        id: "hydroPower",
        active: false,
        content: [descriptions.column2[3]]
      }
    };
    this.changeState = this.changeState.bind(this);
  }

  changeState = property => {
    this.setState({
      [property]: {
        ...this.state[property],
        active: !this.state[property].active
      }
    });
  };

  render() {
    return (
      <div className="campus-energy-description">
        {Object.keys(this.state).map(key => {
          return (
            <div className="campus-energy-description-wrapper" key={key}>
              <div
                className="campus-energy-description-banner"
                onClick={e => this.changeState(this.state[key].id)}
              >
                <img
                  src={this.state[key].content[0].icon}
                  alt={"Energy source icon for " + this.state[key].name}
                />
                <div>{this.state[key].name}</div>
                <button onClick={e => this.changeState(this.state[key].id)}>
                  {this.state[key].active ? "-" : "+"}
                </button>
              </div>
              <div className="campus-energy-description-content">
                {this.state[key].active && (
                  <div>
                    {this.state[key].content.map((description, index) => (
                      <div key={index}>
                        <div className="campus-energy-description-content-title">
                          {description.title}
                        </div>
                        <div className="campus-energy-description-content-description">
                          {description.description}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default EnergySourceDescriptions;
