import React, { Component } from "react";
import PropTypes from "prop-types";
import "./TotalsRow.css";
import SavingsBox from "../gridBoxes/SavingsBox";
import TextBox from "../gridBoxes/TextBox";
import { shortNameList } from "../../Lists";

class TotalsRow extends Component {
  commodityList = ["electricity", "steam", "chilledWater", "totals"];
  buildingList = shortNameList;

  compressedSavings = {
    electricity: [],
    steam: [],
    chilledWater: [],
    totals: []
  };

  componentWillMount() {
    for (let commodity in this.compressedSavings) {
      // fill array with 0's
      let building = this.buildingList[0];
      for (
        let index = 0;
        index < this.props.savings[building][commodity].length;
        index++
      ) {
        this.compressedSavings[commodity][index] = 0;
      }
    }
    this.compressSavings();
  }

  // these loops make me a sad boy
  compressSavings() {
    // shrink down savings into single arrays
    for (
      let buildingIndex = 0;
      buildingIndex < this.buildingList.length;
      buildingIndex++
    ) {
      let building = this.buildingList[buildingIndex];
      for (
        let commodityIndex = 0;
        commodityIndex < this.commodityList.length;
        commodityIndex++
      ) {
        let commodity = this.commodityList[commodityIndex];
        for (
          let arrayIndex = 0;
          arrayIndex < this.props.savings[building][commodity].length;
          arrayIndex++
        ) {
          this.compressedSavings[commodity][arrayIndex] += this.props.savings[
            building
          ][commodity][arrayIndex];
        }
      }
    }
  }

  getBuildingCommTotal = e => {
    this.props.BuildingCommTotal(e);
  };

  // use map here
  render() {
    return (
      <div className="totals-wrapper" style={{ display: "flex" }}>
        <div className="totalsRow-box">
          <div className="totals-title">
            <TextBox name={this.props.rowTitle} text={this.props.text} />
          </div>
        </div>
        <div className="totalsRow-box">
          <SavingsBox
            savingsData={this.compressedSavings["electricity"]}
            isTotal={false}
          />
        </div>
        <div className="totalsRow-box">
          <SavingsBox
            savingsData={this.compressedSavings["steam"]}
            isTotal={false}
          />
        </div>
        <div className="totalsRow-box">
          <SavingsBox
            savingsData={this.compressedSavings["chilledWater"]}
            isTotal={false}
          />
        </div>
        <div className="totalsRow-box">
          <SavingsBox
            savingsData={this.compressedSavings["totals"]}
            BuildingCommTotal={this.getBuildingCommTotal}
            isTotal={true}
          />
        </div>
      </div>
    );
  }
}

TotalsRow.defaultProps = {
  rowTitle: "Totals",
  savings: []
};

TotalsRow.propTypes = {
  rowTitle: PropTypes.string,
  savings: PropTypes.object.isRequired
};

export default TotalsRow;
