import React, { useState, useEffect } from "react"
import ReactHighcharts from "react-highcharts"

import styles from "./DemandChart.module.css"

import * as demandChartOptions from "./demandChartOptions"

export default function DemandChart({ weeklyData, weeklyOat, commodities, isHC }) {
  const [dataLoaded, setDataLoaded] = useState(false)
  const [config, setConfig] = useState(demandChartOptions.initializeGraph(isHC))

  useEffect(() => {
    setDataLoaded(weeklyData.length > 0 && weeklyOat.length > 0)
  }, [weeklyData, weeklyOat])

  useEffect(() => {
    async function build() {
      if (dataLoaded) {
        const newSeries = {
          series: await demandChartOptions.buildSeries(
            weeklyData,
            weeklyOat,
            commodities,
            true,
            isHC
          ),
        }
        setConfig({ ...config, ...newSeries })
      }
    }
    build()
  }, [dataLoaded, commodities])

  return (
    <div className={styles.container}>
      <div>
        <ReactHighcharts config={config} />
      </div>
    </div>
  )
}
