import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as buildingActions from "../../../actions/buildingActions";
import * as mapDataActions from "../../../actions/mapDataActions";
import PropTypes from "prop-types";

import { reorderCommodities } from "../../common/utilities";
import "./Desktop.css";

import Header from "./header/Header";
import Navigation from "./navigation/Navigation";
import NavigationView from "./NavigationView";
import Footer from "./footer/Footer";

const DEFAULT_NAVIGATION_SELECTION = "demand";
const DEFAULT_NAVIGATION_OPTIONS = ["demand", "usage", "comparison"];

class Desktop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationOptions: DEFAULT_NAVIGATION_OPTIONS,
      currentNavigation: DEFAULT_NAVIGATION_SELECTION,
      demandCommodities: [],
      monthlyUsageCommodities: [],
      annualUsageCommodities: [],
      demandDaily: [],
      demandMonthly: [],
      demandWeekly: [],
    };
    this.updateNavigation = this.updateNavigation.bind(this);
  }

  componentDidMount() {
    if (this.props.mapData.length === 0) this.props.mapActions.loadMapData();
    if (this.props.building.id !== undefined && this.props.building.id !== "")
      this.props.actions.loadDesktopEnergyData(this.props.building);
    //Added if a housing building has data preloaded from the map click.
    if (
      this.props.building.buildingType !== undefined &&
      this.props.building.buildingType !== ""
    ) {
      if (this.props.building.buildingType === "housing")
        this.setHousingOptions(this.props.building.id);
      else {
        let mnvOptions = DEFAULT_NAVIGATION_OPTIONS.slice();
        // mnvOptions.push("ace-savings");
        this.setState(
          {
            navigationOptions: mnvOptions
          },
          () => {
            this.setCommodities();
          }
        );
      }
    }
    if (this.props.desktopEnergyData !== null && this.props.desktopEnergyData != []) {
      this.getHotWaterDemandData();
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.tab !== null && this.props.tab !== prevProps.tab)
      this.setState({ currentNavigation: this.props.tab });
    if (prevProps.building.id !== this.props.building.id)
      this.props.actions.loadDesktopEnergyData(this.props.building);
    if (
      this.props.building !== null &&
      this.props.building !== prevProps.building
    ) {
      if (this.props.building.buildingType === "housing")
        this.setHousingOptions(this.props.building.id);
      else {
        let mnvOptions = DEFAULT_NAVIGATION_OPTIONS.slice();
        // mnvOptions.push("ace-savings");
        this.setState(
          {
            navigationOptions: mnvOptions
          },
          () => {
            this.setCommodities();
          }
        );
      }
    }
    if (
      prevProps.building.singleBuildingEuis !==
      this.props.building.singleBuildingEuis
    )
      prevProps.actions.loadAllEuis(this.props.building.singleBuildingEuis);
    if (this.props.desktopEnergyData !== null && this.props.desktopEnergyData != prevProps.desktopEnergyData) {
      this.getHotWaterDemandData();
    };
  }

  async getHotWaterDemandData() {
    const hotWaterConversion = this.props.building.energyInfo.hotWaterConversion;
    const desktopEnergyData = this.props.desktopEnergyData
    if (desktopEnergyData?.demandDaily?.length > 0 && !this.state.demandDaily.find((val)=> val.commodity === "hotWater")) {
      let demandDaily =  [];
      for (let i = 0; i < desktopEnergyData.demandDaily.length; i++) {
        if (hotWaterConversion && desktopEnergyData.demandDaily[i].commodity === "steam") {
          const steamIdx = desktopEnergyData.demandDaily[i].data.findIndex((val) => {
            return new Date(val.Timestamp) > new Date(hotWaterConversion)
          });
          const steamData = Object.assign({}, desktopEnergyData.demandDaily[i])
          steamData.data = desktopEnergyData.demandDaily[i].data.slice(0, steamIdx);
          demandDaily.push(steamData, {building: this.props.building.id, commodity: "hotWater", data: desktopEnergyData.demandDaily[i].data.slice(steamIdx)});
        } else {
          demandDaily.push(desktopEnergyData.demandDaily[i])
        }
      }
      await this.setState({demandDaily})
    }
    if (desktopEnergyData?.demandWeekly?.length > 0 && !this.state.demandWeekly.find((val)=> val.commodity === "hotWater")) {
      let demandWeekly =  [];
      for (let i = 0; i < desktopEnergyData.demandWeekly.length; i++) {
        if (hotWaterConversion && desktopEnergyData.demandWeekly[i].commodity === "steam") {
          const steamIdx = desktopEnergyData.demandWeekly[i].data.findIndex((val) => {
            return new Date(val.Timestamp) > new Date(hotWaterConversion)
          });
          const steamData = Object.assign({}, desktopEnergyData.demandWeekly[i])
          steamData.data = desktopEnergyData.demandWeekly[i].data.slice(0, steamIdx);
          demandWeekly.push(steamData, {building: this.props.building.id, commodity: "hotWater", data: desktopEnergyData.demandWeekly[i].data.slice(steamIdx)});
        } else {
          demandWeekly.push(desktopEnergyData.demandWeekly[i])
        }
      }
      await this.setState({demandWeekly})
    }
    if (desktopEnergyData?.demandMonthly?.length > 0 && !this.state.demandMonthly.find((val)=> val.commodity === "hotWater")) {
      let demandMonthly =  [];
      for (let i = 0; i < desktopEnergyData.demandMonthly.length; i++) {
        if (hotWaterConversion && desktopEnergyData.demandMonthly[i].commodity === "steam") {
          const steamIdx = desktopEnergyData.demandMonthly[i].data.findIndex((val) => {
            return new Date(val.Timestamp) > new Date(hotWaterConversion)
          });
          const steamData = Object.assign({}, desktopEnergyData.demandMonthly[i])
          steamData.data = desktopEnergyData.demandMonthly[i].data.slice(0, steamIdx);
          demandMonthly.push(steamData, {building: this.props.building.id, commodity: "hotWater", data: desktopEnergyData.demandMonthly[i].data.slice(steamIdx)});
        } else {
          demandMonthly.push(desktopEnergyData.demandMonthly[i])
        }
      }
      await this.setState({demandMonthly})
    }
  }

  removeNavigationOption(option) {
    let newNavigation = [];
    for (let i = 0; i < this.state.navigationOptions.length; i++)
      if (this.state.navigationOptions[i] !== option)
        newNavigation.push(this.state.navigationOptions[i]);
    this.setState({ navigationOptions: newNavigation }, () => {
      this.setCommodities();
    });
  }

  async setCommodities() {
    let demandCommodities = await reorderCommodities(
        this.props.building.energyInfo.tags.demand,
        this.props.building.energyInfo.hotWaterConversion
      ),
      monthlyUsageCommodities = await reorderCommodities(
        this.props.building.energyInfo.tags.monthlyUsage,
        this.props.building.energyInfo.hotWaterConversion
      ),
      annualUsageCommodities = await reorderCommodities(
        this.props.building.energyInfo.tags.annualUsage,
        this.props.building.energyInfo.hotWaterConversion
      );
    await this.setState({
      demandCommodities,
      monthlyUsageCommodities,
      annualUsageCommodities
    });
    if (demandCommodities.length === 0)
      await this.removeNavigationOption("demand");
    if (
      monthlyUsageCommodities.length === 0 &&
      annualUsageCommodities.length === 0
    )
      await this.removeNavigationOption("usage");
    if (this.state.currentNavigation === DEFAULT_NAVIGATION_SELECTION) {
      let found = false;
      for (let i = 0; i < this.state.navigationOptions.length; i++)
        if (this.state.navigationOptions[i] === DEFAULT_NAVIGATION_SELECTION)
          found = true;
      if (!found)
        this.setState({ currentNavigation: this.state.navigationOptions[0] });
    }
  }

  setHousingOptions(id) {
    let newOptions = DEFAULT_NAVIGATION_OPTIONS.slice();
    newOptions.push("savings");
    this.setState({ navigationOptions: newOptions }, () => {
      this.setCommodities();
    });
  }

  updateNavigation(event) {
    let newValue = event.target.id;
    this.setState({ currentNavigation: newValue });
  }

  render() {
    return (
      <div className="building">
        <Header
          building={this.props.building}
          euiValues={this.props.desktopEnergyData.singleBuildingEuis}
        />
        <Navigation
          options={this.state.navigationOptions}
          selection={this.state.currentNavigation}
          onClick={this.updateNavigation}
        />
        <div className="building-content">
          {this.props.building.id !== "" && (
            <NavigationView
              view={this.state.currentNavigation}
              building={this.props.building}
              data={{...this.props.desktopEnergyData, demandDaily: this.state.demandDaily, demandMonthly: this.state.demandMonthly, demandWeekly: this.state.demandWeekly}}
              onClick={this.updateNavigation}
              allBuildings={this.props.mapData}
              ranking={this.props.ewcRanking}
              rankingWeekly={this.props.ewcWeekly}
              demandCommodities={this.state.demandCommodities}
              monthlyUsageCommodities={this.state.monthlyUsageCommodities}
              annualUsageCommodities={this.state.annualUsageCommodities}
            />
          )}
          <Footer />
        </div>
      </div>
    );
  }
}

Desktop.propTypes = {
  building: PropTypes.object.isRequired,
  ewcRanking: PropTypes.array,
  ewcWeekly: PropTypes.object,
  mapData: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  tab: PropTypes.string
};

function mapStateToProps(state) {
  return {
    desktopEnergyData: state.desktopEnergyData,
    mapData: state.mapData,
    ewcRanking: state.ewcRanking,
    ewcWeekly: state.ewcWeekly
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(buildingActions, dispatch),
    mapActions: bindActionCreators(mapDataActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Desktop);
