/**
 * Actions used for the Data Download Tool.
 * Loads buildings that are able to get Pi
 * data, and performs the queries for
 * commodity data in bulk.
 */

import * as types from "./actionTypes";
import { createApolloFetch } from "apollo-fetch";
import axios from "axios";
import {
  afBuildingsQuery,
  afCommodityQuery,
  afOatQuery
} from "../queries/dataDownloadQuery";
import { clearSpaces, formatTimestamp } from "../components/common/utilities";
import { commodityProperties } from "../components/dataDownload/common/utilities";

const uri = "/api/graphql";
const apolloFetch = createApolloFetch({ uri });

const KBTU_COMMODITIES = ["electricity", "chilledWater", "steam", "heatingGas"];

// Pushes the data to the store.
const loadAfBuildingsSuccess = buildings => {
  return {
    type: types.DATA_DOWNLOAD_LOAD_BUILDINGS_SUCCESS,
    buildings
  };
};

const loadRequestSuccess = downloadRequest => {
  return {
    type: types.DATA_DOWNLOAD_REQUEST_SUCCESS,
    downloadRequest
  };
};

const buildDataKey = (building, commodity) => {
  return clearSpaces(building).toLowerCase() + commodity;
};

const buildHeaderLabel = (building, commodity, kbtu) => {
  let unitLabel =
    kbtu && KBTU_COMMODITIES.includes(commodity)
      ? "kBtu"
      : commodityProperties[commodity].units;
  return (
    building +
    " - " +
    commodityProperties[commodity].displayName +
    " : " +
    unitLabel
  );
};

const buildHeaders = (requestedData, oat, isKBTU) => {
  return new Promise((res, rej) => {
    let headers = [
      { label: "Start Date Time", key: "timestamp" },
      { label: "End Date Time", key: "timestampEnd" }
    ];
    if (oat) headers.push({ label: "Outside Air Temperature", key: "oat" });
    for (let name in requestedData)
      for (let i = 0; i < requestedData[name].length; i++)
        headers.push({
          label: buildHeaderLabel(name, requestedData[name][i], isKBTU),
          key: buildDataKey(name, requestedData[name][i])
        });
    res(headers);
  });
};

const buildDownloadData = (data, includeOat, oatRequestParams) => {
  return new Promise(async (res, rej) => {
    try {
      let timestamp = await buildTimestamps(
          data,
          false,
          oatRequestParams.interval
        ),
        endTimestamp = await buildTimestamps(
          data,
          true,
          oatRequestParams.interval
        ),
        tempData = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i].building !== undefined && data[i].commodity !== undefined)
          tempData.push({
            [buildDataKey(
              data[i].building,
              data[i].commodity
            )]: await buildValues(data[i].data, timestamp)
          });
      }
      if (includeOat) {
        delete oatRequestParams.dataType;
        tempData.push({
          oat: await downloadOatData(oatRequestParams, timestamp)
        });
      }
      let mergedData = [];
      for (let j = 0; j < timestamp.length; j++) {
        let tempObject = {};
        tempObject.timestamp = timestamp[j];
        tempObject.timestampEnd = endTimestamp[j];
        for (let k = 0; k < tempData.length; k++)
          for (let buildingKey in tempData[k]) {
            tempObject[buildingKey] = tempData[k][buildingKey][j];
          }

        mergedData.push(tempObject);
      }
      res(mergedData);
    } catch (e) {
      console.log("An error occurred while setting the data for downloading.");
      throw new Error(e);
    }
  });
};

const buildFilename = ({ dataType, unitType }) => {
  let today = new Date();
  return (
    today.getMonth() +
    1 +
    "-" +
    today.getDate() +
    "-" +
    today.getFullYear() +
    "_" +
    dataType +
    "_" +
    unitType
  );
};

const buildTimestamps = (timestampSet, endTimestamp, interval) => {
  return new Promise((res, rej) => {
    try {
      let timestampArray = [];
      for (let i = 0; i < timestampSet.length; i++)
        if (timestampSet[i].data.length > 0) {
          for (let j = 0; j < timestampSet[i].data.length; j++) {
            let tempTimeStamp = timestampSet[i].data[j].Timestamp;
            if (endTimestamp) {
              if (j + 1 < timestampSet[i].data.length) {
                tempTimeStamp = timestampSet[i].data[j + 1].Timestamp;
                tempTimeStamp = new Date(tempTimeStamp);
                tempTimeStamp.setSeconds(tempTimeStamp.getSeconds() - 1);
              } else {
                tempTimeStamp = new Date(tempTimeStamp);
                if (interval === "15m")
                  tempTimeStamp.setMinutes(tempTimeStamp.getMinutes() + 15);
                else if (interval === "1h")
                  tempTimeStamp.setHours(tempTimeStamp.getHours() + 1);
                else tempTimeStamp.setHours(tempTimeStamp.getHours() + 24);
                tempTimeStamp.setSeconds(tempTimeStamp.getSeconds() - 1);
              }
            }
            timestampArray.push(formatTimestamp(tempTimeStamp));
          }
          break;
        } else continue;
      res(timestampArray);
    } catch (e) {
      console.log(
        "An error occurred while building the requested timestamp values."
      );
      throw new Error(e);
    }
  });
};

const buildValues = (dataSet, timestampsSet) => {
  return new Promise((res, rej) => {
    try {
      let builtData = [];
      for (let i = 0; i < timestampsSet.length; i++) {
        let timestampFound = false;
        for (let j = 0; j < dataSet.length; j++) {
          if (timestampsSet[i] === formatTimestamp(dataSet[j].Timestamp)) {
            builtData.push(
              dataSet[j].Value > 0 ? dataSet[j].Value.toFixed(3) : 0
            );
            timestampFound = true;
            break;
          }
        }
        if (!timestampFound) builtData.push(null);
      }
      res(builtData);
    } catch (e) {
      console.log("An error occurred while building the CSV data.");
      throw new Error(e);
    }
  });
};

const downloadOatData = (variables, timestampData) => {
  return new Promise((res, rej) => {
    try {
      apolloFetch({
        query: afOatQuery,
        variables
      }).then(async oatData => {
        if (oatData.data !== undefined && oatData.data.oat !== undefined) {
          let compiledOat = await buildValues(oatData.data.oat, timestampData);
          res(compiledOat);
        } else res(["Unable to gather Outside Air Temperature."]);
      });
    } catch (e) {
      console.log(
        "An error occurred while gathering the Outside Air Temperature data."
      );
      throw new Error(e);
    }
  });
};

const makeDataRequest = variables => {
  return new Promise((res, rej) => {
    try {
      apolloFetch({
        query: afCommodityQuery,
        variables
      })
        .then(commodityData => {
          if (
            commodityData.data !== undefined &&
            commodityData.data.downloadData[0] !== undefined
          )
            res(commodityData.data.downloadData[0]);
          else rej({ error: "Data unavailable" });
        })
        .catch(e => {
          console.log(e);
          rej({ error: e });
        });
    } catch (e) {
      console.log("An error has occurred:");
      throw new Error(e);
    }
  });
};

const postRequestDetails = dataBody => {
  axios({
    method: "POST",
    uri:
      window.location.protocol +
      "//" +
      window.location.host +
      "/api/datadownload",
    body: dataBody,
    json: true
  });
};

export const loadAfBuildings = () => {
  return dispatch => {
    apolloFetch({
      query: afBuildingsQuery
    }).then(afBuildingData => {
      if (
        afBuildingData.data !== undefined &&
        afBuildingData.data.buildings !== undefined
      )
        dispatch(loadAfBuildingsSuccess(afBuildingData.data.buildings));
    });
  };
};

export const loadDataRequests = (downloadRequest, buildings) => {
  return dispatch => {
    let baseRequest = {
        dataType: downloadRequest.dataType,
        startTime: downloadRequest.startDate.toISOString(),
        endTime: downloadRequest.endDate.toISOString(),
        interval: downloadRequest.interval
      },
      requestVariables = [];
    postRequestDetails(downloadRequest);
    for (let key in buildings)
      buildings[key].map(commodity => {
        let tempVariables = {
          buildingName: key,
          commodity
        };
        if (
          downloadRequest.unitType === "kbtu" &&
          KBTU_COMMODITIES.includes(commodity)
        )
          tempVariables.dataType = "Demand_kBtu";
        return requestVariables.push({ ...baseRequest, ...tempVariables });
      });
    Promise.all(
      requestVariables.map(variables => {
        return makeDataRequest(variables);
      })
    ).then(async values => {
      let finalData = {
        headers: await buildHeaders(
          buildings,
          downloadRequest.outsideAirTemp,
          downloadRequest.unitType === "kbtu" ? true : false
        ),
        data: await buildDownloadData(
          values,
          downloadRequest.outsideAirTemp,
          Object.assign({}, baseRequest)
        ),
        filename: await buildFilename(downloadRequest)
      };
      dispatch(loadRequestSuccess(finalData));
    });
  };
};
