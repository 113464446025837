import React from "react";
import MediaQuery from "react-responsive";
import { CSSTransition } from "react-transition-group";
import { breakPoints } from "../../common/config";

import calculations from "./images/calculations.png";
import calculationsMobile from "./images/calculations-mobile.png";

const symbol = open => {
  if (!open) return <span className="ewc-toggle-symbol plus">+</span>;
  return <span className="ewc-toggle-symbol minus">-</span>;
};

const SavingsCalculation = ({ expanded, toggle }) => {
  return (
    <div
      className="ewc-calculation-toggle"
      onClick={e => toggle("savingsCalculation")}
    >
      <div className="ewc-calculation-toggle-header">
        {symbol(expanded)} How are the % Savings Calculated?
      </div>
      <CSSTransition
        in={expanded}
        timeout={300}
        classNames="ewc-calculation-toggle-details"
        unmountOnExit
      >
        <div className="ewc-calculation-toggle-expanded">
          <p>
            The percent savings in the ranking table above show how much energy
            and water is being saved <b>compared to a baseline consumption.</b>{" "}
            The project percent savings are determined by finding the difference
            between the past week's usage and the average weekly usage. The
            average weekly usage is calculated with the previous 2 weeks of
            data. This average will move with time, also called a{" "}
            <b>rolling baseline,</b> so the baseline will always be calculated
            using the past two weeks.
          </p>
          <MediaQuery maxWidth={breakPoints.mobile}>
            <img
              src={calculationsMobile}
              alt="Percent savings calculation formula."
            />
          </MediaQuery>
          <MediaQuery minWidth={breakPoints.tablet}>
            <img
              src={calculations}
              alt="Percent savings calculation formula."
            />
          </MediaQuery>
        </div>
      </CSSTransition>
    </div>
  );
};

export default SavingsCalculation;
