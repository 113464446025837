import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export function waterGoal(state = initialState.waterGoalData, action) {
  switch (action.type) {
    case types.LOAD_WATER_GOAL_SUCCESS:
      return action.waterGoalData;
    default:
      return state;
  }
}

export function waterUsage(state = initialState.waterUsageData, action) {
  switch (action.type) {
    case types.LOAD_WATER_USAGE_SUCCESS:
      return action.waterUsageData
    default:
      return state;
  }
}