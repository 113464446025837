import React, { Component } from "react";
import PropTypes from "prop-types";
import HeaderRow from "./gridRows/HeaderRow";
import BuildingProjectRow from "./gridRows/BuildingProjectRow";
import TotalsRow from "./gridRows/TotalsRow";
import { usageTemplateObject } from "../Lists";
import "./ProjectGrid.css";

class ProjectGrid extends Component {
  constructor(props) {
    super(props);
    this.handleGraphSelect.bind(this);
  }

  handleGraphSelect = (e, graphIndex, building) => {
    // change the selected graph when child is selected
    e.preventDefault();
    this.props.selectGraph(e, graphIndex, building);
  };

  projectRowMapper = (name, index) => {
    return (
      <div className={`${name}-container`} key={index}>
        <BuildingProjectRow
          buildingName={this.props.buildingNameList[index]}
          shortName={this.props.shortNameList[index]}
          commodityList={this.props.commodityList}
          savings={
            this.props.savingsInDollars[this.props.shortNameList[index]]
          }
          selectGraph={this.handleGraphSelect}
          graphSelectionArray={
            this.props.graphSelectionArray[this.props.shortNameList[index]]
          }
        />
      </div>
    );
  };

  getBuildingCommTotal = e => {
    this.props.buildingCommTotal(e);
  };

  render() {
    return (
      <div className="grid-container">
        <div className="heading-container">
          <HeaderRow />
        </div>
        {this.props.shortNameList.map((name, index) => {
          return this.projectRowMapper(name, index);
        })}
        <div className="totals-container">
          <TotalsRow
            text="Totals"
            commodityList={this.props.commodityList}
            savings={this.props.savingsInDollars}
            BuildingCommTotal={this.getBuildingCommTotal}
          />
        </div>
      </div>
    );
  }
}

ProjectGrid.propTypes = {
  commodityList: PropTypes.array.isRequired,
  buildingNameList: PropTypes.array.isRequired,
  shortNameList: PropTypes.array.isRequired,
  savingsInDollars: PropTypes.object.isRequired,
  selectGraph: PropTypes.func.isRequired,
  graphSelectionArray: PropTypes.object.isRequired
};

export default ProjectGrid;
