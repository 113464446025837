export const opportunitiesLogQuery = `
{
  opportunitiesLog {
    buildingName
    savings
    description
    date
  }
}
`;

export const hvacSchedulingQuery = `
{
  hvacScheduling {
    buildingName
    savings
  }
}
`;

export const holidayShutdownsQuery = `
{
  holidayShutdowns {
    holiday
    startDate
    endDate
    electricitySavings
    steamSavings
    chilledWaterSavings
    totalSavings
  }
}
`;

export const aceFYSavingsQuery = `
{
  aceFYsavings {
    fyear
    savings
  }
}
`;

export const mnvTotalsQuery = `
{
  mnvTotal {
    commodity
    data {
      currentFY {
        fy
        value
      }
      pastFY {
        fy
        value
      }
    }
  }


}
`;
