export const buildingNameList = [
  "Ghausi Hall",
  "Plant and Environmental Sciences",
  "Vet Med 3A",
  "Shields Library",
  "Meyer Hall",
  "Genome & Biomedical Sciences",
  "RMI North, South, Sensory",
  "Earth and Physical Sciences"
];

export const commodityList = ["electricity", "steam", "chilledWater"];

export const shortNameList = [
  "ghausi",
  "pes",
  "vm3a",
  "shields",
  "meyer",
  "gbsf",
  "rmi_nss",
  "eps"
];

export const usageTemplateJSON = {
  ghausi: {},
  pes: {},
  vm3a: {},
  shields: {},
  meyer: {},
  gbsf: {},
  rmi_nss: {},
  eps: {}
};

export const usageTemplateObject = {
  ghausi: {
    electricity: [],
    steam: [],
    chilledWater: []
  },
  pes: {
    electricity: [],
    steam: [],
    chilledWater: []
  },
  vm3a: {
    electricity: [],
    steam: [],
    chilledWater: []
  },
  shields: {
    electricity: [],
    steam: [],
    chilledWater: []
  },
  meyer: {
    electricity: [],
    steam: [],
    chilledWater: []
  },
  gbsf: {
    electricity: [],
    steam: [],
    chilledWater: []
  },
  rmi_nss: {
    electricity: [],
    steam: [],
    chilledWater: []
  },
  eps: {
    electricity: [],
    steam: [],
    chilledWater: []
  }
  
};
