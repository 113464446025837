let Highcharts = require("highcharts");

export const initializeGraph = args => {
  Highcharts.setOptions({
    lang: {
      thousandsSep: ","
    }
  });
  window.usageChart = new Highcharts.Chart({
    colors: args.color,
    title: null,
    chart: {
      renderTo: "commodity-breakdown-graph",
      type: "bar",
      height: 410,
      plotBorderColor: "#F1F1F2",
      plotBorderWidth: 2
    },
    backgroundColor: "#f1f1f2",
    borderWidth: 0,
    style: {
      color: "#000",
      fontFamily: [
        "Proxima Nova",
        "Lucida Grande",
        "Lucida Sans",
        "Verdana",
        "sans-serif"
      ]
    },
    shadow: false,
    crosshairs: true,
    shared: true,
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    xAxis: {
      categories: args.xAxis,
      labels: {
        align: "center",
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: "12px",
          letterSpacing: "1px",
          textTransform: "uppercase"
        }
      },
      title: {
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        }
      },
      gridLineColor: "#888888",
      lineWidth: 2
    },
    yAxis: {
      title: {
        text: "Usage",
        rotation: 0,
        offset: 40,
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: "14px"
        }
      },
      labels: {
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: "14px"
        }
      },
      lineWidth: 2,
      gridLineWidth: 2,
      lineColor: "#F1F1F2",
      gridLineColor: "#F1F1F2",
      tickColor: "#fff",
      tickWidth: 0,
      maxPadding: 0,
      opposite: false,
      tickAmount: 8,
      showLastLabel: false
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:12px;font-weight:600">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">' +
        '<td style="padding:0"><b>{point.y} {point.unit}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      valueDecimals: 0,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      }
    },
    series: [
      {
        showInLegend: false,
        name: args.seriesLabel,
        data: args.series
      }
    ]
  });
};
