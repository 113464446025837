import * as types from "./actionTypes";
import { createApolloFetch } from "apollo-fetch";
import { mapDataQuery } from "../queries/mapDataQuery";

import { findAverageValue } from "../components/main/sidebar/stats/utilities/graphUtilities";

import { DateTime } from "luxon";

// ACE'd buildings
const GREEN_BUILDINGS = ["ghausi", "pes", "vm3a", "shields"];

const loadConnectionsSuccess = connectionData => {
  return {
    type: types.LOAD_CONNECTIONS_SUCCESS,
    connectionData
  };
};

export const loadMapDataSuccess = mapData => {
  return {
    type: types.LOAD_MAP_DATA_SUCCESS,
    mapData
  };
};

const updateMapDataSuccess = mapData => {
  return {
    type: types.UPDATE_MAP_DATA_SUCCESS,
    mapData
  };
};

export const buildingTypeChanged = (mapData, buttons) => {
  return function(dispatch) {
    dispatch(updateMapDataSuccess(mapData));
  };
};

export const updateMapData = mapData => {
  return function(dispatch) {
    dispatch(updateMapDataSuccess(mapData));
  };
};

export const checkConnections = () => {
  const uri = "/health";
  const apolloFetch = createApolloFetch({ uri });
  return function(dispatch) {
    apolloFetch()
      .then(connectionData => {
        dispatch(loadConnectionsSuccess(connectionData));
      })
      .catch(error => {
        dispatch(loadConnectionsSuccess({ Status: "DOWN" }));
        throw error;
      });
  };
};

export const loadMapData = () => {
  const uri = "/api/graphql";
  const apolloFetch = createApolloFetch({ uri });
  return function(dispatch) {
    apolloFetch({ query: mapDataQuery })
      .then(async mapData => {
        let combinedMapData = await combineArrays(mapData.data);
        combinedMapData = await setAverages(combinedMapData, "eui");
        combinedMapData = await setAverages(combinedMapData, "annualUsage");
        dispatch(loadMapDataSuccess(combinedMapData));
      })
      .catch(error => {
        throw error;
      });
  };
};

const addUpSquareFootage = facLinkData => {
  let totalSquareFootage = 0;
  for (let facLinkBuilding of facLinkData) {
    if (facLinkBuilding !== null)
      totalSquareFootage += facLinkBuilding.relatedGross;
  }
  return totalSquareFootage;
};

const buildEuis = data => {
  return new Promise((resolve, reject) => {
    let buildings = data.buildingMarkers,
      chilledWater = data.chilledWaterEuis.euis,
      electricity = data.electricityEuis.euis,
      gas = data.gasEuis.euis,
      steam = data.steamEuis.euis,
      euis = [];
    for (let i = 0; i < buildings.length; i++) {
      if (buildings[i].euiReady) {
        let tempEui = 0;
        for (let j = 0; j < chilledWater.length; j++)
          if (
            chilledWater[j] !== null &&
            chilledWater[j].building === buildings[i].id
          )
            tempEui += chilledWater[j].eui;
        for (let k = 0; k < electricity.length; k++)
          if (
            electricity[k] !== null &&
            electricity[k].building === buildings[i].id
          )
            tempEui += electricity[k].eui;
        for (let l = 0; l < gas.length; l++)
          if (gas[l] !== null && gas[l].building === buildings[i].id)
            tempEui += gas[l].eui;
        for (let m = 0; m < steam.length; m++)
          if (steam[m] !== null && steam[m].building === buildings[i].id)
            tempEui += steam[m].eui;
        euis.push({ building: buildings[i].id, eui: tempEui });
      }
    }
    resolve(euis);
  });
};

const combineArrays = async mapData => {
  return new Promise(async (res, rej) => {
    let combinedData = [];
    let euis = await buildEuis(mapData);
    mapData.buildingMarkers
      .filter(marker => {
        //filter out markers missing center.lat or center.long properties
        return marker.center && marker.center.lat && marker.center.long;
      })
      //add eui value and 'visible: true' to each marker
      .forEach((marker, index) => {
        let eui = null,
          annualUsage = null,
          euiCircleRadius = null,
          annualCircleRadius = null,
          greenBuilding = false;
        let totalSqft = addUpSquareFootage(marker.facLinkData);
        let occupiedYears = parseOccupiedYears(marker.facLinkData);
        for (let i = 0; i < euis.length; i++) {
          if (marker.euiReady === false) {
            eui = 0;
            break;
          } else if (euis[i].building === marker.id) {
            eui = Math.round(euis[i].eui);
            annualUsage = Math.round(eui * totalSqft);
            euiCircleRadius = Math.round(Math.sqrt(eui * 60));
            annualCircleRadius = Math.round(Math.sqrt(annualUsage * 0.0007));
            break;
          }
        }
        if (
          (marker.certifications !== null &&
            marker.certifications.length > 0) ||
          GREEN_BUILDINGS.includes(marker.id)
        )
          greenBuilding = true;
        combinedData.push({
          ...marker,
          eui,
          annualUsage,
          totalSqft,
          occupiedYears,
          euiCircleRadius,
          annualCircleRadius,
          valueVisible: true,
          buildingTypeVisible: true,
          greenBuilding
        });
      });

    combinedData
      .sort((a, b) => {
        //sort items by eui value in reverse numerical order
        return b.eui - a.eui;
      })
      .forEach((building, index) => {
        //add explicit index property for easier parsing later
        building.index = index;
      });

    res(combinedData);
  });
};

const setAverages = (buildings, type) => {
  let averages = {
    community: findAverageValue(sortBuildingType(buildings, "community"), type),
    lab: findAverageValue(sortBuildingType(buildings, "lab"), type),
    office: findAverageValue(sortBuildingType(buildings, "office"), type),
    classroom: findAverageValue(sortBuildingType(buildings, "classroom"), type),
    housing: findAverageValue(sortBuildingType(buildings, "housing"), type)
  };
  for (let i = 0; i < buildings.length; i++)
    if (type === "eui") {
      buildings[i].euiAverage =
        buildings[i].buildingType !== null
          ? Math.round(averages[buildings[i].buildingType])
          : null;
    } else if (type === "annualUsage") {
      buildings[i].annualAverage =
        buildings[i].buildingType !== null
          ? Math.round(averages[buildings[i].buildingType])
          : null;
    }
  return buildings;
};

const sortBuildingType = (buildings, buildingType) => {
  let tempArray = [];
  for (let i = 0; i < buildings.length; i++)
    if (buildings[i].buildingType === buildingType)
      tempArray.push(buildings[i]);
  return tempArray;
};

const parseOccupiedYears = facLinkData => {
  if (facLinkData.length === 1) {
    if (
      facLinkData[0] !== null &&
      (facLinkData[0].occupied !== null || facLinkData[0].constructed !== null)
    ) {
      return DateTime.fromISO(
        facLinkData[0].occupied === null
          ? facLinkData[0].constructed
          : facLinkData[0].occupied
      ).toLocaleString({
        // just return the one year
        year: "numeric"
      });
    }
  } else {
    let years = [];
    for (let datum of facLinkData) {
      if (
        datum !== null &&
        (datum.occupied !== null || datum.constructed !== null)
      ) {
        let year = DateTime.fromISO(
          datum.occupied === null ? datum.constructed : datum.occupied
        ).toLocaleString({
          // convert ISO YYYY-MM-DD string to a string with just the year
          year: "numeric"
        });
        if (!years.indexOf(year) >= 0) {
          // if not already in array
          years.push(year);
        }
      }
    }
    if (years.length === 1) {
      return years[0]; //just return the one year
    } else if (years.length) {
      years = years.sort(); // sort years in numerical order
      if (years[0] === years[years.length - 1]) return years[0];
      else {
        let { 0: firstYear, [years.length - 1]: lastYear } = years; // pull out first and last values for year range
        return `${firstYear} – ${lastYear}`; // return string with range of years (e.g. '1961 – 1963')
      }
    }
  }
};
