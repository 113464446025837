import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export function filterButtons(state = initialState.filterButtons, action) {
  switch (action.type) {
    case types.FILTER_BUTTON_CLICK:
      return action.filterButtons;

    default:
      return state;
  }
}

export function filterSlider(state = initialState.filterSlider, action) {
  switch (action.type) {
    case types.FILTER_SLIDER_CHANGE:
      return action.filterSlider;

    default:
      return state;
  }
}

export function greenButton(state = initialState.greenButton, action) {
  switch (action.type) {
    case types.GREEN_BUILDING_CLICK:
      return action.greenButton;

    default:
      return state;
  }
}
