import React from "react";
import PropTypes from "prop-types";
import * as utilities from "../../../common/utilities";

import Tooltip from "../../common/Tooltip";

class TotalUsageGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      electricityPopup: false,
      gasPopup: false
    };
  }

  render() {
    return (
      <div className="total-usage-grid">
        <div className="usage-grid-header">
          <div className="usage-grid-column column-1">Commodity</div>
          <div className="usage-grid-column column-2">
            Average Monthly Usage
          </div>
          <div className="usage-grid-column column-3">Total Annual Usage</div>
        </div>
        <div className="usage-grid-row electricity" id="usage-grid-electricity">
          <div className="usage-grid-column column-1">
            <Tooltip tooltipType="electricity" />
            <b>Electricity</b>
          </div>
          <div className="usage-grid-column column-2">
            <b>
              {utilities.commaFormatNumbers(this.props.electricity.monthly)}
            </b>{" "}
            MMBtu
          </div>
          <div className="usage-grid-column column-3">
            <b>{utilities.commaFormatNumbers(this.props.electricity.annual)}</b>{" "}
            MMBtu
          </div>
        </div>
        <div className="usage-grid-row gas" id="usage-grid-gas">
          <div className="usage-grid-column column-1">
            <Tooltip tooltipType="gas" />
            <b>Natural Gas</b>
          </div>
          <div className="usage-grid-column column-2">
            <b>{utilities.commaFormatNumbers(this.props.gas.monthly)}</b> MMBtu
          </div>
          <div className="usage-grid-column column-3">
            <b>{utilities.commaFormatNumbers(this.props.gas.annual)}</b> MMBtu
          </div>
        </div>
      </div>
    );
  }
}

TotalUsageGrid.propTypes = {
  gas: PropTypes.object.isRequired,
  electricity: PropTypes.object.isRequired
};

export default TotalUsageGrid;
