import { setCATimezoneOffset } from "./utilities";

// commonly used axis labels for timestamped Charts
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const monthsAbrev = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

const weekDaysAbrev = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// Builds the Demand graph x-axis labels when the selected time interval is "PAST 24 HRS"
export const getDailyLabels = axisValue => {
  let localDate = new Date(axisValue);
  let GMTScale = setCATimezoneOffset(new Date()); // subtract from UTC hours to get the actual PDT
  let hourOfDay = localDate.getUTCHours() - GMTScale;
  let xLabel = "";

  if (hourOfDay < 0) hourOfDay = hourOfDay + 24;

  if (hourOfDay > 11) xLabel = (hourOfDay - 12).toString() + ":00pm";
  else xLabel = hourOfDay.toString() + ":00am";

  if (xLabel === "0:00pm")
    xLabel = (localDate.getUTCHours() - GMTScale).toString() + ":00pm";

  if (xLabel === "0:00am")
    xLabel =
      weekDays[localDate.getUTCDay()] +
      " <br> " +
      months[localDate.getUTCMonth()] +
      " " +
      localDate.getUTCDate().toString();
  return xLabel;
};

// Builds the Demand graph x-axis labels when the selected time interval is "PAST 7 DAYS"
export const getWeeklyLabels = axisValue => {
  let localDate = new Date(axisValue);

  let dayOfWeek = weekDaysAbrev[localDate.getUTCDay()];
  let dayOfMonth = localDate.getUTCDate();
  let month = monthsAbrev[localDate.getUTCMonth()];

  return dayOfWeek + " <br> " + month + " " + dayOfMonth;
};

// Builds the Demand graph x-axis labels when the selected time interval is "PAST 30 DAYS"
export const getMonthlyLabels = axisValue => {
  let localDate = new Date(axisValue);

  let month = monthsAbrev[localDate.getUTCMonth()];
  let dayOfMonth = localDate.getUTCDate();
  let year = localDate.getUTCFullYear();

  return month + " " + dayOfMonth + " <br> " + year;
};
