import React from "react";
import buildingTypes from "../../images/building-types.png";

export default function ModalPage3() {
  return (
    <div className="modal-content-column">
      <div className="modal-content-wrapper">
        <div className="modal-content-columns-wrapper">
          <div className="modal-content-column1">
            <div className="modal-content-headline">
              Consider building type to compare energy use.
            </div>
            <p>
              Different types of buildings use different amounts of energy.
            </p>
          </div>
          <div className="modal-content-column2"/>
        </div>
        <div className="modal-building-img">
          <img src={buildingTypes} alt="building types image"/>
        </div>
      </div>
    </div>
  )
}