import React from "react";
import { Link } from "react-router-dom";

// Highcharts
import ReactHighcharts from "react-highcharts";

// css
import "./Stats.css";

// images
import BuildingTypeIcon from "./BuildingTypeIcon";

import BuildingTypeDescriptions from "./utilities/buildingTypeDescriptions";

// redux actions, store
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as rankingActions from "../../../../actions/rankingActions";
import * as mapDataActions from "../../../../actions/mapDataActions";
import * as markerClickActions from "../../../../actions/markerClickActions";
import * as buildingActions from "../../../../actions/buildingActions";
import * as currentMapSelectionActions from "../../../../actions/currentMapSelectionActions";
import arrow from "../../../../images/arrow.svg";

// utilities
import {
  commaFormatNumbers,
  capitalizeFirstLetter
} from "../../../common/utilities";
import {
  findAverageValue,
  graphConfigFactory
} from "./utilities/graphUtilities";

const DEFAULT_SWITCH = "eui";

class Stats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedState: true,
      markerClick: {},
      currentMapSelection: "",
      graphConfig: {},
      hoverStyle: {},
      avgValue: null,
      switch: DEFAULT_SWITCH
    };
    this.graphRef = React.createRef(); //ref needed to calculate div position for graph hover
  }

  // shows / hides side panel
  setParentClassOnSlideout = classCheckData => {
    return classCheckData
      ? `building-stats-parent ${classCheckData.buildingType}`
      : "building-stats-parent hide";
  };

  // reset markerClick object when side panel is closed
  resetMarkerClick = () => {
    let markerClickStatus = false;
    let buildingData = null;
    this.props.markerClickActions.markerClickHaveCost(
      markerClickStatus,
      buildingData
    );
    this.setState(prevState => {
      return {
        closedState: true
      };
    });
  };

  // called via onMouseEnter over bar graph to calculate hover position and fade it in
  graphMouseEnter = () => {
    let node = this.graphRef.current;
    let rect = node.getBoundingClientRect();

    this.setState(prevState => {
      return {
        hoverStyle: {
          top: Math.round(rect.top) - 12,
          left: Math.round(rect.right),
          opacity: 1
        }
      };
    });
  };

  // called via onMouseLeave over bar graph to fade hover out
  graphMouseLeave = () => {
    this.setState(prevState => {
      return {
        hoverStyle: {
          top: prevState.hoverStyle.top,
          left: prevState.hoverStyle.left,
          opacity: 0
        }
      };
    });
  };

  // calculate percent above/below/about average for graph hover
  findPercentToAverage = (avgValue, thisValue) => {
    let percentDifference = Math.round((thisValue / avgValue - 1) * 100);

    if (percentDifference < 0) {
      return (
        <span className="below-average">
          {Math.abs(percentDifference)}% below average
        </span>
      );
    } else if (percentDifference > 0) {
      return (
        <span className="above-average">
          {Math.abs(percentDifference)}% above average
        </span>
      );
    } else {
      return "About average";
    }
  };

  // for when user swaps between EUI and Annual Usage view
  handleValueTypeChange = selectedOption => {
    if (selectedOption) {
      this.props.currentMapSelectionActions.mapSelectionHandler(selectedOption);
    }
    this.setState({ switch: selectedOption });
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.state.markerClick !== nextProps.markerClick ||
      this.state.currentMapSelection !== nextProps.currentMapSelection
    ) {
      //always update state with markerClick object
      this.setState(prevState => {
        return {
          markerClick: nextProps.markerClick,
          switch: nextProps.currentMapSelection
        };
      });

      if (nextProps.markerClick.buildingData) {
        // filter map data for buildings of same type (e.g. "lab") and eui value > 0
        let filteredByTypeMapData = nextProps.mapData.filter(marker => {
          return (
            marker.buildingType ===
              nextProps.markerClick.buildingData.buildingType && marker.eui > 0
          );
        });

        // find average value (rounded to nearest whole integer)
        let avgValue = Math.round(
          findAverageValue(filteredByTypeMapData, nextProps.currentMapSelection)
        );

        // create highcharts graph config object
        let graphConfig = graphConfigFactory(
          nextProps.markerClick,
          filteredByTypeMapData,
          avgValue,
          nextProps.currentMapSelection
        );

        //update state
        this.setState(prevState => {
          return {
            avgValue: avgValue,
            currentMapSelection: nextProps.currentMapSelection,
            closedState: false,
            graphConfig: graphConfig
          };
        });
      }
    }
  }

  componentDidUpdate() {
    if (this.props.markerClick.buildingData) {
      // preload building graph data
      this.props.buildingActions.loadBuildingData(
        this.props.markerClick.buildingData.id
      );

      // update mapData object in redux store with annualCost value
      let newMapData = this.props.mapData;
      for (let obj of newMapData) {
        if (obj.id === this.props.markerClick.buildingData.id) {
          obj.annualCost = this.props.markerClick.buildingData.annualCost;
          break;
        }
      }
      this.props.mapActions.updateMapData(newMapData);
    }
  }

  render() {
    let percentAverageComponent = this.state.markerClick.buildingData
      ? this.findPercentToAverage(
          this.state.avgValue,
          this.state.markerClick.buildingData[this.state.currentMapSelection]
        )
      : null;
    return (
      <div
        className={this.setParentClassOnSlideout(
          this.state.markerClick.buildingData
        )}
      >
        {this.state.markerClick.buildingData &&
        this.state.markerClick.buildingData.eui ? (
          <div className="graph-hover-wrapper" style={this.state.hoverStyle}>
            <div className="graph-hover-tip-container">
              <div className="graph-hover-tip" />
            </div>
            <div className="graph-hover-content">
              <div className="building-type">
                {this.state.markerClick.buildingData.buildingType} Average:
              </div>
              <div className="type-average">
                <span className="em-dash">—</span>{" "}
                {this.state.avgValue.toLocaleString("en", {
                  useGrouping: true
                })}{" "}
                kBtu
                {this.state.currentMapSelection === "eui" ? (
                  <span>/ft&sup2;</span>
                ) : null}
              </div>
              <br />
              <div className="this-building">This building:</div>
              <div className="type-average">
                {this.state.markerClick.buildingData[
                  this.state.currentMapSelection
                ].toLocaleString("en", {
                  useGrouping: true
                })}{" "}
                kBtu
                {this.state.currentMapSelection === "eui" ? (
                  <span>/ft&sup2;</span>
                ) : null}
              </div>
              <br />
              <div className="percent">{percentAverageComponent}</div>
            </div>
          </div>
        ) : null}

        {this.state.markerClick.buildingData ? (
          <div className="building-stats">
            <BuildingTypeIcon
              buildingType={this.state.markerClick.buildingData.buildingType}
            />

            {/* Close Button */}
            <div
              className="close-button"
              onClick={this.resetMarkerClick}
              aria-pressed={this.closedState}
              aria-label="Close Building Stats"
            >
              &times;
            </div>
            <div
              className={`stats-content-wrapper-1 ${this.state.currentMapSelection}-selected`}
            >
              {/* Building Name */}
              <h1>{this.state.markerClick.buildingData.name}</h1>

              <div className="stats-content-wrapper-2 top-list">
                <ul>
                  {/* Energy Use Intensity */}
                  <li>
                    {this.state.switch === DEFAULT_SWITCH ? (
                      <div>
                        <div className="value-title">Energy Use Intensity:</div>
                        {this.state.markerClick.buildingData.eui === 0 ? (
                          <div className="value">
                            <b>Incomplete</b>
                          </div>
                        ) : (
                          <div className="value">
                            <b>
                              {this.state.markerClick.buildingData.eui}{" "}
                              kBtu/ft&sup2;
                            </b>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div className="value-title">Annual Usage:</div>
                        <div className="value">
                          <b>
                            <div className="value">
                              {this.state.markerClick.buildingData.annualUsage.toLocaleString(
                                "en",
                                { useGrouping: true }
                              )}{" "}
                              kBtu/yr
                            </div>
                          </b>
                        </div>
                      </div>
                    )}
                  </li>

                  {/* Lab Average */}
                  <li>
                    <div className="value-title">
                      {this.state.markerClick.buildingData.buildingType
                        .charAt(0)
                        .toUpperCase()}
                      {this.state.markerClick.buildingData.buildingType.slice(
                        1
                      )}{" "}
                      Average:
                    </div>
                    <div className="value">
                      <b>
                        {commaFormatNumbers(this.state.avgValue)} kBtu/
                        {this.props.currentMapSelection === "eui" ? (
                          <span>ft&sup2;</span>
                        ) : (
                          "yr"
                        )}
                      </b>
                    </div>
                  </li>

                  {/* Year Constructed */}
                  <li>
                    <div className="value-title">Year Constructed:</div>
                    <div className="value">
                      {this.state.markerClick.buildingData.occupiedYears}
                    </div>
                  </li>

                  {/* Square Footage */}
                  <li>
                    <div className="value-title">Square Footage:</div>
                    <div className="value">
                      {this.state.markerClick.buildingData.totalSqft.toLocaleString(
                        "en",
                        { useGrouping: true }
                      )}{" "}
                      ft&sup2;
                    </div>
                  </li>

                  {/* Annual Cost */}
                  {this.state.markerClick.buildingData.annualCost ? (
                    <li
                      className={
                        this.state.markerClick.buildingData.certifications !==
                          null &&
                        this.state.markerClick.buildingData.certifications
                          .length !== 0
                          ? ""
                          : "annual-cost"
                      }
                    >
                      <div className="value-title">Annual Cost:</div>
                      <div className="value">
                        $
                        {Math.round(
                          this.state.markerClick.buildingData.annualCost
                        ).toLocaleString("en", { useGrouping: true })}
                      </div>
                    </li>
                  ) : null}

                  {/* Green Building */}
                  {this.state.markerClick.buildingData.certifications !==
                    null &&
                  this.state.markerClick.buildingData.certifications.length !==
                    0 ? (
                    <li
                      className={
                        this.state.markerClick.buildingData.annualCost
                          ? ""
                          : "green-building"
                      }
                    >
                      <div className="value-title">Green Building:</div>
                      <div className="value">
                        {this.state.markerClick.buildingData.certifications[0].name.toUpperCase()}{" "}
                        {this.state.markerClick.buildingData.certifications[0]
                          .type !== null &&
                          this.state.markerClick.buildingData.certifications[0]
                            .type !== "" &&
                          capitalizeFirstLetter(
                            this.state.markerClick.buildingData
                              .certifications[0].type
                          )}
                      </div>
                    </li>
                  ) : null}

                  {/* Primary Usage */}
                  <li>
                    <div className="value-title">Primary Usage:</div>
                    <div className="value">
                      <span className="primary-usage">
                        {this.state.markerClick.buildingData.primaryUse}
                      </span>
                      (
                      {Math.round(
                        this.state.markerClick.buildingData.primaryPercent * 100
                      )}
                      %)
                    </div>
                  </li>

                  {/* Secondary Usage */}
                  {this.state.markerClick.buildingData.secondaryUse ? (
                    <li>
                      <div className="value-title">Secondary Usage:</div>
                      <div className="value">
                        <span className="secondary-usage">
                          {this.state.markerClick.buildingData.secondaryUse}
                        </span>
                        (
                        {Math.round(
                          this.state.markerClick.buildingData.secondaryPercent *
                            100
                        )}
                        %)
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
              <ul>
                {/* Annual Usage */}
                {this.state.markerClick.buildingData.annualUsage &&
                this.state.switch === DEFAULT_SWITCH ? (
                  <li className="annual-usage">
                    <div className="usage-value">
                      <div className="value-title">Annual Usage:</div>
                      <div className="value">
                        {this.state.markerClick.buildingData.annualUsage.toLocaleString(
                          "en",
                          { useGrouping: true }
                        )}{" "}
                        kBtu/yr
                      </div>
                    </div>
                    <div className="switch-link">
                      <button
                        id="usageLink"
                        title="switch to usage view"
                        onClick={e => {
                          this.handleValueTypeChange("annualUsage");
                        }}
                      >
                        switch to usage view
                      </button>
                    </div>
                  </li>
                ) : null}
                {this.state.markerClick.buildingData.annualUsage &&
                this.state.switch !== DEFAULT_SWITCH ? (
                  <li className="eui">
                    <div className="usage-value">
                      <div className="value-title">Energy Use Intensity:</div>
                      <div className="value">
                        {this.state.markerClick.buildingData.eui}{" "}
                        <span className="eui-units">kBtu/ft&sup2;</span>
                      </div>
                    </div>
                    <div className="switch-link">
                      <button
                        id="euiLink"
                        title="switch to EUI view"
                        onClick={e => {
                          this.handleValueTypeChange("eui");
                        }}
                      >
                        switch to EUI view
                      </button>
                    </div>
                  </li>
                ) : null}
              </ul>

              <ul>
                <li>
                  {/* View Building Data button */}
                  <Link
                    to={{
                      pathname: `/building/${this.state.markerClick.buildingData.id}`
                    }}
                  >
                    <button
                      className="building-data-button"
                      onClick={e => {
                        this.resetMarkerClick();
                      }}
                    >
                      View Energy Data
                    </button>
                  </Link>
                </li>

                {/* EUI Bar Graph */}
                {this.state.markerClick.buildingData.eui ? (
                  <li
                    id="barGraph"
                    className="bar-graph"
                    ref={this.graphRef}
                    onMouseEnter={this.graphMouseEnter}
                    onMouseLeave={this.graphMouseLeave}
                  >
                    <ReactHighcharts config={this.state.graphConfig} />
                  </li>
                ) : null}
              </ul>
            </div>
            <div
              className="view-ranking"
              id="view-ranking"
              onClick={this.props.actions.openFullRankingSidebar}
            >
              <span>View Full Building Ranking</span>
              <img src={arrow} alt="arrow icon" />
            </div>
            <div className="stats-content-wrapper-2">
              <ul>
                {/* Building Type Description */}
                <li className="description-wrapper">
                  <BuildingTypeDescriptions
                    buildingType={
                      this.state.markerClick.buildingData.buildingType
                    }
                  />
                </li>
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

Stats.propTypes = {
  mapData: PropTypes.array.isRequired,
  markerClick: PropTypes.object.isRequired,
  currentMapSelection: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    mapData: state.mapData,
    markerClick: state.markerClick,
    currentMapSelection: state.currentMapSelection
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(rankingActions, dispatch),
    mapActions: bindActionCreators(mapDataActions, dispatch),
    markerClickActions: bindActionCreators(markerClickActions, dispatch),
    buildingActions: bindActionCreators(buildingActions, dispatch),
    currentMapSelectionActions: bindActionCreators(
      currentMapSelectionActions,
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
