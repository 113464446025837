import React from "react";

import lightningBolt from "../images/toggle-energy.svg";
import waterDrop from "../images/toggle-water.svg";

const commodities = [
  {
    name: "electricity",
    color: "#92BD3B",
    displayName: "Electricity",
    icon: lightningBolt
  },
  {
    name: "water",
    color: "#32BBD5",
    displayName: "Water",
    icon: waterDrop
  }
];

const toggleStyle = {
  display: "flex",
  justifyContent: "space-around",
  width: "50%",
  minWidth: "302px",
  margin: "20px auto"
};

const buttonStyle = (isActive, commodityColor) => {
  let baseStyle = {
      display: "flex",
      justifyContent: "center",
      width: "40%",
      padding: "4px 0 2px",
      marginTop: "-5px",
      textAlign: "center",
      border: "1px solid " + commodityColor,
      borderRadius: "12px",
      color: commodityColor,
      fontSize: "15px",
      lineHeight: "18px",
      cursor: "pointer",
      transition: "ease all 0.3s"
    },
    active = {
      background: commodityColor,
      color: "white",
      fontWeight: "600"
    };
  if (isActive) return { ...baseStyle, ...active };
  return baseStyle;
};

const CommodityToggle = ({ currentCommodity, tip, onClick, visible }) => {
  return (
    <div className="ewc-switch-toggle" style={toggleStyle}>
      <div className="ewc-switch-description">Show:</div>
      {commodities.map(commodity => {
        if (visible === undefined || visible[commodity.name])
          return (
            <div
              className={
                "ewc-switch-button" +
                (commodity.name === currentCommodity ? " active" : "")
              }
              id={commodity.name}
              style={buttonStyle(
                commodity.name === currentCommodity ? true : false,
                commodity.color
              )}
              onClick={onClick}
              key={commodity.name}
            >
              <img
                src={commodity.icon}
                alt="Commodity icon"
                className={"switch-button-image"}
                id={commodity.name}
                style={{ margin: "0 8px 0 -8px" }}
              />
              {commodity.displayName}
              {tip ? " Tips" : ""}
            </div>
          );
        return null;
      })}
    </div>
  );
};

export default CommodityToggle;
