import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";

import TabNavigation from "./tabNavigation/TabNavigation";

import BuildingCommissioning from "./buildingCommissioning/BuildingCommissioning";
import OpportunitiesLog from "./opportunitiesLog/OpportunitiesLog";
import HvacScheduling from "./hvacScheduling/HvacScheduling";
import HolidayShutdowns from "./holidayShutdowns/HolidayShutdowns";
import { breakPoints } from "../../common/config";
import "./BuildingProjects.css";

const SetTabNavigationView = props => {
  const getBuildingCommTotalFromSet = e => {
    props.getBuildingCommTotal(e);
  };

  switch (props.view) {
    case "buildingCommissioning":
      return (
        <BuildingCommissioning
          buildingCommTotal={getBuildingCommTotalFromSet}
        />
      );
    case "opportunitiesLog":
      return <OpportunitiesLog />;
    case "hvac":
      return <HvacScheduling />;
    case "holidayShutdowns":
      return <HolidayShutdowns currentYear={props.totals} />;
    default:
      return null;
  }
};

class BuildingProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTabNavigation: "buildingCommissioning",
      buildingCommTotal: 0
    };
  }

  updateTabNavigation = event => {
    let newValue = event.target.id;
    this.setState({
      currentTabNavigation: newValue
    });
  };

  getBuildingCommTotal = e => {
    this.setState({ buildingCommTotal: e });
  };

  render() {
    return (
      <React.Fragment>
        <MediaQuery minWidth={breakPoints.tablet}>
          <div className="building-projects">
            <TabNavigation
              tabSelection={this.state.currentTabNavigation}
              onClick={this.updateTabNavigation}
              buildingCommTotal={this.state.buildingCommTotal}
              totals={this.props.buildingProjects}
            />
            <div className="building-projects-content">
              <SetTabNavigationView
                view={this.state.currentTabNavigation}
                getBuildingCommTotal={this.getBuildingCommTotal}
                totals={this.props.buildingProjects.currentFYtotals.holidayFY}
              />
            </div>
          </div>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

BuildingProjects.propTypes = {
  buildingProjects: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    buildingProjects: state.buildingProjects
  };
}

export default connect(mapStateToProps)(BuildingProjects);
