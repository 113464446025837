import Highcharts from "highcharts";

export const defaultEnergyUse = {
  data: null,
  name: "Energy Use",
  tooltip: {
    id: "energyUse",
    valueDecimals: 0,
    valueSuffix: " MMBtu"
  },
  yAxis: 0,
  color: "#99be4f",
  marker: {
    symbol: "circle",
    radius: 3
  }
};

export const initializeBarLineGraph = (xAxis, seriesData) => {
  return {
    chart: {
      zoomType: "xy",
      plotBorderColor: "#F1F1F2",
      plotBorderWidth: 0,
      height: 310
    },
    title: {
      text: null
    },
    xAxis: {
      labels: {
        rotation: 75
      },
      categories: xAxis,
      tickLength: 0
    },
    yAxis: setYAxis(seriesData[1].name),
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000,
          easing: "easeOutBounce"
        }
      }
    },
    tooltip: {
      backgroundColor: "#F6F6F6",
      borderColor: "#EEEDEE",
      borderRadius: 3,
      borderWidth: 1.5,
      shadow: true,
      crosshairs: {
        width: 1,
        color: "#cccccc"
      },
      style: {
        color: "#2D3138",
        lineHeight: "17px",
        fontSize: "13px"
      },
      headerFormat:
        '<span style="font-size: 14px; font-weight: 100; color: #2D3138; opacity: 0.5; lineHeight: 17px;">{point.key}</span><table><br/>',
      pointFormat:
        '<span style="background-color:{series.color}; color:{series.color}; font-weight:700; font-size:9px; line-height:18px; vertical-align:top; max-width: 10px; max-height: 10px; border:2px solid white; border-radius: 50%; margin-right:5px; padding-right: 3px;">\u25CF </span><span style="font-weight: 100;">  {series.name}: </span> <span style="font-weight: 500;">{point.y}</span><br/>',
      useHTML: true,
      shared: true
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: seriesData,
    visible: true
  };
};

// Established the default graph parameters.
export const initializeLineGraph = (xAxis, yAxis) => {
  return {
    chart: {
      plotBorderColor: "#F1F1F2",
      plotBorderWidth: 2,
      height: 160
    },
    title: {
      text: null
    },
    xAxis: {
      gridLineWidth: 0,
      gridLineColor: "transparent",
      categories: xAxis,
      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0
    },
    yAxis: setYAxis(yAxis[1].name),
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000,
          easing: "easeOutBounce"
        }
      }
    },
    tooltip: {
      backgroundColor: "#F6F6F6",
      borderColor: "#EEEDEE",
      borderRadius: 3,
      borderWidth: 1.5,
      shadow: true,
      crosshairs: true,
      style: {
        color: "#2D3138",
        lineHeight: "17px",
        fontSize: "13px"
      },
      pointFormat:
        '<span style="color:{series.color};font-weight:700;">\u25CF {series.name}:</span> {point.y}<br/>',
      shared: true,
      useHTML: true
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: yAxis,
    visible: true
  };
};

// Sets the Y Axis parameters for the Demand chart. This chart contains
// a left axis[0] (data) and right axis[1] (Outside Air Temperature).
export const setYAxis = secondaryAxisLabel => {
  return [
    {
      min: 0,
      lineWidth: 2,
      gridLineWidth: 0,
      title: {
        text: "MMBtu/sq.",
        align: "high",
        rotation: 0,
        offset: 15,
        style: {
          color: "#92bd3b",
          fontSize: 13,
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        }
      },
      lineColor: "#F1F1F2",
      gridLineColor: "#F1F1F2",
      minPadding: 0,
      maxPadding: 0,
      opposite: false,
      tickAmount: 5,
      labels: {
        formatter: function() {
          if (this.isLast) {
            return "";
          }
          let label = this.axis.defaultLabelFormatter.call(this);
          if (/^[0-9]{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value, 0);
          }
          return label;
        },
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: 13
        },
        reserveSpace: true
      },
      endOnTick: false
    },
    {
      //secondary yAxis
      title: {
        text: secondaryAxisLabel,
        align: "high",
        rotation: 0,
        offset: 15,
        style: {
          color: "#4B5059",
          fontSize: 13,
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        }
      },
      labels: {
        formatter: function() {
          if (this.isLast) {
            return "";
          }
          let label = this.axis.defaultLabelFormatter.call(this);
          if (/^[0-9]{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value, 0);
          }
          return label;
        },
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: 13
        },
        reserveSpace: true
      },
      opposite: true,
      visible: true,
      tickAmount: 5,
      minPadding: 0
    }
  ];
};
