import React, { useState } from "react"

import styles from "./MainContent.module.css"

import Demand from "./demand/Demand"

export default function MainContent(props) {
  return (
    <main className={styles.container}>
      <h1>Heating & Cooling Data</h1>
      <div className={styles.info}>
        <h2>
          Energy Demand
          <br />
          <span>(rate of consumption)</span>
        </h2>
        <p>
          Gauge energy efficiency by comparing heating and cooling energy demand
          to outside air temperature (represented by the dark line on the graph
          below).
        </p>
      </div>
      <Demand
        weeklyData={props.demandWeeklyTLC}
        weeklyOat={props.oatWeekly}
      />
    </main>
  )
}
