/**
 * HighCharts utilities that are used to build the Water Usage HighChart.
 * These functions set up the default parameters along with some
 * helper functions for formatting the visual elements of the chart.
 */

 import Highcharts from "highcharts";

 const fontFamily =
  '"Proxima Nova","Lucida Grande","Lucida Sans","Helvetica Neue",Helvetica,Arial,sans-serif';

 export const energySource = (id, name, color, visible) => {
   var hoverColor;
   return {
     name: name,
     id: id,
     data: [],
     tooltip: {
       valueDecimals: 0,
       valueSuffix: " Mgals"
     },
     borderColor: color,
     color: Highcharts.color(color)
       .setOpacity(1)
       .get(),
     visible: visible,
     states: {
       hover: {
         color: color
       }
     }
   };
 };
 
 // Established the default graph parameters.
 export const initializeEnergyGraph = (xAxis, yAxis, xAxisMax) => {
   return {
     chart: {
       height: 400,
       type: "column"
     },
     credits: {
      enabled: false
    },
     navigator: {
      enabled: false
     },
     rangeSelector: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
     title: {
       text: null
     },
     time: {
      useUTC: false
    },
     xAxis: {
       scrollbar: {
         enabled: true,
       },
       labels: {
        rotation: 0,
        formatter: function(){
            return xAxis[this.value];
        },
        style: {
        fontFamily: fontFamily,
        fontSize: "13px",
        letterSpacing: "0.9px",
        color: "#2D3138"
        }
       },
       range: 11,
       max: xAxisMax-1,
       tickInterval: 1
     },
     yAxis: setYAxis(),
     legend: {
       enabled: false
     },
     plotOptions: {
       series: {
         animation: {
           duration: 1000,
           easing: "easeOutBounce"
         }
       },
       column: {
         stacking: "normal",
         pointWidth: 18
       }
     },
     tooltip: {
      backgroundColor: "#F6F6F6",
      borderColor: "#EEEDEE",
      borderRadius: 3,
      borderWidth: 1.5,
      style: {
        color: "#2D3138",
        lineHeight: "17px",
        fontSize: "13px",
        fontFamily: fontFamily
      },
      shadow: true,
      headerFormat:
        '<span style="font-size: 14px; color: #2D3138; opacity: 0.5; lineHeight: 17px;">{point.key}</span><table><br/>',
      pointFormat:
        '<span style="background-color:{series.color}; color:{series.color}; font-weight:700; font-size:9px; line-height:18px; vertical-align:top; max-width: 10px; max-height: 10px; border:2px solid white; border-radius: 50%; margin-right:5px; padding-right:3px;">\u25CF </span><span>  {series.name}:</span> {point.y}<br/>',
      crosshairs: true,
      shared: true,
      useHTML: true,
      formatter: function() {
        let text =
          '<span style="font-size: 14px; color: #2D3138; opacity: 0.5; lineHeight: 17px;">' +
          xAxis[this.x] +
          "</span><br>";

        this.points.forEach(function(item, i) {
          text +=
            '<span style="background-color:' +
            item.series.userOptions.states.hover.color +
            "; color:" +
            item.series.color +
            '; font-weight:700; font-size:9px; line-height:18px; vertical-align:top; max-width: 10px; max-height: 10px; border:2px solid white; border-radius: 10px; margin-right:2px;">\u25CF </span><span>  ' +
            item.series.name +
            ": </span>" +
            Number(item.y).toLocaleString() +
            " Mgals" +
            "<br/>";
        });
        return text;
      }
    },
     credits: {
       enabled: false
     },
     exporting: {
       enabled: false
     },
     series: yAxis,
     visible: true
   };
 };
 
 // Sets the Y Axis parameters for the Water usage chart.
 export const setYAxis = () => {
   return [
     {
       min: 0,
       gridLineWidth: 2,
       title: {
         text: "Mgals",
         align: "high",
         rotation: 0,
         offset: 15,
         style: {
           color: "#2D3138",
           fontSize: 13,
           fontFamily: [
             "Proxima Nova",
             "Lucida Grande",
             "Lucida Sans",
             "Verdana",
             "sans-serif"
           ]
         }
       },
       lineColor: "#F1F1F2",
       gridLineColor: "#F1F1F2",
       minPadding: 0,
       maxPadding: 0,
       opposite: false,
       tickAmount: 5,
       labels: {
         formatter: function() {
           if (this.isLast) {
             return "";
           }
           let label = this.axis.defaultLabelFormatter.call(this);
           if (/^[0-9]{4}$/.test(label)) {
             return Highcharts.numberFormat(this.value, 0);
           }
           return label;
         },
         style: {
           color: "#444952",
           fontFamily: [
             "Proxima Nova",
             "Lucida Grande",
             "Lucida Sans",
             "Verdana",
             "sans-serif"
           ],
           fontSize: 13
         },
         reserveSpace: true
       },
       endOnTick: false
     }
   ];
 };
 