import React from "react";

class BuildingTypeIcon extends React.Component {
  render() {
    return (
      <div className="building-type-icon">
        {this.props.buildingType === "classroom" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={
              this.props.filterButton
                ? "-431 231 45.4 78"
                : "-431 233.6 95.4 95.4"
            }
            width={this.props.filterButton ? "40" : "96"}
            height={this.props.filterButton ? "40" : "96"}
          >
            {!this.props.filterButton && (
              <path fill="#3ea0b7" d="M-431 329v-95.4h95.4z" />
            )}
            <path
              fill="#fff"
              d="M-411.9 281.8l4.2-13.8c1.8.1 3.5-.2 5.4-.4l3.3 14.1h1.7l-2.7-14.3c3.5-1.3 4.1-1.5 4.1-2.5 0-.7-.9-.8-2.1-.9l-.9-.2 1.3-3.7 7.2-.5c1.1-.1 1.4-.2 1.7-2l.5-4.2c.4-2.5-9.2-1.9-10.1 1.9l-1.2 1-7.4 2.1c-1.7.4-2.3 2.5-.3 2.5l7.7-.6-1.3 3.4c-2.9-.2-5.5-.4-8 .1-1 .1-1.9.3-2.2.8-1.8-.8-2.4-5-2.4-5.9v-1.1c.2.4.5.5 1 .5 1.4-.1 2-2.9 1.6-6.1-.3-3.2-1.2-5.7-2.8-5.4-.9.2-1.2 1.9-1 4.3-.1 2.2-.3 3.5-.3 7.6 0 2.6 1.5 8.7 4.7 9.1l-3.5 14.3h1.8z"
            />
          </svg>
        ) : null}
        {this.props.buildingType === "community" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={
              this.props.filterButton ? "-431 232.5 10 16" : "-431 233.6 20 20"
            }
            width={this.props.filterButton ? "40" : "96"}
            height={this.props.filterButton ? "40" : "96"}
          >
            {!this.props.filterButton && (
              <path fill="#107d4b" d="M-431 253.6v-20h20z" />
            )}
            <g fill="#fff">
              <path d="M-420.54 239.93l-.02-.25c-.02-.4-.1-.8-.46-1.05-.1-.06-.19-.17-.27-.25-.36-.3-.7-.59-1.03-.9-.11-.11-.08-.18.08-.23h.07c.27.06.54.1.81.18.09.03.17.07.21.13.23.21.46.44.67.67.07.06.15.13.21.06.04-.02.02-.14 0-.2-.12-.3-.29-.6-.4-.89-.1-.31-.29-.46-.62-.52-.88-.17-1.77-.31-2.67-.38a8.67 8.67 0 0 0-2.2.13c-.63.1-1.24.3-1.8.6a2.38 2.38 0 0 0-1.2 2.73c.2.8.7 1.38 1.39 1.78.8.48 1.67.63 2.6.65.94.02 1.86-.06 2.79-.2.46-.07.92-.17 1.38-.28a.26.26 0 0 0 .19-.15l.23-.46c.04-.08.56-1.06.2-.88-.39.1-.56.64-.91.82-1.18.34-2.37.55-3.59.48a5.72 5.72 0 0 1-1-.14c-1.2-.34-1.7-1.4-1.56-2.48.08-.65.52-1.09 1.11-1.36a4.53 4.53 0 0 1 1.66-.38c.14 0 .29-.02.42-.04l1.06-.25c.09-.02.17-.02.26-.02v.04c-.21.13-.44.25-.65.4.1 0 .2-.02.31-.04.04 0 .08-.03.1 0 .36.2.8.35 1.03.73.02.04.06.06.1.08.36.27.72.57 1.03.9.23.23.17.5.23.78-.19.02-.19.02-.37.17h-.03c-.83.15-.77-.32-1.5-.57l.46-.2c-.05-.03-.07-.03-.09-.03l-1.02.25c-.38.1-.68 0-.95-.25a.74.74 0 0 1-.12-.94v-.06c-.2.18-.36.31-.34.6.02.24.13.64-.02.86l-.5.67c-.1.15-.17.34-.23.5.02 0 .02.03.04.03l.08-.13c.21-.33.44-.65.8-.88.37-.27.8.07 1.2-.14.3-.17.62-.13.94.02.23.1.25.16.08.35v.04c.53.15.8.19 1.34.09l.02-.02c.05-.13.15-.13.28-.13.02-.04.08-.04.17-.04m-1.7-1.7a2.44 2.44 0 0 0-.95-.42l.13.08c.29.13.46.35.73.49l.23.04c-.04-.09-.06-.17-.12-.27l-.02.08m1.1.82c-.01 0-.01.02-.03.02l.19.31.04-.02-.2-.31" />
              <path d="M-422.24 238.23l.02-.1.13.27c-.09 0-.17 0-.23-.04-.09-.05-.17-.07-.23-.15a.9.9 0 0 0-.5-.34.44.44 0 0 1-.13-.08c.16.04.33.06.5.12.17.09.3.22.44.32M-421.13 239.05l.19.31-.04.02-.2-.3c.03-.03.03-.03.05-.03" />
            </g>
          </svg>
        ) : null}
        {this.props.buildingType === "housing" ? (
          <svg
            width={this.props.filterButton ? "40" : "96"}
            height={this.props.filterButton ? "40" : "96"}
            viewBox={this.props.filterButton ? "12 0 20 65" : "0 0 96 96"}
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fillRule="evenodd">
              {!this.props.filterButton && (
                <path fill="#3778BC" fillRule="nonzero" d="M0 95.4V0h95.4z" />
              )}
              <path
                d="M48 34v-1H18.1v2H44v3h4v-4zm0-3H18.1v-5 1h10.3v-2h16.1c2 0 3.5 1.6 3.5 3.5V31zM14 20h4.1v18H14V20zm6.2 1h4.1a2 2 0 0 1 2 2v2h-6.1v-4z"
                fill="#FFF"
              />
            </g>
          </svg>
        ) : null}
        {this.props.buildingType === "lab" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={
              this.props.filterButton
                ? "-431 228 45.4 85.4"
                : "-431 233.6 95.4 95.4"
            }
            width={this.props.filterButton ? "40" : "96"}
            height={this.props.filterButton ? "40" : "96"}
          >
            {!this.props.filterButton && (
              <path fill="#2a575f" d="M-431 329v-95.4h95.4z" />
            )}
            <path
              fill="#fff"
              d="M-416.6 257.5c-1.7 3.9-.2 9.1.8 12.9.5 1.7.9 3.1.9 4.1.2 3.2.1 5.7.1 5.8 0 .3.1.5.3.7.1.2.4.3.6.3h3.7c.5 0 .9-.4.9-.9v-3c0-.3.3-.6.6-.6h5.9c.3 0 .6.3.6.6v3c0 .5.4.9.9.9h6.9c.4 0 .8-.3.8-.7a1 1 0 0 0-.4-1s-2.6-1.3-2.9-3.4c-.1-1 .3-2 1.2-3.1h3.3c.5 0 .9-.4.9-.9v-2.3c0-.5-.4-.9-.9-.9h-14.8c-1.3-.3-2.6-1.4-3.5-3.1-1.1-2-1.4-4.2-.8-5.7a6 6 0 0 1 3.6-3.5c1.2-.4 2.4-.4 3.5.1v5.1c0 .5.4.9.9.9h.1v1.2c0 .5.4.9.9.9h3.1c.5 0 .9-.4.9-.9v-1.2h.3c.5 0 .9-.4.9-.9v-10c0-.5-.4-.9-.9-.9h-.3v-.4h1.1c.5 0 .9-.4.9-.9v-2.4c0-.5-.4-.9-.9-.9h-7.1c-.5 0-.9.4-.9.9v2.4c0 .5.4.9.9.9h1.1v.4h-.1c-.5 0-.9.4-.9.8-2-.7-4.1-.7-6.2 0-2.7.9-4.8 3-6 5.7m17.1 3.5h-3.2v-8.2h3.5v8.2h-.3"
            />
          </svg>
        ) : null}
        {this.props.buildingType === "office" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={
              this.props.filterButton
                ? "-431 225 45.4 85.4"
                : "-431 233.6 95.4 95.4"
            }
            width={this.props.filterButton ? "40" : "96"}
            height={this.props.filterButton ? "40" : "96"}
          >
            {!this.props.filterButton && (
              <path fill="#93bb45" d="M-431 329v-95.4h95.4z" />
            )}
            <path
              fill="#fff"
              d="M-414 250.7a3.6 3.6 0 0 1 5.1 0c.6.6 1.6.6 2.2 0 .6-.6.6-1.6 0-2.2-2.6-2.6-6.9-2.6-9.6 0s-2.6 6.9 0 9.6l16.6 16.6c2.6 2.6 6.9 2.6 9.6 0s2.6-6.9 0-9.6l-1.5-1.5-10.1-10.1c-2-2-4.5-2.2-6.3-.4-1.8 1.8-1.6 4.5.3 6.4l9.4 9.4c.6.6 1.6.6 2.2 0 .6-.6.6-1.6 0-2.2l-9.4-9.4c-.3-.3-1.1-1.2-.3-2 .5-.5 1.1-.4 1.9.4l10.1 10.1 1.5 1.5c1.4 1.4 1.4 3.7 0 5.1s-3.7 1.4-5.1 0l-16.6-16.6a3.6 3.6 0 0 1 0-5.1"
            />
          </svg>
        ) : null}
      </div>
    );
  }
}

export default BuildingTypeIcon;
