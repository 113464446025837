import React from "react";

import housingLogo from "../images/sh-logo.png";
import ecoLogo from "../images/fac-logo.png";

const logosWrapperStyle = {
  width: "90%",
  display: "flex",
  justifyContent: "space-around",
  flexWrap: "wrap",
  margin: "auto"
};

const facilitiesStyle = {
  margin: "40px 23px",
  width: "210px"
};

const facilitiesImageStyle = {
  marginTop: "1px",
  width: "inherit",
  height: "auto"
};

const housingStyle = {
  margin: "40px 23px",
  width: "151px",
  filter: "invert(1)"
};

const Logos = () => {
  return (
    <div className="ewc-logos" style={logosWrapperStyle}>
      <a href="https://facilities.ucdavis.edu/">
        <div className="ewc-logo-fac" style={facilitiesStyle}>
          <img
            src={ecoLogo}
            alt="UC Davis Facilities Energy and Engineering logo"
            style={facilitiesImageStyle}
          />
        </div>
      </a>
      <a href="https://housing.ucdavis.edu/">
        <div className="ewc-logo-sh" style={housingStyle}>
          <img
            src={housingLogo}
            alt="UC Davis Student Housing and Dining Services logo"
          />
        </div>
      </a>
    </div>
  );
};

export default Logos;
