export const campusDemandDaily = `
query campusDemandDaily($currentTime:String!, $campusDailyStart:String!){
  campusDemandDaily : energyStoryDemand(
    meterIds:["chcp_gas_demand","pge_demand","solar_farm_demand","rooftop_solar_demand"]
  	startTime:$campusDailyStart,
    endTime:$currentTime,
    interval:"15m"
  ) {
    commodity,
    data {
      Timestamp
      Value
      UnitsAbbreviation
      Good
      Questionable
      Substituted
      Month
    }
  }
}
`;

export const campusDemandMonthly = `
query campusDemandMonthly($currentTime:String!, $campusMonthlyStart:String!){
  campusDemandMonthly : energyStoryDemand(
    meterIds:["chcp_gas_demand","pge_demand","solar_farm_demand","rooftop_solar_demand"]
  	startTime:$campusMonthlyStart,
    endTime:$currentTime,
    interval:"15m"
  ) {
    commodity,
    data {
      Timestamp
      Value
      UnitsAbbreviation
      Good
      Questionable
      Substituted
      Month
    }
  }
}
`;

export const campusDemandWeekly = `
query campusDemandWeekly($currentTime:String!, $campusWeeklyStart:String!){
  campusDemandWeekly : energyStoryDemand(
    meterIds:["chcp_gas_demand","pge_demand","solar_farm_demand","rooftop_solar_demand"]
  	startTime:$campusWeeklyStart,
    endTime:$currentTime,
    interval:"15m"
  ) {
    commodity,
    data {
      Timestamp
      Value
      UnitsAbbreviation
      Good
      Questionable
      Substituted
      Month
    }
  }
}
`;

export const campusUsage = `
query {
  campusUsage : energyStoryUsage {
    id
    fy
    carbonNeutralMMBTU
    solarFarmMMBTU
    rooftopSolarMMBTU
    gridMMBTU
    gasMMBTU
		primateGasMMBTU
		buildingGasMMBTU
    totalMMBTU
    electricityCost
    gasCost
    totalCost
    students
    campusSqFt
  }
}
`;

export const campusYearlyUsage = `
query yearlyUsage($projects: [String]!) {
  aceAnnualUsage: yearlyUsage(projectTypes: $projects) {
		name
    data{
        Timestamp
        Value
    }
  }
}
`;
