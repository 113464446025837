export const afBuildingsQuery = `
{
  buildings: afBuildingData {
    buildingType,
    eui,
		euiStatus,
    name: primaryDisplay,
    commodities
  }
}
`;

export const afCommodityQuery = `
query dataDownloadCommodity($buildingName: String!, $commodity:String!, $dataType:String!, $startTime:String!, $endTime:String!, $interval:String!) {
  downloadData : afCommodityData(
    buildingName: $buildingName 
    commodity: $commodity
    startTime: $startTime 
    endTime: $endTime
    interval:$interval
		dataType: $dataType
  ) {
		building,
		commodity,
		data {
			Timestamp,
			UnitsAbbreviation,
			Value
		}
  }
}
`;

export const afOatQuery = `
query interpolatedOat($startTime:String!, $endTime:String!, $interval:String!) {
  oat : interpolatedOat(
    startTime: $startTime 
    endTime: $endTime
    interval:$interval 
  ) {
      Timestamp
      Value
  }
}
`;
