import React, { Component } from "react";
import chilledWaterIcon from "../../images/ch_icon.png";
import electricityIcon from "../../images/electricity_icon.png";
import gasIcon from "../../images/ng_icon.png";
import steamIcon from "../../images/steam_icon.png";

class CommodityInfo extends Component {
  render() {
    return (
      <div className="commodityinfo-wrap">
        <div className="infowrap">
          <div className="info electricity">
            <div>
              <img src={electricityIcon} alt={"electricity icon"} />
              Electricity Potential Savings
            </div>
            <p>
              The potential savings for electricity are relatively stable
              throughout the year, because electricity use doesn't fluctuate
              with the seasons.
            </p>
          </div>
          <div className="info chilledWater">
            <div>
              <img src={chilledWaterIcon} alt={"chilled water icon"} />
              Chilled Water Potential Savings
            </div>
            <p>
              Chilled water is used for cooling, and the potential savings will
              change with the seasons. There's a higher potential for savings in
              Spring and Summer when chilled water consumption is higher.
            </p>
          </div>
        </div>
        <div className="infowrap">
          <div className="info steam">
            <div>
              <img src={steamIcon} alt={"steam icon"} />
              Steam Potential Savings
            </div>
            <p>
              Steam is used for heating, and the potential savings will change
              with the seasons. There's a higher potential for savings in the
              Fall and Winter when steam consumption is higher.
            </p>
          </div>
          <div className="info gas">
            <div>
              <img src={gasIcon} alt={"gas icon"} />
              Natural Gas Potential Savings
            </div>
            <p>
              The potential savings for natural gas will change with the seasons
              when natural gas is used for heating.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default CommodityInfo;
