import React from "react";
import PropTypes from "prop-types";
import * as chcpChartOptions from "../../common/chcpChartOptions";
import CsvButton from "../../../common/CsvButton";
import zoomIcon from "../../../../images/zoom.svg";
import ChcpChartMenu from "../../common/ChcpChartMenu";
import "./UsageChart.css";

let Highcharts = require("highcharts/highstock"),
  usageChart = null;
Highcharts.setOptions({
  lang: {
    thousandsSep: ","
  },
  global: {
    useUTC: false //Global Highcharts option that set timestamps to local time.
  }
});

const DEFAULT_USAGE_SELECTION = "daily";

class UsageChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeData: [],
      mode: "Real-Time",
      currentSelection: DEFAULT_USAGE_SELECTION,
      historicSelection: "Past 12 Months",
      checkBoxes: {
        daily: {
          electricity: true,
          gas: true
        },
        weekly: {
          electricity: true,
          gas: true
        },
        monthly: {
          electricity: true,
          gas: true
        },
        historic: {
          electricity: true,
          gas: true
        }
      },
      csvData: [],
      csvCommodities: []
    };
    this.activeClass = this.activeClass.bind(this);
    this.setOption = this.setOption.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
  }

  componentDidMount() {
    this.setCsvData();
    if (this.state.mode === "Real-Time") this.buildDemandChart();
    else this.buildHistoricChart();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentSelection !== this.state.currentSelection) {
      this.setCsvData();
      this.updateDemandChart();
    }
    if (prevState.historicSelection !== this.state.historicSelection)
      this.updateHistoricChart();
    if (prevState.mode !== this.state.mode) {
      this.setCsvData();
      if (this.state.mode === "Real-Time") this.buildDemandChart();
      else this.buildHistoricChart();
    }
  }

  componentWillUnmount() {
    usageChart = null;
  }

  activeClass(property) {
    for (let key in this.state)
      if (this.state[key] === property) return " active";
    return "";
  }

  async buildDemandChart() {
    let seriesData = await chcpChartOptions.buildBaseCommodities(
      this.props.usageData[this.state.currentSelection],
      this.state.checkBoxes[this.state.currentSelection]
    );
    usageChart = Highcharts.chart(
      "usage-chart-graph",
      chcpChartOptions.initializeRealTimeGraph(
        seriesData,
        this.state.currentSelection
      )
    );
  }

  async buildHistoricChart() {
    let historicData = await chcpChartOptions.setHistoricSeries(
      this.props.usageData.historic,
      this.state.checkBoxes.historic,
      this.state.historicSelection
    );
    usageChart = Highcharts.chart(
      "usage-chart-graph",
      chcpChartOptions.initializeHistoricGraph(
        historicData,
        this.state.historicSelection
      )
    );
  }

  setCsvData() {
    let tempData = [],
      tempCommoditiesList = [],
      dataSet =
        this.state.mode === "Real-Time"
          ? this.state.currentSelection
          : "historic",
      workingData = this.props.usageData[dataSet],
      workingCommodities = workingData.commodities;
    for (let i = 0; i < workingCommodities.length; i++) {
      let currentSet = workingData[workingCommodities[i]];
      for (let key in currentSet) {
        let tempCommodity =
          key !== "total" ? key : workingCommodities[i] + "Total";
        tempCommoditiesList.push(tempCommodity);
        tempData.push({
          commodity: tempCommodity,
          data: currentSet[key].slice(0)
        });
      }
    }
    this.setState({
      csvData: tempData,
      csvCommodities: tempCommoditiesList
    });
  }

  setOption(property, value) {
    if (this.state[property] !== value)
      this.setState({
        [property]: value
      });
  }

  updateChartCommodities() {
    if (this.state.mode === "Real-Time") this.updateDemandChart();
    else this.updateHistoricChart();
  }

  async updateDemandChart() {
    let series = await chcpChartOptions.buildBaseCommodities(
        this.props.usageData[this.state.currentSelection],
        this.state.checkBoxes[this.state.currentSelection]
      ),
      xAxis = await chcpChartOptions.setXAxis(this.state.currentSelection);
    if (usageChart === null) this.buildDemandChart();
    else
      usageChart.update({
        series,
        xAxis
      });
  }

  async updateHistoricChart() {
    let series = await chcpChartOptions.setHistoricSeries(
      this.props.usageData.historic,
      this.state.checkBoxes.historic,
      this.state.historicSelection
    );
    if (usageChart === null) this.buildHistoricChart();
    else
      usageChart.update({
        series,
        xAxis: chcpChartOptions.setHistoricXAxis(series)
      });
  }

  toggleCheckbox(commodity) {
    let checkBoxes = Object.assign({}, this.state.checkBoxes);
    if (this.state.mode === "Historic")
      checkBoxes.historic[commodity] = !checkBoxes.historic[commodity];
    else
      checkBoxes[this.state.currentSelection][commodity] = !checkBoxes[
        this.state.currentSelection
      ][commodity];
    this.setState({ checkBoxes }, this.updateChartCommodities());
  }

  render() {
    return (
      <div className="usage-chart graph-card">
        <div className="usage-chart-content">
          <div className="usage-chart-title">
            {this.state.mode} Usage
            {this.state.mode === "Real-Time" && (
              <div className="demand-zoom-helper">
                <img src={zoomIcon} alt="Magnifying glass" /> Click and drag to
                zoom.
              </div>
            )}
          </div>
          <div className="usage-chart-graph" id="usage-chart-graph" />
        </div>
        <div className="chart-sidebar">
          <ChcpChartMenu
            realTime={true}
            historic={true}
            data={this.props.usageData}
            stateProps={this.state}
            setOption={this.setOption}
            activeClass={this.activeClass}
            toggleCheckbox={this.toggleCheckbox}
          />
          <CsvButton
            data={this.state.csvData}
            commodities={this.state.csvCommodities}
            shortname={"CHCP_Usage"}
            dataType={
              this.state.mode +
              "_" +
              (this.state.mode === "Real-Time"
                ? this.state.currentSelection
                : "all")
            }
          />
        </div>
      </div>
    );
  }
}

UsageChart.propTypes = {
  usageData: PropTypes.object.isRequired
};

export default UsageChart;
