import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { buildingProjectTabs } from "../../../common/config";
import headshot from "../images/sam.png";
import caret from "../../../../images/caret.png";
import { formatDollarValue } from "../../../common/utilities";
import { getPastFiscalYear } from "../utils/formatting";

const NUMBER_OF_BUILDINGS = 8;

class HolidayShutdowns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DEFAULT_YEAR: this.props.currentYear,
      currentSelectYear: this.props.currentYear,
      activeData: [],
      otherSavings: {
        electricity: 0,
        steam: 0,
        chilledWater: 0,
        total: 0
      },
      totals: {
        electricity: 0,
        steam: 0,
        chilledWater: 0,
        total: 0
      }
    };
  }

  componentDidMount() {
    this.setActiveData();
  }

  componentDidUpdate(prevProps, prevState) {
    let month = new Date().getMonth();
    let currentYear = new Date().getFullYear();
    if (prevState.currentSelectYear !== this.state.currentSelectYear)
      this.setActiveData();
    else if (
      prevProps.holidayShutdowns.length !==
        this.props.holidayShutdowns.length &&
      this.props.holidayShutdowns.length > 0
    )
      this.setActiveData();
  }

  createSelectYears() {
    let years = [];
    let currentYear = new Date().getFullYear();

    for (let i = 2017; i <= currentYear; i++) {
      let nextYear = (i + 1).toString().substr(-2);
      years.push(
        <option key={i} value={i + "-" + nextYear}>
          {i}-{nextYear}
        </option>
      );
    }
    years.push(
      <option key={"all"} value={this.state.DEFAULT_YEAR}>
        {this.state.DEFAULT_YEAR}
      </option>
    );
    return years.reverse();
  }

  async setActiveData() {
    let activeData = [],
      otherSavings = {
        electricity: 0,
        steam: 0,
        chilledWater: 0,
        total: 0
      };
    if (this.state.currentSelectYear === "All") {
      for (let i = 0; i < this.props.holidayShutdowns.length; i++) {
        if (i < NUMBER_OF_BUILDINGS)
          activeData.push(this.props.holidayShutdowns[i]);
        else {
          otherSavings.electricity += this.props.holidayShutdowns[
            i
          ].electricitySavings;
          otherSavings.steam += this.props.holidayShutdowns[i].steamSavings;
          otherSavings.chilledWater += this.props.holidayShutdowns[
            i
          ].chilledWaterSavings;
          otherSavings.total += this.props.holidayShutdowns[i].totalSavings;
        }
      }
    } else {
      for (let j = 0; j < this.props.holidayShutdowns.length; j++) {
        if (
          this.props.holidayShutdowns[j].fy === this.state.currentSelectYear &&
          this.props.holidayShutdowns[j].totalSavings > 0
        ) {
          if (activeData.length < NUMBER_OF_BUILDINGS)
            activeData.push(this.props.holidayShutdowns[j]);
          else {
            otherSavings.electricity += this.props.holidayShutdowns[
              j
            ].electricitySavings;
            otherSavings.steam += this.props.holidayShutdowns[j].steamSavings;
            otherSavings.chilledWater += this.props.holidayShutdowns[
              j
            ].chilledWaterSavings;
            otherSavings.total += this.props.holidayShutdowns[j].totalSavings;
          }
        }
      }
    }
    let totals = await this.setTotals(activeData, otherSavings);
    this.setState({
      activeData,
      otherSavings,
      totals
    });
  }

  setTotals(holidays, others) {
    return new Promise((res, rej) => {
      let grandTotals = {
        electricity: 0,
        steam: 0,
        chilledWater: 0,
        total: 0
      };
      for (let k = 0; k < holidays.length; k++) {
        grandTotals.electricity += holidays[k].electricitySavings;
        grandTotals.steam += holidays[k].steamSavings;
        grandTotals.chilledWater += holidays[k].chilledWaterSavings;
        grandTotals.total += holidays[k].totalSavings;
      }
      grandTotals.electricity += others.electricity;
      grandTotals.steam += others.steam;
      grandTotals.chilledWater += others.chilledWater;
      grandTotals.total += others.total;
      res(grandTotals);
    });
  }

  render() {
    return (
      <div className="holiday-shutdown standard-card">
        <div className="buildComm-info-wrap">
          <div className="buildComm-description">
            {buildingProjectTabs.holiday.description}
          </div>
          <div className="img-quote-wrap">
            <img src={headshot} alt="sam's headshot" />
            <div className="buildComm-quote sam">
              <i>"{buildingProjectTabs.holiday.quote}"</i>
              <div className="buildComm-name">
                <i>
                  <b>- {buildingProjectTabs.holiday.name}</b>
                </i>
              </div>
            </div>
          </div>
        </div>
        <div className="holiday-table">
          <div className="holiday-table-header">
            <div
              className="holiday-table-content"
              style={{ width: "42%", position: "relative" }}
            >
              Holiday
              <select
                className="year-selection"
                style={{
                  background: `white url(${caret}) no-repeat 90% center`
                }}
                onChange={e => {
                  this.setState({ currentSelectYear: e.target.value });
                }}
              >
                {this.createSelectYears()}
              </select>
            </div>
            <div className="holiday-table-content" style={{ width: "14%" }}>
              Electricity
            </div>
            <div className="holiday-table-content" style={{ width: "14%" }}>
              Steam
            </div>
            <div className="holiday-table-content" style={{ width: "16%" }}>
              Chilled Water
            </div>
            <div className="holiday-table-content" style={{ width: "16%" }}>
              Total Savings
            </div>
          </div>
          <div className="holiday-table-data">
            {this.state.activeData.map((item, index) => {
              return (
                <div className="holiday-data-row" key={index}>
                  <div
                    className="holiday-table-content"
                    style={{ width: "42%" }}
                  >
                    <b>{item.holiday}</b>
                    <br />({item.startDate.slice(5)} through{" "}
                    {item.endDate.slice(5)})
                  </div>
                  <div
                    className="holiday-table-content"
                    style={{ width: "14%", borderLeft: "none" }}
                  >
                    {formatDollarValue(Math.round(item.electricitySavings))}
                  </div>
                  <div
                    className="holiday-table-content"
                    style={{ width: "14%", borderLeft: "none" }}
                  >
                    {formatDollarValue(Math.round(item.steamSavings))}
                  </div>
                  <div
                    className="holiday-table-content"
                    style={{ width: "16%", borderLeft: "none" }}
                  >
                    {formatDollarValue(Math.round(item.chilledWaterSavings))}
                  </div>
                  <div
                    className="holiday-table-content"
                    style={{ width: "16%", borderLeft: "none" }}
                  >
                    {formatDollarValue(Math.round(item.totalSavings))}
                  </div>
                </div>
              );
            })}
            {this.state.otherSavings.total > 0 && (
              <div className="holiday-data-row">
                <div className="holiday-table-content" style={{ width: "42%" }}>
                  Other Buildings
                </div>
                <div
                  className="holiday-table-content"
                  style={{ width: "14%", borderLeft: "none" }}
                >
                  {formatDollarValue(
                    Math.round(this.state.otherSavings.electricity)
                  )}
                </div>
                <div
                  className="holiday-table-content"
                  style={{ width: "14%", borderLeft: "none" }}
                >
                  {formatDollarValue(Math.round(this.state.otherSavings.steam))}
                </div>
                <div
                  className="holiday-table-content"
                  style={{ width: "16%", borderLeft: "none" }}
                >
                  {formatDollarValue(
                    Math.round(this.state.otherSavings.chilledWater)
                  )}
                </div>
                <div
                  className="holiday-table-content"
                  style={{ width: "16%", borderLeft: "none" }}
                >
                  {formatDollarValue(Math.round(this.state.otherSavings.total))}
                </div>
              </div>
            )}
            {this.state.activeData.length === 0 && (
              <div className="holiday-table-content" style={{ width: "100%" }}>
                <i>No projects have been added yet. Check back later!</i>
              </div>
            )}
            <div className="holiday-table-header">
              <div
                className="holiday-table-content"
                style={{ width: "42%", fontWeight: 600 }}
              >
                Totals
              </div>
              <div
                className="holiday-table-content"
                style={{ width: "14%", fontWeight: 600, borderLeft: "none" }}
              >
                {formatDollarValue(this.state.totals.electricity)}
              </div>
              <div
                className="holiday-table-content"
                style={{ width: "14%", fontWeight: 600, borderLeft: "none" }}
              >
                {formatDollarValue(this.state.totals.steam)}
              </div>
              <div
                className="holiday-table-content"
                style={{ width: "16%", fontWeight: 600, borderLeft: "none" }}
              >
                {formatDollarValue(this.state.totals.chilledWater)}
              </div>
              <div
                className="holiday-table-content"
                style={{ width: "16%", fontWeight: 600, borderLeft: "none" }}
              >
                {formatDollarValue(this.state.totals.total)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HolidayShutdowns.propTypes = {
  holidayShutdowns: PropTypes.array.isRequired,
  holidayShutdownsTotal: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  return {
    holidayShutdowns: state.buildingProjects.holidayShutdowns,
    holidayShutdownsTotal: state.buildingProjects.totals.holidayShutdowns
  };
};

export default connect(mapStateToProps)(HolidayShutdowns);
