import React from "react"

import styles from "./BottomNavigation.module.css"

import homeButtonImage from "../../images/home-button.png"
import chevronRightImage from "../../images/chevron-right.png"

export default function BottomNavigation({ nextPageString, setIsHcPage }) {
  return (
    <>
      <button className={styles.home}>
        <img src={homeButtonImage} alt="home button" />
      </button>
      <button
        className={styles.next}
        onClick={() => setIsHcPage((isHcPage) => !isHcPage)}
      >
        {nextPageString}
        <img src={chevronRightImage} alt="right chevron" />
      </button>
    </>
  )
}
