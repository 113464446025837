import Highcharts from "highcharts";

export const graphConfigBuilder = (series, currentSelection) => {
  return {
    chart: {
      zoomType: "x",
      type: "area",
      plotBorderColor: "#FFFFFF",
      plotBorderWidth: 0,
      height: 310
    },
    credits: {
      enabled: false
    },
    navigator: {
      enabled: false
    },
    rangeSelector: {
      enabled: false
    },
    title: {
      text: null
    },
    time: {
      useUTC: false
    },
    legend: {
      enabled: false
    },
    xAxis: {
      type: "datetime",
      minPadding: 0,
      maxPadding: 0,
      labels: {
        style: {
          color: "#444952",
          fontSize: 13,
          textTransform: "capitalize",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        },
        padding: 0,
        y: 25
      }
    },
    yAxis: {
      min: 0,
      gridLineWidth: 2,
      title: {
        text: "MMBtu/hr",
        align: "high",
        rotation: 0,
        offset: 15,
        style: {
          color: "#FFFFFF",
          fontSize: 13,
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        }
      },
      lineColor: "#FFFFFF",
      gridLineColor: "#FFFFFF",
      minPadding: 0,
      maxPadding: 0,
      opposite: false,
      tickAmount: 5,
      labels: {
        formatter: function() {
          if (this.isLast) {
            return "";
          }
          let label = this.axis.defaultLabelFormatter.call(this);
          if (/^[0-9]{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value, 0);
          }
          return label;
        },
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: 13
        },
        reserveSpace: true
      },
      endOnTick: false
    },
    plotOptions: {
      areaspline: {
        marker: {
          enabled: true
        },
        lineWidth: 0
      },
      series: {
        stacking: "normal",
        fillOpacity: 0.8,
        states: {
          hover: {
            enabled: true
          }
        },
        animation: {
          duration: 1000,
          easing: "easeOutBounce"
        }
      }
    },
    tooltip: {
      backgroundColor: "#FFFFFF",
      borderRadius: 0,
      borderWidth: 0,
      shadow: false,
      crosshairs: true,
      useHTML: true,
      pointFormat:
        '<div style="display:flex; flex-direction:row; justify-content:space-between; align-items:center; object-fit:contain; width:100%; margin: 10px 0;"><span style="color:{series.color}; margin-right:5px;">\u2B24</span><span style="font-weight:600; margin-top:4px; width:60%;"> {series.name} </span>  <span style="width:30%; margin-top:4px;">{point.y} MMBtu/hr</span><br/></div>',
      shared: true,
      positioner: function() {
        return { x: 50, y: 300 };
      },
      xDateFormat: "%A, %b %e, %l:%M%P"
    },
    series
  };
};
