/* Module strictly for unit conversion between source and kbtu, etc. */
import { conversionFactors } from "./conversionFactors";
import { deepCopy } from "./formatting";
import { usageTemplateObject } from "../buildingCommissioning/Lists";

// convert data from KBTU to source OR source to KBTU
export const convertUsageUnits = (
  commodityObject,
  conversionType,
  shortNameList,
  commodityList
) => {
  let newCommodityObject = deepCopy(usageTemplateObject);

  for (
    let buildingIndex = 0;
    buildingIndex < shortNameList.length;
    buildingIndex++
  ) {
    let building = shortNameList[buildingIndex];
    for (
      let commodityIndex = 0;
      commodityIndex < commodityList.length;
      commodityIndex++
    ) {
      // populate savings array
      let commodity = commodityList[commodityIndex];
      let conversionFactor = conversionFactors[commodity];
      if (conversionType === "kbtu_to_source")
        newCommodityObject[building][commodity] = convertToSourceUnits(
          commodityObject[building][commodity],
          conversionFactor
        );
      if (conversionType === "source_to_kbtu")
        newCommodityObject[building][commodity] = convertToKBTU(
          commodityObject[building][commodity],
          conversionFactor
        );
    }
  }
  return newCommodityObject;
};

export const convertToSourceUnits = (commodityArray, factor) => {
  return commodityArray.map(value => value / factor);
};

export const convertToKBTU = (commodityArray, factor) => {
  return commodityArray.map(value => value * factor);
};


export const calculateSavings = (commodity, commodityBaseline) => {
  let savings = [];
  for (let index in commodity) {
    savings.push(commodityBaseline[index] - commodity[index]);
  }
  return savings;
};
