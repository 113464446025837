import React from "react";

const setClass = (buildingType, tabSelection, tabName) => {
  let className = "mobile-building-nav-item";
  if (buildingType === "housing") className += " housing";
  if (tabSelection === tabName) className += " active";
  return className;
};

const NavBar = ({ buildingType, tabSelection, onClick }) => {
  return (
    <div className="mobile-building-nav-bar">
      <div
        className={setClass(buildingType, tabSelection, "demand")}
        onClick={onClick}
        id="demand"
      >
        Electricity Demand
      </div>
      <div
        className={setClass(buildingType, tabSelection, "report-button")}
        onClick={onClick}
        id="report-button"
      >
        Report Waste
      </div>
      {buildingType === "housing" && (
        <div
          className={setClass(buildingType, tabSelection, "ranking")}
          onClick={onClick}
          id="ranking"
        >
          Savings
        </div>
      )}
    </div>
  );
};

export default NavBar;
