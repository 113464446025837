import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function tlcEnergyReducer(
  state = initialState.tlcEnergyData,
  action
) {
  switch (action.type) {
    case types.LOAD_TLC_ENERGY_SUCCESS:
      let newTlcEnergyState = Object.assign({}, state);
      for (let item in action.tlcEnergyData)
        newTlcEnergyState[item] = action.tlcEnergyData[item];
      return newTlcEnergyState;

    default:
      return state;
  }
}
