import React from "react";
import WaterUsage from "./waterUsage/WaterUsage";
import "./Usage.css";

export default function Usage() {
  return (
    <div className="usage-tab">
      <WaterUsage/>
    </div>
  );
}
