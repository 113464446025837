/**
 * Stateless functional component for displaying a single row of
 * search results from SearchBox.js.
 */

import React from "react";

// Utilities
import { buildingTypes } from "../../../common/config";

// If a building type exists, return the appropriate SVG circle.
const checkBuildingType = buildingData => {
  if (buildingData !== undefined && buildingData.length !== 0)
    return (
      <svg width="10" height="10">
        <circle
          r="5"
          cx="5"
          cy="5"
          fill={buildingTypes[buildingData[0].buildingType].color}
        />{" "}
        Sorry, your browser does not support inline SVG.
      </svg>
    );
};

// Sets the appropriate className based on if the building is
// in the CEED colllection, no results, or non-metered.
const formatBuildingName = buildingObject => {
  if (
    buildingObject.buildingData !== undefined &&
    buildingObject.buildingData.length !== 0
  )
    return (
      <div className="search-box-row-building">
        {truncateString(buildingObject.primaryDisplay)}
      </div>
    );
  else if (buildingObject.primaryDisplay === "No results found.")
    return (
      <div className="search-box-row-building no-results">
        {truncateString(buildingObject.primaryDisplay)}
      </div>
    );
  return (
    <div className="search-box-row-building no-eui">
      {truncateString(buildingObject.primaryDisplay)}
    </div>
  );
};

// Utility function for reducing the length of long building names.
const truncateString = displayString => {
  const MAX_LENGTH = 30;
  if (displayString !== undefined && displayString.length > MAX_LENGTH)
    return displayString.slice(0, MAX_LENGTH - 1) + "...";
  return displayString;
};

const SearchBoxRow = ({ building, onClick }) => {
  return (
    <div
      className="search-box-row"
      onClick={onClick}
      onKeyPress={onClick}
      tabIndex={0}
    >
      {checkBuildingType(building.buildingData)}
      {formatBuildingName(building)}
    </div>
  );
};

export default SearchBoxRow;
