import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { loadMobileBuildingData } from "../../../../actions/mobileActions";
import * as graphBuilder from "./demandChartOptions";

const DEFAULT_TIME_SELECTION = "sevenDay";

let Highcharts = require("highcharts/highstock"),
  mobileDemandChart = null;
Highcharts.setOptions({
  lang: {
    thousandsSep: ","
  },
  global: {
    useUTC: false //Global Highcharts option that set timestamps to local time.
  }
});

class DemandGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSelection: DEFAULT_TIME_SELECTION
    };
  }

  componentWillMount() {
    if (this.props.building.id !== "")
      this.props.loadData(this.props.building.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.props.demandData) !==
        JSON.stringify(prevProps.demandData) ||
      (this.state.timeSelection !== prevState.timeSelection &&
        this.props.demandData[this.state.timeSelection].length !== 0)
    )
      this.loadChart();
    if (
      prevProps.building.id !== this.props.building.id &&
      this.props.building.id !== ""
    )
      this.props.loadData(this.props.building.id);
  }

  componentWillUnmount() {
    mobileDemandChart = null;
  }

  async loadChart() {
    if (mobileDemandChart === null) {
      let chartData = await graphBuilder.initializeGraph(
        this.props.demandData[this.state.timeSelection],
        this.state.timeSelection
      );
      mobileDemandChart = Highcharts.chart("demand-graph", chartData);
    } else {
      mobileDemandChart.update({
        series: await graphBuilder.buildSeries(
          this.props.demandData[this.state.timeSelection]
        ),
        xAxis: await graphBuilder.setXAxis(this.state.timeSelection)
      });
    }
  }

  setOptionClass = option => {
    let newClass = "mobile-demand-option";
    if (option === this.state.timeSelection) newClass += " selected";
    return newClass;
  };

  setTimeSelection(timeSelection) {
    this.setState({ timeSelection });
  }

  render() {
    return (
      <div className="mobile-building-demand mobile-graph-card">
        <div className="mobile-building-demand-title">Electricity Demand</div>
        <div className="mobile-building-demand-subtitle">
          Click to interact with graph
        </div>
        <div id="demand-graph" />
        <div className="mobile-building-demand-options">
          <div
            className={this.setOptionClass("sevenDay")}
            onClick={e => this.setTimeSelection("sevenDay")}
          >
            7 days
          </div>
          <div
            className={this.setOptionClass("threeDay")}
            onClick={e => this.setTimeSelection("threeDay")}
          >
            3 days
          </div>
          <div
            className={this.setOptionClass("halfDay")}
            onClick={e => this.setTimeSelection("halfDay")}
          >
            12 hours
          </div>
        </div>
      </div>
    );
  }
}

DemandGraph.propTypes = {
  building: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    building: state.building,
    demandData: state.ceedMobileData.buildingDemand
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadData: bindActionCreators(loadMobileBuildingData, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DemandGraph);
