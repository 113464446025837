// Module for styling highcharts graphs for the BuildingBreakdownCard

export const getChartTitleFormatting = selectedCommodity => {
  let className = "";
  switch (selectedCommodity) {
    case "electricity":
      className = "chart-title-electricity";
      break;
    case "steam":
      className = "chart-title-steam";
      break;
    case "chilledWater":
      className = "chart-title-chilledWater";
      break;
    case "totals":
      className = "chart-title-totalSavings";
      break;
    default:
      className = "chart-error";
      break;
  }
  return className;
};

export const getChartTitle = selectedCommodity => {
  let chartTitle = "";
  switch (selectedCommodity) {
    case "electricity":
      chartTitle = "Electricity";
      break;
    case "steam":
      chartTitle = "Steam";
      break;
    case "chilledWater":
      chartTitle = "Chilled Water";
      break;
    case "totals":
      chartTitle = "Totals";
      break;
    default:
      chartTitle = "chart error";
      break;
  }
  chartTitle += ": Actual vs. Modeled Usage";
  return chartTitle;
};
