import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { sendSurvey } from "../../../../actions/mobileActions";
import reportImage from "./images/report-waste.svg";
import badImage from "./images/bad-example.png";
import goodImage from "./images/good-example.png";
import nightImage from "./images/report-night.svg";
import noneImage from "./images/report-none.svg";
import unsureImage from "./images/report-unsure.svg";
import weekendImage from "./images/report-weekend.svg";
import "./ReportWaste.css";

const REQUIRED_FIELDS = ["name", "email", "building", "optionSelected"];

class ReportWaste extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      howToOpen: false,
      survey: {
        explain: "",
        indicator: "",
        name: "",
        email: "",
        optionSelected: null,
        building: this.props.building.name
      },
      submit: false
    };
    this.updateQuestion = this.updateQuestion.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.props.building) !== JSON.stringify(prevProps.building)
    )
      this.setState({
        survey: { ...this.state.survey, building: this.props.building.name }
      });
    if (JSON.stringify(this.state.survey) !== JSON.stringify(prevState.survey))
      this.submitReady();
  }

  setButtonClass() {
    let buttonClass = "mobile-waste-button";
    if (this.state.submit) buttonClass += " active";
    if (this.props.posted) buttonClass += " posted";
    return buttonClass;
  }

  setSelectionClass(option) {
    if (option === this.state.survey.optionSelected) return " selected";
    if (this.state.survey.optionSelected === null) return " all";
    return "";
  }

  setSelectionOption(optionSelected) {
    this.setState({
      survey: { ...this.state.survey, optionSelected }
    });
  }

  submitReady() {
    let submissionCheck = Object.assign({}, this.state.survey),
      submit = true;
    for (let i = 0; i < REQUIRED_FIELDS.length; i++)
      if (
        submissionCheck[REQUIRED_FIELDS[i]] === null ||
        submissionCheck[REQUIRED_FIELDS[i]] === ""
      )
        submit = false;
    if (this.state.survey.email.slice(-12) !== "@ucdavis.edu") submit = false;
    this.setState({ submit });
  }

  submitSurvey() {
    if (this.state.submit && !this.props.posted)
      this.props.submit(this.state.survey);
  }

  toggleHowTo() {
    this.setState({
      howToOpen: !this.state.howToOpen
    });
  }

  updateQuestion(event) {
    let newValue = event.target.value,
      property = event.target.id;
    this.setState({
      survey: { ...this.state.survey, [property]: newValue }
    });
  }

  render() {
    return (
      <div className="mobile-waste mobile-graph-card" id="report">
        <div className="mobile-waste-icon">
          <img src={reportImage} alt="Report energy waste" />
        </div>
        <div className="mobile-waste-title">Report Energy Waste</div>
        <div className="mobile-waste-detail">
          Report what you see in your building! Whether you see energy waste or
          not, you'll identify trends that will help the UC Davis Engineers
          track campus buildings.
        </div>
        <div className="mobile-waste-detail">
          Not sure what to look for? Read this first:
        </div>
        <div
          className="mobile-waste-how-to-button"
          onClick={e => this.toggleHowTo()}
        >
          <div className="mobile-waste-toggle-icon">
            {this.state.howToOpen ? "-" : "+"}
          </div>
          <div className="mobile-waste-toggle-headline">
            How to Find Energy Waste
          </div>
        </div>
        <div
          className={
            this.state.howToOpen
              ? "mobile-waste-how-to display"
              : "mobile-waste-how-to"
          }
        >
          <div className="mobile-waste-how-to-detail">
            <b>At night</b> and on <b>weekends</b>, your electricity should drop
            significantly. The close to zero, the better!
          </div>
          <div className="mobile-waste-how-to-images">
            <div className="mobile-waste-how-to-image">
              <img src={goodImage} alt="Good data example" />
            </div>
            <div className="mobile-waste-how-to-image">
              <img src={badImage} alt="Bad data example" />
            </div>
          </div>
          <a
            href="https://www.trimthewaste.ucdavis.edu/"
            rel="noopener noreferrer"
          >
            <div className="mobile-waste-how-to-link">
              Still confused? Visit <b>Trim the Waste</b> to learn how to look
              for energy waste in your building.
            </div>
          </a>
        </div>
        <div className="mobile-waste-survey">
          What do you see in your building?
          <div className="mobile-waste-survey-headline">
            Does the data look normal or abnormal?
          </div>
          Select one:
          <div className="mobile-waste-options">
            <div
              className={
                "mobile-waste-option" + this.setSelectionClass("weekend")
              }
              onClick={e => this.setSelectionOption("weekend")}
            >
              <div
                className={
                  "mobile-waste-option-dot" + this.setSelectionClass("weekend")
                }
              >
                <div className="mobile-waste-option-dot-inner" />
              </div>
              <img src={weekendImage} alt="High weekend use" />
              <div className="mobile-waste-option-text">
                High electricity use on <b>weekends</b>!
              </div>
            </div>
            <div
              className={
                "mobile-waste-option" + this.setSelectionClass("night")
              }
              onClick={e => this.setSelectionOption("night")}
            >
              <div
                className={
                  "mobile-waste-option-dot" + this.setSelectionClass("night")
                }
              >
                <div className="mobile-waste-option-dot-inner" />
              </div>
              <img src={nightImage} alt="High night use" />
              <div className="mobile-waste-option-text">
                High electricity use at <b>night</b>!
              </div>
            </div>
            <div
              className={
                "mobile-waste-option" + this.setSelectionClass("unsure")
              }
              onClick={e => this.setSelectionOption("unsure")}
            >
              <div
                className={
                  "mobile-waste-option-dot" + this.setSelectionClass("unsure")
                }
              >
                <div className="mobile-waste-option-dot-inner" />
              </div>
              <img src={unsureImage} alt="I'm not sure" />
              <div className="mobile-waste-option-text">
                I'm not sure what I'm seeing.
              </div>
            </div>
            <div
              className={"mobile-waste-option" + this.setSelectionClass("none")}
              onClick={e => this.setSelectionOption("none")}
            >
              <div
                className={
                  "mobile-waste-option-dot" + this.setSelectionClass("none")
                }
              >
                <div className="mobile-waste-option-dot-inner" />
              </div>
              <img src={noneImage} alt="No energy waste" />
              <div className="mobile-waste-option-text">No energy waste!</div>
            </div>
          </div>
          <div className="mobile-waste-question">
            Is there anything that could explain the energy waste you see?
            <input
              type="text"
              name="explain"
              id="explain"
              placeholder="Type your response here"
              value={this.state.survey.explain}
              onChange={this.updateQuestion}
            />
          </div>
          <div className="mobile-waste-question">
            What's an indicator of energy waste in campus buildings?
            <input
              type="text"
              name="indicator"
              id="indicator"
              placeholder="Type your response here"
              value={this.state.survey.indicator}
              onChange={this.updateQuestion}
            />
          </div>
          <div className="mobile-waste-question">
            Your Name
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Your preferred name"
              value={this.state.survey.name}
              onChange={this.updateQuestion}
            />
          </div>
          <div className="mobile-waste-question">
            Your Email
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Your UC Davis email"
              value={this.state.survey.email}
              onChange={this.updateQuestion}
            />
          </div>
          <div
            className={this.setButtonClass()}
            onClick={e => this.submitSurvey()}
          >
            {this.props.posted ? "Thank You!" : "Submit"}
          </div>
          <div className="mobile-waste-confirmation">
            {this.props.posted ? "Your feedback has been received." : ""}
          </div>
        </div>
      </div>
    );
  }
}

ReportWaste.propTypes = {
  building: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    building: state.building,
    posted: state.ceedMobileData.posted
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submit: bindActionCreators(sendSurvey, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportWaste);
