import React from "react";

import ceedIcon from "../images/ceedIcon.png";
import chcpIcon from "../images/chcp.png";
import ewcIcon from "../images/ewc.png";
import totalCampusIcon from "../images/totalCampus.png";

import "./Menu.css";

const MENU_OPTIONS_DATA = {
  ceed: {
    icon: ceedIcon,
    alt: "Energy Map Icon",
    displayName: "Building Energy",
    link: "/"
  },
  chcp: {
    icon: chcpIcon,
    alt: "Central Heating and Cooling Plant Icon",
    displayName: "Central Heating & Cooling Plant",
    link: "/chcp"
  },
  ewc: {
    icon: ewcIcon,
    alt: "Energy and Water Challenge Icon",
    displayName: "Energy & Water Challenge",
    link: "/ewc"
  },
  energystory: {
    icon: totalCampusIcon,
    alt: "Total Campus Energy Icon",
    displayName: "Total Campus Energy",
    link: "/energystory"
  }
};

const Menu = ({ menuOpen, menuOptions, onClick }) => {
  return (
    <div className={"mobile-menu" + (menuOpen ? " open" : "")}>
      <div className="mobile-navigation">
        <div className="mobile-navigation-menu" onClick={onClick}>
          &times;
        </div>
        <div className="mobile-navigation-title">Menu</div>
        <div className="mobile-navigation-right"></div>
      </div>
      <div className="mobile-menu-options">
        {menuOptions.map((menuItem, index) => (
          <div className="mobile-menu-option-wrapper" key={menuItem + index}>
            {index === 0 && (
              <div className={"mobile-menu-option current"}>
                <img
                  src={MENU_OPTIONS_DATA[menuItem].icon}
                  alt={MENU_OPTIONS_DATA[menuItem].alt}
                />
                {MENU_OPTIONS_DATA[menuItem].displayName}
              </div>
            )}
            {index !== 0 && (
              <a
                href={MENU_OPTIONS_DATA[menuItem].link}
                rel="noopener noreferrer"
              >
                <div className={"mobile-menu-option"} key={menuItem + index}>
                  <img
                    src={MENU_OPTIONS_DATA[menuItem].icon}
                    alt={MENU_OPTIONS_DATA[menuItem].alt}
                  />
                  {MENU_OPTIONS_DATA[menuItem].displayName}
                </div>
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
