import React from "react";
import PropTypes from "prop-types";

import "./Navigation.css";

const NAVIGATION_HEADERS = ["production", "usage", "metrics"];

const Navigation = ({ selection, onClick }) => {
  const navigationClass = property => {
    let baseClass = "chcp-navigation-button";
    if (property === selection) return baseClass + " active";
    return baseClass;
  };

  return (
    <div className="chcp-navigation">
      <div className="chcp-navigation-wrapper">
        {NAVIGATION_HEADERS.map((navigationElement, index) => (
          <div
            className={navigationClass(navigationElement)}
            id={navigationElement}
            onClick={onClick}
            key={index}
          >
            {navigationElement.toUpperCase()}
          </div>
        ))}
      </div>
    </div>
  );
};

Navigation.propTypes = {
  selection: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Navigation;
