import React from "react";

import Navigation from "../../../common/mobile/navigation/Navigation";

import lightSwitch from "../../images/lightswitch.svg";
import showerHead from "../../images/shower.svg";
import ewcLogo from "../../images/ewc-logo.png";
import ucdLogo from "../../images/ucd-logo.png";
import bgImage from "../../images/housingBackground.jpg";
import "./MobileHeader.css";

class MobileHeader extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="ewc-mobile-nav" style={{ width: "100%" }}>
          <Navigation location={"ewc"}>
            <img
              src={ucdLogo}
              alt="University of California Davis"
              style={{ height: "18px", width: "auto" }}
            />
          </Navigation>
        </div>
        <div
          className="ewc-mobile-wrapper"
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        >
          <div className="ewc-mobile-header">
            <div className="ewc-mobile-header-logo">
              <img
                className="ewc-mobile-logo"
                src={ewcLogo}
                alt="Energy and Water Challenge"
              />
            </div>
          </div>
          <div className="ewc-mobile-header-detail">
            <div className="ewc-mobile-header-detail-box">
              <div className="ewc-mobile-header-detail-title">HOW TO WIN</div>
              <div className="ewc-mobile-header-detail-description">
                Work with your team to save
                <br />
                the most electricity and water!
              </div>
            </div>
            <div className="ewc-mobile-header-tips">
              <div className="ewc-mobile-header-tip">
                <img
                  src={lightSwitch}
                  alt="Light switch"
                  className="ewc-mobile-header-tip-light"
                />
                <div className="header-tip-detail">
                  <div className="header-tip-header electricity">
                    Electricity
                  </div>
                  <div className="header-tip-body">
                    Turn lights off and watch plug load use.{" "}
                    <i>Measured in kiloWatt hours (kWh)</i>
                  </div>
                </div>
              </div>
              <div className="ewc-mobile-header-tip">
                <img src={showerHead} alt="Shower head" />
                <div className="header-tip-detail">
                  <div className="header-tip-header water">Water</div>
                  <div className="header-tip-body">
                    Shorten your shower length <i>Measured in gallons (gal)</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MobileHeader;
