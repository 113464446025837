import React from "react";
import { Circle } from "react-leaflet";

class EuiCircle extends React.Component {
  render() {
    return (
      <Circle
        key={`${this.props.markerId}-circle`}
        radius={this.props.radius}
        center={this.props.center}
        stroke={false}
        interactive={false}
        fillColor={this.props.fillColor}
        fillOpacity={this.props.opacity}
      />
    );
  }
}

export default EuiCircle;
