import ceedIcon from "./images/ceedIcon.png";
import chcpIcon from "./images/chcp.png";
import dataDownloadIcon from "./images/dataDownload.png";
import ewcIcon from "./images/ewc.png";
import waterDashboardIcon from "./images/waterDashboard.png";
import savingsIcon from "./images/savings.png";
import totalCampusIcon from "./images/totalCampus.png";
import gridElectricity from "./images/Grid_electricity.svg";
import heating2 from "./images/Heating_Plant_Natural_Gas_2.svg";
import heating3 from "./images/Heating_Plant_Natural_Gas_3.svg";
import heating1 from "./images/Heating_Plant_Natural_Gas.svg";
import hydropowerElectricity from "./images/Hydropower_Electricity.svg";
import rooftopSolar from "./images/Rooftop_Solar.svg";
import solarFarm from "./images/Solar_Farm.svg";
import labIcon from "../../images/lab-icon.png";
import officeIcon from "../../images/office-icon.png";
import classroomIcon from "../../images/classroom-icon.png";
import housingIcon from "../../images/housing-icon.png";
import communityIcon from "../../images/community-icon.png";

//const variables and common properties used throughout the app.

export const buildingTypes = {
  office: {
    singular: "Office",
    displayName: "Offices",
    color: "#93bb45",
    description:
      "Office spaces are where most of the UC Davis staff and faculty spend their time. Unlike a laboratory space, the air coming into the room can be recirculated throughout the building. This lowers energy use because less energy is needed to heat and cool the outside air to reach the desired temperature."
  },
  lab: {
    singular: "Lab",
    displayName: "Labs",
    color: "#2a575f",
    description:
      "Buildings with laboratory spaces typically use a lot of energy because of the required ventilation. The air coming into the lab must be 100% outside air, and then it must completely leave the building. Moving this quantity of air with fans, and heating and cooling the air, is an energy intensive process."
  },
  housing: {
    singular: "Housing",
    displayName: "Housing",
    color: "#3778BC",
    description:
      "The housing category is specifically for the residence hall buildings on campus. The buildings are clustered together based on when they were built, and these clusters are metered for their energy use as one group. The building clusters fall into three distinct housing areas: Segundo, Tercero and Cuarto."
  },
  classroom: {
    singular: "Classroom",
    displayName: "Classrooms",
    color: "#3ea0b7",
    description:
      "Classroom buildings have fluctuating periods of high occupancy and vacancy. The fluctuating occupancy should be matched by the HVAC system that provides heating, cooling and ventilation to the space. So when there’s a class, the system should be running accordingly to keep people comfortable."
  },
  community: {
    singular: "Community",
    displayName: "Community",
    color: "#107d4b",
    description:
      "The community category describes buildings that have higher traffic flow, with gathering spaces for people to meet and/or study. Some of these buildings include the ARC and the Student Community Center. This category also includes buildings with functions other than classrooms, offices and laboratories, like the ‘hospital’ space in the Student Health & Wellness building."
  }
};

export const breakPoints = {
  desktopWide: 1900, //minWidth
  desktop: 1200, //minWidth
  tablet: 769, //minWidth
  mobile: 768 //maxWidth
};

export const commodityTypes = {
  electricity: {
    displayName: "Electricity",
    color: "#98BF47",
    graphColor: "rgba(152, 191, 71, 0.7)",
    gradient: [
      [0, "#98BF47"],
      [1, "#c9dd9f"]
    ],
    unit: "kWh",
    kBtuConversion: 3.142,
    cost: 0.02245,
    description:
      "Lights, plug loads, and fans in the HVAC system are run off of the electricity delivered to the building. UC Davis gets its electricity from a variety of non-renewable and renewable sources.",
    uses: ["Plug Loads", "Fans", "Lighting"],
    oatDependent: "No",
    normalUseDescription:
      "The demand should drop when occupants leave the building.",
    abnormalUseDescription: "Look for high use on weekends and at night."
  },
  gas: {
    displayName: "Natural Gas",
    color: "#8D67D6",
    graphColor: "#8D67D6",
    gradient: [
      [0, "#8D67D6"],
      [1, "#aa95d3"]
    ],
    unit: "CuFt",
    kBtuConversion: 1.05,
    cost: 0.0088,
    description:
      "Natural gas is purchased from PG&E and used in buildings for heating and hot water. It is primarily used in smaller buildings that do not have steam connections to the central plant.",
    uses: ["Heating"],
    oatDependent: "Yes",
    normalUseDescription:
      "Similar to steam, natural gas usage typically spikes in the morning when a building requires heating as the occupants arrive.",
    abnormalUseDescription: "Look for high use on weekends and at night."
  },
  steam: {
    displayName: "Steam",
    color: "#f69222",
    graphColor: "rgba(246, 146, 34, 0.7)",
    gradient: [
      [0, "#f69222"],
      [1, "#fac88e"]
    ],
    unit: "Lbs",
    kBtuConversion: (1194 - 180) / 1000,
    cost: 0.01186,
    description: "Our shift to a hot water system will ultimately use electricity instead of natural gas to heat our buildings. This hot water system does not need to be heated to the same temperature as steam, making it more efficient and safer to maintain.\r\n\r\nHeating from hot water saves energy, takes advantage of renewable resources, and reduces our reliance on fossil fuels.",
    uses: ["Heating"],
    oatDependent: "Yes",
    normalUseDescription:
      "Hot water use matches the occupancy of the building, dropping to zero at night when it is not needed.",
    abnormalUseDescription:
      "Heating is commonly used in the mornings and in cooler outside air temperatures. Heating use outside these parameters is suspicious."
  },
  chilledWater: {
    displayName: "Chilled Water",
    color: "#1caac8",
    graphColor: "rgba(28, 170, 200, 0.7)",
    gradient: [
      [0, "#1caac8"],
      [1, "#90d6e5"]
    ],
    unit: "TonHr",
    kBtuConversion: 12,
    cost: 0.00797,
    description:
      "Campus buildings use chilled water from the Central Heating and Cooling Plant in their HVAC systems for cooling.\r\n\r\nThe amount of chilled water required by the cooling system is metered and traced by Facilities Management. Chilled water is metered in ton-hours, which we can convert back into Kwh of electricity used to produce the chilled water at the CHCP.",
    uses: ["Cooling"],
    oatDependent: "Yes",
    normalUseDescription:
      "Chilled water usage typically increases in the afternoons, when it's warmer outside.",
    abnormalUseDescription:
      "If you see chilled water use that is consistently high in a building, even in the mornings and at night, this should seem suspicious."
  },
  solar: {
    displayName: "Solar",
    color: "#F8D81C",
    gradient: [
      [0, "#F8D81C"],
      [1, "#FFFAAC"]
    ],
    unit: "kWh",
    kBtuConversion: 3.142,
    cost: 0,
    description:
      "Solar electricity is generated from a 62 acre solar farm on campus, providing around 14% of the UC Davis electricity supply. Additionally, UC Davis receives solar power from an off-site solar farm that supplies power that is shared by all UC campuses. Lastly, some campue buildings have rooftop solar and parking lots have solar shade structures that provide additional clean power for the campus.",
    uses: ["Plug Loads", "Fans", "Lighting"],
    oatDependent: "No",
    normalUseDescription:
      "Solar energy supply rises and falls along with the daylight and evening hours.",
    abnormalUseDescription: null
  },
  oat: {
    displayName: "Outside Air Temperature",
    color: "rgba(204,204,204,0.3)",
    gradient: [],
    unit: "degrees",
    kBtuConversion: 0,
    cost: 0,
    description: "",
    uses: [],
    oatDependent: "No",
    normalUseDescription: null,
    abnormalUseDescription: null
  },
  hotWater: {
    displayName: "Hot Water",
    color: "#FFC149",
    graphColor: "#FFC149",
    gradient: [
      [0, "#FFC149"],
      [1, "#FFDA91"]
    ],
    unit: "Lbs",
    kBtuConversion: (1194 - 180) / 1000,
    cost: 0.01186,
    description:
      "UC Davis uses steam condensate in the HVAC (heating, cooling, and air conditioning) system to heat the buildings and to heat hot water used in the building.\r\n\r\nEach building receives steam from the UCD central plant, and the amount of steam used by the building is metered. Steam is metered in pounds of steam, but we convert this to therms, and into kBtus to sum with chilled water and electricity data.",
    uses: ["Heating"],
    oatDependent: "Yes",
    normalUseDescription:
      "Usage typically spikes in the morning when a building requires heating as the occupants arrive.",
    abnormalUseDescription:
      "Steam use commonly occurs in the mornings and in cooler outside air temperatures. Seeing steam use outside these parameters is suspicious."
  }
};

export const menuOptions = {
  ceed: {
    icon: ceedIcon,
    alt: "Energy Map Icon",
    displayName: "Energy Map",
    link: "/"
  },
  savings: {
    icon: savingsIcon,
    alt: "Energy Saving Projects Icon",
    displayName: "Energy Saving Projects",
    link: "/energysaving"
  },
  totalCampus: {
    icon: totalCampusIcon,
    alt: "Total Campus Energy Icon",
    displayName: "Total Campus Energy",
    link: "/energystory"
  },
  chcp: {
    icon: chcpIcon,
    alt: "Central Heating and Cooling Plant Icon",
    displayName: "Central Heating and Cooling Plant",
    link: "/chcp"
  },
  dataDownload: {
    icon: dataDownloadIcon,
    alt: "Data Download Tool Icon",
    displayName: "Data Download Tool",
    link: "/datadownload"
  },
  ewc: {
    icon: ewcIcon,
    alt: "Energy and Water Challenge Icon",
    displayName: "Energy and Water Challenge",
    link: "/ewc"
  },
  water: {
    icon: waterDashboardIcon,
    alt: "Water Dashboard Icon",
    displayName: "Water Dashboard",
    link: "/water"
  }
};

export const energyStoryTypes = {
  pge_demand: {
    displayName: "Carbon Based Electricity and Hydropower",
    color: "#98BF47",
    unit: "MMBtu",
    description:
      "The campus purchases electricity from the grid to supplement its electricity needs.",
    uses: ["Plug Loads", "Fans", "Lighting"]
  },
  chcp_gas_demand: {
    displayName: "Heating Natural Gas",
    color: "#875878",
    unit: "MMBtu",
    description:
      "The campus purchases electricity from the grid to supplement its electricity needs.",
    uses: ["Heating"]
  },
  solar_farm_demand: {
    displayName: "Solar Farm Electricity",
    color: "#CAD93E",
    unit: "MMBtu",
    description:
      "The UC Davis Solar farm is a collaboration with Sunpower corporation that will generate about 13% of the campus' electricity needs. It is situated on the 62 aces of the UC Davis south campus.",
    uses: ["Plug Loads", "Fans", "Lighting"]
  },
  rooftop_solar_demand: {
    displayName: "Rooftop Solar Electricity",
    color: "#cad93f",
    unit: "MMBtu",
    description:
      "Several buildings on campus get electricity from solar panels placed directly on their roofs or adjacent shade structures.",
    uses: ["Plug Loads", "Fans", "Lighting"]
  },
  biodigester_demand: {
    displayName: "Biodigester",
    color: "#9AC049",
    unit: "MMBtu",
    description:
      "The campus renewable energy biodigester reactor is a partnership with CleanWorld Partners using UC Davis-developed technology. The system is designed to take campus food waste and convert it to gas using anaerobic digestion and then convert that gas into electricity.",
    uses: ["Plug Loads", "Fans", "Lighting"]
  },
  other_gas_demand: {
    displayName: "Other Natural Gas",
    color: null,
    unit: "MMBtu",
    description: null,
    uses: null
  }
};

export const mobileEnergyStoryTypes = {
  pge_demand: {
    displayName: "Electricity",
    color: "#99c23f",
    unit: "MMBtu",
    description:
      "The campus purchases electricity from the grid to supplement its electricity needs.",
    uses: ["Plug Loads", "Fans", "Lighting"]
  },
  chcp_gas_demand: {
    displayName: "Heating Natural Gas",
    color: "#875878",
    unit: "MMBtu",
    description:
      "The campus purchases electricity from the grid to supplement its electricity needs.",
    uses: ["Heating"]
  },
  solar_farm_demand: {
    displayName: "Solar Farm Electricity",
    color: "#CAD93E",
    unit: "MMBtu",
    description:
      "The UC Davis Solar farm is a collaboration with Sunpower corporation that will generate about 13% of the campus' electricity needs. It is situated on the 62 aces of the UC Davis south campus.",
    uses: ["Plug Loads", "Fans", "Lighting"]
  },
  rooftop_solar_demand: {
    displayName: "Rooftop Solar Electricity",
    color: "#cad93f",
    unit: "MMBtu",
    description:
      "Several buildings on campus get electricity from solar panels placed directly on their roofs or adjacent shade structures.",
    uses: ["Plug Loads", "Fans", "Lighting"]
  },
  biodigester_demand: {
    displayName: "Biodigester",
    color: "#9AC049",
    unit: "MMBtu",
    description:
      "The campus renewable energy biodigester reactor is a partnership with CleanWorld Partners using UC Davis-developed technology. The system is designed to take campus food waste and convert it to gas using anaerobic digestion and then convert that gas into electricity.",
    uses: ["Plug Loads", "Fans", "Lighting"]
  },
  other_gas_demand: {
    displayName: "Other Natural Gas",
    color: null,
    unit: "MMBtu",
    description: null,
    uses: null
  }
};

export const descriptions = {
  column1: [
    {
      title: "Heating Plant Natural Gas",
      description:
        "Natural gas is burned in the Central Heating & Cooling Plant to produce steam and hot water. These are distributed through underground pipes to heat campus buildings.",
      icon: heating1
    },
    {
      title: "Primate Heating Plant Natural Gas",
      description:
        "The California National Primate Research Center is a stand-alone building complex. It burns natural gas at a central heating and cooling facility for heating lab and office spaces.",
      note: "Not available in real-time.",
      icon: heating2
    },
    {
      title: "Building Natural Gas",
      description:
        "Natural gas is used for heating in some campus buildings that aren’t connected to the district heating system, or where natural gas is needed for other uses like labs or kitchens.",
      note: "Not available in real-time.",
      icon: heating3
    }
  ],
  column2: [
    {
      id: "pge_demand",
      title: "Carbon-Based Electricity",
      description:
        "The campus purchases electricity from the grid to supplement its electricity needs. About 30% is renewable electricity from two large off-site solar PV farms in California.",
      icon: gridElectricity
    },
    {
      id: "solar_farm_demand",
      title: "Solar Farm Electricity",
      description:
        "The UC Davis Solar farm generates about 14% of the campus' electricity needs. It is situated on the 62 aces of the UC Davis south campus. The solar farm generates 33 million killawatt-hours per year, and reduces the University’s carbon footprint by 9%.",
      icon: solarFarm,
      flag: "Renewable"
    },
    {
      id: "rooftop_solar_demand",
      title: "Rooftop Solar Electricity",
      description:
        "UC Davis has a number of smaller solar systems on rooftops and parking lots.  This category also includes historical production from our on-campus biodigester.",
      icon: rooftopSolar,
      flag: "Renewable"
    },
    {
      id: "hydropower",
      title: "Hydropower",
      description:
        "UC Davis has a contract with Western Area Power Administration to purchase electricity generated from large-scale hydro-power sources. The electricity is not considered 'renewable' under common definitions, but it is 100% Carbon Neutral.",
      note: "Real-time demand included in Carbon-based Electricity",
      icon: hydropowerElectricity,
      flag: "Carbon Neutral"
    }
  ]
};

export const waterDescriptions = {
  column1: [
    {
      id: "domestic",
      title: "Domestic Water",
      description: "Water used for campus buildings and central plant heating and cooling systems."
    },
    {
      id: "agriculture",
      title: "Agriculture Water",
      description: "Water used primarily for agricultural research."
    }
  ],
  column2: [
    {
      id: "utility",
      title: "Utility Water",
      description: "Water used primarily for landscape irrigation."
    },
    {
      id: "fisheries",
      title: "Fisheries Water",
      description: "Water used for fisheries research."
    }
  ],
}

export const buildingPopup = {
  lab: {
    icon: labIcon,
    description:
      "Buildings with lab spaces typically use a lot of energy because of the required ventilation."
  },
  office: {
    icon: officeIcon,
    description:
      "Unlike a lab building, the air in an office building can be recirculated, lowering energy use."
  },
  classroom: {
    icon: classroomIcon,
    description:
      "Unlike lab buildings, classrooms can be turned down or off at night when there's no occupancy."
  },
  community: {
    icon: communityIcon,
    description:
      "Community buildings may have kitchens, which add to their energy footprint."
  },
  housing: {
    icon: housingIcon,
    description:
      "While residence halls may use energy 24/7, they are not as energy-intensive as lab buildings."
  },
  leed: {
    description:
      "This building is LEED Gold Certified and the HVAC system was commissioned by our energy engineers."
  },
  hvac: {
    description:
      "This building's HVAC system was commissioned by our energy engineers."
  }
};

export const buildingProjectTabs = {
  buildingComm: {
    description:
      "By focusing on individual buildings for 6-12 months and performing in-depth optimization of HVAC systems, the ACE Team is able to save 15-20% of those buildings' overall energy consumption. To get there, we work closely with building stakeholders and implement state-of-the-art control sequences for the HVAC systems.",
    quote:
      "We tested the functionality of the HVAC systems throughout Shields Library, which led to a Deferred Maintenance project to replace the AHU dampers. Properly modulating OA dampers are important to achieve occupants' comfort and energy savings.",
    name: "Hiroko Masuda, Energy Engineer"
  },
  opportunities: {
    description:
      "We implement many small improvements in buildings' HVAC systems across campus. Often, this involves fixing deficiencies that were previously unnoticed, such as faulty or uncalibrated sensors, disconnected actuators, or systems accidentally left in manual mode. When these issues are resolved, building HVAC systems run more smoothly and use less energy. These individual fixes ad up to large savings.",
    quote:
      "There's a lot we can do across campus by catching the small issues that no one ever noticed but are still wasting energy, like mechanical deficiencies or control points not working quite right.",
    name: "Nico Fauchier-Magnan, Energy Engineer Supervisor"
  },
  hvac: {
    description:
      "Historically, HVAC schedules on campus were set to avoid complaints from occupants - which often meant that systems were run late into the nights and weekends to cover any possible occupancy hours. To reduce the amount of energy wasted by conditioning empty spaces, our team is working closely with individual buildings to match HVAC schedules to actual occpuancy patterns and provide flexbility to occupants for after-hours HVAC through local buttons.",
    quote:
      "The power of data is amazing: From our office we are able to identify occupancy patterns, adjust HVAC schedules, and create descriptive models to quantify energy savings.",
    name: "Dan, Data Scientist"
  },
  holiday: {
    description:
      "By focusing on individual buildings for 6-12 months and performing in-depth optimization of HVAC systems, the ACE Team is able to save 15-20% of those buildings' overall energy consumption. To get there, we work closely with building stakeholders and implement state-of-the-art control sequences for the HVAC systems.",
    quote:
      "You wouldn't leave your home's heating or cooling systems on while you go on vacation. It would continue to run as scheduled and be an utter energy waste.",
    name: "Sam Cole, Energy Engineer"
  }
};

export const savingsCalculator = {
  before: {
    id: "before",
    displayName: "Current Demand",
    color: "#2D3138",
    valueSuffix: " kBtu/hr"
  },
  during: {
    id: "during",
    displayName: "Reduced Demand",
    color: "#98BF47",
    valueSuffix: " kBtu/hr"
  },
  savings: {
    id: "savings",
    displayName: "Savings",
    color: "transparent",
    valueSuffix: "%"
  }
};
