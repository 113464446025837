import { DateTime } from "luxon";

import { commodityTypes } from "../../../../common/config";
const formTempRangeSeries = (minTemps, maxTemps) => {
  let tempRanges = [];
  for (let i = 0; i < minTemps.length; i++) {
    if (minTemps[i].Value && maxTemps[i].Value) {
      tempRanges.push([minTemps[i], maxTemps[i]]);
    }
  }

  return {
    name: "Temperature Range",
    id: "tempRange",
    data: tempRanges.map(datum => [
      DateTime.fromISO(datum[0].Timestamp)
        .plus({ minutes: 1 })
        .minus({ months: 1 })
        .valueOf(),
      Math.round(datum[0].Value),
      Math.round(datum[1].Value)
    ]),
    visible: false,
    type: "arearange",
    yAxis: 1,
    xAxis: 0,
    lineWidth: 0,
    color: "#ECECEC",
    fillOpacity: 0.75,
    zIndex: 1
  };
};

const formAvgTempSeries = avgTemps => {
  return {
    name: "Average Temperature",
    id: "avgTemp",
    data: avgTemps.map(datum => [
      DateTime.fromISO(datum.Timestamp)
        .plus({ minutes: 1 })
        .minus({ months: 1 })
        .valueOf(),
      Math.round(datum.Value)
    ]),
    type: "line",
    linkedTo: ":previous",
    color: "black",
    lineWidth: 1,
    marker: {
      enabled: false,
      states: {
        hover: {
          enabled: false
        }
      }
    },
    yAxis: 1,
    zIndex: 2
  };
};

export const seriesBuilder = (usageData, oatData) => {
  //usage data
  let series = usageData.map(commodityData => {
    return {
      name: commodityTypes[commodityData.commodity].displayName,
      id: commodityData.commodity,
      color: commodityTypes[commodityData.commodity].graphColor,
      borderColor: commodityTypes[commodityData.commodity].color,
      borderWidth: 1,
      states: {
        hover: {
          color: commodityTypes[commodityData.commodity].color
        }
      },
      data: commodityData.data.map(datum => [
        DateTime.fromISO(datum.Timestamp)
          .plus({ minutes: 1 })
          .minus({ months: 1 })
          .valueOf(),
        datum.Value
      ]),
      visible: true,
      zIndex: 3,
      yAxis: 0
    };
  });

  //temperature data
  let oatDataObj = {};
  oatData.forEach(tempObj => {
    oatDataObj[tempObj.tempType] = tempObj.data;
  });
  series.push(
    formTempRangeSeries(oatDataObj.monthlyMinTemp, oatDataObj.monthlyMaxTemp),
    formAvgTempSeries(oatDataObj.monthlyAvgTemp)
  );

  return series;
};
