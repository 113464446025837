import React from "react";

import lightSwitch from "../../images/lightswitch.svg";
import showerHead from "../../images/shower.svg";
import ewcLogo from "../../images/ewc-logo.png";
import bgImage from "../../images/housingBackground.jpg";
import "./DesktopHeader.css";

class DesktopHeader extends React.Component {
  render() {
    return (
      <div
        className="ewc-desktop-wrapper"
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      >
        <div className="ewc-desktop-header">
          <div className="ewc-desktop-header-logo">
            <img
              className="ewc-desktop-logo"
              src={ewcLogo}
              alt="Energy and Water Challenge"
            />
          </div>
          <div className="ewc-desktop-header-detail">
            <div className="ewc-desktop-header-detail-box">
              <div className="ewc-desktop-header-detail-title">HOW TO WIN</div>
              <div className="ewc-desktop-header-detail-description">
                Work with your team to save the <br /> most electricity and
                water!
              </div>
              <div className="ewc-desktop-header-tips">
                <div className="ewc-desktop-header-tip">
                  <img
                    src={lightSwitch}
                    alt="Light switch"
                    className="ewc-desktop-header-tip-light"
                  />
                  <div className="header-tip-detail">
                    <div className="header-tip-header electricity">
                      Electricity
                    </div>
                    <div className="header-tip-body">
                      Turn lights off and watch plug load use.{" "}
                      <i>Measured in kiloWatt hours (kWh)</i>
                    </div>
                  </div>
                </div>
                <div className="ewc-desktop-header-tip">
                  <img src={showerHead} alt="Shower head" />
                  <div className="header-tip-detail">
                    <div className="header-tip-header water">Water</div>
                    <div className="header-tip-body">
                      Shorten your shower length{" "}
                      <i>Measured in gallons (gal)</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DesktopHeader;
