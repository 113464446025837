import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SeasonTable from "./SeasonTable";
import SearchBox from "../../../main/map/searchBox/SearchBox";
import CommodityInfo from "./CommodityInfo";
import "./Season.css";

const DEFAULT_BUILDING = "shields";
const DEFAULT_BUILDING_NAME = "Shields Library";

class Season extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBuildingName: DEFAULT_BUILDING,
      displayBuildingName: DEFAULT_BUILDING_NAME,
      temp: true,
      potentialSavingEUI: {
        current: 0,
        potential: 0
      }
    };
  }

  componentWillMount() {
    this.props.buildingActions.loadBuildingData(this.state.currentBuildingName);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentBuildingName !== this.state.currentBuildingName) {
      this.props.buildingActions.loadBuildingData(
        this.state.currentBuildingName
      );
    }
  }

  displayBuildingName = e => {
    if (e.buildingData.length > 0) {
      this.setState({
        displayBuildingName: e.primaryDisplay,
        currentBuildingName: e.buildingData[0].id
      });
    }
  };

  isDataAvailable(data) {
    if (
      data.energyInfo.commodities.includes("chilledWater") &&
      data.energyInfo.commodities.includes("electricity") &&
      data.energyInfo.commodities.includes("steam") &&
      data.euiReady === true
    ) {
      return true;
    } else {
      return false;
    }
  }

  gettotalEUI = e => {
    this.setState({
      potentialSavingEUI: {
        current: e[1],
        potential: e[0]
      }
    });
  };

  render() {
    return (
      <div className="demand-season graph-card">
        <div className="title-wrap">
          <div className="line" />
          <div className="step2">
            <div>MONTHLY ENERGY SAVINGS</div>
            <p>
              Search for a building to see how the potential monthly energy
              savings change with the seasons and type of energy.
            </p>
            <div className="select-building">
              <SearchBox
                enableSeason={true}
                displayBuildingName={this.displayBuildingName}
              />
            </div>
          </div>
          <div className="potential-saving">
            <div>Potential Savings:</div>
            <div className="totaleui currenteui">
              <div className="title">Current EUI</div>
              <div className="eui current">
                <b>{this.state.potentialSavingEUI.current}</b> kBtu/ft&sup2;
              </div>
            </div>
            <div className="totaleui potentialeui">
              <div className="title">Potential EUI</div>
              <div className="eui potential">
                <b>
                  {this.state.potentialSavingEUI.current -
                    this.state.potentialSavingEUI.potential}
                </b>{" "}
                kBtu/ft&sup2;
              </div>
            </div>
          </div>
        </div>
        {this.isDataAvailable(this.props.building) ? (
          <div className="demand-table">
            <div className="table-title">
              <span className="table-column-text commodity">Commodity</span>
              <span className="table-column-text monthly">
                Potential Monthly Energy Savings for{" "}
                {this.state.displayBuildingName}
              </span>
              <span className="table-column-text total">
                Potential Total Savings
              </span>
            </div>
            <div className="season-background-wrap">
              <div className="season-background spring">Winter</div>
              <div className="season-background summer">Spring</div>
              <div className="season-background fall">Summer</div>
              <div className="season-background winter">Fall</div>
            </div>

            <SeasonTable
              buildingActions={this.props.buildingActions}
              building={this.props.building}
              buildingName={this.state.currentBuildingName}
              savings={this.props.savings}
              potentialeui={this.gettotalEUI}
            />
          </div>
        ) : (
          <div className="no-data">
            The building you're searching for is currently unavailable in the
            Calculator.
          </div>
        )}
        <CommodityInfo />
      </div>
    );
  }
}

Season.propTypes = {
  buildingActions: PropTypes.object.isRequired,
  savings: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    building: state.building
  };
}

export default connect(mapStateToProps)(Season);
