import * as types from "./actionTypes";

export function fullRankingClick(ranking) {
  return {
    type: types.FULL_RANKING_CLICK,
    ranking
  };
}

export const openFullRankingSidebar = () => {
  let rankingStatus = {
    fullRanking: true,
    rankingButton: false
  };
  return function(dispatch) {
    dispatch(fullRankingClick(rankingStatus));
  };
};

export const closeFullRankingSidebar = () => {
  let rankingStatus = {
    fullRanking: false,
    rankingButton: true
  };
  return function(dispatch) {
    dispatch(fullRankingClick(rankingStatus));
  };
};
