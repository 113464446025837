/**
 * This component sets up the CSV download button.
 */

import React from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";

//Fixes fy formatting bug in Excel.
const convertFiscalYear = data => {
  if (data.length > 0 && data[0].fy[0] !== "F")
    for (let i = 0; i < data.length; i++) {
      data[i].fy = "FY " + data[i].fy;
    }
  return data;
};

// Creates a filename for downloading campus data.
// Format is 'campus_MM_DD_YYYY.csv'.
const getCsvFilename = shortname => {
  let downloadDate = new Date(),
    downloadMonth = downloadDate.getMonth() + 1;
  return (
    shortname +
    "_" +
    downloadMonth +
    "-" +
    downloadDate.getDate() +
    "-" +
    downloadDate.getFullYear() +
    ".csv"
  );
};

// Sets up the header row for the CSV based on the
// energy and cost data available for the campus.
const getCsvHeaders = sources => {
  let headers = [{ label: "Fiscal Year", key: "fy" }];
  if (sources.energy.length > 0)
    for (let i = 0; i < sources.energy.length; i++)
      headers.push({
        label: sources.energy[i].displayName,
        key: sources.energy[i].value
      });
  headers.push({ label: "Total Energy (MMBtu)", key: "totalEnergy" });
  if (sources.cost.length > 0)
    for (let i = 0; i < sources.cost.length; i++)
      headers.push({
        label: sources.cost[i].displayName,
        key: sources.cost[i].value
      });
  headers.push({ label: "Total Cost ($)", key: "totalCost" });
  return headers;
};

const TotalEnergyCsv = ({ data, sources, icon }) => {
  return (
    <div className="total-energy-csv">
      <CSVLink
        className="csv-link"
        data={convertFiscalYear(data)}
        filename={getCsvFilename("campus")}
        headers={getCsvHeaders(sources)}
      >
        <div className="total-energy-csv-button">
          <img className="download-img" src={icon} alt="download icon" />
          <div>Download CSV</div>
        </div>
      </CSVLink>
    </div>
  );
};

TotalEnergyCsv.propTypes = {
  data: PropTypes.array.isRequired,
  sources: PropTypes.object.isRequired
};

export default TotalEnergyCsv;
