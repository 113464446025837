import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TotalProduction from "./totalProduction/TotalProduction";
import ProductionChart from "./productionChart/ProductionChart";
import Loading from "../../building/loading/Loading";

class Production extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TotalProductionReady: false,
      ProductionChartReady: false
    };
  }

  componentDidMount() {
    if (JSON.stringify(this.props.totalData) !== JSON.stringify({}))
      this.dataIsReady("TotalProductionReady", true);
    if (JSON.stringify(this.props.realTimeData) !== JSON.stringify({}))
      this.dataIsReady("ProductionChartReady", true);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.realTimeData) !==
      JSON.stringify(prevProps.realTimeData)
    )
      this.dataIsReady("ProductionChartReady", true);
    if (
      JSON.stringify(this.props.totalData) !==
      JSON.stringify(prevProps.totalData)
    )
      this.dataIsReady("TotalProductionReady", true);
  }

  dataIsReady(field, value) {
    this.setState({ [field]: value });
  }

  render() {
    if (!this.state.TotalProductionReady || !this.state.ProductionChartReady)
      return <Loading />;
    return (
      <div className="production-tab">
        <TotalProduction totalData={this.props.totalData} />
        <ProductionChart productionData={this.props.realTimeData} />
      </div>
    );
  }
}

Production.propTypes = {
  realTimeData: PropTypes.object.isRequired,
  totalData: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    realTimeData: state.chcpProduction.realTimeProduction,
    totalData: state.chcpProduction.totalProduction
  };
}

export default connect(mapStateToProps)(Production);
