import React from "react";
import PropTypes from "prop-types";
import * as utils from "../../../common/utilities";
import { buildingTypes } from "../../../common/config";

const BuildingRankRow = ({
  name,
  buildingType,
  mapSelection,
  currentValue,
  maxValue,
  onClick
}) => {
  const BUILDING_NAME_MAX_LENGTH = 15;

  const buildingDataValueDisplay = (currentValue, maxValue, buildingInfo) => {
    const SVG_MAX_WIDTH = 100,
      SVG_MAX_HEIGHT = 4;
    let color = {
        fill: buildingTypes[buildingInfo.buildingType].color
      },
      width =
        Math.round(
          (currentValue.currentValue / maxValue.maxValue) * SVG_MAX_WIDTH
        ) + "px";
    return (
      <div className="building-ranking-column2">
        <div className="building-ranking-value">
          {utils.commaFormatNumbers(Math.round(currentValue.currentValue))}
        </div>
        <svg width={SVG_MAX_WIDTH + "px"} height={SVG_MAX_HEIGHT + "px"}>
          <rect width={width} height="4px" style={color} /> Sorry, your browser
          does not support inline SVG.
        </svg>
      </div>
    );
  };

  return (
    <div className="building-ranking-row" onClick={onClick}>
      <div className="building-ranking-column1">
        {utils.truncateString(name, BUILDING_NAME_MAX_LENGTH)}
      </div>
      {buildingDataValueDisplay(
        { currentValue },
        { maxValue },
        { buildingType }
      )}
    </div>
  );
};

BuildingRankRow.propTypes = {
  name: PropTypes.string.isRequired,
  buildingType: PropTypes.string.isRequired,
  mapSelection: PropTypes.string.isRequired,
  currentValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired
};

export default BuildingRankRow;
