import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function mobileReducer(
  state = initialState.ceedMobileData,
  action
) {
  switch (action.type) {
    case types.LOAD_CEED_MOBILE_SUCCESS:
      let newMobileState = Object.assign({}, state);
      for (let item in action.ceedMobileData)
        newMobileState[item] = action.ceedMobileData[item];
      return newMobileState;

    default:
      return state;
  }
}
