import React from "react";

const WaterUsageGraph = ({ onClick, options, sources }) => {
  return (
    <div className="water-usage-graph">
      <div className="water-graph-options">
        {sources.map((source, index) => (
          <label
            key={source.displayName}
            className="water-graph-option"
            htmlFor={source.value}
          >
            <input
              type="checkbox"
              defaultChecked={options[source.value]}
              onClick={onClick}
              id={source.value}
            />
            <span className={"water-graph-option-label " + source.value}>
              {source.displayName}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default WaterUsageGraph;
