import React from "react"
import { Link } from "react-router-dom"


import "./Header.css"

import SavingDescription from "./SavingDescription"
import DashboardMenu from "../../common/dashboardMenu/DashboardMenu"

import arrowImage from "../../../images/arrow.svg"
class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
    this.dropdown = null
    this.setReference = (element) => {
      this.dropdown = element
    }
    this.handleOutsideClick = (event) => {
      if (this.dropdown !== null && !this.dropdown.contains(event.target))
        this.setState({ menuOpen: false })
      else return
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.menuOpen)
      document.addEventListener("mousedown", this.handleOutsideClick, false)
    else
      document.removeEventListener("mousedown", this.handleOutsideClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick, false)
  }

  openMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    })
  }

  render() {
    return (
      <div className="saving-header">
        <div ref={this.setReference} className="chcp-header-navigation">
          <DashboardMenu />
        </div>
        <div className="saving-header-wrap">
          <div className="saving-header-title">Energy Saving Projects</div>
          <SavingDescription />
        </div>
        <Link to="/" className="building-header-map-link">
          <img
            src={arrowImage}
            alt="Back to campus map arrow."
            id="bounce-arrow-left"
          />
          Back to map
        </Link>
      </div>
    )
  }
}

export default Header
