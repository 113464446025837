import React from "react";
import "./Metrics.css";

export default function Metrics() {
  return (
    <div className="metrics-tab">
      <div className="water-metrics water-card">
        <div className="water-headline">Coming soon!</div>
      </div>
    </div>
  );
}