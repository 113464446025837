import React from "react";
import { Marker } from "react-leaflet";

import EuiCircle from "./EuiCircle";
import EuiPopups from "./popups/EuiPopups";
import { buildingIcon } from "./DivIcons";
import { circleColor } from "./MapUtils";

// redux actions, store
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as markerClickActions from "../../../actions/markerClickActions";

const DEFAULT_OPACITY = 0.55;
const NON_GREEN_BUILDING = 0.3;
const HOVERED_OPACITY = 0.85;
const GREEN_OPACITY = 0.95;

class BuildingMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: DEFAULT_OPACITY
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.greenButton !== prevProps.greenButton) {
      if (this.props.marker.greenBuilding && this.props.greenButton) {
        this.setState({ opacity: HOVERED_OPACITY });
      } else {
        if (this.props.greenButton) {
          this.setState({ opacity: NON_GREEN_BUILDING });
        } else {
          this.setState({ opacity: DEFAULT_OPACITY });
        }
      }
    } else if (
      this.props.markerClick.clickStatus !==
        prevProps.markerClick.clickStatus &&
      this.props.markerClick.clickStatus === false
    ) {
      if (this.props.greenButton && this.props.marker.greenBuilding) {
        this.setState({ opacity: HOVERED_OPACITY });
      } else {
        this.setState({ opacity: DEFAULT_OPACITY });
      }
    }
  }

  render() {
    let icon = buildingIcon(
      this.props.markerClick,
      this.props.greenButton,
      this.props.marker
    );

    let markerPosition = [
      this.props.marker.center.lat,
      this.props.marker.center.long
    ];
    //eui circle (only render if there is an eui and euiReady is 'true')
    let circleComponent = null;
    if (this.props.marker.euiReady && this.props.marker.eui) {
      circleComponent = (
        <EuiCircle
          markerId={this.props.marker.id}
          radius={
            this.props.currentMapSelection === "eui"
              ? this.props.marker.euiCircleRadius
              : this.props.marker.annualCircleRadius
          }
          center={markerPosition}
          fillColor={circleColor(this.props.marker.buildingType)}
          opacity={this.state.opacity}
        />
      );
    }
    return (
      <div key={`${this.props.index}-div`}>
        <Marker
          key={`${this.props.index}-marker`}
          position={markerPosition}
          alt={this.props.marker.name}
          icon={icon}
          id={this.props.marker.id}
          closePopupOnClick={false}
          riseOnHover={true}
          onMouseOver={e => {
            if (this.props.markerClick.clickStatus === false) {
              if (this.props.greenButton) {
                if (
                  this.props.marker.greenBuilding &&
                  this.state.opacity === HOVERED_OPACITY
                ) {
                  this.setState({ opacity: GREEN_OPACITY });
                }
              } else {
                if (this.state.opacity === DEFAULT_OPACITY) {
                  this.setState({ opacity: HOVERED_OPACITY });
                }
              }
              e.target.openPopup();
            }
          }}
          onMouseOut={e => {
            if (this.props.markerClick.clickStatus === false) {
              if (this.props.greenButton) {
                if (
                  this.props.marker.greenBuilding &&
                  this.state.opacity === GREEN_OPACITY
                ) {
                  this.setState({ opacity: HOVERED_OPACITY });
                }
              } else {
                if (this.state.opacity === HOVERED_OPACITY) {
                  this.setState({ opacity: DEFAULT_OPACITY });
                }
              }
              e.target.closePopup();
            }
          }}
          onClick={e => {
            if (this.props.greenButton) {
              if (this.props.marker.greenBuilding) {
                this.setState({ opacity: GREEN_OPACITY });
              } else {
                this.setState({ opacity: DEFAULT_OPACITY });
              }
            } else {
              this.setState({ opacity: HOVERED_OPACITY });
            }
            e.target.openPopup();

            if (this.props.marker.annualCost || !this.props.marker.euiReady) {
              this.props.markerClickActions.markerClickHaveCost(
                true,
                this.props.marker
              );
            } else {
              this.props.markerClickActions.markerClickNeedCost(
                true,
                this.props.marker
              );
            }
          }}
        >
          {/*{popupComponent}*/}
          <EuiPopups
            marker={this.props.marker}
            clicked={this.props.markerClick.clickStatus}
            greenButton={this.props.greenButton}
            currentMapSelection={this.props.currentMapSelection}
          />
        </Marker>
        {circleComponent}
      </div>
    );
  }
}

BuildingMarker.propTypes = {
  markerClick: PropTypes.object.isRequired,
  currentMapSelection: PropTypes.string.isRequired,
  filterSlider: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    markerClick: state.markerClick,
    currentMapSelection: state.currentMapSelection,
    filterSlider: state.filterSlider
  };
};

const mapDispatchToProps = dispatch => {
  return {
    markerClickActions: bindActionCreators(markerClickActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingMarker);
