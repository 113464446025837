import React from "react"
import { Link } from "react-router-dom"

import "./Header.css"

import DashboardMenu from "../../common/dashboardMenu/DashboardMenu"

import arrowImage from "../../../images/arrow.svg"

export default function Header() {
  return (
    <header className="water-header">
      <DashboardMenu />
      <div className="water-header-wrap">
        <h1 className="water-header-title">
          UC Davis
          <br />
          Water Usage
        </h1>
        <div className="water-header-description">
          <p>
            In line with the UC Sustainable Practices Policy goals, UC Davis is
            committed to reducing growth-adjusted potable water consumption by 20%
            by 2020 and 36% by 2025, when compared to a three-year average
            baseline of fiscal years 05/06, 06/07, and 07/08. These graphs on
            campus water use help us to stay on track.
          </p>
        </div>
      </div>
      <Link to="/" className="building-header-map-link">
        <img
          src={arrowImage}
          alt="Back to campus map arrow."
          id="bounce-arrow-left"
        />
        Back to map
      </Link>
    </header>
  )
}
