import React from "react";
import { Link } from "react-router-dom";

import "./Header.css";

import DashboardMenu from "../../common/dashboardMenu/DashboardMenu";

import arrowImage from "../../../images/arrow.svg";

const Header = () => {
  return (
    <div className="chcp-header">
      <DashboardMenu />
      <div className="chcp-header-content">
        <div className="chcp-header-title">
          Central Heating and Cooling Plant
        </div>
        <div className="chcp-header-description">
          The Central Heating and Cooling Plant (CHCP) of UC Davis is where the
          real energy action happens. From this plant, our energy supply is
          distributed to the many buildings across campus. <br />
          <br />
          The CHCP has boilers that take in natural gas to make steam. This
          steam is piped around the campus and used to heat our buildings.
        </div>
      </div>
      <Link to="/" className="building-header-map-link">
        <img
          src={arrowImage}
          alt="Back to campus map arrow."
          id="bounce-arrow-left"
        />
        Back to map
      </Link>
    </div>
  );
};

export default Header;
