/**
 * These are reusable utility function used for the app.
 */

import { commodityTypes } from "./config";

const COMMODITIES_ORDER = [
  "chilledWater",
  "steam",
  "hotWater",
  "electricity",
  "gas",
  "solar"
];

export const clearSpaces = str => {
  return str.replace(/\s/g, "");
};

// Returns a color array for Highcharts graphs.
export const commoditiesColorArray = commodityArray => {
  let colorArray = [];
  for (let i = 0; i < commodityArray.length; i++)
    colorArray.push(commodityTypes[commodityArray[i].id].color);
  return colorArray;
};

// Determines the Annual Usage value based on the eui and sq. footage.
export const calculateAnnualUsage = (euiValues, squareFeet) => {
  return commaFormatNumbers(squareFeet * combineEuiValues(euiValues));
};

// Capitalizes the first letter of the string passed in.
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Takes an array of eui values and generates a rounded total.
export const combineEuiValues = euiValues => {
  let total = 0;
  if (euiValues.length > 0)
    for (let i = 0; i < euiValues.length; i++) total += euiValues[i].eui;
  return Math.round(total);
};

// Takes an array of cost values and generates a total.
export const combineCostValues = costValues => {
  let total = 0;
  if (costValues.length > 0)
    for (let i = 0; i < costValues.length; i++)
      total += costValues[i].relatedGross;
  return Math.round(total);
};

// Formats a series of numbers into readable comma formatting.
export const commaFormatNumbers = numberDisplay => {
  if (!numberDisplay) numberDisplay = 0
  return numberDisplay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatDollarValue = numberDisplay => {
  if (typeof numberDisplay !== "number") return numberDisplay;
  if (numberDisplay !== undefined) {
    numberDisplay = Math.round(numberDisplay);
    if (numberDisplay === 0) return "--";
    return "$" + commaFormatNumbers(numberDisplay);
  }
};

export const formatTimestamp = time => {
  time = new Date(time);
  return (
    time.getMonth() +
    1 +
    "/" +
    time.getDate() +
    "/" +
    time.getFullYear() +
    " " +
    formatTime(time)
  );
};

const formatTime = time => {
  let hours = time.getHours(),
    minutes = time.getMinutes(),
    seconds = time.getSeconds(),
    suffix = " AM";
  if (hours >= 12) {
    hours = hours % 12;
    suffix = " PM";
  }
  if (hours === 0) hours = 12;
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;
  return hours + ":" + minutes + ":" + seconds + suffix;
};

export const pluralCheck = (noun, amount) => {
  if (amount === 1) return noun;
  return noun + "s";
};

// Reorders an array of commodities based on the format listed in
// the const COMMODITIES_ORDER at the top of this file.
export const reorderCommodities = (commodities, hotWaterConversion) => {
  let orderedArray = [];
  if (hotWaterConversion) {
    commodities["hotWater"] = { active: "true" }
    // commodities.push("hotWater")
  }
  for (let i = 0; i < COMMODITIES_ORDER.length; i++) {
    if (commodities instanceof Array) {
      for (let j = 0; j < commodities.length; j++)
        if (COMMODITIES_ORDER[i] === commodities[j])
          orderedArray.push(COMMODITIES_ORDER[i]);
    } else {
      if (commodities !== null)
        if (
          commodities[COMMODITIES_ORDER[i]] !== undefined &&
          commodities[COMMODITIES_ORDER[i]] !== null &&
          commodities[COMMODITIES_ORDER[i]].active === "true"
        )
          orderedArray.push(COMMODITIES_ORDER[i]);
    }
  }
  return orderedArray;
};

export const sortEwcRanking = (a, b) => {
  return getEwcAverage(b) - getEwcAverage(a);
};

const getEwcAverage = rankingObject => {
  if (
    rankingObject["domesticWaterPercent"] !== null &&
    rankingObject["electricityPercent"] !== null
  )
    return (
      (rankingObject["domesticWaterPercent"] +
        rankingObject["electricityPercent"]) /
      2
    );
  else if (rankingObject["electricityPercent"] !== null)
    return rankingObject["electricityPercent"];
  return rankingObject["domesticWaterPercent"];
};

// Takes in an UTC date and determines if it was PST or PDT at the timeset
// Returns the difference in hours
export const setCATimezoneOffset = DateToCheck => {
  let dateCheck = new Date(DateToCheck),
    janOffset = new Date(dateCheck.getFullYear(), 0, 1).getTimezoneOffset(),
    offset = dateCheck.getTimezoneOffset();
  if (offset === janOffset) return 8;
  return 7;
};

export const setDefaultCheckboxes = {
  chilledWater: true,
  electricity: true,
  gas: true,
  solar: true,
  steam: true,
  hotWater: true,
  oat: false
};

export const setGraphDates = data => {
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  let dates = [],
    lastWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 8 * 1000),
    day = lastWeek.getDay(),
    setWednesday = lastWeek.getDate() - day + (day === 0 ? -4 : 3),
    lastWednesday = new Date(lastWeek.setDate(setWednesday));
  for (let i = data.length - 1; i >= 0; i--) {
    let d = new Date(lastWednesday - 1000 * 60 * 60 * 24 * 7 * i);
    dates.push(MONTHS[d.getMonth()] + " " + d.getDate());
  }
  return dates;
};

export const getCurrFiscalYear = () => {
  let date = new Date();
  let currentYear = date.getFullYear();
  if (date.getMonth() < 4) {
    let fiscalYear = `${currentYear - 1}-${getTwoDigitYear(currentYear)}`;
    return fiscalYear.toString();
  } else {
    let fiscalYear = `${currentYear}-${getTwoDigitYear(currentYear + 1)}`;
    return fiscalYear.toString();
  }
};

const getTwoDigitYear = year => {
  return year.toString().slice(2);
};

// Truncates a string length to be shorter than the const MAX_LENGTH
// listed at the top of this file. Adds an elipsis if it truncates.
export const truncateString = (displayString, MAX_LENGTH) => {
  if (displayString !== undefined && displayString.length > MAX_LENGTH)
    return displayString.slice(0, MAX_LENGTH - 1) + "...";
  return displayString;
};
