/**
 * HighCharts utilities that are used to build the demand HighChart.
 * These functions set up the default parameters along with some
 * helper functions for formatting the visual elements of the chart.
 */

import { commodityTypes } from "../../../../common/config";
import {
  getDailyLabels,
  getWeeklyLabels,
  getMonthlyLabels
} from "../../../../common/axisLabels";

import Highcharts from "highcharts";
const MAX_POSSIBLE_COMMODITIES = 5,
  DEFAULT_SECONDARY_Y = false;

// Established the default graph parameters.
export const initializeGraph = () => {
  return {
    chart: {
      zoomType: "x",
      type: "areaspline",
      plotBorderColor: "#F1F1F2",
      plotBorderWidth: 2,
      height: 410
    },
    title: {
      text: null
    },
    xAxis: setXAxis(),
    yAxis: setYAxis(DEFAULT_SECONDARY_Y),
    legend: {
      enabled: false
    },
    plotOptions: {
      areaspline: {
        marker: {
          enabled: false
        }
      },
      series: {
        stacking: "normal",
        states: {
          hover: {
            enabled: false
          }
        },
        animation: {
          duration: 2000,
          easing: "easeOutBounce"
        }
      }
    },
    tooltip: {
      backgroundColor: "#F6F6F6",
      borderColor: "#EEEDEE",
      borderRadius: 3,
      borderWidth: 1.5,
      shadow: true,
      crosshairs: true,
      style: {
        color: "#2D3138",
        lineHeight: "17px",
        fontSize: "13px"
      },
      headerFormat:
        '<span style="font-size: 14px; color: #2D3138; opacity: 0.5; lineHeight: 17px;">{point.key}</span><table><br/>',
      pointFormat:
        '<span style="background-color:{series.color}; color:{series.color}; font-weight:700; font-size:9px; line-height:18px; vertical-align:top; max-width: 10px; max-height: 10px; border:2px solid white; border-radius: 50%; margin-right:5px; padding-right: 3px;">\u25CF </span><span>  {series.name}:</span> {point.y}<br/>',
      shared: true,
      useHTML: true,
      xDateFormat: "%A, %b %e, %l:%M%P"
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: setDefaultSeries(),
    visible: false
  };
};

// Builds out the empty arrays for the chart initialization.
// Outside Air Temperature is considered a commodity in regards to
// data initialization and should be counted.
const setDefaultSeries = () => {
  let defaultSeries = [];
  for (let i = 0; i < MAX_POSSIBLE_COMMODITIES; i++)
    defaultSeries.push({
      data: null
    });
  return defaultSeries;
};

// Sets the parameters for each of the commodities. This includes
// color, tooltip information, and visibility status.
export const buildSeries = async (
  commodityData,
  oatData,
  activeCommodities,
  checkedState
) => {
  let seriesData = await buildBaseCommodities(
    commodityData,
    activeCommodities,
    checkedState
  );
  seriesData.push(await buildOat(oatData, checkedState.oat));
  return seriesData;
};

const buildBaseCommodities = (data, commodities, visibility) => {
  let tempSeries = [];
  for (let h = 0; h < commodities.length; h++) {
    for (let i = 0; i < data.length; i++) {
      if (commodities[h] === data[i].commodity)
        tempSeries.push({
          name: commodityTypes[data[i].commodity].displayName,
          id: data[i].commodity,
          data: formatData(data[i].data),
          tooltip: {
            valueDecimals: 0,
            valueSuffix: " kBtu/hr"
          },
          yAxis: 0,
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: commodityTypes[data[i].commodity].gradient
          },
          visible: visibility[data[i].commodity]
        });
    }
  }
  return tempSeries;
};

// Sets the X Axis parameters for the Demand Chart. In particular,
// it sets different label types depending on what time interval is selected
export const setXAxis = currentDemandSelection => {
  return [
    {
      scrollbar: {
        enabled: true,
        showFull: false
      },
      type: "datetime",
      minPadding: 0,
      maxPadding: 0,
      labels: {
        step: currentDemandSelection === "monthlyData" ? 2 : null,
        style: {
          color: "#444952",
          fontSize: 13,
          textTransform: "capitalize",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        },
        padding: 0,
        y: 25,
        formatter: function() {
          let returnValue = "";
          switch (currentDemandSelection) {
            case "dailyData":
              returnValue = getDailyLabels(this.value);
              break;
            case "weeklyData":
              returnValue = getWeeklyLabels(this.value);
              break;
            case "monthlyData":
              returnValue = getMonthlyLabels(this.value);
              break;
            default:
              returnValue = "";
              break;
          }
          return returnValue;
        }
      }
    }
  ];
};

// Sets the Y Axis parameters for the Demand chart. This chart contains
// a left axis[0] (data) and right axis[1] (Outside Air Temperature).
export const setYAxis = secondaryAxisVisibility => {
  return [
    {
      min: 0,
      lineWidth: 2,
      gridLineWidth: 2,
      title: {
        text: "kBtu/hr",
        align: "high",
        rotation: 0,
        offset: 15,
        style: {
          color: "#2D3138",
          fontWeight: "bold",
          fontSize: 13,
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        }
      },
      lineColor: "#F1F1F2",
      gridLineColor: "#F1F1F2",
      minPadding: 0,
      maxPadding: 0,
      opposite: false,
      tickAmount: 5,
      labels: {
        formatter: function() {
          if (this.isLast) {
            return "";
          }
          let label = this.axis.defaultLabelFormatter.call(this);
          if (/^[0-9]{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value, 0);
          }
          return label;
        },
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: 13
        },
        reserveSpace: true
      },
      endOnTick: false
    },
    {
      //secondary yAxis
      title: {
        text: "Temp (\xB0F)",
        align: "high",
        rotation: 0,
        offset: 15
      },
      labels: {
        formatter: function() {
          if (this.isLast) {
            return "";
          }
          return this.value;
        },
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: 13
        },
        reserveSpace: true
      },
      opposite: true,
      visible: secondaryAxisVisibility,
      tickAmount: 5,
      minPadding: 0
    }
  ];
};

// Adds an Outside Air Temperature series to the graph.
const buildOat = (oatData, visible) => {
  return {
    name: "Outside Air Temperature",
    id: "oat",
    data: formatData(oatData),
    type: "line",
    tooltip: {
      valueDecimals: 0,
      valueSuffix: " \xB0F"
    },
    color: "black",
    lineWidth: 1,
    marker: {
      enabled: false,
      states: {
        hover: {
          enabled: false
        }
      }
    },
    yAxis: 1,
    visible: visible
  };
};

// Sets the data to values and associated dates for Highcharts
// to parse and display appropriately.
const formatData = dataArray => {
  let formattedArray = [];
  for (let i = 0; i < dataArray.length; i++) {
    let newDate = new Date(dataArray[i].Timestamp);
    if (dataArray[i].Value > 0)
      formattedArray.push([Date.parse(newDate), dataArray[i].Value]);
    else formattedArray.push([Date.parse(newDate), 0]);
  }
  return formattedArray;
};
