export const setDataPoint = (value, currentCommodity, indexPoint, isActive) => {
  const colors = {
    electricity: {
      active: "#92BD3B",
      inactive: "#ECF2DB"
    },
    water: {
      active: "#32BBD5",
      inactive: "#C9E9F1"
    },
    overuse: {
      active: "#FC6616",
      inactive: "#F7CFB6"
    }
  };
  let commodity = currentCommodity;
  if (value < 0) commodity = "overuse";
  if (isActive)
    return {
      y: value,
      color: colors[commodity].active,
      borderColor: colors[commodity].active
    };
  return {
    y: value,
    color: colors[commodity].inactive,
    borderColor: colors[commodity].active,
    indexPoint
  };
};

export const getGraphPoints = (dataArray, currentIndex) => {
  return new Promise((res, rej) => {
    let dataLength = dataArray.length,
      holdArray = [],
      indexPoint = 2,
      start = currentIndex - 2,
      end = currentIndex + 3;
    switch (currentIndex) {
      case 0:
        start = 0;
        end = 5;
        indexPoint = 0;
        break;
      case 1:
        start = 0;
        end = 5;
        indexPoint = 1;
        break;
      case dataLength - 2:
        start = dataLength - 5;
        end = dataLength;
        indexPoint = 3;
        break;
      case dataLength - 1:
        start = dataLength - 5;
        end = dataLength;
        indexPoint = 4;
        break;
      default:
    }
    for (let i = start; i < end; i++) {
      dataArray[i].indexPoint = i;
      holdArray.push(dataArray[i]);
    }
    res({
      data: holdArray,
      indexPoint
    });
  });
};

export const initializeGraph = (
  data,
  dates,
  label,
  onClickCallback,
  mobile
) => {
  return {
    title: null,
    chart: {
      type: "column",
      height: 230,
      width: null
    },
    backgroundColor: "#f1f1f2",
    borderWidth: 0,
    style: {
      color: "#000",
      fontFamily: [
        "Proxima Nova",
        "Lucida Grande",
        "Lucida Sans",
        "Verdana",
        "sans-serif"
      ]
    },
    shadow: false,
    crosshairs: true,
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      title: {
        text: label,
        align: "high",
        rotation: 0,
        offset: 12,
        style: {
          color: "#6c6c6c",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontWeight: "200"
        }
      },
      labels: {
        style: {
          color: "#6c6c6c",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: "12px",
          fontWeight: "200"
        }
      },
      lineWidth: 2,
      gridLineWidth: 1,
      lineColor: "#F1F1F2",
      gridLineColor: "#F1F1F2",
      tickColor: "#fff",
      tickWidth: 0,
      maxPadding: 0,
      opposite: false,
      tickAmount: 6,
      showLastLabel: false
    },
    tooltip: {
      enabled: mobile ? false : true,
      headerFormat: null,
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      valueDecimals: 0,
      valueSuffix: " " + label
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 2
      },
      series: {
        cursor: "pointer",
        point: {
          events: {
            click: function() {
              onClickCallback(this.indexPoint);
            }
          }
        }
      }
    },
    series: [
      {
        pointWidth: mobile === undefined ? 73 : 50,
        data
      }
    ]
  };
};
