import React from "react";
import PropTypes from "prop-types";
import { UNIT_TYPE } from "../DataFilter";
import { helpHovers } from "../../common/utilities";
import HelpIcon from "../../common/images/helpicon.png";

export default function UnitTypeSelection({
  toggleUnitTypeSelection,
  selectedUnitType,
  usageSelected
}) {
  return (
    <div className="data-filter-inner-column col2">
      <span className="data-filter-section-header">Select meter units*</span>
      <div className="radio-group">
        <div
          className={
            selectedUnitType !== UNIT_TYPE.METER_UNITS
              ? "custom-radio-button input-box input-box-unselected"
              : "custom-radio-button input-box input-box-selected"
          }
        >
          <input
            type="radio"
            id="meter-units"
            value={UNIT_TYPE.METER_UNITS}
            name="unit-types"
            checked={selectedUnitType === UNIT_TYPE.METER_UNITS}
            onChange={toggleUnitTypeSelection}
          />
          <label htmlFor="meter-units">Meter Units</label>
        </div>
        <div className="helpicon">
          <img src={HelpIcon} alt="Meter Unites Help" />
          <div className="helpicon-hovers">{helpHovers.meter}</div>
        </div>
      </div>
      <div
        className="radio-group"
        style={usageSelected ? { opacity: "0.3", pointerEvents: "none" } : {}}
      >
        <div
          className={
            selectedUnitType !== UNIT_TYPE.KBTU
              ? "custom-radio-button input-box input-box-unselected"
              : "custom-radio-button input-box input-box-selected"
          }
        >
          <input
            type="radio"
            id="meter-kbtu"
            value={UNIT_TYPE.KBTU}
            name="unit-types"
            checked={selectedUnitType === UNIT_TYPE.KBTU}
            onChange={toggleUnitTypeSelection}
          />
          <label htmlFor="meter-kbtu">kBtu</label>
        </div>
        <div className="helpicon">
          <img src={HelpIcon} alt="kBtu Units Help" />
          <div className="helpicon-hovers">{helpHovers.kbtu}</div>
        </div>
      </div>
    </div>
  );
}

UnitTypeSelection.propTypes = {
  toggleUnitTypeSelection: PropTypes.func.isRequired,
  selectedUnitType: PropTypes.string.isRequired
};
