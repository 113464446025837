import React from "react";
import PropTypes from "prop-types";

import Demand from "./demand/Demand";
import Usage from "./usage/Usage";
import Comparison from "./comparison/Comparison";
import EwcBuilding from "../../ewc/ewcBuilding/EwcBuilding";

const NavigationView = ({
  view,
  building,
  data,
  onClick,
  allBuildings,
  ranking,
  rankingWeekly,
  demandCommodities,
  monthlyUsageCommodities,
  annualUsageCommodities
}) => {
  switch (view) {
    case "demand":
      return (
        <Demand
          dailyData={data.demandDaily}
          dailyOat={data.oatDaily}
          weeklyData={data.demandWeekly}
          weeklyOat={data.oatWeekly}
          monthlyData={data.demandMonthly}
          monthlyOat={data.oatMonthly}
          commodities={demandCommodities}
          shortname={building.id}
          onClick={onClick}
        />
      );
    case "usage":
      return (
        <Usage
          usageData={data.monthlyUsage}
          building={building}
          oatData={data.monthlyOat}
          monthlyCommodities={monthlyUsageCommodities}
          annualCommodities={annualUsageCommodities}
          onClick={onClick}
        />
      );
    case "comparison":
      return (
        <Comparison
          building={building}
          allBuildingEuis={data.allBuildingEuis}
          allBuildingsData={allBuildings}
        />
      );
    case "savings":
      return <EwcBuilding rankingWeekly={rankingWeekly} />;
    default:
      return null;
  }
};

NavigationView.propTypes = {
  view: PropTypes.string.isRequired,
  building: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  allBuildings: PropTypes.array.isRequired,
  ranking: PropTypes.array.isRequired,
  rankingWeekly: PropTypes.object.isRequired,
  demandCommodities: PropTypes.array.isRequired,
  monthlyUsageCommodities: PropTypes.array.isRequired,
  annualUsageCommodities: PropTypes.array.isRequired
};

export default NavigationView;
