/**
 * HighCharts utilities that are used to build the Total Energy HighChart.
 * These functions set up the default parameters along with some
 * helper functions for formatting the visual elements of the chart.
 */

import Highcharts from "highcharts";

export const energySource = (id, name, color, visible) => {
  var hoverColor;
  return {
    name: name,
    id: id,
    data: [],
    tooltip: {
      valueDecimals: 0,
      valueSuffix: " MMBtu"
    },
    borderColor: color,
    color: Highcharts.color(color)
      .setOpacity(0.8)
      .get(),
    visible: visible,
    states: {
      hover: {
        color: color
      }
    }
  };
};

export const costSource = (id, name, color, visible) => {
  return {
    name: name,
    id: id,
    data: [],
    tooltip: {
      valueDecimals: 0,
      valuePrefix: "$"
    },
    borderColor: color,
    color: Highcharts.color(color)
      .setOpacity(0.8)
      .get(),
    visible: visible,
    states: {
      hover: {
        color: color
      }
    }
  };
};

// Established the default graph parameters.
export const initializeEnergyGraph = (xAxis, yAxis) => {
  return {
    chart: {
      height: 400,
      type: "column"
    },
    title: {
      text: null
    },
    xAxis: {
      categories: xAxis,
      labels: {
        step: 2,
        y: 25,
        rotation: 45
      }
    },
    yAxis: setYAxis(),
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000,
          easing: "easeOutBounce"
        }
      },
      column: {
        stacking: "normal"
      }
    },
    tooltip: {
      backgroundColor: "#F6F6F6",
      borderColor: "#EEEDEE",
      borderRadius: 3,
      borderWidth: 1.5,
      shadow: true,
      crosshairs: {
        width: 1,
        color: "#cccccc"
      },
      style: {
        color: "#2D3138",
        lineHeight: "17px",
        fontSize: "13px"
      },
      headerFormat:
        '<span style="font-size: 14px; color: #2D3138; opacity: 0.5; lineHeight: 17px;">{point.key}</span><table><br/>',
      pointFormat:
        '<span style="background-color:{series.color}; color:#ffffff00; font-weight:700; font-size:9px; line-height:18px; vertical-align:top; max-width: 10px; max-height: 10px; border:2px solid white; border-radius: 50%; margin-right:5px; padding-right: 3px;">\u25CF </span><span>  {series.name}:</span> {point.y}<br/>',
      footerFormat:
      '<span style= "color:#ffffff00; font-weight:700; font-size:9px; line-height:18px; vertical-align:top; max-width: 10px; max-height: 10px; margin-right:17px; padding-right: 3px;"> </span><span>Total:</span> {point.total:.,0f} MMBtu<br/>',
      shared: true,
      useHTML: true
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: yAxis,
    visible: true
  };
};

// Sets the Y Axis parameters for the Total Energy chart.
export const setYAxis = () => {
  return [
    {
      min: 0,
      gridLineWidth: 2,
      title: {
        text: "MMBtu",
        align: "high",
        rotation: 0,
        offset: 15,
        style: {
          color: "#676767",
          fontSize: 13,
          fontWeight: "bold",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        }
      },
      lineColor: "#F1F1F2",
      gridLineColor: "#F1F1F2",
      minPadding: 0,
      maxPadding: 0,
      opposite: false,
      tickAmount: 5,
      labels: {
        formatter: function() {
          if (this.isLast) {
            return "";
          }
          let label = this.axis.defaultLabelFormatter.call(this);
          if (/^[0-9]{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value, 0);
          }
          return label;
        },
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: 13
        },
        reserveSpace: true
      },
      endOnTick: false
    }
  ];
};
