import Highcharts from "highcharts";

export const defaultEnergyUse = {
  data: null,
  name: "Energy Use",
  tooltip: {
    id: "energyUse",
    valueDecimals: 0,
    valueSuffix: " MMBtu"
  },
  yAxis: 0,
  color: "#99be4f",
  marker: {
    symbol: "circle",
    radius: 3
  }
};

export const initializeMobileBarLineGraph = (xAxis, seriesData) => {
  return {
    chart: {
      zoomType: "xy",
      plotBorderColor: "#FFFFFF",
      plotBorderWidth: 0,
      height: 250
    },
    title: {
      text: null
    },
    xAxis: {
      labels: {
        style: {
          color: "#FFFFFF"
        },
        rotation: 75
      },
      categories: xAxis,
      tickLength: 0
    },
    yAxis: setYAxis(seriesData[1].name),
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000,
          easing: "easeOutBounce"
        }
      }
    },
    tooltip: {
      backgroundColor: "#FFFFFF",
      borderColor: "#FFFFFF",
      borderRadius: 0,
      borderWidth: 0,
      shadow: false,
      crosshairs: {
        width: 1,
        color: "#cccccc"
      },
      positioner: function() {
        return { x: 70, y: 200 };
      },
      useHTML: true,
      pointFormat:
        '<div style="display:flex; flex-direction:row; justify-content:space-between; align-items:center; object-fit:contain; width:100%; margin: 10px 0;"><span style="color:{series.color}; margin-right:5px;">\u2B24</span><span style="font-weight:600; margin-top:4px; width:60%;"> {series.name} </span>  <span style="width:30%; margin-top:4px;">{point.y} MMBtu/hr</span><br/></div>',
      shared: true
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: seriesData,
    visible: true
  };
};

// Established the default graph parameters.
export const initializeLineGraph = (xAxis, yAxis) => {
  return {
    chart: {
      plotBorderColor: "#F1F1F2",
      plotBorderWidth: 2,
      height: 160
    },
    title: {
      text: null
    },
    xAxis: {
      gridLineWidth: 0,
      gridLineColor: "transparent",
      categories: xAxis,
      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0
    },
    yAxis: setYAxis(yAxis[1].name),
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000,
          easing: "easeOutBounce"
        }
      }
    },
    tooltip: {
      backgroundColor: "#f1f1f2",
      borderColor: "#92949a",
      borderRadius: 1,
      borderWidth: 1.5,
      shadow: false,
      crosshairs: true,
      useHTML: true,
      pointFormat:
        '<div style="display:flex; flex-direction:row; justify-content:s pace-between; align-items:center; object-fit:contain; width:375px"><span style="font-weight:500; width:30%;"> {series.name} </span> <span style="color:{series.color};">\u2B24</span> <p style="width:30%">{point.y} MMBtu/hr</p><br/></div>',
      shared: true
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: yAxis,
    visible: true
  };
};

// Sets the Y Axis parameters for the Demand chart. This chart contains
// a left axis[0] (data) and right axis[1] (Outside Air Temperature).
export const setYAxis = secondaryAxisLabel => {
  return [
    {
      min: 0,
      lineWidth: 2,
      gridLineWidth: 0,
      title: {
        text: "MMBtu/sq.",
        align: "high",
        rotation: 0,
        offset: 15,
        style: {
          color: "#92bd3b",
          fontSize: 13,
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        }
      },
      lineColor: "#F1F1F2",
      gridLineColor: "#F1F1F2",
      minPadding: 0,
      maxPadding: 0,
      opposite: false,
      tickAmount: 5,
      labels: {
        formatter: function() {
          if (this.isLast) {
            return "";
          }
          let label = this.axis.defaultLabelFormatter.call(this);
          if (/^[0-9]{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value, 0);
          }
          return label;
        },
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: 13
        },
        reserveSpace: true
      },
      endOnTick: false
    },
    {
      //secondary yAxis
      title: {
        text: secondaryAxisLabel,
        align: "high",
        rotation: 0,
        offset: 15,
        style: {
          color: "#4B5059",
          fontSize: 13,
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        }
      },
      labels: {
        formatter: function() {
          if (this.isLast) {
            return "";
          }
          let label = this.axis.defaultLabelFormatter.call(this);
          if (/^[0-9]{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value, 0);
          }
          return label;
        },
        style: {
          color: "#444952",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ],
          fontSize: 13
        },
        reserveSpace: true
      },
      opposite: true,
      visible: true,
      tickAmount: 5,
      minPadding: 0
    }
  ];
};
