import React from "react";

export default function ModalTiles({gifs, setInfoPage}) {
  const gifComponent = gifs.map((gif, i) => {
    return (
      <div className="gif-tile" key={i} onClick={() => setInfoPage(gif.src)}>
        <div className={`static ${gif.src}`}/>
        <img src={gif.apng} alt={`${gif.name}-animation`}/>
        <div className="gif-name">{gif.name}</div>
      </div>
    )
  })
  return (
    <div className="gif-tiles-wrapper">
    {gifComponent}
    </div>
  )
}