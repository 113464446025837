import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export function chcpProduction(state = initialState.chcpProduction, action) {
  switch (action.type) {
    case types.LOAD_CHCP_PRODUCTION_SUCCESS:
      let newProductionState = Object.assign({}, state);
      for (let item in action.chcpProduction)
        newProductionState[item] = action.chcpProduction[item];
      return newProductionState;

    default:
      return state;
  }
}

export function chcpUsage(state = initialState.chcpUsage, action) {
  switch (action.type) {
    case types.LOAD_CHCP_USAGE_SUCCESS:
      let newUsageState = Object.assign({}, state);
      for (let item in action.chcpUsage)
        newUsageState[item] = action.chcpUsage[item];
      return newUsageState;

    default:
      return state;
  }
}

export function chcpMetrics(state = initialState.chcpMetrics, action) {
  switch (action.type) {
    case types.LOAD_CHCP_METRICS_SUCCESS:
      let newMetricsState = Object.assign({}, state);
      for (let item in action.chcpMetrics)
        newMetricsState[item] = action.chcpMetrics[item];
      return newMetricsState;

    default:
      return state;
  }
}
