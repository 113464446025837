import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productionActions from "../../actions/chcp/chcpProductionActions";
import * as usageActions from "../../actions/chcp/chcpUsageActions";
import * as metricsActions from "../../actions/chcp/chcpMetricsActions";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { breakPoints } from "../common/config";

import MobileComingSoon from "../mobileComingSoon/MobileComingSoon";
import Header from "./header/Header";
import Navigation from "./navigation/Navigation";
import Production from "./production/Production";
import Usage from "./usage/Usage";
import Metrics from "./metrics/Metrics";
import Footer from "../common/footer/Footer";
import "./Chcp.css";

const DEFAULT_NAVIGATION = "production";

const chcpComponents = {
  production: Production,
  usage: Usage,
  metrics: Metrics
};

class Chcp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentNavigation: DEFAULT_NAVIGATION
    };
    this.updateNavigation = this.updateNavigation.bind(this);
  }

  componentWillMount() {
    this.props.productionActions.loadProductionTotal();
    this.props.productionActions.loadProductionRealTime();
    this.props.usageActions.loadUsageTotal();
    this.props.usageActions.loadUsageRealTime();
    this.props.metricsActions.loadMetricsTotal();
    this.props.metricsActions.loadMetricsRealTime("boilerEfficiency");
    this.props.metricsActions.loadMetricsRealTime("coolingEfficiency");
    this.props.metricsActions.loadMetricsRealTime("deltaT");
    this.props.metricsActions.loadMetricsWaterTower();
  }

  getChcpComponent() {
    let ChcpComponent = chcpComponents[this.state.currentNavigation];
    return <ChcpComponent />;
  }

  updateNavigation(event) {
    let currentNavigation = event.target.id;
    this.setState({
      currentNavigation
    });
  }

  render() {
    return (
      <React.Fragment>
        <MediaQuery maxWidth={breakPoints.mobile}>
          <MobileComingSoon />
        </MediaQuery>
        <MediaQuery minWidth={breakPoints.tablet}>
          <div className="chcp">
            <Header />
            <Navigation
              selection={this.state.currentNavigation}
              onClick={this.updateNavigation}
            />
            <div className="chcp-content">{this.getChcpComponent()}</div>
            <Footer />
          </div>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

Chcp.propTypes = {
  productionActions: PropTypes.object.isRequired,
  usageActions: PropTypes.object.isRequired,
  metricsActions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    productionActions: bindActionCreators(productionActions, dispatch),
    usageActions: bindActionCreators(usageActions, dispatch),
    metricsActions: bindActionCreators(metricsActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(Chcp);
