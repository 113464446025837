import React from "react"

import styles from "./HeatingCooling.module.css"

import MainContent from "./mainContent/MainContent"
import DefinitionsCard from "./definitionsCard/DefinitionsCard"
import ExampleCard from "./exampleCard/ExampleCard"

export default function HeatingCooling(props) {
  return (
    <div className={styles.container}>
      <MainContent demandWeeklyTLC={props.data} oatWeekly={props.oatWeekly} />
      <section className={styles.aside}>
        <DefinitionsCard />
        <ExampleCard />
      </section>
    </div>
  )
}


