/**
 * This component sets up the header portion of the Compare Chart Grid rows.
 */

import React from "react";
import PropTypes from "prop-types";

import * as utilities from "../../../../common/utilities";
import { commodityTypes } from "../../../../common/config";
const MAX_BUILDING_LENGTH = 15,
  SVG_MAX_HEIGHT = 10;

const createEuiSvgRow = (euiValues, maxEui) => {
  if (euiValues !== undefined && maxEui !== 0) {
    let electricityWidth =
        Math.floor((euiValues.electricity / maxEui) * 97) + "%",
      steamWidth = Math.floor((euiValues.steam / maxEui) * 97) + "%",
      chilledWaterWidth =
        Math.floor((euiValues.chilledWater / maxEui) * 97) + "%",
      electrcityColor = {
        fill: commodityTypes.electricity.color
      },
      steamColor = {
        fill: commodityTypes.steam.color
      },
      chilledWaterColor = {
        fill: commodityTypes.chilledWater.color
      };
    return (
      <div className="building-comparison-grid-row-svg">
        {createEuiSvg(electricityWidth, electrcityColor)}
        {createEuiSvg(steamWidth, steamColor)}
        {createEuiSvg(chilledWaterWidth, chilledWaterColor)}
        <svg
          width="0"
          height={SVG_MAX_HEIGHT + "px"}
          className="building-comparison-grid-row-svg-end"
        >
          {" "}
          Sorry, your browser does not support inline SVG.
        </svg>
      </div>
    );
  }
  return null;
};

const createEuiSvg = (width, color) => {
  if (width !== 0)
    return (
      <svg width={width} height={SVG_MAX_HEIGHT + "px"}>
        <rect width="100%" height={SVG_MAX_HEIGHT + "px"} style={color} />
      </svg>
    );
};

const normalizedValues = (building, property, normalized) => {
  if (building.eui[property] === 0) return "--";
  if (normalized) return building.eui[property];
  return utilities.commaFormatNumbers(
    building.annualUsage[property].toFixed(0)
  );
};

const CompareGridRow = ({ onClick, building, normalized, maxEui }) => {
  return (
    <div className="building-comparison-grid-row">
      <div className="building-comparison-grid-row-content col1">
        {utilities.truncateString(building.name, MAX_BUILDING_LENGTH)}
      </div>
      <div className="building-comparison-grid-row-content col2">
        <div className="building-comparison-grid-row-text">
          {normalizedValues(building, "total", true)}
        </div>
        {createEuiSvgRow(building.eui, maxEui)}
      </div>
      <div className="building-comparison-grid-row-content col3">
        {normalizedValues(building, "electricity", normalized)}
      </div>
      <div className="building-comparison-grid-row-content col4">
        {normalizedValues(building, "steam", normalized)}
      </div>
      <div className="building-comparison-grid-row-content col5">
        {normalizedValues(building, "chilledWater", normalized)}
      </div>
      <div className="building-comparison-grid-row-content col6">
        {utilities.formatDollarValue(building.annualCost)}
      </div>
    </div>
  );
};

CompareGridRow.propTypes = {
  onClick: PropTypes.func,
  building: PropTypes.object.isRequired,
  normalized: PropTypes.bool.isRequired,
  maxEui: PropTypes.number.isRequired
};

export default CompareGridRow;
