import React from "react";

export default function ModalNav({prevTxt, nextTxt, prevFunc, nextFunc, toggleIcon}) {
  return (
    <div className="modal-nav-wrapper">
      <div className="modal-content-button-set">
        {prevTxt && <div className="modal-content-back-button" onClick={prevFunc}>
          {prevTxt}
        </div>}
        <div className="modal-content-next-button" onClick={nextFunc}>
          {nextTxt}
        </div>
      </div>
      <div className="modal-content-toggle-image">
          <img src={toggleIcon} alt="toggle icon" />
      </div>
  </div>
  )
}