import React from "react";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "../../../common/utilities";

class MobileBuildingHeader extends React.Component {
  constructor(props) {
    super(props);
    this.imageNotFound = this.imageNotFound.bind(this);
  }

  getConstructionYear() {
    let constructionDateRange = "",
      tempBuildings = this.props.buildingInfo.facLinkData;
    if (tempBuildings.length === 1) {
      let constructionDate = new Date(tempBuildings[0].constructed);
      constructionDateRange = constructionDate.getFullYear();
    } else {
      let tempRange = [];
      for (let i = 0; i < tempBuildings.length; i++) {
        let constructionDate = new Date(tempBuildings[i].constructed);
        tempRange.push(constructionDate.getFullYear());
      }
      tempRange.sort();
      if (tempRange[0] === tempRange[tempRange.length - 1])
        constructionDateRange = tempRange[0];
      else
        constructionDateRange =
          tempRange[0] + " - " + tempRange[tempRange.length - 1];
    }
    return constructionDateRange;
  }

  imageNotFound(event) {
    event.target.src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=";
    event.target.alt = "Building photograph placeholder.";
  }

  render() {
    if (this.props.buildingInfo !== null && this.props.buildingInfo.id !== "")
      return (
        <div className="mobile-building-header">
          {this.props.buildingInfo.id !== undefined && (
            <div className="mobile-building-header-image">
              <img
                src={"/images/" + this.props.buildingInfo.id + ".jpg"}
                alt="Selected building photograph"
                onError={this.imageNotFound}
              />
            </div>
          )}
          <div className="mobile-building-header-overlay" />
          <div className="mobile-building-stats title">
            {this.props.buildingInfo.name}
          </div>
          {this.props.buildingInfo.facLinkData !== undefined &&
            this.props.buildingInfo.facLinkData[0].constructed !== null && (
              <div className="mobile-building-stats">
                Constructed: <b>{this.getConstructionYear()}</b>
              </div>
            )}
          <div className="mobile-building-stats">
            {capitalizeFirstLetter(this.props.buildingInfo.primaryUse)}:{" "}
            <b>{Math.round(this.props.buildingInfo.primaryPercent * 100)}%</b>
          </div>
        </div>
      );
    return null;
  }
}

MobileBuildingHeader.propTypes = {
  buildingInfo: PropTypes.object.isRequired
};

export default MobileBuildingHeader;
