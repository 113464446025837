import React, { Component } from "react";
import PropTypes from "prop-types";
import { numberWithCommas, roundNumber } from "../utils/formatting";

class SavingsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: [],
      usage: [],
      savings: [],
      doneFormatting: false
    };
  }
  tableContents = [];
  isGreyRow = false;
  rowBackgroundColor = "#F5F5F5";

  componentDidMount = async () => {
    // async because of setState
    await this.updateTableContents(this.props);
    this.setState({ doneFormatting: true });
  };

  componentWillReceiveProps = async nextProps => {
    this.setState({ doneFormatting: false });
    await this.updateTableContents(nextProps);
    this.setState({ doneFormatting: true });
  };

  updateTableContents = async props => {
    await this.formatTableValues(props);

    await this.resetTableSettings();

    // add each row of data to the table
    for (let i = 0; i < this.props.dates.length; i++) {
      this.toggleTableRowColor();
      this.addTableRow(i);
    }
  };

  addTableRow = rowIndex => {
    let savingsLabel =
      parseInt(this.state.savings[rowIndex], 10) > 0 ? "$" : "-$";
    let savings = this.state.savings[rowIndex].replace("-", "");
    let rowStyle = this.getRowStyle(rowIndex);

    this.tableContents.push(
      <div
        key={rowIndex}
        className="table-row"
        style={{ backgroundColor: this.rowBackgroundColor }}
      >
        <div className="table-row-item" style={rowStyle}>
          {this.state.dates[rowIndex]}
        </div>
        <div className="table-row-item" style={rowStyle}>
          {this.state.usage[rowIndex]}
        </div>
        <div className="table-row-item" style={rowStyle}>
          {savingsLabel}
          {savings}
        </div>
      </div>
    );
  };

  resetTableSettings = () => {
    this.isGreyRow = false;
    this.tableContents = [];
  };

  getTableContents = () => {
    return this.tableContents;
  };

  // return bold if we are rendering the last row
  getRowStyle = index => {
    if (index === this.state.dates.length - 1) {
      // last row has bolded values
      return { fontWeight: "bold" };
    } else {
      return null;
    }
  };

  toggleTableRowColor = () => {
    if (this.isGreyRow) this.rowBackgroundColor = "#F5F5F5";
    else this.rowBackgroundColor = "white";
    this.isGreyRow = !this.isGreyRow;
  };

  formatTableValues = async props => {
    let savings = [];
    let usage = [];
    let dates = props.dates;

    let totalUsage = this.sumArray(props.usage);
    let totalSavings = this.sumArray(props.savings);

    totalUsage = roundNumber(totalUsage, 0);
    totalSavings = roundNumber(totalSavings, 0);
    totalUsage = numberWithCommas(totalUsage);
    totalSavings = numberWithCommas(totalSavings);

    for (let i = 0; i < props.dates.length; i++) {
      let usageValue = roundNumber(props.usage[i], 0);
      let savingsValue = roundNumber(props.savings[i], 0);
      usageValue = numberWithCommas(usageValue);
      savingsValue = numberWithCommas(savingsValue);
      usage.push(usageValue);
      savings.push(savingsValue);
    }
    usage.push(totalUsage);
    savings.push(totalSavings);
    dates.push("Totals");

    await this.setState({
      dates: dates,
      usage: usage,
      savings: savings
    });
  };

  sumArray = array => {
    let sum = array.reduce((accumulator, value) => {
      return accumulator + value;
    });
    return sum;
  };

  render() {
    if (this.state.doneFormatting) {
      return (
        <div className="savingsTable-wrapper">
          <div className="headings-wrapper">
            <div className="table-heading">Month</div>
            <div className="table-heading">kBtu</div>
            <div className="table-heading">Savings</div>
          </div>
          {this.getTableContents()}
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

SavingsTable.defaultProps = {
  dates: [],
  usage: [],
  savings: []
};

SavingsTable.propTypes = {
  dates: PropTypes.array.isRequired,
  usage: PropTypes.array.isRequired,
  savings: PropTypes.array.isRequired
};

export default SavingsTable;
