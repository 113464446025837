/**
 * This component returns the appropriate building type description based on user selection.
 */

import React from "react";
import PropTypes from "prop-types";

import { buildingTypes } from "../../../../common/config";

const BuildingDescription = ({ selection }) => {
  return (
    <div className="compare-building-types-description">
      {buildingTypes[selection].description}
    </div>
  );
};

BuildingDescription.propTypes = {
  selection: PropTypes.string.isRequired
};

export default BuildingDescription;
