import React from "react";

import zoomIcon from "../../../../images/zoom.svg";
import { getOptions } from "../../usage/totalEnergy/TotalEnergy";
import { seriesBuilder } from "./seriesBuilder";
import { graphConfigBuilder } from "./graphConfigBuilder";
import HighchartsReact from 'highcharts-react-official';
import HighchartsSankey from 'highcharts/modules/sankey';

//styles
import "./TotalEnergySourceChart.css";

let Highcharts = require("highcharts");
HighchartsSankey(Highcharts);
const DEFAULT_SELECTION = "campusDemandWeekly";

class TotalEnergySourceChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartRef: null,
      maxEpoch: 0,
      campus: [],
      currentDemandSelection: DEFAULT_SELECTION
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.campus !== state.campus) {
      return {
        campus: props.campus
      };
    } else {
      return null;
    }
  }

  toggleSeries(seriesId, checkedState) {
    for (let i = 0; i < this.state.chartRef.series.length; i++) {
      if (this.state.chartRef.series[i].options.id === seriesId) {
        if (checkedState === true) {
          this.state.chartRef.series[i].show();
        } else {
          this.state.chartRef.series[i].hide();
        }
        break;
      }
    }
  }

  // Creates a default chart for data to load into.
  buildChart(demandSelection) {
    let series = seriesBuilder(this.state.campus["campusDemandDaily"]);

    let graphConfig = graphConfigBuilder(series, "campusDemandDaily");

    let chartRef = Highcharts.chart("total-campus-demand-chart", graphConfig);

    this.setState({ chartRef });
  }

  componentDidMount() {
    if (this.state.campus[DEFAULT_SELECTION] !== undefined) {
      this.buildChart();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.campus !== this.state.campus &&
      this.state.campus["campusDemandDaily"] !== undefined
    )
      this.buildChart();
  }

  render() {
    if (this.state.campus[DEFAULT_SELECTION] === undefined) return null;
    return (
      <div>
         <div className="super-graph-card">
          <HighchartsReact highcharts={Highcharts} options={getOptions('sankey')}/>
        </div>
        <br></br>
        <br></br>
        <div className="super-graph-card">
          <div className="total-campus-graph-title">
            Solar Farm Impact
            <div className="demand-zoom-helper">
              <img src={zoomIcon} alt="Magnifying glass" /> Click to interact with
              graph
            </div>
          </div>
          <div className="campus-graph">
            <div className="total-energy-campus-chart">
              <div id="total-campus-demand-chart" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TotalEnergySourceChart;
