import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sidebarActions from "../../../../actions/sidebarActions";
import PropTypes from "prop-types";

import "./AppMenu.css";

class AppMenu extends React.Component {
  render() {
    return (
      <div className="app-menu">
        <div className="menu-apps" onClick={this.props.actions.openAppsSidebar}>
          <div className="menu-apps-content">Dashboards Menu</div>
        </div>
      </div>
    );
  }
}

AppMenu.propTypes = {
  actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sidebarActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(AppMenu);
