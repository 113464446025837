import React from "react";

import "./Copyright.css";

const BuildingRankRow = () => {
  return (
    <div className="copyright">
      <div className="copyright-text">
        Copyright &copy; The Regents of the University of California, <br />Davis
        campus. All rights reserved.
      </div>
    </div>
  );
};

export default BuildingRankRow;
