import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as filterActions from "../../../../actions/filterActions";
import * as mapDataActions from "../../../../actions/mapDataActions";
import * as markerClickActions from "../../../../actions/markerClickActions";

class GreenBuildingFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      greenButton: false
    };
  }

  filterGreenBuilding = (greenButton, mapData) => {
    let newMapData = [];
    mapData.forEach(marker => {
      if (greenButton) {
        marker.buildingTypeVisible = marker.greenBuilding;
      } else {
        marker.buildingTypeVisible = true;
      }
      newMapData.push(marker);
    });
    return newMapData;
  };

  buttonClassName() {
    let name = "sidebar-green-button";
    if (this.state.greenButton) {
      return name + " active";
    }
    return name;
  }

  onClickHandler = () => {
    let greenButton = !this.state.greenButton;
    this.setState({ greenButton });
    this.props.greenButtonActions.greenBuildingClicked(greenButton);
  };

  render() {
    return (
      <div className={this.buttonClassName()} onClick={this.onClickHandler}>
        Show Green Buildings
      </div>
    );
  }
}

GreenBuildingFilter.propTypes = {
  mapData: PropTypes.array.isRequired,
  greenButton: PropTypes.bool.isRequired,
  markerClick: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    mapData: state.mapData,
    greenButton: state.greenButton,
    markerClick: state.markerClick
  };
};

const mapDispatchToProps = dispatch => {
  return {
    mapActions: bindActionCreators(mapDataActions, dispatch),
    greenButtonActions: bindActionCreators(filterActions, dispatch),
    markerClickActions: bindActionCreators(markerClickActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GreenBuildingFilter);
