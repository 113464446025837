import { DateTime } from "luxon";

import { energyStoryTypes } from "../../../common/config";

export const seriesBuilder = demandData => {
  let series = demandData.map(commodityData => {
    return {
      name: energyStoryTypes[commodityData.commodity].displayName,
      id: commodityData.commodity,
      color: energyStoryTypes[commodityData.commodity].color,
      data: commodityData.data.map(datum => [
        DateTime.fromISO(datum.Timestamp)
          .plus({ minutes: 1 })
          .valueOf(),
        datum.Value > 0 ? Math.round(datum.Value * 100) / 100 : 0
      ]),
      visible: true,
      zIndex: 3,
      yAxis: 0
    };
  });

  return series;
};
