import React from "react";
import PropTypes from "prop-types";

export const SelectButton = props => {
  const { open, listId, buttonId, onKeyDown, onClick, children } = props;
  return (
    <div className="sidebar-dropdown">
      <button
        className="ReactA11ySelect__button"
        id={buttonId}
        tabIndex={open ? "-1" : "0"}
        aria-haspopup="true"
        aria-expanded={open ? true : undefined} // ARIA recommends not including over false
        aria-controls={listId}
        onKeyDown={onKeyDown}
        onClick={onClick}
      >
        <span>{children}</span>
        <span
          className="ReactA11ySelect__button__arrow-indicator"
          aria-hidden="true"
        />
      </button>
    </div>
  );
};

SelectButton.propTypes = {
  open: PropTypes.bool,
  buttonId: PropTypes.string,
  listId: PropTypes.string,
  highlightedId: PropTypes.string,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func
};

export default SelectButton;
