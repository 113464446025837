import React from "react";
import PropTypes from "prop-types";

import { tooltipData } from "./config";
import infoIcon from "./images/info-icon.svg";

import "./Tooltip.css";

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false
    };
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(tooltipOpen) {
    this.setState({ tooltipOpen });
  }

  render() {
    return (
      <div
        className="chcp-tooltip"
        onMouseLeave={e => this.toggleTooltip(false)}
      >
        {this.state.tooltipOpen && (
          <div className="chcp-tooltip-bubble">
            <div className="chcp-tooltip-content">
              {tooltipData[this.props.tooltipType]}
            </div>
          </div>
        )}
        <img
          src={infoIcon}
          alt="Detailated information about this commodity icon"
          className="chcp-tooltip-icon"
          onMouseOver={e => this.toggleTooltip(true)}
        />
      </div>
    );
  }
}

Tooltip.propTypes = {
  tooltipType: PropTypes.string.isRequired
};

export default Tooltip;
