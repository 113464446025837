import React, { Component } from "react";
import "./GraphBox.css";
import PropTypes from "prop-types";
import SavingsBox from "./SavingsBox";
import BarChart from "../barChart/BarChart";

class GraphBox extends Component {
  getClassName = () => {
    if (this.props.isSelected) return "box-wrapper-selected";
    return "box-wrapper";
  };

  render() {
    return (
      <div className={this.getClassName()}>
        <div className="barChart-wrapper">
          <BarChart
            savingsData={this.props.savingsData}
            isSelected={this.props.isSelected}
            graphType={this.props.graphType}
          />
        </div>
        <div className="savingsText-wrapper">
          <SavingsBox savingsData={this.props.savingsData} />
        </div>
      </div>
    );
  }
}

GraphBox.defaultProps = {
  savingsData: [0],
  isSelected: false,
  graphType: ""
};

GraphBox.propTypes = {
  savingsData: PropTypes.array.isRequired,
  isSelected: PropTypes.bool.isRequired,
  graphType: PropTypes.string.isRequired
};

export default GraphBox;
