import React from "react";
import { waterDescriptions } from "../../../common/config";

export default function WaterGoalsStory() {
  return (
    <div className="water-story">
      <div className="water-story-column1">
        <div className="water-story-box" key={"domestic"}>
          <div>
            {waterDescriptions.column1[0].title}
            <p>{waterDescriptions.column1[0].description}</p>
          </div>
        </div>
      </div>
      <div className="water-story-column2">
        <div className="water-story-box" key={"utility"}>
          <div>
            {waterDescriptions.column2[0].title}
            <p>{waterDescriptions.column2[0].description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
