import React from "react"

import styles from "./ElectricityCard.module.css"

import elecGraphic from "../../images/elec-graphic.png"

export default function ElectricityCard() {
  return (
    <article className={styles.container}>
      <h2>What renewable electricity sources power this building?</h2>
      <img src={elecGraphic} alt="electricity sources diagram" />
    </article>
  )
}
