import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MediaQuery from "react-responsive";
import PropTypes from "prop-types";
import { CSVDownload } from "react-csv";
import MobileComingSoon from "../mobileComingSoon/MobileComingSoon";
import { breakPoints } from "../common/config";
import * as dataDownloadActions from "../../actions/dataDownloadActions";
import Header from "./header/Header";
import BuildingFilter from "./buildingFilter/BuildingFilter";
import DataFilter from "./dataFilter/DataFilter";
import DownloadStarted from "./downloadStarted/DownloadStarted";
import Footer from "../common/footer/Footer";
import "./DataDownload.css";

class DataDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      buildingSelection: {},
      beginDownload: false,
      downloadReady: false
    };
    this.handleSelectBuildingCommodity = this.handleSelectBuildingCommodity.bind(
      this
    );
    this.handleSelectVisible = this.handleSelectVisible.bind(this);
    this.removeBuilding = this.removeBuilding.bind(this);
    this.startDownload = this.startDownload.bind(this);
  }

  componentDidMount() {
    this.props.dataDownloadActions.loadAfBuildings();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.csvData) !== JSON.stringify(this.props.csvData)
    )
      this.setState({ downloadReady: true });
    if (this.state.beginDownload && this.state.downloadReady)
      this.setState(
        {
          searchText: "",
          buildingSelection: {},
          beginDownload: false,
          downloadReady: false
        },
        () => {
          window.scrollTo({
            top: 220,
            behavior: "smooth"
          });
        }
      );
  }

  removeBuilding(buildingName) {
    let buildingSelection = Object.assign({}, this.state.buildingSelection);
    delete buildingSelection[buildingName];
    this.setState({ buildingSelection });
  }

  startDownload(downloadOptions) {
    this.props.dataDownloadActions.loadDataRequests(
      downloadOptions,
      this.state.buildingSelection
    );
    this.setState({ beginDownload: true });
  }

  handleSelectBuildingCommodity(buildingId, commodity) {
    let buildingSelection = Object.assign({}, this.state.buildingSelection);
    if (buildingSelection[buildingId] === undefined)
      buildingSelection[buildingId] = [commodity];
    else {
      let commodityIndex = buildingSelection[buildingId].indexOf(commodity);
      if (commodityIndex === -1) buildingSelection[buildingId].push(commodity);
      else buildingSelection[buildingId].splice(commodityIndex, 1);
    }
    if (buildingSelection[buildingId].length === 0)
      delete buildingSelection[buildingId];
    this.setState({ buildingSelection });
  }

  handleSelectVisible(visibleBuildings) {
    let currentSelection = Object.assign({}, this.state.buildingSelection),
      buildingSelection = { ...currentSelection, ...visibleBuildings };
    this.setState({
      buildingSelection
    });
  }

  render() {
    return (
      <React.Fragment>
        <MediaQuery maxWidth={breakPoints.mobile}>
          <MobileComingSoon />
        </MediaQuery>
        {this.props.allBuildingsData.length !== 0 && (
          <MediaQuery minWidth={breakPoints.tablet}>
            <Header />
            {!this.state.beginDownload && (
              <BuildingFilter
                allBuildingsData={this.props.allBuildingsData}
                buildingSelection={this.state.buildingSelection}
                handleSelectBuildingCommodity={
                  this.handleSelectBuildingCommodity
                }
                handleSearchEvent={e =>
                  this.setState({ searchText: e.target.value })
                }
                handleSelectVisible={this.handleSelectVisible}
                searchText={this.state.searchText}
              />
            )}
            <DataFilter
              selectedBuildings={this.state.buildingSelection}
              removeBuilding={this.removeBuilding}
              startDownload={this.startDownload}
              beginDownload={this.state.beginDownload}
            />
            {this.state.beginDownload && <DownloadStarted />}
          </MediaQuery>
        )}
        {this.state.downloadReady && (
          <CSVDownload
            data={this.props.csvData.data}
            download={this.props.csvData.filename}
            headers={this.props.csvData.headers}
            target="_blank"
          />
        )}
        <Footer />
      </React.Fragment>
    );
  }
}

DataDownload.propTypes = {
  allBuildingsData: PropTypes.array.isRequired,
  csvData: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    allBuildingsData: state.dataDownload.buildings,
    csvData: state.dataDownload.downloadRequest
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dataDownloadActions: bindActionCreators(dataDownloadActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataDownload);
