export default {
  building: {
    id: "",
    name: "",
    buildingType: "",
    euiReady: false,
    hasImage: false,
    energyInfo: {
      commodities: [],
      tags: {
        annualCost: {},
        annualUsage: {},
        demand: {},
        euis: {},
        monthlyUsage: {}
      }
    },
    facLinkData: {
      footprint: 0
    }
  },
  ceedMobileData: {
    all: [],
    ace: [],
    classroom: [],
    green: [],
    lab: [],
    buildingDemand: {
      halfDay: [],
      threeDay: [],
      sevenDay: []
    },
    posted: false
  },
  chcpProduction: {
    totalProduction: {},
    realTimeProduction: {}
  },
  chcpUsage: {
    totalUsage: {},
    realTimeUsage: {}
  },
  chcpMetrics: {
    totalMetrics: {},
    boilerEfficiencyMetrics: {},
    coolingEfficiencyMetrics: {},
    deltaTMetrics: {},
    towerMetrics: {}
  },
  desktopEnergyData: {
    demandDaily: [],
    oatDaily: [],
    demandMonthly: [],
    oatMonthly: [],
    demandWeekly: [],
    oatWeekly: [],
    monthlyUsage: [],
    singleBuildingEuis: [],
    allBuildingEuis: {},
    monthlyOat: []
  },
  tlcEnergyData: {
    demandWeeklyTLC: [],
    oatWeekly: []
  },
  ewcRanking: [],
  ewcWeekly: {
    building: null,
    electricity: [],
    domesticWater: []
  },
  mapData: [],
  connectionData: {
    Status: "UP"
  },
  mapLocation: {
    lat: 38.538,
    lng: -121.756,
    zoom: 16
  },
  searchData: [],
  markerClick: {
    clickStatus: false,
    buildingData: null
  },
  sidebar: {
    about: false,
    apps: false
  },
  currentMapSelection: "eui",
  ranking: {
    fullRanking: false,
    rankingButton: true
  },
  filterSlider: {
    values: [],
    maxValue: 0
  },
  filterButtons: {
    lab: true,
    office: true,
    housing: true,
    classroom: true,
    community: true
  },
  greenButton: false,
  aceUsage: {},
  campus: {
    campusDemandDaily: [],
    campusDemandMonthly: [],
    campusDemandWeekly: [],
    campusUsage: []
  },
  dataDownload: {
    buildings: [],
    downloadRequest: {}
  },
  buildingProjects: {
    holidayShutdowns: [],
    hvacScheduling: [],
    opportunitiesLog: [],
    aceFYsavings: [],
    totals: {
      holidayShutdowns: 0,
      hvacScheduling: 0,
      opportunitiesLog: 0
    }
  },
  savingsCalculator: {
    time: "",
    holiday: "",
    building: "",
    before: 0,
    during: 0,
    percent: 0,
    commodity: "",
    season: ""
  },
  waterUsageData: {
    data: {}
  },
  waterGoalData: {
    data: {}
  },
};
