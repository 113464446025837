import React from "react"

import styles from "./ElectricitySolar.module.css"

import MainContent from "./mainContent/MainContent"
import ElectricityCard from "./electricityCard/ElectricityCard"


export default function ElectricitySolar(props) {
  return (
    <div className={styles.container}>
      <MainContent demandWeeklyTLC={props.data} oatWeekly={props.oatWeekly}/>
      <section className={styles.aside}>
        <ElectricityCard />
      </section>
    </div>
  )
}