import React, { useState } from "react"

import styles from "./Demand.module.css"

import DemandChart from "../../../common/demandChart/DemandChart"

import elecImage from "../../../images/elec-icon.svg"
import solarImage from "../../../images/solar-icon.svg"

export default function Demand({ weeklyData, weeklyOat }) {
  const allCommodities = ["electricity", "solar"]
  const [commodities, setCommodities] = useState(allCommodities)
  const [toggled, setToggled] = useState(false)
  const [toggleString, setToggleString] = useState("Show Graph")

  function toggleGraph(e) {
    if (commodities.length === 1 && commodities[0] === e.target.value) {
      setCommodities(allCommodities)
      setToggled(false)
      setToggleString("Show Graph")
    } else {
      setCommodities([e.target.value])
      setToggled(true)
      setToggleString("Show All")
    }
  }

  function liveData(idx) {
    if (weeklyData.length === 0) {
      const raw = idx === 0 ? 960 : 154
      return {
        raw,
        equivalent: {
          microwaves: Math.round(raw * 0.82),
        },
      }
    }
    const data = weeklyData[idx].data
    const rounded = Math.round(data[data.length - 1].Value)

    return {
      raw: rounded,
      equivalent: {
        microwaves: Math.round(rounded * 0.82),
      },
    }
  }

  return (
    <>
      <div className={styles.key}>
        <ul className={styles.legend}>
          <li>Electricity Demand</li>
          <li>Solar Production</li>
        </ul>
      </div>
      <DemandChart
        weeklyData={weeklyData}
        weeklyOat={weeklyOat}
        commodities={commodities}
        isHC={false}
      />
      <div className={styles.demand_wrap}>
        <div
          className={styles.demand}
          hidden={toggled && commodities[0] === "solar"}
        >
          <h3>Electricity Demand</h3>
          <p>
            Current rate of consumption <strong>{liveData(0).raw} kW</strong>
            <br />
            <span>
              Equivalent to running {liveData(0).equivalent.microwaves} microwaves.
            </span>
          </p>
          <button
            className={
              toggled ? `${styles.even} ${styles.green}` : styles.green
            }
            onClick={toggleGraph}
            value="electricity"
          >
            {toggleString}{" "}
            <img src={elecImage} alt="electricity img" hidden={toggled} />
          </button>
        </div>
        <div
          className={styles.demand}
          hidden={toggled && commodities[0] === "electricity"}
        >
          <h3>Solar Production</h3>
          <p>
            Current rate of production <strong>{liveData(1).raw} kW</strong>
            <br />
            <span>
              Equivalent to running {liveData(1).equivalent.microwaves}{" "}
              microwaves.
            </span>
          </p>
          <button
            className={
              toggled ? `${styles.even} ${styles.yellow}` : styles.yellow
            }
            onClick={toggleGraph}
            value="solar"
          >
            {toggleString}{" "}
            <img src={solarImage} alt="solar img" hidden={toggled} />
          </button>
        </div>
      </div>
    </>
  )
}
