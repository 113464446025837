import React from "react";
import { CSSTransition } from "react-transition-group";

const symbol = open => {
  if (!open) return <span className="ewc-toggle-symbol plus">+</span>;
  return <span className="ewc-toggle-symbol minus">-</span>;
};

const AboutChallenge = ({ expanded, toggle }) => {
  return (
    <div className="ewc-about-toggle">
      <div
        className="ewc-about-toggle-header"
        onClick={e => toggle("aboutChallenge")}
      >
        {symbol(expanded)} More About the Challenge
      </div>
      <CSSTransition
        in={expanded}
        timeout={300}
        classNames="ewc-about-toggle-details"
        unmountOnExit
      >
        <div className="ewc-about-toggle-expanded">
          <p>
            The Energy & Water Challenge is a partnership between UC Davis
            Student Housing and Facilities Energy & Engineering. This annual
            competition started in 2016; to see information about this year’s
            challenge visit{" "}
            <a
              href="https://housing.ucdavis.edu/sustainability/energy-water-challenge/"
              target="_blank"
            >
              https://housing.ucdavis.edu/sustainability/energy-water-challenge/
            </a>
            .
          </p>
        </div>
      </CSSTransition>
    </div>
  );
};

export default AboutChallenge;
