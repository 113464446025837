export const waterGoalQuery = `
query {
  waterGoalData : waterData(
    startYear: "2005"
  ) {
    year
    usage {
      normalizedDomesticWaterUsage
      normalizedUtilityWaterUsage
    }
  }
}
`

export const waterUsageQuery = `
query {
  waterUsageData : waterData(
    startYear: "2019"
  ) {
    year
    monthlyUsage {
      Jan {
        agriculture
        fisheries
        domestic 
        utility 
      },
      Feb {
        agriculture
        fisheries
        domestic 
        utility 
      }
      Mar {
        agriculture
        fisheries
        domestic 
        utility 
      }
      Apr {
        agriculture
        fisheries
        domestic 
        utility 
      }
      May {
        agriculture
        fisheries
        domestic 
        utility 
      }
      Jun {
        agriculture
        fisheries
        domestic 
        utility 
      }
      Jul {
        agriculture
        fisheries
        domestic 
        utility 
      }
      Aug {
        agriculture
        fisheries
        domestic 
        utility 
      }
      Sep {
        agriculture
        fisheries
        domestic 
        utility 
      }
      Oct {
        agriculture
        fisheries
        domestic 
        utility 
      }
      Nov {
        agriculture
        fisheries
        domestic 
        utility 
      }
      Dec {
        agriculture
        fisheries
        domestic 
        utility 
      }
    }
  }
}
`
