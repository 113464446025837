import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { buildingProjectTabs } from "../../../common/config";
import headshot from "../images/nico.png";
import { formatDollarValue } from "../../../common/utilities";

const NUMBER_OF_BUILDINGS = 8;

class OpportunitiesLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeData: [],
      otherSavings: 0
    };
  }

  componentDidMount() {
    if (this.props.opportunities.length > 0) this.setDataPoints();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.opportunities.length !== this.props.opportunities.length &&
      this.props.opportunities.length > 0
    )
      this.setDataPoints();
  }

  setDataPoints() {
    let activeData = [],
      otherSavings = 0;
    for (let i = 0; i < this.props.opportunities.length; i++) {
      if (i < NUMBER_OF_BUILDINGS) activeData.push(this.props.opportunities[i]);
      else otherSavings += this.props.opportunities[i].savings;
    }
    this.setState({
      activeData,
      otherSavings
    });
  }

  render() {
    return (
      <div className="opportunities-log standard-card">
        <div className="buildComm-info-wrap">
          <div className="buildComm-description">
            {buildingProjectTabs.opportunities.description}
          </div>
          <div className="img-quote-wrap">
            <img src={headshot} alt="nico's headshot" />
            <div className="buildComm-quote nico">
              <i>"{buildingProjectTabs.opportunities.quote}"</i>
              <div className="buildComm-name">
                <i>
                  <b>- {buildingProjectTabs.opportunities.name}</b>
                </i>
              </div>
            </div>
          </div>
        </div>
        <div className="opportunities-table">
          <div className="opportunities-table-header">
            <div
              className="opportunities-table-content"
              style={{ width: "20%" }}
            >
              Building
            </div>
            <div
              className="opportunities-table-content"
              style={{ width: "20%" }}
            >
              Total Savings
            </div>
            <div
              className="opportunities-table-content"
              style={{ width: "58%" }}
            >
              Summary
            </div>
          </div>
          <div className="opportunities-table-data">
            {this.state.activeData.map((item, index) => {
              return (
                <div className="opportunities-data-row" key={index}>
                  <div
                    className="opportunities-table-content"
                    style={{ width: "20%" }}
                  >
                    {item.buildingName}
                  </div>
                  <div
                    className="opportunities-table-content"
                    style={{ width: "20%" }}
                  >
                    {formatDollarValue(item.savings)}
                  </div>
                  <div
                    className="opportunities-table-content"
                    style={{ width: "58%" }}
                  >
                    {item.description}
                  </div>
                </div>
              );
            })}
            <div className="opportunities-data-row">
              <div
                className="opportunities-table-content"
                style={{ width: "20%" }}
              >
                Other Buildings
              </div>
              <div
                className="opportunities-table-content"
                style={{ width: "20%" }}
              >
                {formatDollarValue(this.state.otherSavings)}
              </div>
              <div
                className="opportunities-table-content"
                style={{ width: "58%" }}
              />
            </div>
            <div className="opportunities-table-header">
              <div
                className="opportunities-table-content"
                style={{ width: "20%", fontWeight: 600 }}
              >
                Totals
              </div>
              <div
                className="opportunities-table-content"
                style={{ width: "20%", fontWeight: 600 }}
              >
                {formatDollarValue(this.props.opportunitiesTotal)}
              </div>
              <div
                className="opportunities-table-content"
                style={{ width: "58%" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OpportunitiesLog.propTypes = {
  opportunities: PropTypes.array.isRequired,
  opportunitiesTotal: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  return {
    opportunities: state.buildingProjects.opportunitiesLog,
    opportunitiesTotal: state.buildingProjects.totals.opportunitiesLog
  };
};

export default connect(mapStateToProps)(OpportunitiesLog);
