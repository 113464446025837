/**
 * GraphQL Query format for searchData in the Redux store.
 */

export const mapSearchQuery = `
query {
	buildingSearch:facLinkBuildings{
		officialName
		primaryDisplay
		buildingData {
		  id
		  name
			alias
		  buildingType
		  bldgKey
		  center {
			  lat
			  long
		  }      
		}
	}
}`;
