import * as types from "../actionTypes";
import { createApolloFetch } from "apollo-fetch";
import * as chcpQueries from "../../queries/chcpQuery";
import * as chcpUtils from "./chcpUtilities";

const uri = "/api/graphql";
const apolloFetch = createApolloFetch({ uri });

const COMMODITY_FORMATTING = {
  boilerEfficiency: {
    totalEfficiency: [],
    boiler1Metrics: [],
    boiler2Metrics: [],
    boiler3Metrics: [],
    boiler4Metrics: []
  },
  coolingEfficiency: {
    totalChcpEfficiency: [],
    chcpEfficiency: [],
    tesEfficiency: []
  },
  tower: {
    domestic: [],
    recycled: []
  },
  deltaT: {
    total: []
  }
};

const ANNUAL_METRICS_TOTAL_TAGS = [
    "Boiler_Plant.Efficiency",
    "Chiller_Plants.Efficiency",
    "Chiller_Plants.Annual_Tower_Blowdown",
    "Chiller_Plants.Chilled_Water_delta_T"
  ],
  DEMAND_TAGS = {
    boilerEfficiency: [
      "Boiler_Plant.Efficiency",
      "Boiler_1.Efficiency",
      "Boiler_2.Efficiency",
      "Boiler_3.Efficiency",
      "Boiler_4.Efficiency"
    ],
    coolingEfficiency: [
      "Chiller_Plants.Efficiency",
      "CHCP.Chiller_Plant_Efficiency",
      "TES.Chiller_Plant_Efficiency"
    ],
    deltaT: [
      "Chiller_Plants.Chilled_Water_delta_T",
      "CHCP.Chilled_Water_delta_T",
      "TES.Chilled_Water_delta_T"
    ]
  },
  HISTORIC_TAGS = {
    boilerEfficiency: [
      "Boiler_1.Monthly_Efficiency",
      "Boiler_2.Monthly_Efficiency",
      "Boiler_3.Monthly_Efficiency",
      "Boiler_4.Monthly_Efficiency",
      "Boiler_Plant.Monthly_Efficiency"
    ],
    coolingEfficiency: ["Chiller_Plants.Monthly_Efficiency"],
    tower: [
      "Chiller_Plants.Monthly_Tower_Domestic_Water_Use",
      "Chiller_Plants.Monthly_Tower_Recycled_Water_Use"
    ],
    deltaT: ["Chiller_Plants.Monthly_Chilled_Water_delta_T"]
  };

const chcpMetricsSuccess = chcpMetrics => {
  return {
    type: types.LOAD_CHCP_METRICS_SUCCESS,
    chcpMetrics
  };
};

export const loadMetricsTotal = () => {
  let dates = chcpUtils.getDateFormats();
  return function(dispatch) {
    apolloFetch({
      query: chcpQueries.metricsTotal,
      variables: {
        currentTime: dates.currentMonth,
        metricsStart: dates.lastYearMonth,
        annualTags: ANNUAL_METRICS_TOTAL_TAGS
      }
    }).then(async chcpMetricsData => {
      if (chcpMetricsData.data !== undefined) {
        let value = await splitTotalData(chcpMetricsData.data.totalAnnual),
          boilerEfficiency = {
            value: value["boilerEfficiency"]
          },
          coolingEfficiency = {
            value: value["coolingEfficiency"]
          },
          tower = {
            value: value["tower"]
          },
          deltaT = {
            value: value["deltaT"]
          };
        dispatch(
          chcpMetricsSuccess({
            totalMetrics: {
              boilerEfficiency,
              coolingEfficiency,
              tower,
              deltaT
            }
          })
        );
      } else
        console.log("A Metrics data error occurred. Please refresh your page.");
    });
  };
};

export const loadMetricsRealTime = dataSet => {
  let dates = chcpUtils.getDateFormats(),
    variables = {
      currentTime: dates.currentTime,
      metricsStart: dates.tagStart,
      dailyStart: dates.yesterday,
      weeklyStart: dates.weekAgo,
      monthlyStart: dates.monthAgo,
      demandTags: DEMAND_TAGS[dataSet],
      usageTags: HISTORIC_TAGS[dataSet]
    };
  return function(dispatch) {
    let daily = processDemandQuery(
        { query: chcpQueries.metricsDaily, variables },
        "daily",
        dataSet
      ),
      weekly = processDemandQuery(
        { query: chcpQueries.metricsWeekly, variables },
        "weekly",
        dataSet
      ),
      monthly = processDemandQuery(
        { query: chcpQueries.metricsMonthly, variables },
        "monthly",
        dataSet
      ),
      historic = processMetricsQuery(
        { query: chcpQueries.metricsHistoric, variables },
        "historic",
        dataSet
      );
    Promise.all([daily, weekly, monthly, historic]).then(values => {
      let finalData = {
        daily: {},
        weekly: {},
        monthly: {},
        historic: {}
      };
      for (let finalKey in finalData)
        for (let i = 0; i < values.length; i++)
          if (finalKey === values[i].label) {
            finalData[finalKey] = values[i].data;
            finalData[finalKey].commodities = [];
            for (let commodity in values[i].data)
              if (commodity !== "commodities" && commodity !== "years")
                finalData[finalKey].commodities.push(commodity);
          }
      if (dataSet === "boilerEfficiency")
        finalData.historic = Object.assign(
          {},
          scrubKey(finalData.historic, "boiler")
        );
      dispatch(
        chcpMetricsSuccess({
          [dataSet + "Metrics"]: finalData
        })
      );
    });
  };
};

export const loadMetricsWaterTower = () => {
  let dates = chcpUtils.getDateFormats(),
    variables = {
      currentTime: dates.currentTime,
      metricsStart: dates.tagStart,
      usageTags: HISTORIC_TAGS["tower"]
    };
  return function(dispatch) {
    processMetricsQuery(
      { query: chcpQueries.metricsHistoric, variables },
      "waterTower",
      "tower"
    ).then(values => {
      if (values.data.label) delete values.data.label;
      values.data.commodities = [];
      for (let commodity in values.data)
        if (commodity !== "commodities" && commodity !== "years")
          values.data.commodities.push(commodity);
      let finalData = {
        historic: values.data
      };
      dispatch(
        chcpMetricsSuccess({
          towerMetrics: finalData
        })
      );
    });
  };
};

const processDemandQuery = async (query, label, dataSet) => {
  return new Promise((resolve, reject) => {
    apolloFetch(query).then(async chcpDemandData => {
      let formatData = Object.assign({}, COMMODITY_FORMATTING[dataSet]),
        rawDemandData = [],
        demandMetricsTags = DEMAND_TAGS[dataSet];
      for (let key in chcpDemandData.data)
        rawDemandData = chcpDemandData.data[key];
      for (let dataKey in formatData) {
        let index = Object.keys(formatData).indexOf(dataKey);
        for (let i = 0; i < rawDemandData.length; i++)
          if (
            rawDemandData[i].commodity.indexOf(demandMetricsTags[index]) !== -1
          )
            formatData[dataKey] = rawDemandData[i].data;
      }
      resolve({ label, data: formatData });
    });
  });
};

const processMetricsQuery = async (query, label, dataSet) => {
  return new Promise((resolve, reject) => {
    apolloFetch(query).then(async chcpMetricsData => {
      let formatData = Object.assign({}, COMMODITY_FORMATTING[dataSet]),
        rawMetricsData = [],
        historicMetricsTags = HISTORIC_TAGS[dataSet];
      if (dataSet === "coolingEfficiency") {
        delete formatData.chcpEfficiency;
        delete formatData.tesEfficiency;
      } else if (dataSet === "deltaT") {
        delete formatData.chcpDelta;
        delete formatData.tesDelta;
      }
      for (let key in chcpMetricsData.data)
        rawMetricsData = chcpMetricsData.data[key];
      for (let dataKey in formatData) {
        let index = Object.keys(formatData).indexOf(dataKey);
        for (let i = 0; i < rawMetricsData.length; i++)
          if (
            rawMetricsData[i].commodity.indexOf(historicMetricsTags[index]) !==
            -1
          )
            formatData[dataKey] = rawMetricsData[i].data;
      }
      let yearSet = false;
      for (let yearKey in formatData)
        if (!yearSet && formatData[yearKey].length > 0) {
          formatData.years = chcpUtils.processYears(formatData[yearKey]);
          yearSet = true;
        }
      resolve({ label, data: formatData });
    });
  });
};

const scrubKey = (dataObject, removeKeyTerm) => {
  for (let key in dataObject)
    if (key.indexOf(removeKeyTerm) !== -1) delete dataObject[key];
  dataObject.commodities = dataObject.commodities.filter(value => {
    return value.indexOf(removeKeyTerm) === -1;
  });
  return dataObject;
};

const splitTotalData = totalData => {
  let tempBoiler = 0,
    tempCoolingEfficiency = 0,
    tempTower = 0,
    tempDelta = 0;
  for (let i = 0; i < totalData.length; i++) {
    for (let j = 0; j < totalData[i].data.length; j++) {
      if (totalData[i].commodity.toLowerCase().indexOf("boiler") !== -1)
        tempBoiler = totalData[i].data[j].Value;
      else if (
        totalData[i].commodity.toLowerCase().indexOf("efficiency") !== -1
      )
        tempCoolingEfficiency = totalData[i].data[j].Value;
      else if (totalData[i].commodity.toLowerCase().indexOf("tower") !== -1)
        tempTower = totalData[i].data[j].Value;
      else if (totalData[i].commodity.toLowerCase().indexOf("delta") !== -1)
        tempDelta = totalData[i].data[j].Value;
    }
  }
  return {
    boilerEfficiency: Math.round(tempBoiler),
    coolingEfficiency:
      tempCoolingEfficiency !== null ? tempCoolingEfficiency.toFixed(2) : 0,
    tower: Math.round(tempTower),
    deltaT: tempDelta !== null ? Math.round(tempDelta) : 0
  };
};
