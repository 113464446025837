import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AppsMenu from "./appsMenu/AppsMenu";
import { menuOptions } from "../../../common/config";

import "./AppsPage.css";

const MENU_OPTIONS = ["totalCampus", "chcp", "dataDownload", "savings", "ewc", "water"];

class AppsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: null
    };
  }

  render() {
    return (
      <div className={this.setAppsClassOnSlideout(this.props.sidebar.apps)}>
        <AppsMenu />
        <div className="apps-content">
          <div className="apps-menu">
            <div className="apps-menu-current">
              <img
                src={menuOptions["ceed"].icon}
                alt={menuOptions["ceed"].alt}
              />
              {menuOptions["ceed"].displayName}
            </div>
            {MENU_OPTIONS.map((item, index) => {
              return (
                <a
                  href={menuOptions[item].link}
                  rel="noopener noreferrer"
                  key={index}
                >
                  <div className="apps-menu-item">
                    <img
                      src={menuOptions[item].icon}
                      alt={menuOptions[item].alt}
                    />
                    {menuOptions[item].displayName}
                  </div>
                </a>
              );
            })}
          </div>
          <div className="apps-menu-email">
            <a href="mailto:energyfeedback@ucdavis.edu">
              You can contact our team at
              <br />
              energyfeedback@ucdavis.edu
            </a>
          </div>
        </div>
      </div>
    );
  }

  setAppsClassOnSlideout = classCheck => {
    if (!classCheck) return "apps-page hide";
    return "apps-page";
  };
}

AppsPage.propTypes = {
  sidebar: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    sidebar: state.sidebar
  };
}

export default connect(mapStateToProps)(AppsPage);
