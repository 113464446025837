/**
 * HighCharts utilities that are used to build the demand HighChart.
 * These functions set up the default parameters along with some
 * helper functions for formatting the visual elements of the chart.
 */

import { chcpEnergyTypes, chcpDisplayNames } from "./config";
import {
  getDailyLabels,
  getWeeklyLabels,
  getMonthlyLabels
} from "../../common/axisLabels";

import Highcharts from "highcharts";

//    _   _ _     _             _
//   | | | (_)___| |_ ___  _ __(_) ___
//   | |_| | / __| __/ _ \| '__| |/ __|
//   |  _  | \__ \ || (_) | |  | | (__
//   |_| |_|_|___/\__\___/|_|  |_|\___|
//

export const initializeHistoricGraph = (seriesData, yearSelection) => {
  let suffix = "";
  if (seriesData.length > 0)
    if (
      seriesData[0].valueSuffix === "° F" ||
      seriesData[0].valueSuffix === "%"
    )
      suffix = seriesData[0].valueSuffix;
    else suffix = " " + seriesData[0].valueSuffix;
  return {
    chart: {
      type: "column",
      plotBorderColor: "#F1F1F2",
      plotBorderWidth: 2,
      height: 410
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    xAxis: setHistoricXAxis(seriesData),
    yAxis: setYAxis(suffix),
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true
          }
        },
        animation: {
          duration: 2000,
          easing: "easeOutBounce"
        },
        stacking: "normal"
      }
    },
    tooltip: {
      backgroundColor: "#F6F6F6",
      borderColor: "#EEEDEE",
      borderRadius: 3,
      borderWidth: 1.5,
      shadow: true,
      crosshairs: true,
      pointFormat:
        '<span style="background-color:{series.color}; font-weight:700; font-size:10px; line-height:18px; vertical-align:top; max-width: 12px; max-height: 12px; border:2px solid white; border-radius: 10px;">&nbsp;&nbsp;&nbsp;&nbsp;</span> <span style="font-size:13px; line-height:20px; vertical-align:bottom">{series.name}: <b>{point.y}</b>' +
        suffix +
        "<br/></span>",
      shared: true,
      useHTML: true
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: seriesData,
    visible: true
  };
};

// Sets the parameters for each of the commodities. This includes
// color, tooltip information, and visibility status.
export const setHistoricSeries = (
  data,
  visibility,
  yearSelection,
  decimals
) => {
  let commodities = data.commodities;
  return new Promise((resolve, reject) => {
    let tempSeries = [];
    for (let key in data)
      for (let i = 0; i < commodities.length; i++)
        if (key === commodities[i]) {
          if (Array.isArray(data[key])) {
            tempSeries.push({
              name: chcpEnergyTypes[key].displayName,
              id: key,
              data: formatHistoricData(data[key], yearSelection),
              tooltip: {
                valueDecimals: decimals || 0
              },
              yAxis: 0,
              borderColor: chcpEnergyTypes[key].color,
              borderWidth: 1,
              color: chcpEnergyTypes[key].opacityColor,
              visible: visibility[key],
              valueSuffix: chcpEnergyTypes[key].unit,
              states: {
                hover: {
                  color: chcpEnergyTypes[key].color
                }
              }
            });
          } else {
            for (let innerKey in data[key])
              tempSeries.push({
                name: chcpDisplayNames(key)[innerKey],
                id: key + "-" + innerKey,
                data: formatHistoricData(data[key][innerKey], yearSelection),
                tooltip: {
                  valueDecimals: 0
                },
                yAxis: 0,
                borderColor: chcpEnergyTypes[key].color,
                borderWidth: 1,
                color: chcpEnergyTypes[key].opacityColor,
                visible: visibility[key],
                valueSuffix: chcpEnergyTypes[key].unit,
                states: {
                  hover: {
                    color: chcpEnergyTypes[key].color
                  }
                }
              });
          }
        }
    resolve(tempSeries);
  });
};

// Sets the data to values and associated dates for Highcharts
// to parse and display appropriately.
const formatHistoricData = (dataArray, year) => {
  let formattedArray = [],
    arrayLength = dataArray.length;
  if (year === "Past 12 Months") {
    for (let i = 1; i < 13; i++) {
      if (dataArray[arrayLength - i].Value > 0)
        formattedArray.push([
          dataArray[arrayLength - i].Month,
          dataArray[arrayLength - i].Value
        ]);
      else formattedArray.push([dataArray[arrayLength - i].Month, 0]);
    }
    formattedArray.reverse();
  } else {
    for (let i = 0; i < arrayLength; i++) {
      if (dataArray[i].Month.indexOf(year) !== -1) {
        if (dataArray[i].Value > 0)
          formattedArray.push([dataArray[i].Month, dataArray[i].Value]);
        else formattedArray.push([dataArray[i].Month, 0]);
      }
    }
  }
  return formattedArray;
};

// Sets the X Axis parameters for the Historic Chart.
export const setHistoricXAxis = series => {
  return [
    {
      tickInterval: 1,
      minPadding: 0,
      maxPadding: 0,
      labels: {
        style: {
          color: "#444952",
          fontSize: 13,
          textTransform: "capitalize",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        },
        padding: 0,
        y: 25,
        formatter: function() {
          if (series.length !== 0)
            return series[0].data[this.value][0].slice(0, 3);
          return "";
        }
      }
    }
  ];
};

//    ____            _     _____ _
//   |  _ \ ___  __ _| |   |_   _(_)_ __ ___   ___
//   | |_) / _ \/ _` | |_____| | | | '_ ` _ \ / _ \
//   |  _ <  __/ (_| | |_____| | | | | | | | |  __/
//   |_| \_\___|\__,_|_|     |_| |_|_| |_| |_|\___|
//

// Established the default graph parameters.
export const initializeRealTimeGraph = (seriesData, lengthOfTime) => {
  let suffix = "";
  if (seriesData.length > 0)
    if (
      seriesData[0].valueSuffix === "° F" ||
      seriesData[0].valueSuffix === "%"
    )
      suffix = seriesData[0].valueSuffix;
    else suffix = " " + seriesData[0].valueSuffix;
  return {
    chart: {
      zoomType: "x",
      type: "areaspline",
      plotBorderColor: "#F1F1F2",
      plotBorderWidth: 2,
      height: 410
    },
    title: {
      text: null
    },
    xAxis: setXAxis(lengthOfTime),
    yAxis: setYAxis(suffix),
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        },
        animation: {
          duration: 2000,
          easing: "easeOutBounce"
        }
      }
    },
    tooltip: {
      backgroundColor: "#F6F6F6",
      borderColor: "#EEEDEE",
      borderRadius: 3,
      borderWidth: 1.5,
      shadow: true,
      crosshairs: true,
      headerFormat:
        '<span style="font-size: 14px; font-weight: 100; color: #2D3138; opacity: 0.5; lineHeight: 17px;">{point.key}</span><table><br/>',
      pointFormat:
        '<span style="background-color:{series.color}; font-weight:700; font-size:10px; line-height:18px; vertical-align:top; max-width: 12px; max-height: 12px; border:2px solid white; border-radius: 10px;">&nbsp;&nbsp;&nbsp;&nbsp;</span> <span style="font-size:13px; line-height:20px; vertical-align:bottom">{series.name}: <b>{point.y}</b>' +
        suffix +
        "<br/></span>",
      shared: true,
      xDateFormat: "%A, %b %e, %l:%M%P",
      useHTML: true
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: seriesData,
    visible: true
  };
};

// Sets the parameters for each of the commodities. This includes
// color, tooltip information, and visibility status.
export const buildBaseCommodities = async (data, visibility, decimals) => {
  let commodities = data.commodities;
  return new Promise((resolve, reject) => {
    let tempSeries = [];
    for (let key in data)
      for (let i = 0; i < commodities.length; i++)
        if (key === commodities[i])
          if (Array.isArray(data[key])) {
            tempSeries.push({
              name: chcpEnergyTypes[key].displayName,
              id: key,
              data: formatData(data[key]),
              marker: {
                enabled: false
              },
              tooltip: {
                valueDecimals: decimals || 0
              },
              yAxis: 0,
              color:
                key.toLowerCase().indexOf("total") !== -1
                  ? chcpEnergyTypes[key].color
                  : chcpEnergyTypes[key].subcolor,
              fillColor: "transparent",
              visible: visibility[key],
              valueSuffix: chcpEnergyTypes[key].unit,
              zIndex: key.indexOf("total") > -1 ? 2 : 1
            });
          } else {
            for (let innerKey in data[key])
              tempSeries.push({
                name: chcpDisplayNames(key)[innerKey],
                id: key + "-" + innerKey,
                data: formatData(data[key][innerKey]),
                marker: {
                  enabled: false
                },
                tooltip: {
                  valueDecimals: decimals || 0
                },
                yAxis: 0,
                color:
                  innerKey === "total"
                    ? chcpEnergyTypes[key].color
                    : chcpEnergyTypes[key].subcolor,
                zIndex: innerKey === "total" ? 2 : 1,
                fillColor: "transparent",
                visible: visibility[key],
                valueSuffix: chcpEnergyTypes[key].unit
              });
          }
    resolve(tempSeries);
  });
};

// Sets the X Axis parameters for the Demand Chart. In particular,
// it sets different label types depending on what time interval is selected
export const setXAxis = currentDemandSelection => {
  return [
    {
      scrollbar: {
        enabled: true,
        showFull: false
      },
      type: "datetime",
      minPadding: 0,
      maxPadding: 0,
      labels: {
        style: {
          color: "#444952",
          fontSize: 13,
          textTransform: "capitalize",
          fontFamily: [
            "Proxima Nova",
            "Lucida Grande",
            "Lucida Sans",
            "Verdana",
            "sans-serif"
          ]
        },
        padding: 0,
        y: 25,
        formatter: function() {
          let returnValue = "";
          switch (currentDemandSelection) {
            case "daily":
              returnValue = getDailyLabels(this.value);
              break;
            case "weekly":
              returnValue = getWeeklyLabels(this.value);
              break;
            case "monthly":
              returnValue = getMonthlyLabels(this.value);
              break;
            default:
              returnValue = "";
              break;
          }
          return returnValue;
        }
      }
    }
  ];
};

// Sets the Y Axis parameters for the Demand chart. This chart contains
// a left axis[0] (data) and right axis[1] (Outside Air Temperature).
export const setYAxis = units => {
  return {
    min: 0,
    lineWidth: 2,
    gridLineWidth: 2,
    title: {
      text: units,
      align: "high",
      rotation: 0,
      offset: 15,
      style: {
        color: "#444952",
        fontSize: 13,
        fontWeight: "bold",
        fontFamily: [
          "Proxima Nova",
          "Lucida Grande",
          "Lucida Sans",
          "Verdana",
          "sans-serif"
        ]
      }
    },
    lineColor: "#F1F1F2",
    gridLineColor: "#F1F1F2",
    minPadding: 0,
    maxPadding: 0,
    opposite: false,
    tickAmount: 5,
    labels: {
      formatter: function() {
        if (this.isLast) {
          return "";
        }
        let label = this.axis.defaultLabelFormatter.call(this);
        if (/^[0-9]{4}$/.test(label)) {
          return Highcharts.numberFormat(this.value, 0);
        }
        return label;
      },
      style: {
        color: "#444952",
        fontFamily: [
          "Proxima Nova",
          "Lucida Grande",
          "Lucida Sans",
          "Verdana",
          "sans-serif"
        ],
        fontSize: 13
      },
      reserveSpace: true
    },
    endOnTick: false
  };
};

// Sets the data to values and associated dates for Highcharts
// to parse and display appropriately.
const formatData = dataArray => {
  let formattedArray = [];
  for (let i = 0; i < dataArray.length; i++) {
    let newDate = new Date(dataArray[i].Timestamp);
    if (dataArray[i].Value > 0)
      formattedArray.push([Date.parse(newDate), dataArray[i].Value]);
    else formattedArray.push([Date.parse(newDate), 0]);
  }
  return formattedArray;
};
