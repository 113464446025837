import React from "react";
import ModalTiles from "../modalComponents/ModalTiles";

export default function ModalPage4({gifs, setInfoPage}) {
  return (
    <div className="modal-content-column">
      <div className="modal-content-wrapper">
        <div className="modal-content-subheadline">
          What can <i>you</i> do on CEED?
          <div>
          click the tiles to see more information.
          </div>
        </div>
        <ModalTiles gifs={gifs} setInfoPage={setInfoPage} />
      </div>
    </div>
  )
}