import electricityIcon from "./images/electric.svg";
import chilledWaterIcon from "./images/chilledwater.svg";
import domesticWaterIcon from "./images/domesticWater.svg";
import steamIcon from "./images/steam.svg";
import naturalGasIcon from "./images/naturalGas.svg";
import heatingNaturalGasIcon from "./images/heatingNaturalGas.svg";
import solarIcon from "./images/solar.svg";

export const commodityProperties = {
  electricity: {
    displayName: "Electricity",
    image: electricityIcon,
    alt: "Electricity commodity",
    topText: "Electricity",
    bottomText: "",
    units: "kW"
  },
  steam: {
    displayName: "Steam",
    image: steamIcon,
    alt: "Steam commodity",
    topText: "Heating",
    bottomText: "Steam",
    units: "lb/h"
  },
  chilledWater: {
    displayName: "Chilled Water",
    image: chilledWaterIcon,
    alt: "Chilled Water commodity",
    topText: "Cooling",
    bottomText: "Chilled Water",
    units: "ton"
  },
  gas: {
    displayName: "Natural Gas",
    image: naturalGasIcon,
    alt: "Natural Gas commodity",
    topText: "Natural",
    bottomText: "Gas",
    units: "US gal/min"
  },
  solar: {
    displayName: "Solar Electricity",
    image: solarIcon,
    alt: "Solar Electricity commodity",
    topText: "Solar",
    bottomText: "Electricity",
    units: "kW"
  },
  domesticWater: {
    displayName: "Domestic Water",
    image: domesticWaterIcon,
    alt: "Domestic Water commodity",
    topText: "Domestic",
    bottomText: "Water",
    units: "US gal/min"
  },
  heatingGas: {
    displayName: "Heating Natural Gas",
    image: heatingNaturalGasIcon,
    alt: "Heating Natural Gas commodity",
    topText: "Heating",
    bottomText: "Natural Gas",
    units: "cubic ft/min"
  }
};

export const downloadBuildingTypes = {
  showAll: "Show All",
  office: "Office",
  lab: "Lab",
  housing: "Housing",
  classroom: "Classroom",
  community: "Community",
  null: "Not Categorized"
};

export const helpHovers = {
  usage:
    "Usage data will give you energy use over a period of time. Usage data is currently only available at monthly intervals for chilled water, electricity, heating natural gas, and steam in metered units.",
  demand:
    "Demand data is the rate at which energy is consumed. Demand data is only available in kBtu for chilled water, electricity, heating natural gas, and steam. Other commodities will return metered values.",
  meter:
    "The meter units depend on the commodity. For example, electricity is measured in kW or kWh.",
  kbtu:
    "Measured commodities are converted into kBtu in order to sum several commodities together."
};
