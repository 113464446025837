import React from "react";

const MobileCampusDescription = () => {
  return (
    <div className="campus-header-description">
      <p>
        How does the campus keep energy use low while maintaining this growth?
        The University has been proactive in diversifying energy sources in
        order to meet the campus demand.
      </p>
    </div>
  );
};

export default MobileCampusDescription;
