import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as buildingActions from "../../actions/buildingActions";

import MediaQuery from "react-responsive";
import { breakPoints } from "../common/config";
import MobileHeader from "./mobile/landing/MobileHeader";
import DesktopHeader from "./desktop/landing/DesktopHeader";
import CommodityToggle from "./common/CommodityToggle";
import EwcRankingTable from "./ewcRankingTable/EwcRankingTable";
import AboutChallenge from "./aboutChallenge/AboutChallenge";
import SavingsCalculation from "./savingsCalculation/SavingsCalculation";
import Logos from "./common/Logos";
import "./Ewc.css";

const DEFAULT_COMMODITY = "electricity";

class Ewc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commodity: DEFAULT_COMMODITY,
      ranking: {
        electricity: [],
        water: []
      },
      expandOptions: {
        aboutChallenge: false,
        savingsCalculation: false
      }
    };
    this.switchCommodity = this.switchCommodity.bind(this);
    this.expandOption = this.expandOption.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadEwcRankingData();
    if (this.props.ranking.length > 0) this.setRankings();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.ranking) !== JSON.stringify(prevProps.ranking)
    )
      this.setRankings();
  }

  expandOption(option) {
    let optionState = {
      aboutChallenge: false,
      savingsCalculation: false
    };
    if (!this.state.expandOptions[option]) optionState[option] = true;
    this.setState({
      expandOptions: optionState
    });
  }

  setRankings() {
    let electricity = [],
      water = [];
    for (let i = 0; i < this.props.ranking.length; i++) {
      electricity.push(Object.assign({}, this.props.ranking[i]));
      water.push(Object.assign({}, this.props.ranking[i]));
      electricity[i].Value =
        electricity[i].electricityPercent === null
          ? "--"
          : electricity[i].electricityPercent.toFixed(1);
      water[i].Value =
        water[i].domesticWaterPercent === null
          ? "--"
          : water[i].domesticWaterPercent.toFixed(1);
      delete electricity[i].electricityPercent;
      delete electricity[i].domesticWaterPercent;
      delete water[i].electricityPercent;
      delete water[i].domesticWaterPercent;
    }
    electricity.sort((a, b) =>
      a.Value === "--"
        ? 1
        : b.Value === "--"
        ? -1
        : parseFloat(a.Value) < parseFloat(b.Value)
        ? 1
        : -1
    );
    water.sort((a, b) =>
      a.Value === "--"
        ? 1
        : b.Value === "--"
        ? -1
        : parseFloat(a.Value) < parseFloat(b.Value)
        ? 1
        : -1
    );
    this.setState({
      ranking: { electricity, water }
    });
  }

  switchCommodity(event) {
    let commodity = event.target.id;
    if (commodity !== this.state.commodity) this.setState({ commodity });
  }

  render() {
    return (
      <div className="ewc-landing">
        <MediaQuery maxWidth={breakPoints.mobile}>
          <MobileHeader />
        </MediaQuery>
        <MediaQuery minWidth={breakPoints.tablet}>
          <DesktopHeader />
        </MediaQuery>
        <div className="ewc-landing-switch">
          <div className="ewc-landing-switch-title">
            This Week's Ranking
            <div className="ewc-landing-switch-subtitle">
              (see{" "}
              <a
                href="https://housing.ucdavis.edu/sustainability/energy-water-challenge/"
                rel="noopener noreferrer"
              >
                Student Housing's website
              </a>{" "}
              for cumulative ranking)
            </div>
          </div>
          <CommodityToggle
            currentCommodity={this.state.commodity}
            onClick={this.switchCommodity}
          />
        </div>
        <EwcRankingTable tableData={this.state.ranking[this.state.commodity]} />
        <div className="ewc-landing-footer">
          <div className="ewc-landing-footer-raffle">
            <AboutChallenge
              expanded={this.state.expandOptions.aboutChallenge}
              toggle={this.expandOption}
            />
            <SavingsCalculation
              expanded={this.state.expandOptions.savingsCalculation}
              toggle={this.expandOption}
            />
          </div>
          <Logos />
        </div>
      </div>
    );
  }
}

Ewc.propTypes = {
  actions: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    ranking: state.ewcRanking
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(buildingActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ewc);
