import React from "react";

export default function ModalPage1() {
  return (
    <div className="modal-content-column">
      <div className="modal-content-wrapper">
        <div className="modal-content-headline">
          Welcome to UC Davis'<br />
          <b>
            Campus Energy Education Dashboard
          </b> <br />
          <div className="modal-content-normal">
            (or <b>CEED</b> for short.)
          </div>
        </div>
        <p>
          We all play a part in helping UC Davis reach carbon neutrality by
          2025. At the Energy Conservation Office we believe{" "}
          <b>the first step to saving energy is seeing how much you use.</b>
        </p>
      </div>
    </div>
  )
}