import React from "react";
import PropTypes from "prop-types";
import buildingCommIcon from "../images/Commissioning.svg";
import opportunitiesIcon from "../images/Opportunities.svg";
import hvacIcon from "../images/HVAC-Scheduling.svg";
import holidayIcon from "../images/HolidayShutdowns.svg";
import { formatDollarValue } from "../../../common/utilities";
import "./TabNavigation.css";

const TabNavigation = ({
  tabSelection,
  onClick,
  buildingCommTotal,
  totals
}) => {
  const tabNavigationClass = property => {
    let baseClass = "tab-navigation-button " + property;
    if (property === tabSelection) return baseClass + " active";
    return baseClass;
  };
  let FYTotals = {};
  for (let i = 0; i < totals.currentFYtotals.mnvTotals.mnvTotal.length; i++) {
    let CommodityWord = totals.currentFYtotals.mnvTotals.mnvTotal[i].commodity;
    let Commodity = CommodityWord.split(" ")[0];
    FYTotals[Commodity] =
      totals.currentFYtotals.mnvTotals.mnvTotal[i].data.pastFY;
  }
  return (
    <React.Fragment>
      <div className="tab-wrap">
        <div
          className={tabNavigationClass("buildingCommissioning")}
          id="buildingCommissioning"
          onClick={onClick}
        >
          <img
            src={buildingCommIcon}
            alt="Building Commissioning icon"
            id="buildingCommissioning"
          />
          <span className="tab-title" id="buildingCommissioning">
            Building Commissioning
          </span>
          <span className="tab-FY">Savings for FY{FYTotals.Building.fy}: </span>
          <b id="buildingCommissioning">
            {formatDollarValue(FYTotals.Building.value)}
          </b>
        </div>
        <div
          className={tabNavigationClass("opportunitiesLog")}
          id="opportunitiesLog"
          onClick={onClick}
        >
          <img
            src={opportunitiesIcon}
            alt="Building Commissioning icon"
            id="opportunitiesLog"
          />
          <span className="tab-title" id="opportunitiesLog">
            Opportunities Log
          </span>
          <span className="tab-FY">
            Savings for FY{FYTotals.Opportunity.fy}:{" "}
          </span>
          <b id="opportunitiesLog">
            {formatDollarValue(FYTotals.Opportunity.value)}
          </b>
        </div>
        <div className={tabNavigationClass("hvac")} id="hvac" onClick={onClick}>
          <img src={hvacIcon} alt="Building Commissioning icon" id="hvac" />
          <span className="tab-title" id="hvac">
            HVAC Scheduling
          </span>
          <span className="tab-FY">Savings for FY{FYTotals.Holiday.fy}: </span>
          <b id="hvac">{formatDollarValue(FYTotals.Hvac.value)}</b>
        </div>
        <div
          className={tabNavigationClass("holidayShutdowns")}
          id="holidayShutdowns"
          onClick={onClick}
        >
          <img
            src={holidayIcon}
            alt="Building Commissioning icon"
            id="holidayShutdowns"
          />
          <span className="tab-title" id="holidayShutdowns">
            Holiday Shutdowns
          </span>
          <span className="tab-FY">Savings for FY{FYTotals.Holiday.fy}: </span>
          <b id="holidayShutdowns">
            {formatDollarValue(FYTotals.Holiday.value)}
          </b>
        </div>
      </div>
    </React.Fragment>
  );
};

TabNavigation.propTypes = {
  totals: PropTypes.object.isRequired,
  tabSelection: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default TabNavigation;
