/**
 * Actions used for the individual building pages.
 * Collects building data and Pi data for a building
 * then caches it for quick access later. Allows
 * multiple buildings to be cached. Queries are
 * performed asynchronously, and push a new state
 * as soon as queried data is received.
 */

import * as types from "./actionTypes";
import { createApolloFetch } from "apollo-fetch";
import * as graphqlQueries from "../queries/buildingQuery";
import Dexie from "dexie";

const uri = "/api/graphql";
const apolloFetch = createApolloFetch({ uri });
const CACHE_TIMEOUT = 5 * 60 * 1000; //5 Minutes
const QUERIES = [
    "demandDaily",
    "demandWeekly",
    "demandMonthly",
    "singleBuildingEuis",
    "monthlyUsage",
    "allBuildingEuis"
  ],
  RANKING_WEEKS = 12;
const db = new Dexie("cached");
db.version(1).stores({
  objects: "id"
});

// Pushes the data to the store.
const loadBuildingSuccess = building => {
  return {
    type: types.LOAD_BUILDING_SUCCESS,
    building
  };
};

// Pushes the data to the store.
const loadDesktopEnergySuccess = desktopEnergyData => {
  return {
    type: types.LOAD_DESKTOP_ENERGY_SUCCESS,
    desktopEnergyData
  };
};

// Pushes the data to the store.
const loadTLCEnergySuccess = tlcEnergyData => {
  return {
    type: types.LOAD_TLC_ENERGY_SUCCESS,
    tlcEnergyData
  };
};

// Pushes the data to the store.
const loadEwcRankingSuccess = ewcRanking => {
  return {
    type: types.LOAD_EWC_RANKING_SUCCESS,
    ewcRanking
  };
};

// Pushes the data to the store.
const loadEwcWeeklySuccess = ewcWeekly => {
  return {
    type: types.LOAD_EWC_WEEKLY_SUCCESS,
    ewcWeekly
  };
};

// Pushes the data to the store.
export function loadSavingsCalculatorSuccess(savingsCalculator) {
  return {
    type: types.LOAD_SAVINGS_SUCCESS,
    savingsCalculator
  };
}

// Initial call for an individual building's data. Collects the base information
// from the DB, then uses that to query Pi based on queries specified in QUERIES.
export const loadBuildingData = buildingId => {
  return dispatch => {
    apolloFetch({
      query: graphqlQueries.buildingInformation,
      variables: {
        id: buildingId
      }
    }).then(buildingInformation => {
      dispatch(loadBuildingSuccess(buildingInformation.data.information));
    });
  };
};

export const loadDesktopEnergyData = buildingInfo => {
  return dispatch => {
    if (buildingInfo !== null) {
      let timestamp = new Date();
      for (let i = 0; i < QUERIES.length; i++)
        getBuildingPiData(buildingInfo, QUERIES[i], dispatch, timestamp);
    }
  };
};

export const loadTLCEnergyData = buildingInfo => {
  return dispatch => {
    if (buildingInfo !== null) {
      let timestamp = new Date();
      getBuildingPiData(buildingInfo, "demandWeeklyTLC", dispatch, timestamp);
    }
  };
};

export const loadEwcRankingData = () => {
  return dispatch => {
    let id = "all_ranking";
    checkCache(id).then(cachedObject => {
      if (
        cachedObject !== undefined &&
        cachedObject.data !== undefined &&
        cachedObject.timestamp !== undefined &&
        checkCacheDay(cachedObject.timestamp)
      )
        dispatch(loadEwcRankingSuccess(cachedObject.data));
      else {
        apolloFetch({
          query: graphqlQueries.rankingPercents
        }).then(rankingInformation => {
          let information = rankingInformation.data.rankingPercents;
          dispatch(loadEwcRankingSuccess(information));
          if (information !== null) setCache("all", information, "ranking");
        });
      }
    });
  };
};

export const loadEwcRankingWeekly = buildingId => {
  return dispatch => {
    let id = buildingId + "_weekly_ranking";
    checkCache(id).then(cachedObject => {
      if (
        cachedObject !== undefined &&
        cachedObject.data !== undefined &&
        cachedObject.timestamp !== undefined &&
        checkCacheDay(cachedObject.timestamp)
      )
        dispatch(loadEwcWeeklySuccess(cachedObject.data));
      else {
        apolloFetch({
          query: graphqlQueries.rankingWeekly,
          variables: {
            id: buildingId,
            weeks: RANKING_WEEKS
          }
        }).then(weeklyData => {
          let ewcWeekly = weeklyData.data.rankingWeekly[0];
          for (let key in ewcWeekly)
            if (ewcWeekly[key] === null) ewcWeekly[key] = [];
          ewcWeekly.building = buildingId;
          dispatch(loadEwcWeeklySuccess(ewcWeekly));
          if (weeklyData !== null)
            setCache(buildingId, ewcWeekly, "weekly_ranking");
        });
      }
    });
  };
};

// Savings calculator data from the DB
export const loadSavingsCalculatorData = buildingType => {
  return dispatch => {
    apolloFetch({
      query: graphqlQueries.savingsCalculator,
      variables: {
        typeOfBuilding: buildingType
      }
    }).then(savingsCalculatorData => {
      let savings = savingsCalculatorData.data.savingsCalculator;
      dispatch(loadSavingsCalculatorSuccess(savings));
    });
  };
};

// Dispatches from the cache if the data exists, and timestamp is valid. Otherwise,
// it performs a fresh query.
const getBuildingPiData = (
  buildingInformation,
  specifiedQuery,
  dispatch,
  timestamp
) => {
  let id = buildingInformation.id + "_" + specifiedQuery;
  checkCache(id).then(cachedObject => {
    if (
      (specifiedQuery.indexOf("demand") !== -1 ||
        specifiedQuery.indexOf("oat") !== -1) &&
      cachedObject !== undefined
    ) {
      if (
        cachedObject.data !== undefined &&
        cachedObject.timestamp !== null &&
        checkCacheTime(cachedObject.timestamp) &&
        cachedObject.data[specifiedQuery].length > 0
      ) {
        let cachedData = Object.assign({}, cachedObject.data);
        delete cachedData.id;
        dispatch(loadDesktopEnergySuccess(cachedData));
        dispatch(loadTLCEnergySuccess(cachedData));
      } else
        queryNewData(buildingInformation, specifiedQuery, dispatch, timestamp);
    } else if (
      specifiedQuery.indexOf("demand") === -1 &&
      cachedObject !== undefined
    ) {
      if (
        cachedObject.timestamp !== null &&
        checkCacheDay(cachedObject.timestamp) &&
        cachedObject.data[specifiedQuery].length > 0
      ) {
        let cachedData = Object.assign({}, cachedObject.data);
        delete cachedData.id;
        dispatch(loadDesktopEnergySuccess(cachedData));
      } else
        queryNewData(buildingInformation, specifiedQuery, dispatch, timestamp);
    } else
      queryNewData(buildingInformation, specifiedQuery, dispatch, timestamp);
  });
};

// Returns the cached data if it exists. If not, returns undefined.
async function checkCache(id) {
  return await db.objects.get(id);
}

// Returns true if data is still fresh. (Resets after CACHE_TIMEOUT const above)
function checkCacheTime(oldTimestamp) {
  let timeCheck = new Date() - new Date(oldTimestamp);
  if (timeCheck < CACHE_TIMEOUT) return true;
  return false;
}

//Returns true if data is still fresh. (Resets each day)
function checkCacheDay(oldTimestamp) {
  let dayCheck = new Date().getDay() - new Date(oldTimestamp).getDay();
  if (dayCheck === 0) return true;
  return false;
}

async function queryComparisonData(commodities, id, dispatch) {
  let allBuildingEuis = {};
  for (let i = 0; i < commodities.length; i++) {
    await apolloFetch({
      query: graphqlQueries["allBuildingEuis"],
      variables: {
        commodity: commodities[i]
      }
    })
      .then(buildingPiInformation => {
        let commodity = buildingPiInformation.data.euiData.commodity;
        allBuildingEuis[commodity] = buildingPiInformation.data.euiData.euis;
      })
      .catch(error => {
        throw error;
      });
  }
  let allEuisObject = { allBuildingEuis: allBuildingEuis };
  dispatch(loadDesktopEnergySuccess(allEuisObject));
  setCache(id, allEuisObject, "allBuildingEuis");
}

// Queries Pi through GraphQL for fresh data. Sets the cache after the query.
function queryNewData(
  buildingInformation,
  specifiedQuery,
  dispatch,
  timestamp
) {
  if (specifiedQuery === "allBuildingEuis")
    queryComparisonData(
      buildingInformation.energyInfo.commodities,
      buildingInformation.id,
      dispatch
    );
  else {
    apolloFetch({
      query: graphqlQueries[specifiedQuery],
      variables: {
        id: buildingInformation.id,
        commodities: buildingInformation.energyInfo.commodities,
        currentTime: timestamp,
        demandDailyStart: getDemandDailyTimestamp(timestamp),
        demandWeeklyStart: getDemandWeeklyTimestamp(timestamp),
        demandMonthlyStart: getDemandMonthlyTimestamp(timestamp),
        usageMonthlyEnd: getUsageMonthlyTimestamp(timestamp)
      }
    })
      .then(buildingPiInformation => {
        dispatch(loadDesktopEnergySuccess(buildingPiInformation.data));
        dispatch(loadTLCEnergySuccess(buildingPiInformation.data));
        setCache(
          buildingInformation.id,
          buildingPiInformation.data,
          specifiedQuery
        );
      })
      .catch(error => {
        throw error;
      });
  }
}

// Returns 24 hours prior to current time.
function getDemandDailyTimestamp(currentTime) {
  return new Date(currentTime.getTime() - 24 * 60 * 60 * 1000);
}

// Returns 7 days prior to current time.
function getDemandWeeklyTimestamp(currentTime) {
  return new Date(currentTime.getTime() - 7 * 24 * 60 * 60 * 1000);
}

// Returns 1 month prior to current time.
function getDemandMonthlyTimestamp(currentTime) {
  let monthAgo = new Date(currentTime);
  monthAgo.setMonth(monthAgo.getMonth() - 1);
  return monthAgo;
}

// Returns 1 year prior to start of current month.
function getUsageMonthlyTimestamp(currentTime) {
  let startOfMonth = new Date(currentTime);
  let month = startOfMonth.getMonth() + 1,
    year = startOfMonth.getFullYear();
  if (month < 10) month = "0" + month;
  return year + "-" + month;
}

// Determines if the cache should be session storage (Demand data)
// or if it should be local storage (other data types).
function setCache(buildingId, buildingData, query) {
  db.objects.put({
    id: buildingId + "_" + query,
    data: buildingData,
    timestamp: new Date()
  });
}
