import React, { Component } from "react";
import PropTypes from "prop-types";
import "./UsageNext.css";

class UsageNext extends Component {
  render() {
    return (
      <div className="usage-next-wrapper">
        Next: see this building's monthly breakdown in the{" "}
        <span className="usage-link" id="usage" onClick={this.props.onClick}>
          Usage tab.
        </span>
      </div>
    );
  }
}

UsageNext.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default UsageNext;
