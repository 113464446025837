import { DateTime } from "luxon";

const fontFamily =
  '"Proxima Nova","Lucida Grande","Lucida Sans","Helvetica Neue",Helvetica,Arial,sans-serif';

export const graphConfigBuilder = (max, maxEpoch, series) => {
  let baseInformation = {
    chart: {
      type: "column",
      plotBorderColor: "#F1F1F2",
      plotBorderWidth: 2,
      marginLeft: 70
    },
    credits: {
      enabled: false
    },
    navigator: {
      enabled: false
    },
    rangeSelector: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
    title: {
      text: null
    },
    time: {
      useUTC: false
    },
    xAxis: {
      scrollbar: {
        enabled: true,
        showFull: false
      },
      type: "datetime",
      labels: {
        style: {
          fontFamily: fontFamily,
          fontSize: "13px",
          letterSpacing: "0.9px",
          color: "#6c6c6c"
        }
      },
      ceiling: DateTime.fromISO(max.Timestamp)
        .plus({ minutes: 1 }) // add 1 minute, pushing it to midnight on the first day of the following month
        .minus({ months: 1 }) // subtract 1 month, changing it to midnight on the first day of the correct month
        .valueOf(), // return Unix epoch milliseconds
      max: maxEpoch,
      range: 36 * 30 * 24 * 3600 * 1000 // 36 months
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: true,
          forced: true,
          units: [["month", [1]]]
        }
      },
      column: {
        stacking: "normal",
        getExtremesFromAll: true, //TODO: Figure out why this isn't working
        pointWidth: 8
      }
    },
    tooltip: {
      backgroundColor: "#F6F6F6",
      borderColor: "#EEEDEE",
      borderRadius: 3,
      borderWidth: 1.5,
      style: {
        color: "#2D3138",
        lineHeight: "17px",
        fontSize: "13px",
        fontFamily: fontFamily
      },
      shadow: true,
      headerFormat:
        '<span style="font-size: 14px; color: #2D3138; opacity: 0.5; lineHeight: 17px;">{point.key}</span><table><br/>',
      pointFormat:
        '<span style="background-color:{series.color}; color:{series.color}; font-weight:700; font-size:9px; line-height:18px; vertical-align:top; max-width: 10px; max-height: 10px; border:2px solid white; border-radius: 50%; margin-right:5px; padding-right:3px;">\u25CF </span><span>  {series.name}:</span> {point.y}<br/>',
      crosshairs: true,
      shared: true,
      useHTML: true,
      xDateFormat: "%A, %b %e, %l:%M%P",
      formatter: function() {
        let dt = DateTime.fromMillis(this.x);

        let unitName = this.points[0].series.currentDataGrouping.unitName;

        let dateText =
          unitName === "month"
            ? dt.toLocaleString({ month: "long", year: "numeric" }) // monthly view
            : dt.toLocaleString({ year: "numeric" }); // annual view

        let text =
          '<span style="font-size: 14px; color: #2D3138; opacity: 0.5; lineHeight: 17px;">' +
          dateText +
          "</span><br>";
        let total = 0;

        this.points.forEach(function(item, i) {
          if (
            item.series.name !== "Temperature Range" &&
            item.series.name !== "Average Temperature"
          ) {
            text +=
              '<span style="background-color:' +
              item.series.userOptions.states.hover.color +
              "; color:" +
              item.series.color +
              '; font-weight:700; font-size:9px; line-height:18px; vertical-align:top; max-width: 10px; max-height: 10px; border:2px solid white; border-radius: 10px; margin-right:2px;">\u25CF </span><span>  ' +
              item.series.name +
              ": </span>" +
              Number(item.y).toLocaleString() +
              " kBtu" +
              "<br/>";
            total += item.y;
          }
        });
        text +=
          "<span> &emsp; Total: </span>" +
          Number(total).toLocaleString() +
          " kBtu" +
          "<br/>";

        this.points.forEach(function(item, i) {
          if (item.series.name === "Temperature Range") {
            text +=
              '<span style="color:#494949;font-weight:700;">\u25CF ' +
              item.series.name +
              ":</span> " +
              item.point.low +
              " - " +
              item.point.high +
              " \xB0F<br>";
          } else if (item.series.name === "Average Temperature") {
            text +=
              '<span style="color:' +
              item.series.color +
              ';font-weight:700;">\u25CF ' +
              item.series.name +
              ":</span> " +
              item.y +
              " \xB0F<br>";
          }
        });

        return text;
      }
    },
    series
  };
  baseInformation = { ...baseInformation, ...usageYAxis(false) };
  return baseInformation;
};

export const usageYAxis = visibility => {
  return {
    yAxis: [
      {
        //primary yAxis
        opposite: false,
        title: {
          text: "kBtu",
          align: "high",
          rotation: 0,
          offset: 15,
          style: {
            fontFamily: fontFamily,
            fontWeight: "bold",
            fontSize: 13,
            letterSpacing: "0.9px",
            color: "#2D3138"
          }
        },
        labels: {
          style: {
            fontFamily: fontFamily,
            fontSize: 13,
            letterSpacing: "0.9px",
            color: "#6c6c6c"
          }
        },
        lineWidth: 2,
        gridLineWidth: 2,
        lineColor: "#F1F1F2",
        gridLineColor: "#F1F1F2",
        tickColor: "#fff",
        tickWidth: 0,
        maxPadding: 0,
        tickAmount: 6,
        showLastLabel: false
      },
      {
        //secondary yAxis
        title: {
          text: "Temp (\xB0F)",
          align: "high",
          rotation: 0,
          offset: 0,
          style: {
            color: "#444952",
            fontFamily: fontFamily
          }
        },
        labels: {
          style: {
            color: "#444952",
            fontFamily: fontFamily,
            fontSize: 13
          }
        },
        opposite: true,
        visible: visibility,
        tickAmount: 6,
        minPadding: 0,
        maxPadding: 0,
        showLastLabel: false
      }
    ]
  };
};
