import allJoules from "./images/all-cows.png";
import energySources from "./images/energy-sources.png";
import nerdJoules from "./images/nerd-joules.png";
import trimJoules from "./images/trim-joules.png";

export const loading = [
  {
    link: "https://thermoostat.ucdavis.edu/",
    image: allJoules,
    headline: "Tell Us How You feel!",
    body: {
      __html:
        "Let Joules know your comfort level at <span>thermoostat.ucdavis.edu</span>"
    }
  },
  {
    link: "https://www.trimthewaste.ucdavis.edu/",
    image: trimJoules,
    headline: "Not sure how to save energy? Don't sweat it!",
    body: {
      __html:
        "Learn how to Trim the Waste at <span>trimthewaste.ucdavis.edu</span>"
    }
  },
  {
    link: "https://ceed.ucdavis.edu/energystory",
    image: energySources,
    headline: "What Are Our Renewable Energy Sources?",
    body: {
      __html:
        "Find out by visiting our Energy Story <span>ceed.ucdavis.edu/energystory</span>"
    }
  }
];

export const dataUnavailable = {
  link: null,
  image: nerdJoules,
  headline: "Oh, sorry!",
  body: {
    __html:
      "It looks like this data isn't quite available yet. Check back early next month."
  }
};
