/**
 * This component builds out the Demand chart.
 */

import React from "react";
import PropTypes from "prop-types";

import caret from "../../../images/caret.png";
import { chcpEnergyTypes } from "../common/config";

import "../Chcp.css";

const checkboxState = (state, commodity) => {
  if (state.mode === "Historic") return state.checkBoxes.historic[commodity];
  else return state.checkBoxes[state.currentSelection][commodity];
};

const ChcpChartMenu = ({
  realTime,
  historic,
  data,
  stateProps,
  setOption,
  activeClass,
  toggleCheckbox,
  props
}) => {
  return (
    <div className="chart-menu">
      {realTime && (
        <div
          className={"chart-primary-button" + activeClass("Real-Time")}
          onClick={e => setOption("mode", "Real-Time")}
        >
          REAL-TIME
        </div>
      )}
      {stateProps.mode === "Real-Time" && (
        <div className="chart-primary-options">
          <div
            className={"chart-secondary-button" + activeClass("daily")}
            onClick={e => setOption("currentSelection", "daily")}
          >
            <div className="secondary-button-radio">
              <span />
            </div>
            24 hrs
          </div>
          <div
            className={"chart-secondary-button" + activeClass("weekly")}
            onClick={e => setOption("currentSelection", "weekly")}
          >
            <div className="secondary-button-radio">
              <span />
            </div>
            7 days
          </div>
          <div
            className={"chart-secondary-button" + activeClass("monthly")}
            onClick={e => setOption("currentSelection", "monthly")}
          >
            <div className="secondary-button-radio">
              <span />
            </div>
            30 days
          </div>
        </div>
      )}
      {historic && (
        <div
          className={"chart-primary-button" + activeClass("Historic")}
          onClick={e => setOption("mode", "Historic")}
        >
          HISTORICAL
        </div>
      )}
      {stateProps.mode === "Historic" && (
        <div className="chart-primary-historic-options">
          <div className="historic-select-wrapper">
            <select
              value={stateProps.historicSelection}
              name="historicSelection"
              className="historic-select-box"
              style={{
                background: `transparent url(${caret}) no-repeat 90% center`
              }}
              onChange={e => setOption("historicSelection", e.target.value)}
            >
              {data.historic.years.map(year => {
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      )}
      <div className="chcp-commodities">
        {stateProps.mode === "Real-Time" &&
          data.daily.commodities !== undefined &&
          data.daily.commodities.map(commodity => (
            <label
              key={commodity}
              className={"chcp-commodity " + commodity}
              htmlFor={commodity}
            >
              <input
                type="checkbox"
                checked={checkboxState(stateProps, commodity)}
                onChange={e => toggleCheckbox(e.target.id)}
                id={commodity}
              />
              <span className={"chcp-comparison-options " + commodity}>
                {chcpEnergyTypes[commodity].displayName}
              </span>
            </label>
          ))}
        {stateProps.mode === "Historic" &&
          data.historic.commodities !== undefined &&
          data.historic.commodities.map(commodity => (
            <label
              key={commodity}
              className={"chcp-commodity " + commodity}
              htmlFor={commodity}
            >
              <input
                type="checkbox"
                checked={checkboxState(stateProps, commodity)}
                onChange={e => toggleCheckbox(e.target.id)}
                id={commodity}
              />
              <span className={"chcp-comparison-options " + commodity}>
                {chcpEnergyTypes[commodity].displayName}
              </span>
            </label>
          ))}
      </div>
      {props}
    </div>
  );
};

ChcpChartMenu.propTypes = {
  realTime: PropTypes.bool.isRequired,
  historic: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  stateProps: PropTypes.object.isRequired,
  setOption: PropTypes.func.isRequired,
  activeClass: PropTypes.func.isRequired
};

export default ChcpChartMenu;
