export const productionTotal = `
query chcpProductionTotal($currentTime:String!, $productionStart:String!, $annualTags:[String]!, $monthlyTags:[String]!) {
	totalAnnual : chcpSingleValue(
		tagType: "production"
		meterIds: $annualTags
	) {
			commodity
			data {
				Timestamp
				Value
			}
	}
	totalMonthly : chcpMonthlyData(
		tagType: "production"
		startTime: $productionStart
		endTime: $currentTime
		meterIds: $monthlyTags
	) {
			commodity
			data {
				Timestamp
				Value
				Month
			}
	}
}
`;

export const productionDaily = `
query chcpProductionCurrent($currentTime:String!, $dailyStart:String!, $demandTags:[String]!) {
	dailyProduction : chcpCurrentData(
		tagType: "production"
		startTime: $dailyStart
		endTime: $currentTime
		meterIds: $demandTags
		interval: "5m"
	) {
			commodity
			data {
				Timestamp
				Value
			}
	}
}
`;

export const productionWeekly = `
query chcpProductionCurrent($currentTime:String!, $weeklyStart:String!, $demandTags:[String]!) {
	weeklyProduction : chcpCurrentData(
		tagType: "production"
		startTime: $weeklyStart
		endTime: $currentTime
		meterIds: $demandTags
		interval: "15m"
	) {
			commodity
			data {
				Timestamp
				Value
			}
	}
}
`;

export const productionMonthly = `
query chcpProductionCurrent($currentTime:String!, $monthlyStart:String!,  $demandTags:[String]!) {
	monthlyProduction : chcpCurrentData(
		tagType: "production"
		startTime: $monthlyStart
		endTime: $currentTime
		meterIds: $demandTags
		interval: "60m"
	) {
			commodity
			data {
				Timestamp
				Value
			}
	}
}
`;

export const productionHistoric = `
query chcpProductionCurrent($currentTime:String!, $productionStart:String!, $usageTags:[String]!) {
	historicProduction : chcpMonthlyData(
		tagType: "production"
		startTime: $productionStart
		endTime: $currentTime
		meterIds: $usageTags
	) {
			commodity
			data {
				Timestamp
				Value
				Month
			}
	}
}
`;

export const usageTotal = `
query chcpUsageTotal($currentTime:String!, $usageStart:String!, $annualTags:[String]!, $monthlyTags:[String]!) {
	totalAnnual : chcpSingleValue(
		tagType: "usage"
		meterIds: $annualTags
	) {
			commodity
			data {
				Timestamp
				Value
			}
	}
	totalMonthly : chcpMonthlyData(
		tagType: "usage"
		startTime: $usageStart
		endTime: $currentTime
		meterIds: $monthlyTags
	) {
			commodity
			data {
				Timestamp
				Value
				Month
			}
	}
}
`;

export const usageDaily = `
query chcpUsageCurrent($currentTime:String!, $dailyStart:String!, $demandTags:[String]!) {
	dailyUsage : chcpCurrentData(
		tagType: "usage"
		startTime: $dailyStart
		endTime: $currentTime
		meterIds: $demandTags
		interval: "5m"
	) {
			commodity
			data {
				Timestamp
				Value
			}
	}
}
`;

export const usageWeekly = `
query chcpUsageCurrent($currentTime:String!, $weeklyStart:String!, $demandTags:[String]!) {
	weeklyUsage : chcpCurrentData(
		tagType: "usage"
		startTime: $weeklyStart
		endTime: $currentTime
		meterIds: $demandTags
		interval: "15m"
	) {
			commodity
			data {
				Timestamp
				Value
			}
	}
}
`;

export const usageMonthly = `
query chcpUsageCurrent($currentTime:String!, $monthlyStart:String!,  $demandTags:[String]!) {
	monthlyUsage : chcpCurrentData(
		tagType: "usage"
		startTime: $monthlyStart
		endTime: $currentTime
		meterIds: $demandTags
		interval: "60m"
	) {
			commodity
			data {
				Timestamp
				Value
			}
	}
}
`;

export const usageHistoric = `
query chcpUsageCurrent($currentTime:String!, $usageStart:String!, $usageTags:[String]!) {
	historicUsage : chcpMonthlyData(
		tagType: "usage"
		startTime: $usageStart
		endTime: $currentTime
		meterIds: $usageTags
	) {
			commodity
			data {
				Timestamp
				Value
				Month
			}
	}
}
`;

export const metricsTotal = `
query chcpMetricsTotal($annualTags:[String]!) {
	totalAnnual : chcpSingleValue(
		tagType: "metrics"
		meterIds: $annualTags
	) {
			commodity
			data {
				Timestamp
				Value
			}
	}
}
`;

export const metricsDaily = `
query chcpMetricsCurrent($currentTime:String!, $dailyStart:String!, $demandTags:[String]!) {
	dailyMetrics : chcpCurrentData(
		tagType: "metrics"
		startTime: $dailyStart
		endTime: $currentTime
		meterIds: $demandTags
		interval: "5m"
	) {
			commodity
			data {
				Timestamp
				Value
			}
	}
}
`;

export const metricsWeekly = `
query chcpMetricsCurrent($currentTime:String!, $weeklyStart:String!, $demandTags:[String]!) {
	weeklyMetrics : chcpCurrentData(
		tagType: "metrics"
		startTime: $weeklyStart
		endTime: $currentTime
		meterIds: $demandTags
		interval: "15m"
	) {
			commodity
			data {
				Timestamp
				Value
			}
	}
}
`;

export const metricsMonthly = `
query chcpMetricsCurrent($currentTime:String!, $monthlyStart:String!,  $demandTags:[String]!) {
	monthlyMetrics : chcpCurrentData(
		tagType: "metrics"
		startTime: $monthlyStart
		endTime: $currentTime
		meterIds: $demandTags
		interval: "60m"
	) {
			commodity
			data {
				Timestamp
				Value
			}
	}
}
`;

export const metricsHistoric = `
query chcpMetricsCurrent($currentTime:String!, $metricsStart:String!, $usageTags:[String]!) {
	historicMetrics : chcpMonthlyData(
		tagType: "metrics"
		startTime: $metricsStart
		endTime: $currentTime
		meterIds: $usageTags
	) {
			commodity
			data {
				Timestamp
				Value
				Month
			}
	}
}
`;
