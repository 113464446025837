import React from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import SearchRow from "./SearchRow.js";
import magnifyingGlass from "../images/search.svg";
import "./Search.css";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTarget: "",
      results: []
    };
    this.loadBuildingOptions = this.loadBuildingOptions.bind(this);
  }

  // Begins searching when a key is pressed in the searchbox.
  loadBuildingOptions(event) {
    let buildingSearchTarget = event.target.value,
      searchResults = [];
    if (event.target.value.length > 0) {
      searchResults = this.parseBuildingList(buildingSearchTarget);
      if (searchResults.length === 0)
        searchResults.push({
          primaryDisplay: "No results found."
        });
    }
    this.setState(prevState => {
      return {
        searchTarget: buildingSearchTarget,
        results: searchResults
      };
    });
  }

  // Performs the search as described in the intro.
  parseBuildingList(searchString) {
    let results = [];
    for (let i = 0; i < this.props.searchData.length; i++) {
      let buildingObject = this.props.searchData[i];
      let name = buildingObject.primaryDisplay.toLowerCase(),
        officialName = buildingObject.officialName.toLowerCase(),
        shortName = "",
        ceedName = "",
        query = searchString.toLowerCase(),
        aliases = [],
        found = false;
      if (buildingObject.buildingData.length !== 0) {
        shortName = buildingObject.buildingData[0].id.toLowerCase();
        ceedName = buildingObject.buildingData[0].name.toLowerCase();
        aliases = buildingObject.buildingData[0].alias;
      }
      if (
        name.indexOf(query) !== -1 ||
        officialName.indexOf(query) !== -1 ||
        shortName.indexOf(query) !== -1 ||
        ceedName.indexOf(query) !== -1
      )
        found = true;
      //Check possible alias names if not found on first pass.
      if (!found)
        if (aliases !== null && aliases.length > 0)
          for (let j = 0; j < aliases.length; j++) {
            let aliasName = aliases[j].toLowerCase();
            if (aliasName.indexOf(query) !== -1) found = true;
          }
      if (found && buildingObject.buildingData.length !== 0)
        results.push(buildingObject);
    }
    return results;
  }

  render() {
    return (
      <div className="mobile-search">
        <div className="mobile-search-input">
          <input
            type="text"
            name="buildingSearch"
            className="mobile-search-input-control"
            placeholder="Search for a building"
            value={this.state.searchTarget}
            onChange={this.loadBuildingOptions}
            autoComplete="off"
          />
          <img
            src={magnifyingGlass}
            alt="Search icon"
            className="mobile-search-icon"
          />
        </div>
        <div className="mobile-search-results">
          {this.state.results.map((building, key) => (
            <SearchRow key={key} building={building} />
          ))}
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  searchData: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  return {
    searchData: state.searchData
  };
};

export default connect(mapStateToProps)(Search);
