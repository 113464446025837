import React from "react";
import PropTypes from "prop-types";

import { commaFormatNumbers } from "../../../common/utilities";
import * as graphBuilder from "../../common/graphBuilder";

import arrow from "../../../../images/arrow.svg";
import lightBulb from "../../images/lightbulb.svg";
import waterDrop from "../../images/toggle-water.svg";

import "./WeeklyEnergySavings.css";

let Highcharts = require("highcharts"),
  rankingChart = null;
Highcharts.setOptions({
  lang: {
    thousandsSep: ","
  },
  global: {
    useUTC: false //Global Highcharts option that set timestamps to local time.
  }
});

class WeeklyEnergySavings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weekSelection: this.props.rankingWeekly.length - 1
    };
    this.changeWeek = this.changeWeek.bind(this);
    this.setWeek = this.setWeek.bind(this);
  }

  componentDidMount() {
    if (this.state.weekSelection !== -1) this.updateGraph();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.props.rankingWeekly) !==
      JSON.stringify(prevProps.rankingWeekly)
    )
      this.initializeData();
    if (this.state.weekSelection !== prevState.weekSelection)
      this.updateGraph();
    if (this.props.commodity !== prevProps.commodity) {
      rankingChart = null;
      this.updateGraph();
    }
  }

  componentWillUnmount() {
    if (rankingChart !== null && rankingChart !== undefined) {
      rankingChart.destroy();
      rankingChart = null;
    }
  }

  changeWeek(event) {
    let shift = event.target.id,
      weekSelection = this.state.weekSelection;
    if (shift === "weekLeft" && weekSelection > 0) weekSelection--;
    else if (
      shift === "weekRight" &&
      weekSelection < this.props.rankingWeekly.length - 1
    )
      weekSelection++;
    this.setState({ weekSelection });
  }

  checkNegative() {
    if (this.props.rankingWeekly[this.state.weekSelection].value < 0)
      return " overuse";
    return "";
  }

  getConversionValue(value) {
    if (this.props.commodity === "electricity")
      return commaFormatNumbers(Math.round(value / (0.02 * 168)));
    return commaFormatNumbers(Math.round(value / 12.3));
  }

  initializeData() {
    if (
      this.state.weekSelection < 0 ||
      this.state.weekSelection >= this.props.rankingWeekly.length
    )
      this.setState({ weekSelection: this.props.rankingWeekly.length - 1 });
  }

  setWeek(weekSelection) {
    this.setState({ weekSelection });
  }

  async updateGraph() {
    let dataSet = [],
      datesArray = [],
      tempData = await graphBuilder.getGraphPoints(
        this.props.rankingWeekly,
        this.state.weekSelection
      );
    for (let i = 0; i < tempData.data.length; i++) {
      dataSet.push(
        graphBuilder.setDataPoint(
          tempData.data[i].value,
          this.props.commodity,
          tempData.data[i].indexPoint,
          i === tempData.indexPoint
        )
      );
      datesArray.push(tempData.data[i].dates);
    }
    if (rankingChart === null)
      rankingChart = Highcharts.chart(
        "ewc-savings-chart",
        graphBuilder.initializeGraph(
          dataSet,
          datesArray,
          this.props.commodity === "electricity" ? "kWh" : "gal",
          this.setWeek
        )
      );
    else
      rankingChart.update({
        series: [{ data: dataSet }]
      });
  }

  render() {
    if (this.state.weekSelection === -1) return null;
    if (this.state.weekSelection > this.props.rankingWeekly.length) return null;
    return (
      <div className="ewc-savings-card-desktop graph-card">
        <div className="ewc-savings-card-left">
          <div className="ewc-savings-card-title">Weekly Energy Savings</div>
          <div className="ewc-savings-chart" id="ewc-savings-chart" />
          <div className="ewc-savings-range-picker">
            <div
              className="ewc-savings-range-arrow left"
              id="weekLeft"
              onClick={this.changeWeek}
              style={{
                visibility: this.state.weekSelection > 0 ? "visible" : "hidden"
              }}
            >
              <img
                src={arrow}
                id="weekLeft"
                alt="Move date range back one week."
              />
            </div>
            <div className="ewc-savings-range-selection">
              {this.state.weekSelection >= 0
                ? this.props.rankingWeekly[this.state.weekSelection].dates
                : "Loading..."}
            </div>
            <div
              className="ewc-savings-range-arrow right"
              id="weekRight"
              onClick={this.changeWeek}
              style={{
                visibility:
                  this.state.weekSelection < this.props.rankingWeekly.length - 1
                    ? "visible"
                    : "hidden"
              }}
            >
              <img
                src={arrow}
                id="weekRight"
                alt="Move date range forward one week."
              />
            </div>
          </div>
        </div>
        <div className="ewc-savings-card-right">
          <div className="ewc-savings-stats">
            <div className="ewc-savings-stats-title">WEEKLY SAVINGS</div>
            <div className="ewc-savings-detail">
              <div className="ewc-savings-detail-label">
                {this.props.commodity === "electricity"
                  ? "in kWh"
                  : "in Gallons"}
              </div>
              <div
                className={
                  "ewc-savings-detail-value " +
                  this.props.commodity +
                  this.checkNegative()
                }
              >
                <b>
                  {commaFormatNumbers(
                    this.props.rankingWeekly[this.state.weekSelection].value
                  )}
                </b>{" "}
                {this.props.commodity === "electricity" ? "kWh" : "gallons"}
              </div>
            </div>
            <div className="ewc-savings-detail">
              <div className="ewc-savings-detail-label">
                {this.props.commodity === "electricity"
                  ? "in Lightbulbs*"
                  : "in Showers*"}
              </div>
              <div
                className={
                  "ewc-savings-detail-value " +
                  this.props.commodity +
                  this.checkNegative()
                }
              >
                <b>
                  {this.getConversionValue(
                    this.props.rankingWeekly[this.state.weekSelection].value
                  )}
                </b>{" "}
                {this.props.commodity === "electricity"
                  ? "lightbulbs"
                  : "showers"}
              </div>
            </div>
          </div>
          <div className="ewc-savings-example">
            <img
              src={
                this.props.commodity === "electricity" ? lightBulb : waterDrop
              }
              alt="Commodity icon"
            />
            <div className="ewc-savings-example-detail">
              *
              {this.props.commodity === "electricity"
                ? "left on 24/7 for 1 week. Calculated using a 20 watt LED lightbulb."
                : "A typical shower lasts about 8 minutes and uses a total of 17 gallons of water."}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WeeklyEnergySavings.propTypes = {
  commodity: PropTypes.string.isRequired,
  rankingWeekly: PropTypes.array.isRequired
};

export default WeeklyEnergySavings;
