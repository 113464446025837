import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import DashboardMenu from "../../../common/dashboardMenu/DashboardMenu";
import Stats from "./stats/Stats.js";
import "./Header.css";
import arrowImage from "../../../../images/arrow.svg";

const Header = ({ building, euiValues }) => {
  const imageNotFound = event => {
    event.target.src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=";
    event.target.alt = "Building photograph placeholder.";
  };
  if (building.id === "") return <div className="building-header" />;

  return (
    <div className="building-header">
      <DashboardMenu />
      <div className="building-header-title">{building.name}</div>
      <Stats building={building} euiValues={euiValues} />
      <div className="building-header-image">
        <img
          src={"/images/buildings/" + building.id + ".jpg"}
          alt="Selected building photograph"
          onError={imageNotFound}
        />
      </div>
      <Link to="/" className="building-header-map-link">
        <img
          src={arrowImage}
          alt="Back to campus map arrow."
          id="bounce-arrow-left"
        />
        Back to map
      </Link>
    </div>
  );
};

Header.propTypes = {
  building: PropTypes.object.isRequired,
  euiValues: PropTypes.array.isRequired
};

export default Header;
