import React from "react";

import ecoLogo from "./images/ecoLogo.png";

const Logo = () => {
  return (
    <div className="eco-logo">
      <a
        href="https://eco.ucdavis.edu"
        target="_blank"
        rel="noopener noreferrer"
        className="more-ewc-button"
      >
        <img src={ecoLogo} alt="Energy Conservation Office logo" />
      </a>
    </div>
  );
};

export default Logo;
