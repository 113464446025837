import React, { useState } from "react"

import styles from "./Demand.module.css"

import DemandChart from "../../../common/demandChart/DemandChart"

import hotWaterImage from "../../../images/hot-water.svg"
import chillWaterImage from "../../../images/chill-water.svg"

export default function Demand({ weeklyData, weeklyOat }) {
  const allCommodities = ["chilledWater", "hotWater"]
  const [commodities, setCommodities] = useState(allCommodities)
  const [toggled, setToggled] = useState(false)
  const [toggleString, setToggleString] = useState("Show Graph")

  function toggleGraph(e) {
    if (commodities.length === 1 && commodities[0] === e.target.value) {
      setCommodities(allCommodities)
      setToggled(false)
      setToggleString("Show Graph")
    } else {
      setCommodities([e.target.value])
      setToggled(true)
      setToggleString("Show All")
    }
  }

  // idx: 0 for cooling, 1 for heating
  function liveData(idx) {
    const conversions = {
      oven: 1/17,
      refrigerator: 2,
    }

    if (weeklyData.length === 0) {
      const raw = idx === 0 ? 1540 : 160
      return {
        raw,
        equivalent: {
          ovens: Math.round(raw * conversions.oven),
          refrigerators: Math.round(raw * conversions.refrigerator),
        },
      }
    }

    const data = weeklyData[idx].data
    const rounded = Math.round(data[data.length - 1].Value)

    return {
      raw: rounded,
      equivalent: {
        ovens: Math.round(rounded * conversions.oven),
        refrigerators: Math.round(rounded * conversions.refrigerator),
      },
    }
  }

  return (
    <>
      <ul className={styles.key}>
        <li>Energy Demand</li>
        <ul className={styles.legend}>
          <li>Heating Demand</li>
          <li>Cooling Demand</li>
          <li>Outside Air Temperature</li>
        </ul>
        <li>Temperature</li>
      </ul>

      <DemandChart
        weeklyData={weeklyData}
        weeklyOat={weeklyOat}
        commodities={commodities}
        isHC={true}
      />

      <div className={styles.demand_wrap}>
        <div
          className={styles.demand}
          hidden={toggled && commodities[0] === "chilledWater"}
        >
          <h3>Heating Demand</h3>
          <p>
            Current rate of consumption{" "}
            <strong>{liveData(1).raw} kBtu/hr</strong>
            <br />
            <span>
              Equivalent to heating {liveData(1).equivalent.ovens} ovens.
            </span>
          </p>
          <button
            className={
              toggled ? `${styles.even} ${styles.yellow}` : styles.yellow
            }
            onClick={toggleGraph}
            value="hotWater"
          >
            {toggleString}{" "}
            <img src={hotWaterImage} alt="hot water" hidden={toggled} />
          </button>
        </div>
        <div
          className={styles.demand}
          hidden={toggled && commodities[0] === "hotWater"}
        >
          <h3>Cooling Demand</h3>
          <p>
            Current rate of consumption{" "}
            <strong>{liveData(0).raw} kBtu/hr</strong>
            <br />
            <span>
              Equivalent to running {liveData(0).equivalent.refrigerators}{" "}
              refrigerators.
            </span>
          </p>
          <button
            className={toggled ? `${styles.even} ${styles.blue}` : styles.blue}
            onClick={toggleGraph}
            value="chilledWater"
          >
            {toggleString}{" "}
            <img src={chillWaterImage} alt="chill water" hidden={toggled} />
          </button>
        </div>
      </div>
    </>
  )
}
