import React from "react";
import WaterGoals from "./waterGoals/WaterGoals";
import "./Goals.css";

export default function Goals() {
  return (
    <div className="goals-tab">
      <WaterGoals/>
    </div>
  );
}
