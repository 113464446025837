import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const buildingProjects = (
  state = initialState.buildingProjects,
  action
) => {
  switch (action.type) {
    case types.LOAD_BUILDING_PROJECTS_SUCCESS:
      return action.buildingProjects;

    default:
      return state;
  }
};

export const savingsCalculator = (
  state = initialState.savingsCalculator,
  action
) => {
  switch (action.type) {
    case types.LOAD_SAVINGS_SUCCESS:
      return action.savingsCalculator;

    default:
      return state;
  }
};
