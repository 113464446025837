/**
 * Actions used for the full campus page.
 * Collects Pi data for campus demand
 * then caches it for quick access later.Queries are
 * performed asynchronously, and push a new state
 * as soon as queried data is received.
 */

import * as types from "./actionTypes";
import { createApolloFetch } from "apollo-fetch";
import * as graphqlQueries from "../queries/campusQuery";
import Dexie from "dexie";

const uri = "/api/graphql";
const apolloFetch = createApolloFetch({ uri });
const CACHE_TIMEOUT = 5 * 60 * 1000; //5 Minutes
const QUERIES = [
  "campusDemandDaily",
  "campusDemandMonthly",
  "campusDemandWeekly",
  "campusUsage"
];

const db = new Dexie("cached");
db.version(1).stores({
  objects: "id"
});

// Pushes the data to the store.
export function loadCampusSuccess(campus) {
  return {
    type: types.LOAD_CAMPUS_SUCCESS,
    campus
  };
}

// Initial call for loading the full campus data.
export function loadCampusData() {
  return dispatch => {
    let timestamp = new Date();
    for (let i = 0; i < QUERIES.length; i++)
      getCampusPiData(QUERIES[i], dispatch, timestamp);
  };
}

// Dispatches from the cache if the data exists, and timestamp is valid. Otherwise,
// it performs a fresh query.
function getCampusPiData(specifiedQuery, dispatch, timestamp) {
  let id = specifiedQuery;
  checkCache(id).then(cachedObject => {
    if (cachedObject !== undefined) {
      if (
        cachedObject.data !== undefined &&
        cachedObject.timestamp !== null &&
        checkCacheTime(cachedObject.timestamp) &&
        cachedObject.data[specifiedQuery].length > 0
      ) {
        dispatch(
          loadCampusSuccess({
            [specifiedQuery]: cachedObject.data[specifiedQuery]
          })
        );
      } else queryNewData(specifiedQuery, dispatch, timestamp);
    } else queryNewData(specifiedQuery, dispatch, timestamp);
  });
}

// Returns the cached data if it exists. If not, returns undefined.
async function checkCache(id) {
  return await db.objects.get(id);
}

// Returns true if data is still fresh. (Resets after CACHE_TIMEOUT const above)
function checkCacheTime(oldTimestamp) {
  let timeCheck = new Date() - new Date(oldTimestamp);
  if (timeCheck < CACHE_TIMEOUT) return true;
  return false;
}

// Queries Pi through GraphQL for fresh data. Sets the cache after the query.
function queryNewData(specifiedQuery, dispatch, timestamp) {
  apolloFetch({
    query: graphqlQueries[specifiedQuery],
    variables: {
      currentTime: timestamp,
      campusDailyStart: getDemandDailyTimestamp(timestamp),
      campusWeeklyStart: getDemandWeeklyTimestamp(timestamp),
      campusMonthlyStart: getDemandMonthlyTimestamp(timestamp)
    }
  })
    .then(campusPiInformation => {
      dispatch(loadCampusSuccess(campusPiInformation.data));
      setCache(campusPiInformation.data, specifiedQuery);
    })
    .catch(error => {
      throw error;
    });
}

// Returns 24 hours prior to current time.
function getDemandDailyTimestamp(currentTime) {
  return new Date(currentTime.getTime() - 24 * 60 * 60 * 1000);
}

// Returns 7 days prior to current time.
function getDemandWeeklyTimestamp(currentTime) {
  return new Date(currentTime.getTime() - 7 * 24 * 60 * 60 * 1000);
}

// Returns 1 month prior to current time.
function getDemandMonthlyTimestamp(currentTime) {
  let monthAgo = new Date(currentTime);
  monthAgo.setMonth(monthAgo.getMonth() - 1);
  return monthAgo;
}

// Determines if the cache should be session storage (Demand data)
// or if it should be local storage (other data types).
function setCache(campusData, query) {
  db.objects.put({
    id: query,
    data: campusData,
    timestamp: new Date()
  });
}
