import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { breakPoints } from "../../common/config";

import Loading from "../../building/loading/Loading";
import CommodityToggle from "../common/CommodityToggle";
import WeeklyEnergySavings from "../desktop/building/WeeklyEnergySavings";
import MobileEnergySavings from "../mobile/building/MobileEnergySavings";
import Tips from "../common/Tips";
import SocialMedia from "../common/SocialMedia";
import Logos from "../common/Logos";

import "./EwcBuilding.css";

const DEFAULT_COMMODITY = "electricity",
  MONTHS = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
  ];

class EwcBuilding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commodity: DEFAULT_COMMODITY,
      loading: true,
      displayLoading: false,
      visible: {
        electricity: false,
        water: false
      },
      usage: {
        electricity: [],
        water: []
      }
    };
    this.switchCommodity = this.switchCommodity.bind(this);
  }

  componentDidMount() {
    if (
      this.props.rankingWeekly.building === this.props.match.params.buildingId
    )
      this.setRankingData();
    else this.setState({ displayLoading: true });
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.rankingWeekly) !==
      JSON.stringify(prevProps.rankingWeekly)
    )
      if (
        this.props.rankingWeekly.building === this.props.match.params.buildingId
      )
        this.setRankingData();
  }

  getWeekRange(timestamp) {
    let convertDate = Date.parse(timestamp),
      startDate = new Date(convertDate - 7 * 24 * 60 * 60 * 1000),
      endDate = new Date(convertDate - 24 * 60 * 60 * 1000);
    if (startDate.getMonth() === endDate.getMonth())
      return (
        MONTHS[startDate.getMonth()] +
        " " +
        startDate.getDate() +
        " - " +
        endDate.getDate()
      );
    else
      return (
        MONTHS[startDate.getMonth()] +
        " " +
        startDate.getDate() +
        " - " +
        MONTHS[endDate.getMonth()] +
        " " +
        endDate.getDate()
      );
  }

  async setRankingData() {
    let usage = {
      electricity: [],
      water: []
    };
    for (let key in this.props.rankingWeekly) {
      if (key === "electricity")
        usage.electricity = await this.setCommodityData(
          this.props.rankingWeekly[key]
        );
      if (key === "domesticWater")
        usage.water = await this.setCommodityData(
          this.props.rankingWeekly[key]
        );
    }
    this.setState({
      usage,
      visible: {
        electricity: usage.electricity.length > 0 ? true : false,
        water: usage.water.length > 0 ? true : false
      },
      loading: false,
      displayLoading: false
    });
  }

  setCommodityData(data) {
    return new Promise((res, rej) => {
      let results = [];
      for (let i = 0; i < data.length; i++)
        results.push({
          dates: this.getWeekRange(data[i].Timestamp),
          value: Math.round(data[i].Value)
        });
      res(results);
    });
  }

  switchCommodity(event) {
    let commodity = event.target.id;
    if (commodity !== this.state.commodity) this.setState({ commodity });
  }

  render() {
    if (this.state.loading && this.state.displayLoading) return <Loading />;
    else if (this.state.loading) return null;
    return (
      <div className="ewc-building-ranking">
        <MediaQuery maxWidth={breakPoints.mobile}>
          <MobileEnergySavings
            commodity={this.state.commodity}
            rankingWeekly={this.state.usage[this.state.commodity]}
            visible={this.state.visible}
            onClick={this.switchCommodity}
          />
        </MediaQuery>
        <MediaQuery minWidth={breakPoints.tablet}>
          <CommodityToggle
            currentCommodity={this.state.commodity}
            onClick={this.switchCommodity}
            visible={this.state.visible}
          />
          <WeeklyEnergySavings
            commodity={this.state.commodity}
            rankingWeekly={this.state.usage[this.state.commodity]}
          />
        </MediaQuery>
        <div
          className="ewc-building-tips-wrapper"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap"
          }}
        >
          <MediaQuery maxWidth={breakPoints.mobile}>
            <div style={{ width: "100%", marginBottom: "-30px" }}>
              <CommodityToggle
                currentCommodity={this.state.commodity}
                onClick={this.switchCommodity}
                tip={true}
                visible={this.state.visible}
              />
            </div>
          </MediaQuery>
          <Tips commodity={this.state.commodity} />
          <SocialMedia />
        </div>
        <div className="ewc-building-ranking-footer">
          <Logos />
        </div>
      </div>
    );
  }
}

EwcBuilding.propTypes = {
  rankingWeekly: PropTypes.object.isRequired
};

export default withRouter(EwcBuilding);
