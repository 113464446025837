import React from "react";
import PropTypes from "prop-types";

export default function OutsideAirTempSelection({
  isChecked,
  toggleOatSelection
}) {
  return (
    <div className="data-filter-inner-column col3">
      <span className="data-filter-section-header">Include:</span>
      <div className="radio-group">
        <div
          className={
            isChecked
              ? "input-box input-box-selected"
              : "input-box input-box-unselected"
          }
        >
          <div className="oat-checkbox">
            <input
              type="checkbox"
              id="include-oat"
              value="oat"
              name="include-oat"
              checked={isChecked}
              onChange={toggleOatSelection}
            />
            <label htmlFor="include-oat">Outside Air Tempurature</label>
          </div>
        </div>
      </div>
    </div>
  );
}

OutsideAirTempSelection.propTypes = {
  toggleOatSelection: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired
};
