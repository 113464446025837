import React, { useState } from "react";
import PropTypes from "prop-types";
import "./ModalContent.css";
import bottomToggle1 from "../images/bottom-toggle-1.png";
import bottomToggle2 from "../images/bottom-toggle-2.png";
import bottomToggle3 from "../images/bottom-toggle-3.png";
import bottomToggle4 from "../images/bottom-toggle-4.png";
import FilterBuildingAnimation from "../images/filter-by-building-type-animation.png";
import FilterEUIAnimation from "../images/filter-by-eui-animation.png";
import ViewBuildingAnimation from "../images/view-building-data-animation.png";
import ModalPage1 from "./modalPages/ModalPage1";
import ModalPage2 from "./modalPages/ModalPage2";
import ModalPage3 from "./modalPages/ModalPage3";
import ModalPage4 from "./modalPages/ModalPage4";
import ModalNav from "./modalComponents/ModalNav";
import InfoModal from "./modalComponents/InfoModal";

const ModalContent = ({ onClick }) => {
  const [modalPage, setModalPage] = useState(1);
  const [infoPage, setInfoPage] = useState(null);
  const gifs = [
    {name: "Filter By Energy Use", src: "filter-by-eui", apng: FilterEUIAnimation}, 
    {name: "Filter By Building Type", src: "filter-by-building-type", apng: FilterBuildingAnimation}, 
    {name: "View Building Data", src: "view-building-data", apng: ViewBuildingAnimation}];
  const onNextClick = () => {
    setModalPage(modalPage+1)
  }
  const onBackClick = () => {
    setModalPage(modalPage-1)
  }

  return (
    <div className="modal-content">
      <div className="modal-content-overlay" onClick={onClick} />
      <div className={modalPage==1 ? "modal-content-background-image" : "modal-content-background"}>
        <div className="modal-content-close-button" onClick={onClick}>
          <span className="close-icon">&times;</span> <span className="close-text">close</span>
        </div>
            { modalPage == 1 &&
              <>
                <ModalNav nextTxt="Let's Get Started!" nextFunc={onNextClick} toggleIcon={bottomToggle1} />
                <ModalPage1 />
              </>
            }
             { modalPage == 2 &&
              <>
                <ModalNav prevTxt="Back" nextTxt="Next" prevFunc={onBackClick} nextFunc={onNextClick} toggleIcon={bottomToggle2} />
                <ModalPage2 />
              </>
            }
            { modalPage == 3 &&
              <>
                <ModalNav prevTxt="Back" nextTxt="Next" prevFunc={onBackClick} nextFunc={onNextClick} toggleIcon={bottomToggle3} />
                <ModalPage3 />
              </>
            }
            { (modalPage === 4 && infoPage === null) &&
              <>
                <ModalNav prevTxt="Back" nextTxt="Explore CEED" prevFunc={onBackClick} nextFunc={onClick} toggleIcon={bottomToggle4} />
                <ModalPage4 gifs={gifs} setInfoPage={setInfoPage}/>
              </>
            }
            { (modalPage === 4 && infoPage) &&
              <>
                <InfoModal infoPage={infoPage} setInfoPage={setInfoPage}/>
              </>
            }
      </div>
    </div>
  );
};

ModalContent.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default ModalContent;
