import { DateTime } from "luxon";

import { mobileEnergyStoryTypes } from "../../../common/config";

export const seriesBuilder = demandData => {
  let series = demandData.map(commodityData => {
    return {
      name: mobileEnergyStoryTypes[commodityData.commodity].displayName,
      id: commodityData.commodity,
      color: mobileEnergyStoryTypes[commodityData.commodity].color,
      marker: {
        symbol: "circle"
      },
      data: commodityData.data.map(datum => [
        DateTime.fromISO(datum.Timestamp)
          .plus({ minutes: 1 })
          .minus({ months: 1 })
          .valueOf(),
        Math.round(datum.Value * 100) / 100
      ]),
      visible: true,
      zIndex: 3,
      yAxis: 0
    };
  });

  return series;
};
