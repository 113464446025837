import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function buildingReducer(state = initialState.building, action) {
  switch (action.type) {
    case types.LOAD_BUILDING_SUCCESS:
      return action.building;

    default:
      return state;
  }
}
