import React from "react";
import { Link } from "react-router-dom";
import DashboardMenu from "../../common/dashboardMenu/DashboardMenu";
import "./Header.css";
import CampusDescription from "./CampusDescription";
import arrowImage from "../../../images/arrow.svg";

const Header = () => {
  return (
    <div className="campus-header">
      <DashboardMenu />
      <div className="campus-header-wrap">
        <div className="campus-header-title">Total UC Davis Energy</div>
        <CampusDescription />
      </div>
      <Link to="/" className="building-header-map-link">
        <img
          src={arrowImage}
          alt="Back to campus map arrow."
          id="bounce-arrow-left"
        />
        Back to map
      </Link>
    </div>
  );
};

export default Header;
