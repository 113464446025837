import React from "react";

import "./MobileComingSoon.css";

import backgroundImage from "./images/background.jpg";
import phoneImage from "./images/phone.png";

import buildingTypesImage from "../main/welcomeModal/images/building-types.png";
import coolingHeatingImage from "../main/welcomeModal/images/cooling-heating.png";
import findWasteImage from "../main/welcomeModal/images/find-waste.png";

class MobileComingSoon extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="mobile-header">
          <div className="mobile-header-image">
            <img
              src={backgroundImage}
              alt="UC Davis at sunset, centered on a water tower"
            />
          </div>
          <h1>
            Welcome to UC Davis' <br />
            <b>Campus Energy Education Dashboard</b>!
          </h1>
          <h2>
            (or <b>CEED</b> for short.)
          </h2>
          <div className="phone-image">
            <img src={phoneImage} alt="Generic mobile phone" />
            <h3>
              Our mobile site is <br />
              <em>coming soon</em>
            </h3>
            <p>
              In the meantime, please <em>visit CEED on your desktop</em> to see
              how UC Davis uses energy!
            </p>
          </div>
        </div>
        <div className="what-can-you-do">
          <h2>What can you do on CEED?</h2>
          <ul>
            <li>
              <img src={coolingHeatingImage} alt="Heating and cooling icon" />
              <p>
                The campus runs differently than your home— see what{" "}
                <em>heats and cools</em> each building.
              </p>
            </li>
            <li>
              <img src={buildingTypesImage} alt="Building types icon" />
              <p>
                Find out how different <em>building types</em> use energy!
              </p>
            </li>
            <li>
              <img
                src={findWasteImage}
                alt="Graph icon"
                className="graph-icon"
              />
              <p>
                Dive into the energy graphs to <em>find energy waste</em> in
                your building!
              </p>
            </li>
          </ul>
        </div>
        <div className="we-all-play-a-part">
          <p>
            We all play a part in helping UC Davis reach carbon neutrality by
            2025. At the Energy Conservation Office we believe{" "}
            <b>the first step to saving energy is seeing how much you use.</b>
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default MobileComingSoon;
