import React from "react";

import { Select, Option } from "./react-a11y-select/src/index.js";
import "./react-a11y-select/src/styles.css";

import "./SidebarDropdown.css";

// redux actions, store
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as currentMapSelectionActions from "../../../../actions/currentMapSelectionActions";

let euiCircleSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 90 90"
    width="30"
    height="30"
    className="main-svg"
  >
    <circle r="41" cy="46" cx="41" fill="#EAEAEA" />
    <circle r="14" cy="46" cx="41" fill="#fff" />
    <circle r="9.5" cy="46" cx="41" fill="#D6D6D6" />
  </svg>
);

let buildingDotSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 90 90"
    width="30"
    height="30"
    className="partial-svg"
  >
    <circle r="9.5" cy="46" cx="41" fill="#D6D6D6" />
  </svg>
);

class SidebarDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMapSelection: "eui"
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.currentMapSelection !== nextProps.currentMapSelection) {
      this.setState(prevState => {
        return { currentMapSelection: nextProps.currentMapSelection };
      });
      this.forceUpdate();
    }
  }

  handleChange = selectedOption => {
    if (selectedOption) {
      this.props.actions.mapSelectionHandler(selectedOption);
    }
  };

  render() {
    return (
      <div className="sidebar-dropdown">
        <Select
          label="Energy Data Type"
          initialValue={this.state.currentMapSelection}
          onChange={this.handleChange}
          key={this.state.currentMapSelection}
        >
          <Option value="eui">
            <div>
              {euiCircleSvg}
              <div className="text main-text">Energy Use Intensity (EUI)</div>
            </div>
            <div>
              {buildingDotSvg}
              <div className="text partial-data-text">
                Building with partial data
              </div>
            </div>
          </Option>
          <Option value="annualUsage">
            <div className="main">
              {euiCircleSvg}
              <div className="text main-text">Annual Energy Use</div>
            </div>
            <div className="partial">
              {buildingDotSvg}
              <div className="text partial-data-text">
                Building with partial data
              </div>
            </div>
          </Option>
        </Select>
      </div>
    );
  }
}

SidebarDropdown.propTypes = {
  currentMapSelection: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    currentMapSelection: state.currentMapSelection
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(currentMapSelectionActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarDropdown);
