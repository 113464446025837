import React from "react";
import PropTypes from "prop-types";
import "./BuildingFilterSearch.css";

const BuildingFilterSearch = ({ onChangeHandler }) => {
  return (
    <div className="building-search-label">
      Select one or multiple buildings
      <div>
        <input
          autoComplete="off"
          type="text"
          name="buildingSearch"
          className="building-search"
          placeholder="Search Buildings"
          onChange={onChangeHandler}
        />
      </div>
    </div>
  );
};

BuildingFilterSearch.propTypes = {
  onChangeHandler: PropTypes.func.isRequired
};

export default BuildingFilterSearch;
