import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as rankingActions from "../../../../actions/rankingActions";
import * as markerClickActions from "../../../../actions/markerClickActions";
import PropTypes from "prop-types";

import BuildingRankRow from "./BuildingRankRow";
import arrowImage from "../../../../images/arrow.svg";
import "./Ranking.css";

class Ranking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapData: [],
      rankedBuildings: [
        {
          name: "",
          buildingType: "lab",
          eui: 0,
          annual: 0
        }
      ],
      maxPropertyValue: 1,
      currentMapSelection: "eui",
      ranking: {}
    };
  }

  updateRanking(props) {
    this.setRankedBuildings(props.mapData, props.currentMapSelection);
    this.setState(prevState => {
      return {
        ranking: props.ranking,
        currentMapSelection: props.currentMapSelection
      };
    });
  }

  componentWillMount() {
    this.updateRanking(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateRanking(nextProps);
  }

  setRankedBuildings(buildingData, mapSelection) {
    let rankedFull = [],
      maxValue = 1;
    for (let i = 0; i < buildingData.length; i++)
      if (
        buildingData[i].buildingTypeVisible &&
        buildingData[i].valueVisible &&
        buildingData[i][mapSelection] > 0
      )
        rankedFull.push(buildingData[i]);
    if (rankedFull.length > 1)
      rankedFull.sort(function(a, b) {
        return b[mapSelection] - a[mapSelection];
      });
    if (
      this.props.buildingLimit !== undefined &&
      this.props.buildingLimit < rankedFull.length
    )
      rankedFull = this.applyLimitToArray(rankedFull);
    if (rankedFull.length !== 0) maxValue = rankedFull[0][mapSelection];
    this.setState(prevState => {
      return { rankedBuildings: rankedFull, maxPropertyValue: maxValue };
    });
  }

  applyLimitToArray(buildingList) {
    let rankedFinal = [];
    for (let i = 0; i < this.props.buildingLimit; i++)
      rankedFinal.push(buildingList[i]);
    return rankedFinal;
  }

  mapSelectionHeading(mapSelection) {
    if (mapSelection === "eui")
      return (
        <div className="building-ranking-column2 header">
          Energy Use Intensity{" "}
          <span>
            (kBtu/ft
            <sup>2</sup>)
          </span>
        </div>
      );
    return (
      <div className="building-ranking-column2 header">
        Annual Energy Use <span>(kBtu/yr)</span>
      </div>
    );
  }

  onClickRanking = clickedBuilding => {
    if (clickedBuilding.annualCost || !clickedBuilding.euiReady) {
      this.props.markerClickActions.markerClickHaveCost(true, clickedBuilding);
    } else {
      this.props.markerClickActions.markerClickNeedCost(true, clickedBuilding);
    }
    this.props.markerClickActions.changeMapLocation(clickedBuilding.center);
  };

  setRankingButton = status => {
    if (!status) return null;
    return (
      <div
        className="building-ranking-full-link"
        onClick={this.props.actions.openFullRankingSidebar}
      >
        Expand Building List
        <img
          src={arrowImage}
          id="bounce-arrow-down"
          alt="Expand Building List arrow icon"
        />
      </div>
    );
  };

  render() {
    return (
      <div className="building-ranking">
        <div className="building-ranking-group">
          <div className="building-ranking-row header">
            <div className="building-ranking-column1 header">Building</div>
            {this.mapSelectionHeading(this.props.currentMapSelection)}
          </div>
          {this.state.rankedBuildings.map((buildingObject, i) => (
            <BuildingRankRow
              key={i}
              name={buildingObject.name}
              buildingType={buildingObject.buildingType}
              mapSelection={this.state.currentMapSelection}
              currentValue={buildingObject[this.state.currentMapSelection]}
              maxValue={this.state.maxPropertyValue}
              onClick={() => this.onClickRanking(buildingObject)}
            />
          ))}
        </div>
        {this.setRankingButton(this.state.ranking.rankingButton)}
      </div>
    );
  }
}

Ranking.propTypes = {
  mapData: PropTypes.array.isRequired,
  buildingLimit: PropTypes.number,
  currentMapSelection: PropTypes.string.isRequired,
  ranking: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    mapData: state.mapData,
    ranking: state.ranking,
    currentMapSelection: state.currentMapSelection
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(rankingActions, dispatch),
    markerClickActions: bindActionCreators(markerClickActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ranking);
