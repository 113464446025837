import React from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import MobileCampusDescription from "./MobileCampusDescription";
import Navigation from "../../../common/mobile/navigation/Navigation";
import facilitiesLogo from "../../../common/mobile/images/facilities-logo-white.svg";

import "./MobileHeader.css";

class MobileHeader extends React.Component {
  render() {
    return (
      <div>
        <Navigation location={this.props.location.pathname.substr(1)}>
          <img src={facilitiesLogo} alt="UC Davis Facilities logo" />
        </Navigation>
        <div className="mobile-campus-header">
          <div className="campus-header-title">Total Campus Energy</div>
          <MobileCampusDescription />
        </div>
      </div>
    );
  }
}
MobileHeader.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(MobileHeader);
