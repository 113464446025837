import React, { Component } from "react";
import Rheostat from "rheostat";

class TimeSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderValues: [6, 22] //default values
    };
  }

  updateValue = e => {
    this.setState({
      sliderValues: e.values
    });
    this.props.timeSliderVal(this.state.sliderValues);
  };

  displayTime(time) {
    if (time < 12) {
      if (time === 0) {
        time = 12;
      }
      return time + " am";
    } else {
      time = time - 12;
      if (time === 0) {
        time = 12;
      }
      return time + " pm";
    }
  }

  render() {
    const snaps = Array.from(Array(24).keys());
    return (
      <div className="slider">
        <Rheostat
          min={0}
          max={23}
          values={this.state.sliderValues}
          snap
          snapPoints={snaps}
          onValuesUpdated={this.updateValue}
        />
        <div className="slider-values">
          <span>{this.displayTime(this.state.sliderValues[0])}</span>
          <span>{this.displayTime(this.state.sliderValues[1])}</span>
        </div>
      </div>
    );
  }
}

export default TimeSlider;
