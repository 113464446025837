import React from "react";

import facilitiesColorLogo from "../images/facilities-logo-color.png";
import facilitiesWhiteLogo from "../images/facilities-logo-white.svg";

import "./Footer.css";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      popup: false
    };
    this.setFixedFooter = this.setFixedFooter.bind(this);
  }

  componentDidMount() {
    if (this.props.fixed)
      window.addEventListener("scroll", this.setFixedFooter);
  }

  componentWillUnmount() {
    if (this.props.fixed)
      window.removeEventListener("scroll", this.setFixedFooter);
  }

  setDisplay() {
    this.setState({ display: !this.state.display });
  }

  setFixedFooter(event) {
    if (window.scrollY > 300 && !this.state.popup)
      this.setState({ popup: true });
    else if (window.scrollY <= 300 && this.state.popup)
      this.setState({ popup: false });
  }

  setFooterClass() {
    let footerClass = "ceed-footer " + this.props.mode;
    if (this.props.fixed) footerClass += " fixed";
    if (this.props.fixed && this.state.popup) footerClass += " pop";
    if (this.props.fixed && this.state.display) footerClass += " display";
    return footerClass;
  }

  render() {
    return (
      <div className={this.setFooterClass()} onClick={e => this.setDisplay()}>
        <div className="ceed-footer-logo">
          {this.props.fixed && (
            <img
              src={
                this.props.mode === "light"
                  ? facilitiesColorLogo
                  : facilitiesWhiteLogo
              }
              alt="UC Davis Facilities logo"
            />
          )}
          {!this.props.fixed && (
            <a
              href="https://facilities.ucdavis.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={
                  this.props.mode === "light"
                    ? facilitiesColorLogo
                    : facilitiesWhiteLogo
                }
                alt="UC Davis Facilities logo"
              />
            </a>
          )}
        </div>
        <div className="ceed-footer-slogan">
          Our goal is to improve campus comfort and energy efficiency.
        </div>
        <div className="ceed-footer-sites">
          <div className="ceed-footer-site-header">Our other sites:</div>
          <div className="ceed-footer-site">
            <a
              href="https://trimthewaste.ucdavis.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trim the Waste
            </a>
          </div>
          <div className="ceed-footer-site">
            <a
              href="https://thermoostat.ucdavis.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              TherMOOstat
            </a>
          </div>
          <div className="ceed-footer-site">
            <a
              href="https://comfort.ucdavis.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Campus Comfort 101
            </a>
          </div>
          {this.props.fixed && (
            <div className="ceed-footer-site">
              <a
                href="https://facilities.ucdavis.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                UC Davis Facilities
              </a>
            </div>
          )}
        </div>
        <div className="ceed-footer-newsletter">
          Get all of campus' energy updates in our monthly newsletter:
          <a
            href="https://mailchi.mp/a5c02ecb6b63/whatswatt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="ceed-footer-newsletter-button">
              Sign up for the Newsletter
            </div>
          </a>
        </div>
        <div className="ceed-footer-contact">
          Contact us at:{" "}
          <a href="mailto:energyfeedback@ucdavis.edu">
            energyfeedback@ucdavis.edu
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
