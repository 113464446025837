import React, { Component } from "react";
import ReactHighcharts from "react-highcharts";
import PropTypes from "prop-types";
import * as SeasonChartOption from "./SeasonChartOption";

class SeasonChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      chartConfig: SeasonChartOption.initializeGraph(
        this.props.commodity,
        this.props.data,
        this.props.yMax
      )
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        loaded: true,
        chartConfig: SeasonChartOption.initializeGraph(
          this.props.commodity,
          this.props.data,
          this.props.yMax
        )
      });
    }
  }

  render() {
    return (
      <div>
        <ReactHighcharts config={this.state.chartConfig} ref="chart" />
      </div>
    );
  }
}

SeasonChart.propTypes = {
  commodity: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
};

export default SeasonChart;
