import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export function campusReducer(state = initialState.campus, action) {
  switch (action.type) {
    case types.LOAD_CAMPUS_SUCCESS:
      let newCampusState = Object.assign({}, state);
      for (let item in action.campus)
        newCampusState[item] = action.campus[item];
      return newCampusState;
    default:
      return state;
  }
}

export function aceReducer(state = initialState.aceUsage, action) {
  switch (action.type) {
    case types.LOAD_ACE_SUCCESS:
      let newAceState = Object.assign({}, state);
      return newAceState;
    default:
      return state;
  }
}
