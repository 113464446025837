import React from "react";
import PropTypes from "prop-types";

import { fySort } from "../chcpUtilities";
import TotalEnergy from "./totalEnergy/TotalEnergy";
import "./Usage.css";

class Usage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalEnergy: [],
      metrics: []
    };
  }

  componentWillMount() {
    if (this.props.usageData.length > 0) this.parseData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.usageData !== prevProps.usageData) this.parseData();
  }

  calculatePerAmount(total, item) {
    const convertMMBTUtoBTU = 1000000;
    return Math.round((total / item) * convertMMBTUtoBTU);
  }

  async parseData() {
    let tempEnergy = [],
      tempMetrics = [],
      sortedFiscalYears = await fySort(this.props.usageData);
    for (let i = 0; i < sortedFiscalYears.length; i++) {
      let item = sortedFiscalYears[i];
      let tempEnergyObj = {
          fy: this.sliceFiscalYear(item.fy),
          gas: Math.round(item.gasMMBTU),
          primateGas: Math.round(item.primateGasMMBTU),
          buildingGas: Math.round(item.buildingGasMMBTU),
          grid: Math.round(item.gridMMBTU),
          carbonNeutral: Math.round(item.carbonNeutralMMBTU),
          solarFarm: Math.round(item.solarFarmMMBTU),
          rooftopSolar: Math.round(item.rooftopSolarMMBTU),
          renewable: Math.round(item.renewableMMBTU),
          totalEnergy: Math.round(
            item.gasMMBTU +
              item.gridMMBTU +
              item.carbonNeutralMMBTU +
              item.renewableMMBTU +
              item.primateGasMMBTU +
              item.buildingGasMMBTU +
              item.solarFarmMMBTU +
              item.rooftopSolarMMBTU +
              item.renewableMMBTU
          ),
          gasCost: Math.round(item.gasCost),
          electricityCost: Math.round(item.electricityCost),
          totalCost: Math.round(item.gasCost + item.electricityCost)
        },
        tempMetricsObj = {
          fy: this.sliceFiscalYear(item.fy),
          students: item.students,
          sqFt: Math.round(item.campusSqFt),
          totalEnergy: Math.round(item.totalMMBTU),
          energyPerFt: this.calculatePerAmount(
            item.totalMMBTU,
            item.campusSqFt
          ),
          energyPerStudent: this.calculatePerAmount(
            item.totalMMBTU,
            item.students
          )
        };
      tempEnergy.push(tempEnergyObj);
      tempMetrics.push(tempMetricsObj);
    }
    this.setState({
      totalEnergy: tempEnergy,
      metrics: tempMetrics
    });
  }

  sliceFiscalYear(year) {
    return year.slice(2);
  }

  render() {
    return (
      <div className="campus-usage">
        <TotalEnergy energyData={this.state.totalEnergy} />
      </div>
    );
  }
}

Usage.propTypes = {
  usageData: PropTypes.array.isRequired
};

export default Usage;
