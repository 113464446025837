import React from "react";
import PropTypes from "prop-types";
import { commodityProperties } from "../../common/utilities";
import "./BuildingFilterGridRow.css";

const getCommodities = (
  commodities,
  buildingId,
  onClick,
  selectedCommodities,
  firstRow
) => {
  return (
    <div className="building-selector-grid-row-commodity-selection">
      {commodities.map((commodity, index) => {
        let commodityId = commodity.split(" ").join("") + "-" + buildingId; // Faster than regex. Negligible for this use case.
        return (
          <label
            key={commodityId + "-" + index}
            className="building-selector-building-types-label"
            htmlFor={commodityId}
          >
            {index === 3 && <br />}
            <input
              type="checkbox"
              id={commodityId}
              checked={selectedCommodities.includes(commodity)}
              onChange={event => onClick(buildingId, commodity)}
            />
            <span className="building-selector-options-building-types-label checkbox">
              <img
                src={commodityProperties[commodity].image}
                alt={commodityProperties[commodity].alt}
              />
              <span
                className={
                  "building-types-label-tooltip" + (firstRow ? " right" : "")
                }
              >
                {commodityProperties[commodity].displayName}
              </span>
            </span>
          </label>
        );
      })}
    </div>
  );
};

const getClassNamesForRow = selectedBuilding => {
  let classNames = "building-selector-grid-row";
  if (selectedBuilding.length > 0) {
    classNames += " building-selector-grid-row-selected";
  }
  return classNames;
};

const getEui = building => {
  if (building.euiStatus !== "active")
    return building.euiStatus === "comingSoon" ? "Coming Soon" : "Unavailable";
  return building.eui + " kBtu/ft²";
};

const BuildingFilterGridRow = ({
  onClickHandler,
  building,
  selectedBuilding,
  firstRow
}) => {
  return (
    <div className={getClassNamesForRow(selectedBuilding)}>
      <div className="building-selector-grid-row-content col1">
        {building.name}
      </div>
      <div className="building-selector-grid-row-content col2">
        <div className="building-selector-grid-row-text building-selector-options-building-types">
          {getCommodities(
            building.commodities,
            building.name,
            onClickHandler,
            selectedBuilding,
            firstRow
          )}
        </div>
      </div>
      <div className="building-selector-grid-row-content col3">
        {building.buildingType}
      </div>
      <div className="building-selector-grid-row-content col4">
        {getEui(building)}
      </div>
    </div>
  );
};

BuildingFilterGridRow.propTypes = {
  selectedBuilding: PropTypes.array.isRequired,
  building: PropTypes.object.isRequired,
  onClickHandler: PropTypes.func.isRequired
};

export default BuildingFilterGridRow;
