import * as types from "./actionTypes";

export function mapSelectionChange(currentMapSelection) {
  return {
    type: types.MAP_SELECTION_CHANGE,
    currentMapSelection
  };
}

export const mapSelectionHandler = selection => {
  return function(dispatch) {
    dispatch(mapSelectionChange(selection));
  };
};
