/**
 * This component builds out the Comparison 'page' on an individual building.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import { commodityTypes } from "../../../common/config";
import "./Comparison.css";
import CompareChart from "./compareChart/CompareChart";
import CompareBuildingTypes from "./compareBuildingTypes/CompareBuildingTypes";

const HEADER_HEIGHT = 410;

class Comparison extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parsedBuildings: [],
      compareBuilding: {
        id: "",
        name: "",
        annualCost: 0,
        buildingType: "lab",
        totalSqft: 0,
        eui: {
          total: 0,
          electricity: 0,
          steam: 0,
          chilledWater: 0
        }
      }
    };
  }

  componentDidMount() {
    if (window.scrollY > HEADER_HEIGHT) window.scrollTo(0, 410);
    if (this.checkEuiReady(this.props.allBuildingEuis))
      this.parseBuildings(this.props.allBuildingsData);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.allBuildingEuis !== prevProps.allBuildingEuis &&
      this.checkEuiReady(this.props.allBuildingEuis)
    )
      this.parseBuildings(this.props.allBuildingsData);
  }

  checkEuiReady(euis) {
    let answer = false;
    for (let eui in euis) {
      if (euis[eui].length > 0) answer = true;
    }
    return answer;
  }

  getBuildingDetails(buildingShortName) {
    let buildingDetails = {
        id: buildingShortName,
        name: "",
        buildingType: "",
        totalSqft: 0
      },
      compare = this.props.allBuildingsData;
    for (let i = 0; i < compare.length; i++) {
      if (compare[i].id === buildingShortName) {
        buildingDetails.name = compare[i].name;
        buildingDetails.buildingType = compare[i].buildingType;
        buildingDetails.totalSqft = compare[i].totalSqft;
      }
    }
    return buildingDetails;
  }

  calculateEuiTotal(euiValues) {
    let total = 0;
    for (let eui in euiValues) total += euiValues[eui];
    return total;
  }

  generateAnnualCost(sqFt, euis) {
    let total = 0;
    for (let eui in euis) total += commodityTypes[eui].cost * euis[eui];
    return total;
  }

  getEuiValues(buildingShortName) {
    let euiObject = {
      total: 0,
      electricity: 0,
      steam: 0,
      chilledWater: 0
    };
    let checkObject = this.props.allBuildingEuis;
    for (let commodity in checkObject) {
      for (let i = 0; i < checkObject[commodity].length; i++) {
        if (
          checkObject[commodity][i] !== null &&
          checkObject[commodity][i].building === buildingShortName
        )
          euiObject[commodity] = Math.round(checkObject[commodity][i].eui);
      }
    }
    euiObject.total = this.calculateEuiTotal(euiObject);
    return euiObject;
  }

  getUsageValues(building) {
    return {
      annualUsage: {
        electricity:
          (building.eui.electricity * building.totalSqft) /
          commodityTypes.electricity.kBtuConversion,
        steam:
          (building.eui.steam * building.totalSqft) /
          commodityTypes.steam.kBtuConversion,
        chilledWater:
          (building.eui.chilledWater * building.totalSqft) /
          commodityTypes.chilledWater.kBtuConversion
      }
    };
  }

  async parseBuildings(allBuildings) {
    let comparablesArray = [];
    for (let i = 0; i < allBuildings.length; i++) {
      let buildingStats = await this.setBuildingComparison(allBuildings[i].id);
      if (buildingStats.id === this.props.building.id) {
        this.setState({ compareBuilding: buildingStats });
      } else comparablesArray.push(buildingStats);
    }
    this.setState({ parsedBuildings: comparablesArray });
  }

  setBuildingComparison(buildingShortName) {
    let euis = { eui: this.getEuiValues(buildingShortName) },
      details = this.getBuildingDetails(buildingShortName),
      combinedObject = { ...euis, ...details };
    combinedObject = {
      ...combinedObject,
      ...this.getUsageValues(combinedObject)
    };
    combinedObject.annualCost = this.generateAnnualCost(
      combinedObject.totalSqft,
      combinedObject.annualUsage
    );
    return combinedObject;
  }

  render() {
    return (
      <div className="building-comparison">
        <CompareChart
          building={this.state.compareBuilding}
          comparables={this.state.parsedBuildings}
        />
        <CompareBuildingTypes
          buildings={this.props.allBuildingsData}
          buidingType={this.state.compareBuilding.buildingType}
        />
      </div>
    );
  }
}

Comparison.propTypes = {
  building: PropTypes.object.isRequired,
  allBuildingEuis: PropTypes.object.isRequired,
  allBuildingsData: PropTypes.array.isRequired
};

export default Comparison;
