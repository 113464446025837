import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import WaterStory from "./WaterStory";
import * as waterUsageChartOptions from "./WaterUsageChartOptions";
import WaterUsageGraph from "./WaterUsageGraph";
import downloadHoverIcon from "../../../common/images/download_csv.png";
import downloadIcon from "../../../common/images/download_csv_hover.png";
import WaterUsageCsv from "./WaterUsageCsv";
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more.src.js";
import HighchartsReact from 'highcharts-react-official';
import HighchartsSankey from 'highcharts/modules/sankey';

HighchartsMore(Highcharts);
HighchartsSankey(Highcharts);

let waterBarChart = null;

const SOURCES = [
  {
    value: "domestic",
    displayName: "Dometic Water",
    color: "#336CC8"
  },
  {
    value: "utility",
    displayName: "Utility Water",
    color: "#66D1EE"
  },
  {
    value: "agriculture",
    displayName: "Agriculture Water",
    color: "#194D6D"
  },
  {
    value: "fisheries",
    displayName: "Fisheries Water",
    color: "#81FCF2"
  }
]
// sets all fonts for Highcharts diagrams to be Proxima Nova
Highcharts.setOptions({
  chart: {
      style: {
          fontFamily: '24px Proxima Nova'
      }
  }
});

const getOptions = (type) => ({
  chart: {
    type,
    width: 900,
    height: 900
  },
  title: {
    text: 'UC Davis 2013 Water Use',
    
  },
  accessibility: {
    pointFormat: '{index}. {point.from} to {point.to}, {point.weight}',
  },
  series: [{
    keys: ['from', 'to', 'weight'],
    data: [
      ['Solano Project Surface Water', 'Field Teaching & Research', 495],
      ['Solano Project Surface Water', 'CABA', 15],
      ['Shallow Aquifer Wells', 'Field Teaching & Research', 323],
      ['Shallow Aquifer Wells', 'CABA', 125],
      ['Intermediate Aquifer Wells', 'CABA', 227],
      ['Intermediate Aquifer Wells', 'Ac.Surge & Watershed', 26],
      ['Intermediate Aquifer Wells', 'Landscape Irrigation', 336],
      ['Deep Aquifer Wells', 'Landscape Irrigation', 8],
      ['Deep Aquifer Wells', 'Central Plants', 110],
      ['Deep Aquifer Wells', 'Buildings', 581],
      ['Deep Aquifer Wells', 'Fire Hydrants', 2],
      ['Deep Aquifer Wells', 'Leaks', 14],
    ],
    name: 'UC Davis 2013 Water Use',
    tooltip: {
      pointFormat: '{point.fromNode.name} \u2192 {point.toNode.name}: <b>{point.weight}</b> Mgal<br/>',
    },
    colors: ['#8198B2','#333333', '#333333', '#4F7094', '#1D4776', '#333333', '#333333', '#022851','#333333', '#333333', '#333333', '#333333'],
  }]
});

function WaterUsage(props) {
  const [checkBoxes, setCheckBoxes] = useState({
    domestic: true,
    utility: true,
    agriculture: true,
    fisheries: true
  })
  const [chartData, setChartData] = useState({
    xAxis : [],
    data: []
  })
  const [csvIcon, setCsvIcon] = useState(downloadHoverIcon)

  const updateGraphOptions = (event) => {
    let selection = event.target.id;
    let newCheckBoxes = Object.assign({}, checkBoxes);
    newCheckBoxes[selection] = !newCheckBoxes[selection];
    setCheckBoxes(newCheckBoxes)
    updateGraphView(selection);
  }

  const updateGraphView = (checkBox) => {
    if (checkBox === undefined) {
      waterBarChart.destroy();
      waterBarChart = Highcharts.StockChart(
        "water-usage-chart",
        waterUsageChartOptions.initializeEnergyGraph(
          chartData.xAxis,
          chartData.data
        )
      );
    } else {
      for (let i = 0; i < chartData.data.length; i++)
        if (chartData.data[i].id === checkBox)
          chartData.data[i].visible = !chartData.data[i].visible;
      waterBarChart.update({ series: chartData.data });
      setChartData(chartData);
    }
  }

  function buildWaterChart() {
    if(props.waterUsageData.length > 0) {
      let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        water = [],
        xAxis = []
      for (let i = 0; i < SOURCES.length; i++)
        water.push(
          waterUsageChartOptions.energySource(
            SOURCES[i].value,
            SOURCES[i].displayName,
            SOURCES[i].color,
            checkBoxes[SOURCES[i].value]
          )
        );
      for (let x = 0; x < props.waterUsageData.length; x++) {
        let data = props.waterUsageData[x].monthlyUsage;
        let year = props.waterUsageData[x].year;
        
        for (let y = 0; y < water.length; y++){
          let temp = months.reduce((acc, val)=> {
            data[val] && acc.push(data[val][water[y].id])
            return acc
          }, [])
          if(water[y].data) {
            water[y].data.push(...temp)
          } else {
            water[y].data = temp;
          } 
        }
        for (let z = 0; z < Object.keys(data).length; z++) {
          if (data[months[z]]) z == 0 ? xAxis.push(months[z] + " " + year) : xAxis.push(months[z])
        }
      }
      let newChartData = Object.assign({}, chartData);
      newChartData.data = water;
      newChartData.xAxis = xAxis;
      setChartData(newChartData);
      waterBarChart = Highcharts.StockChart(
        "water-usage-chart",
        waterUsageChartOptions.initializeEnergyGraph(xAxis, water, xAxis.length)
      );
    }
  }

  useEffect(() => {
    if(props.waterUsageData) {
      buildWaterChart();
    }    
  }, [props.waterUsageData])
  
  return (
    <div>
      <div className="water-usage water-card">
        <div className="water-headline">Historical Usage</div>
        <div className="water-graph">
            <div className="water-chart">
              <div id="water-usage-chart" />
            </div>
            <div className="water-chart-selection">
              <WaterUsageGraph
                onClick={updateGraphOptions}
                options={checkBoxes}
                sources={SOURCES}
              />
              <div
                className="csv-div"
                onMouseOver={() => {
                  setCsvIcon(downloadIcon);
                }}
                onMouseOut={() => {
                  setCsvIcon(downloadHoverIcon);
                }}
              >
                <WaterUsageCsv
                  data={props.waterUsageData}
                  sources={SOURCES}
                  icon={csvIcon}
                />
              </div>
            </div>
          </div>
        <WaterStory />
      </div>
      <div className="water-card" style={{marginTop: `40px`}}>
        <HighchartsReact highcharts={Highcharts} options={getOptions('sankey')} />
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    waterUsageData: state.waterUsage.waterUsageData
  }
}

export default connect(mapStateToProps)(WaterUsage)
