import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export function ewcRanking(state = initialState.ewcRanking, action) {
  switch (action.type) {
    case types.LOAD_EWC_RANKING_SUCCESS:
      return action.ewcRanking;

    default:
      return state;
  }
}

export function ewcWeekly(state = initialState.ewcWeekly, action) {
  switch (action.type) {
    case types.LOAD_EWC_WEEKLY_SUCCESS:
      return action.ewcWeekly;

    default:
      return state;
  }
}
